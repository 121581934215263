import React, { Component } from 'react';
//import $ from "jquery";
import { connect } from 'react-redux';

class  Footer extends Component {

    constructor(props){
        super(props)

    }

    render() {
        return(
            <footer>
                <section className="footerBloc1">
                    <div className="container">
                        <div className="footerBloc1_1">
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <p><a href="/">Accueil</a></p>
                                <p><a href="/liste-annonce">Place disponibles</a></p>
                                <p><a href="/contactez-nous">Contact</a></p>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <p><a href="/place-maison">Je cherche une place</a></p>
                                <p><a href="/place-etablissement">J'ai une place disponible</a></p>
                                <p><a href="/alerte-dispo">Je crée mon Alerte-dispo</a></p>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <p><a href="/mentions-legales">Mentions légales</a></p>
                                <p><a href="/conditions-generales">Conditions générales d'utilisation</a></p>
                                <p><a href="/charte-utilisation">Charte d'utilisation</a></p>
                                <p><a href="/donnees-personnelles">Données personnelles</a></p>
                            </div>
                        </div>
                        <div className="footerBloc1_2">
                            <a href="https://www.cnil.fr/">
                                <img className="img-responsive" src="/img/home/footer/logo-cnil.jpg"
                                     alt="logo-cnil"/>
                            </a>
                            <p><a href="/register/">Inscription</a> | <a href="/login">Connexion</a></p>
                        </div>
                    </div>
                    <p>Tous droits réservés © Place maison de retraite.fr 2017</p>
                </section>
            </footer>
        );
    }
}

function mapStateToProps(state){
    return {

    }
}

export default connect(mapStateToProps, {  })( Footer)
