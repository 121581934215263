import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollUpButton from "react-scroll-up-button";
import moment from 'moment';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import {userActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import {Confirm} from "react-st-modal";

class Professionnel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                id          : 0,
                username    : '',
                password    : '',
                password2   : '',
                email       : ''
            },
            filter: {
              nom: "",
              email: ""
            },
            updateUser: false,
            submitted: false,
            deleted: []
        };

        this.deleteUser         = this.deleteUser.bind(this);
        this.switchToUpdate     = this.switchToUpdate.bind(this);
        this.handleChangeUser   = this.handleChangeUser.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter       = this.renderFilter.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(userActions.getAllPros())
    }

    async deleteUser(e) {
        e.preventDefault();

        const { id } = e.target;

        let response = await Confirm("Voulez-vous vraiment supprimer ce compte Pro?", "", "Oui", "Fermer");

        if (response === true) {
            this.props.dispatch(userActions.delUser(id));
            this.props.dispatch(userActions.getAllPros());
            this.setState({deleted: [...this.state.deleted, id]})
        }
    }

    switchToUpdate(e) {
        e.preventDefault();

        const { id } = e.target;
        const { updateUser, user } = this.state;

        if ( !updateUser ) {
            this.setState({
                user: {
                    ...user,
                    id: this.props.users.users.users[id].id,
                    email: this.props.users.users.users[id].email,
                    username: this.props.users.users.users[id].username,
                }
            })
        }

        this.setState({updateUser: !updateUser})
    }

    handleChangeUser(event) {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            },
        })
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;
        const {filter} = this.state;

        this.setState({
            filter: {
                ...filter,
                [name]: value
            }
        })
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;

        const nom           = filter.nom    !== '' ? item.username.toLowerCase().includes(filter.nom.toLocaleLowerCase()) :  true;
        const email         = filter.email  !== '' ? item.email.toLowerCase().includes(filter.email.toLocaleLowerCase()) :  true;
        const isDeleted     = deleted.includes(item.id);
        return (nom && email && !isDeleted)
    }

    handleSubmit(e) {
        e.preventDefault();


        this.setState({ submitted: true });
        const { user, updateUser } = this.state;
        const { dispatch } = this.props;

        if (lib.isValidUsername(lib.protectEntry(user.username)) && lib.isValidEmail(lib.protectEntry(user.email))) {
            dispatch(userActions.adminUpdateUser(user, "personne"));
            this.props.dispatch(userActions.getAllPros());
            this.setState({updateUser: !updateUser});
        } else {
            console.log(" NOT triggered condition ")
            console.log("is valid user", lib.isValidUsername(lib.protectEntry(user.username)))
            console.log("is valid email", lib.isValidPassword(lib.protectEntry(user.email)))
        }
    }

    render(){
        const { updating  } = this.props;
        const { user, updateUser, submitted, filter } = this.state;

        return  (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        updateUser ?
                            <section className="inscriptionStep1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/assistante/">Pros</a> &gt; les informations</p>
                                    <h1>Les informations</h1>
                                    <form name="form" onSubmit={this.handleSubmit}>
                                        <div className="encadreForm col-xs-12">
                                            <div className="question">
                                                <p>Modifiez les identifiants de connexion</p>
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                                                <label>Identifiant </label>
                                                <input type="text" className="form-control" id="adminbundle_user_username" name="username" placeholder={user.username}
                                                       required="required" maxLength="180" pattern=".{2,}" value={user.username} onChange={this.handleChangeUser}/>
                                                {submitted && !lib.isValidUsername(user.username) &&
                                                <div className="help-block">Mauvais format d'identifiant</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !user.email ? ' has-error' : '')}>
                                                <label>Email </label>
                                                <input type="email" id="adminbundle_user_email" className="form-control" name="email"
                                                       required="required" maxLength="180" pattern=".{2,}" placeholder={user.email}
                                                       value={user.email} onChange={this.handleChangeUser}/>
                                                {submitted && !lib.isValidEmail(user.email) &&
                                                <div className="help-block">Mauvais format d'email</div>
                                                }
                                            </div>
                                            <div className="question">
                                                <p>Modifiez le mot de passe (à remplir uniquement si vous changez de mot de
                                                    passe)</p>
                                            </div>

                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidUpdatePassword(user.password) ? ' has-error' : '')}>
                                                <label htmlFor="userPassword">Mot de passe </label>
                                                <input type="password" id="adminbundle_user_plainPassword_first"
                                                       name="password" className="form-control" onChange={this.handleChangeUser}/>
                                                {submitted && !lib.isValidUpdatePassword(user.password) &&
                                                <div className="help-block">Mauvais format de Mot de mot de passe</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.passwordMatch(user.password, user.password2) ? ' has-error' : '')}>
                                                <label htmlFor="userPassword">Retapez votre mot de passe </label>
                                                <input type="password" id="adminbundle_user_plainPassword_second"
                                                       name="password2" className="form-control" onChange={this.handleChangeUser}/>
                                                {submitted && !lib.passwordMatch(user.password, user.password2) &&
                                                <div className="help-block">La confirmation doit correspondre au mot de passe</div>
                                                }
                                            </div>
                                            <div className="bouton annuler" style={{float: "left"}}>
                                                <button onClick={this.switchToUpdate}>
                                                    Annuler
                                                </button>
                                            </div>
                                            <div >
                                                <div className="bouton submit">
                                                    <input type="submit" name="envoyer" id="valideEtablissement" value="Modifer les informations"/>
                                                    {updating &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </section>
                            :
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; Pros</p>
                                    <h1><span>Pros</span></h1>
                                    <br/>
                                    <div className="filtres col-xs-12">
                                        <p>Filtrer par</p>
                                        <hr/>
                                        <input type="text" name="nom" value={filter.nom} onChange={this.handleChangeFilter} placeholder="recherche par nom d'utilisateur" autoComplete="off"/>
                                        <input type="text" name="email" value={filter.email} onChange={this.handleChangeFilter} placeholder="recherche par email d'utilisateur" autoComplete="off"/>
                                    </div>
                                    <br/>
                                    {
                                        this.props.users.users ?
                                        <h1>Nombre de Pros: {this.props.users.users.users.length}</h1> : null
                                    }
                                    <br/>
                                    <div id="listeDemande">
                                        {!this.props.users.users ? <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                                            this.props.users.users.users.filter(item => this.renderFilter(item)).map((item, index) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                    <div className="singleDemande" style={{minHeight: '250px'}}>
                                                        <p>dernière connexion: <strong>{moment(item.last_login).format("DD/MM/YYYY HH:mm")}</strong></p>
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>{item.username}</p>
                                                            </div>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-at" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word'}}>Email
                                                                : <strong>{item.email}</strong></p>
                                                        </div>

                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.switchToUpdate} id={index}>Modifier</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.deleteUser} id={item.id}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state){
    const { users } = state;
    const { updating } = state.setUpdate;

    return  {
        users,
        updating
    }
}

export default connect(mapStateToProps)(Professionnel)
