import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Confirm} from "react-st-modal";

import moment from "moment";

import { contactActions } from "../../../../_actions";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {withRouter} from "react-router";


class Admin_Messages extends Component {
    constructor(props){
        super(props)


        this.state = {
            user: (localStorage.getItem('user') ? this.props.user : false),
            list: []
        };

        this.deleteMessage = this.deleteMessage.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(contactActions.getMessages(this.props.history));

    }

    async deleteMessage(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        const { value } = e.target;

        var response = await Confirm("Voulez-vous vraiment supprimer ce Message?", "", "Oui", "Fermer");

        if (response === true) {
            dispatch(contactActions.delMessage(value, this.props.history))
            this.props.dispatch(contactActions.getMessages(this.props.history));
        }

    }

    render() {
        const { alert } = this.props;
        return (

            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !(this.props.list) ? <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> :
                    <main>
                        <section className="listeDemandeBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; Mes messages</p>
                                <h1>Mes <span>messages</span></h1>
                                <br/>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        null
                                }
                                <br/>
                                <div id="listeDemande">
                                    <div>
                                        { this.props.list.list.map((item) =>
                                        <div className="singleDemande " key={item.id}>
                                            <div className="col-xs-12">
                                                <div className="question">
                                                    <p>Message de : {item.nom} {item.prenom}</p>
                                                </div>
                                            </div>
                                            <div className="visualisationInfo col-xs-12">
                                                <i className="fa fa-clock-o" aria-hidden="true"> </i>
                                                <p>
                                                    Publié le : <strong>{moment(item.created_at).format("DD/MM/YYYY")}, </strong>
                                                    à : <strong>{moment(item.created_at).format("HH:mm")}, </strong>
                                                </p>
                                            </div>
                                            <div className="visualisationInfo col-xs-12">
                                                <img className="img-responsive"
                                                     src="/img/mails/telephone.png"
                                                     alt="icone-sexe"/>
                                                <p>Téléphone : <strong>{item.telephone}</strong></p>
                                            </div>
                                            <div className="visualisationInfo col-xs-12">
                                                <img className="img-responsive"
                                                     src="/img/mails/mail.png"
                                                     alt="icone-type-etablissement"/>
                                                <p>E-mail : <strong>{item.email}</strong>
                                                </p>
                                            </div>
                                            {item.entite &&
                                            <div className="visualisationInfo col-xs-12">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-infos.png"
                                                     alt="icone-type-etablissement"/>
                                                <p>Entité : <strong>{item.entite}</strong>
                                                </p>
                                            </div>
                                            }
                                            <div className="visualisationInfo col-xs-8">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-commentaires.png"
                                                     alt="icone-type-etablissement"/>
                                                <p>Message :<br/>
                                                    <strong>
                                                        {item.message}
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className="bouton submit">
                                                <button value={item.id} onClick={this.deleteMessage}>Supprimer</button>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                }
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state){
    const { authentication, alert } = state;
    const { contact } = state;
    const { user } = authentication;
    const { list } = contact;
    return {
        user,
        list,
        alert
    };
}

export default withRouter(connect(mapStateToProps)(Admin_Messages))
