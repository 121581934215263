import {contactConstants, etablissementConstants, userConstants} from '../_constants';
import {etablissementService} from '../_services';
import {alertActions, userActions} from './';
import {history} from '../_helpers';

export const etablissementActions = {
    getEtablissement,
    getAllActivatedAnnonces,
    getAllUnactivatedAnnonces,
    getGroupeEtablissement,
    getAllEtablissement,
    updateEtablissement,
    delAnnonce,
    getAnnoncesById,
    getLatestAnnonces,
    getAllAnnonces,
    postDemande,
    updateDemande,
    getAllDemandes,
    delDemande,
    getDemandeById,
    getDemandeByCP,
    activateAnnonce,
    activateDemande,
    getUnactiveDemandes,
    getActiveDemandes,
    delEtablissement,
    delEtablissementGroupe,
    getAnnoncesCount,
    getAnnoncesByGroupe,
    activateMultiAnnonces
};

/****************************/
/* Get Single Etablissement */
/****************************/

function getEtablissement(etablissement_id) {
    return dispatch => {
        dispatch(request());

        etablissementService.getEtablissement(etablissement_id)
            .then(
                infos => {
                    dispatch(success(infos));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.INFOS_REQUEST } }
    function success(infos) { return { type: etablissementConstants.INFOS_SUCCESS, infos } }
    function failure(error) { return { type: etablissementConstants.INFOS_FAILURE, error } }
};

/************************/
/* Delete Etablissement */
/************************/

function delEtablissement(id) {
    return dispatch => {
        //dispatch(request());

        etablissementService.delEtablissement(id)
            .then(
                result => {
                    //dispatch(success(list));
                    //history.push('/etablissements/annonces');
                    dispatch(alertActions.success("Etablissement supprimé"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    //function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.INFO_FAILURE, error } }
}

/************************************/
/* Delete Etablissement From Groupe */
/************************************/

function delEtablissementGroupe(id, user_id) {
    return dispatch => {
        //dispatch(request());

        etablissementService.delEtablissementGroupe(id, user_id)
            .then(
                result => {
                    dispatch(alertActions.success("Etablissement supprimé du Groupe"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    //function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.INFO_FAILURE, error } }
}

/*************************/
/* Get All Etablissement */
/*************************/

function getAllEtablissement() {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllEtablissement()
            .then(
                list => {
                    dispatch(success(list.list));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.LIST_REQUEST } }
    function success(list) { return { type: etablissementConstants.LIST_SUCCESS, list } }
    function failure(error) { return { type: etablissementConstants.LIST_FAILURE, error } }
};

/*************************/
/* Get All Etablissement */
/*************************/

function getGroupeEtablissement(id) {
    return dispatch => {
        dispatch(request());

        etablissementService.getGroupeEtablissement(id)
            .then(
                result => {
                    dispatch(success(result.list));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.LIST_REQUEST } }
    function success(list) { return { type: etablissementConstants.LIST_SUCCESS, list } }
    function failure(error) { return { type: etablissementConstants.LIST_FAILURE, error } }
};

/********************/
/* Activate Annonce */
/********************/

function activateAnnonce(id, val) {
    return dispatch => {
        dispatch(request());

        etablissementService.activateAnnonce(id, val)
            .then(
                result => {

                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ANNONCES_REQUEST } }
    //function success(demandes) { return { type: etablissementConstants.ANNONCES_SUCCESS, annonces } }
    function failure(error) { return { type: etablissementConstants.ANNONCES_FAILURE, error } }
}

/******************************/
/* Activate Multiple Annonces */
/******************************/

function activateMultiAnnonces(ids, val) {
    return dispatch => {
        dispatch(request());

        etablissementService.activateMultiAnnonces(ids, val)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ANNONCES_REQUEST } }
    //function success(demandes) { return { type: etablissementConstants.ANNONCES_SUCCESS, annonces } }
    function failure(error) { return { type: etablissementConstants.ANNONCES_FAILURE, error } }
}

/****************************************/
/* Get Activated Etablissement's Annonce*/
/****************************************/

function getAllActivatedAnnonces(history) {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllActivated()
            .then(
                activated => {
                    dispatch(success(activated));
                    history.push('/liste-annonce');
                    //dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ACTIVATED_REQUEST } }
    function success(activated) { return { type: etablissementConstants.ACTIVATED_SUCCESS, activated } }
    function failure(error) { return { type: etablissementConstants.ACTIVATED_FAILURE, error } }
}

/*******************************/
/* Get Activated Annonce count */
/*******************************/

function getAnnoncesCount() {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllActivated()
            .then(
                activated => {
                    dispatch(success(activated));
                    //dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ACTIVATED_COUNT_REQUEST } }
    function success(activated) { return { type: etablissementConstants.ACTIVATED_COUNT_SUCCESS, count: activated.list.length } }
    function failure(error) { return { type: etablissementConstants.ACTIVATED_COUNT_FAILURE, error } }
}


/******************************************/
/* Get Unactivated Etablissement's Annonce*/
/******************************************/

function getAllUnactivatedAnnonces(history) {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllUnactivated()
            .then(
                unactivated => {
                    dispatch(success(unactivated));
                    history.push('/administrateur/activations/etablissements');
                    //dispatch(alertActions.success(''));
                    return unactivated;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.UNACTIVATED_REQUEST } }
    function success(unactivated) { return { type: etablissementConstants.UNACTIVATED_SUCCESS, unactivated } }
    function failure(error) { return { type: etablissementConstants.UNACTIVATED_FAILURE, error } }

}

/**********************************/
/* Get All Etablissement's Annonce*/
/**********************************/

function getAllAnnonces() {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllAnnonces()
            .then(
                unactivated => {
                    dispatch(success(unactivated));
                    //history.push('/administrateur/annonce-etablissement');
                    //dispatch(alertActions.success(''));
                    return unactivated;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.UNACTIVATED_REQUEST } }
    function success(unactivated) { return { type: etablissementConstants.UNACTIVATED_SUCCESS, unactivated } }
    function failure(error) { return { type: etablissementConstants.UNACTIVATED_FAILURE, error } }

}

/***********************/
/* Get Latest Annonces */
/***********************/

function getLatestAnnonces() {
    return dispatch => {
        //dispatch(request());

        etablissementService.getLatestAnnonces()
            .then(
                latest => {
                    dispatch(success(latest));
                    //history.push('/administrateur/activations/etablissements');
                    //dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: etablissementConstants.LATEST_REQUEST } }
    function success(latest) { return { type: etablissementConstants.LATEST_SUCCESS, latest } }
    function failure(error) { return { type: etablissementConstants.LATEST_SUCCESS, error } }

}

/**************************************/
/* Get Annonces By Etablissement's Id */
/**************************************/

function getAnnoncesById(id) {
    return dispatch => {
        dispatch(request());

        etablissementService.getAnnoncesById(id)
            .then(
                annonces => {
                    dispatch(success(annonces));
                    //dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ANNONCES_REQUEST } }
    function success(annonces) { return { type: etablissementConstants.ANNONCES_SUCCESS, annonces } }
    function failure(error) { return { type: etablissementConstants.ANNONCES_FAILURE, error } }

}

/*******************************/
/* Get Annonces By Groupe's Id */
/*******************************/

function getAnnoncesByGroupe(id) {
    return dispatch => {
        dispatch(request());

        etablissementService.getAnnoncesByGroupe(id)
            .then(
                annonces => {
                    dispatch(success(annonces));
                    //dispatch(alertActions.success(''));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.ANNONCES_REQUEST } }
    function success(annonces) { return { type: etablissementConstants.ANNONCES_SUCCESS, annonces } }
    function failure(error) { return { type: etablissementConstants.ANNONCES_FAILURE, error } }

}

/******************/
/* Delete Annonce */
/******************/

function delAnnonce(id) {
    return dispatch => {
        //dispatch(request());

        etablissementService.delAnnonce(id)
            .then(
                result => {
                    console.log("action result: ", result);
                    //dispatch(success(list));
                    //history.push('/etablissements/annonces');
                    dispatch(alertActions.success("annonce supprimée"));
                },
                error => {
                    console.log("action error: ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    //function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}

/************************/
/* Update Etablissement */
/************************/

function updateEtablissement(etablissement, user, history) {
    return dispatch => {
        //dispatch(request());

        etablissementService.update(etablissement, user)
            .then(
                result => {
                    if (result.success) {
                        //history.push('/etablissements/mes-informations');
                        if (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
                            dispatch(success({user: result.user}));
                        } else if (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                            history.push("/administrateur/etablissements");
                        }
                        dispatch(alertActions.success('Mise à jour effectuée avec succès'))
                    } else {
                        dispatch(failure(result.message));
                        dispatch(alertActions.error(result.message));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.INFOS_REQUEST } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: etablissementConstants.INFOS_FAILURE, error } }
}



/********************************/
/* Create Demande Etablissement */
/********************************/

function postDemande(demande, history) {
    return dispatch => {
        dispatch(request());

        etablissementService.postDemande(demande)
            .then(
                result => {
                    //dispatch(success(result.demande));
                    JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}" ?
                        history.push('/administrateur')
                    :
                        history.push('/personnes/mes-annonces');
                    dispatch(alertActions.success('Votre demande a bien été enregistrée et est en attente de validation par notre modérateur avant d’être publiée pour 30 jours'))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    //function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/********************************/
/* Update Demande Etablissement */
/********************************/

function updateDemande(demande, history) {
    return dispatch => {
        //dispatch(request());

        etablissementService.updateDemande(demande)
            .then(
                infos => {
                    //dispatch(success(infos));
                    JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}" ?
                        history.push('/administrateur/activations/demande-etablissement')
                    :
                        history.push('/personnes/mes-annonces');
                    dispatch(alertActions.success('Mise à jour effectuée avec succès'))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.INFOS_REQUEST } }
    function success(infos) { return { type: etablissementConstants.INFOS_SUCCESS, infos } }
    function failure(error) { return { type: etablissementConstants.INFOS_FAILURE, error } }
}

/********************/
/* Activate Demande */
/********************/

function activateDemande(id, val) {
    return dispatch => {
        //dispatch(request());

        etablissementService.activateDemande(id, val)
            .then(
                result => {
                    //dispatch(success(result.demande));
                    //history.push('/personnes/mes-annonces');
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    //function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/********************/
/* Get All Demandes */
/********************/

function getAllDemandes() {
    return dispatch => {
        dispatch(request());

        etablissementService.getAllDemandes()
            .then(
                result => {
                    dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    //dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/**************************/
/* Get Activated Demandes */
/**************************/

function getActiveDemandes() {
    return dispatch => {
        dispatch(request());

        etablissementService.getActiveDemandes()
            .then(
                result => {
                    dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    //dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/****************************/
/* Get Unactivated Demandes */
/****************************/

function getUnactiveDemandes() {
    return dispatch => {
        dispatch(request());

        etablissementService.getUnactiveDemandes()
            .then(
                result => {
                    dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    //dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/****************************/
/* Get Demande By user's Id */
/****************************/

function getDemandeById(id) {
    return dispatch => {
        dispatch(request());

        etablissementService.getDemandeById(id)
            .then(
                result => {
                    dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    //dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/******************************/
/* Get Demande By Code Postal */
/******************************/

function getDemandeByCP(Codepostal) {
    return dispatch => {
        dispatch(request());

        etablissementService.getDemandeByCP(Codepostal)
            .then(
                result => {
                    dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    //dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/*************************/
/* Delete Single Demande */
/*************************/

function delDemande(id) {
    return dispatch => {
        //dispatch(request());

        etablissementService.delDemande(id)
            .then(
                result => {
                    //dispatch(success(result.demandes));
                    //history.push('/personnes/mes-annonces');
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request() { return { type: etablissementConstants.DEMANDES_REQUEST } }
    //function success(demandes) { return { type: etablissementConstants.DEMANDES_SUCCESS, demandes } }
    function failure(error) { return { type: etablissementConstants.DEMANDES_FAILURE, error } }
}

/********************************/
/* CallBack to handle Responses */
/********************************/

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
