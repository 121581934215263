import React, { Component } from 'react';
import { connect } from 'react-redux';

class Fonctionnement extends Component {

    constructor(props){
        super(props)


    }

    render(){
        return (
            <section className="homeBloc3">
                <div className="container">
                    <h3>Comment <span>ça marche</span> ?</h3>
                    <p>Je suis ...</p>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="contentFonctionnement">
                            <img className="img-responsive"
                                 src="/img/home/homeBloc3/etablissement.png"
                                 alt="icone-etablissement"/>
                                <p>Vous pouvez être une Résidence Services Seniors (RSS), une Résidence Autonomie (RA),
                                    un Etablissement d’Hébergement pour Personnes Agées Dépendantes (EHPAD),
                                    une unité de Soins de Longue Durée (USLD).
                                </p>
                                <br/>
                                <p>Si vous avez une place immédiatement disponible ou qui va se libérer dans les 30 jours,</p>
                                <p className="text-center">
                                    <span className="urltitre2">Place
                                        <span className="rouge">Maison</span>De
                                        <span className="rouge">Retraite</span>.fr
                                    </span> vous offre 2 outils :
                                </p>

                                <div className="outils">

                                    <a href="/place-etablissement">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-demande.png"
                                             alt="icone-maison"/>
                                            <p>Je publie une annonce de place disponible</p>
                                    </a>
                                </div>
                                <div className="outils">
                                    <a href="/place-etablissement">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-places-dispo.png"
                                             alt="icone-maison"/>
                                            <p>Je consulte les demandes des familles</p>
                                    </a>
                                </div>


                                <div className="bouton">
                                    <a href="/place-etablissement">J’ai une place DANS MON ETABLISSEMENT</a>
                                </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="contentFonctionnement fonctionnementDeux">
                            <img className="img-responsive" src="/img/home/homeBloc3/famille.png"
                                 alt="icone-etablissement"/>
                                <p>Vous êtes une famille : une personne âgée, un parent ou un proche</p>
                                <p className="textOu">Ou</p>
                                <p>Un professionnel qui informe les familles : CLIC, Pôle Autonomie/seniors, Maison des Solidarités, CCAS, hôpitaux, cliniques, tuteur,
                                    médecin, assistant(e) social(e)… </p>
                                <p>Si vous êtes à la recherche d’une place en maison de retraite pour vous, un proche,
                                    un patient ou une personne que vous accompagnez, </p>
                                <p className="text-center"><span className="urltitre2">Place<span
                                    className="rouge">Maison</span>De<span
                                    className="rouge">Retraite</span>.fr</span> vous offre 2 outils :</p>
                                <div className="outils">
                                    <a href="/liste-annonce">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-places-dispo.png"
                                             alt="icone-maison"/>
                                            <p>Je consulte les places disponibles</p>
                                    </a>
                                </div>
                                <div className="outils">
                                    <a href="/demande-etablissement">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-demande.png"
                                             alt="icone-maison"/>
                                            <p>Je dépose une demande auprès des établissements</p>
                                    </a>
                                </div>

                                <div className="bouton">
                                    <a href="/place-maison">Je cherche une place en maison de retraite</a>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Fonctionnement)
