import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";

import lib from "../../../../lib/lib";
import {alertActions, userActions} from "../../../../_actions";
import {withRouter} from "react-router";

class Personne_Infos extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: {
                id          : (localStorage.getItem('user') ? this.props.user.user.id : false),
                username    : (localStorage.getItem('user') ? this.props.user.user.username : false),
                password    : '',
                password2   : '',
                email       : (localStorage.getItem('user') ? this.props.user.user.email : false),
                email2      : "nowhere@place.com",
                roles       : (localStorage.getItem('user') ? this.props.user.user.roles : false)
            },
            errorMessage: 'le mot de passe ne correspond pas',
            submitted: false
        };

        this.handleChangeUser   = this.handleChangeUser.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }

    handleChangeUser(event) {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            },
        })
    }

    handleSubmit(e) {
        e.preventDefault();


        this.setState({submitted: true});
        const {user} = this.state;
        const {dispatch} = this.props;

        if (user.password && user.password !== user.password2) {
            dispatch(alertActions.error("Veuillez confirmer le mot de passe"));
        } else if (lib.isValidUsername(lib.protectEntry(user.username)) && lib.isValidEmail(lib.protectEntry(user.email))) {
            dispatch(userActions.updateUser(user, "personne", this.props.history));
        } else {
            console.log(" NOT triggered condition ")
            console.log("is valid user", lib.isValidUsername(lib.protectEntry(user.username)))
            console.log("is valid email", lib.isValidPassword(lib.protectEntry(user.email)))
        }
    }

    render(){
        const { updating, alert } = this.props;
        const { user, submitted } = this.state;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        alert.message ?
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                            :
                            null
                    }
                <section className="inscriptionStep1">
                    <div className="container">
                        <p className="chemin"><a href="/personnes">Mon compte</a> &gt; Mes informations</p>
                        <h1>Mes informations</h1>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className="encadreForm col-xs-12">
                                <div className="question">
                                    <p>Modifiez vos identifiants de connexion</p>
                                </div>
                                <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                                    <label>Identifiant </label>
                                    <input type="text" className="form-control" id="adminbundle_user_username" name="username" placeholder={user.username}
                                           required="required" maxLength="180" pattern=".{2,}" value={user.username} onChange={this.handleChangeUser}/>
                                    {submitted && !lib.isValidUsername(user.username) &&
                                    <div className="help-block">Mauvais format d'identifiant</div>
                                    }
                                </div>
                                <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !user.email ? ' has-error' : '')}>
                                    <label>Email </label>
                                    <input type="email" id="adminbundle_user_email" className="form-control" name="email"
                                           required="required" maxLength="180" pattern=".{2,}" placeholder={user.email}
                                           value={user.email} onChange={this.handleChangeUser}/>
                                    {submitted && !lib.isValidEmail(user.email) &&
                                    <div className="help-block">Mauvais format d'email</div>
                                    }
                                </div>
                                <div className="question">
                                    <p>Modifiez votre mot de passe (à remplir uniquement si vous changez de mot de
                                        passe)</p>
                                </div>

                                <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidUpdatePassword(user.password) ? ' has-error' : '')}>
                                    <label htmlFor="userPassword">Mot de passe </label>
                                    <input type="password" id="adminbundle_user_plainPassword_first"
                                           name="password" className="form-control" onChange={this.handleChangeUser}/>
                                    {submitted && !lib.isValidUpdatePassword(user.password) &&
                                    <div className="help-block">Mauvais format de Mot de passe</div>
                                    }
                                </div>
                                <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.passwordMatch(user.password, user.password2) ? ' has-error' : '')}>
                                    <label htmlFor="userPassword">Retapez votre mot de passe </label>
                                    <input type="password" id="adminbundle_user_plainPassword_second"
                                           name="password2" className="form-control" onChange={this.handleChangeUser}/>
                                    {submitted && !lib.passwordMatch(user.password, user.password2) &&
                                    <div className="help-block">{user.password2 ? this.state.errorMessage : "Veuillez confirmer le mot de passe"}</div>
                                    }
                                </div>
                                <div className="bouton annuler" style={{float: "left"}}>
                                    <a href="/personnes">
                                        Annuler
                                    </a>
                                </div>
                                <div >
                                    <div className="bouton submit">
                                        <input type="submit" name="envoyer" id="valideEtablissement" value="Modifer mes informations"/>
                                        {updating &&
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                </main>
                <Footer />
            </div>
        )
    }
}



function mapStateToProps(state){
    const { authentication, alert } = state;
    const { user } = authentication;
    const { updating } = state.setUpdate;

    return {
        updating,
        alert,
        user
    }
}

//export default connect(mapStateToProps, { dispatch })(Personne_Infos)
const connectedPersonne_Infos = withRouter(connect(mapStateToProps)(Personne_Infos));
export { connectedPersonne_Infos as Personne_Infos };
