import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from "../../_components/Footer";
import Navbar from "../../_components/Navbar";

class Donnees_Personelles extends Component {

    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar {...this.props}/>
                <main>


                <section className="placeMaisonBloc1">
                    <div className="container">
                        <h1>Données personnelles</h1>
                        <div style={{textAlign: 'center', fontSize: '4rem'}}>
                            <b><span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b>
                        </div>
                        <br/>
                        <p><strong>Conformément au Règlement Général sur la Protection des Données (RGPD) et dans la
                            continuité de la Loi française «&nbsp;Informatique et Libertés&nbsp;» de 1978, vous
                            trouverez ci-dessous les informations vous permettant d’accéder à vos données pour les
                            modifier ou les supprimer.</strong></p>


                        <h2>Pour les Établissements</h2>

                        <p style={{textAlign: 'center'}}><strong>Adresse de contact = <a
                            href="mailto:etablissement@PlaceMaisonDeRetraite.fr">etablissement@PlaceMaisonDeRetraite.fr</a></strong>
                        </p>

                        <h3>1 - Informations contenues dans «&nbsp;Mes Informations&nbsp;»</h3>

                        <p>Ces informations sont enregistrées à l’initiative de l’établissement lors de son
                            inscription.</p>

                        <ul>
                            <li>
                                1.1 Modification
                                <ul>
                                    <li>
                                        L’ensemble des informations est modifiable à tout moment par l’établissement en
                                        se connectant à «&nbsp;Mon compte&nbsp;» puis en cliquant sur «&nbsp;Mes
                                        informations&nbsp;» puis en allant sur&nbsp;:
                                        <ul>
                                            <li>
                                                «&nbsp;Mon établissement&nbsp;»
                                            </li>
                                            <li>
                                                «&nbsp;Mes identifiants&nbsp;»
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                1.2 Suppression
                                <ul>
                                    <li>
                                        La suppression des données contenues dans «&nbsp;Mes Informations&nbsp;»
                                        entraine la suppression définitive du compte de l’établissement de la
                                        plate-forme <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>

                                        <ul>
                                            <li>
                                                Envoyer un email à etablissement@PlaceMaisonDeRetraite.fr avec&nbsp;les
                                                informations suivantes :
                                                <ul>
                                                    <li>Objet&nbsp;: Suppression de mon compte établissement</li>
                                                    <li>Identifiant&nbsp;: xxx</li>
                                                    <li>E-mail du Responsable : xxxx@xxx</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h3>2 - Informations contenues dans «&nbsp;Mes annonces »</h3>

                        <p>Ces informations sont enregistrées à l’initiative de l’établissement lors de la création et
                            la publication de ses annonces.</p>

                        <ul>
                            <li>
                                2.1 Modification
                                <ul>
                                    <li>
                                        L’ensemble des informations est modifiable à tout moment par l’établissement en
                                        se connectant à <strong>«&nbsp;Mon compte&nbsp;»</strong> puis en cliquant sur <strong>«&nbsp;Mes
                                        Annonces&nbsp;»</strong> puis en cliquant sur le bouton <strong>«&nbsp;MODIFIER&nbsp;»</strong> de
                                        l’annonce à modifier.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                2.2 Suppression
                                <ul>
                                    <li>
                                        ◦ Une annonce est supprimable à tout moment par l’établissement en se connectant
                                        à <strong>«&nbsp;Mon compte&nbsp;»</strong> puis en cliquant sur <strong>«&nbsp;Mes Annonces&nbsp;»</strong> puis
                                        en cliquant sur le bouton <strong>«&nbsp;SUPPRIMER&nbsp;»</strong> de l’annonce à supprimer.
                                    </li>
                                    <li>
                                        L’annonce est également automatiquement supprimée au bout de 7 jours de parution
                                        et peut être prolonger à nouveau pour 7 jours si l’établissement l’accepte en
                                        répondant positivement à la question «&nbsp;Voulez-vous la réactiver pour une
                                        semaine ?&nbsp;» envoyée par e-mail.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h2>Pour les familles ou les professionnels qui les accompagnent</h2>

                        <p>
                            <strong>
                                Adresse de contact pour les familles = <a
                                href="mailto:famille@PlaceMaisonDeRetraite.fr">famille@PlaceMaisonDeRetraite.fr</a><br/>
                                Adresse de contact pour les professionnels = <a
                                href="mailto:pro@PlaceMaisonDeRetraite.fr">pro@PlaceMaisonDeRetraite.fr</a>
                            </strong>
                        </p>

                        <h3>1 - Informations contenues dans «&nbsp;Mes Informations&nbsp;» </h3>

                        <p>
                            Ces informations sont enregistrées à l’initiative de la famille ou du professionnel qui
                            l’accompagne lors de son inscription.
                        </p>

                        <ul>
                            <li>
                                Modification
                                <ul>
                                    <li>L’ensemble des informations est modifiable à tout moment par la famille ou le
                                        professionnel qui l’accompagne en se connectant à <strong>«&nbsp;Mon compte&nbsp;»</strong> puis
                                        en cliquant sur <strong>«&nbsp;Mes informations&nbsp;»</strong>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                Suppression
                                <ul>
                                    <li>
                                        La suppression des données contenues dans <strong>«&nbsp;Mes Informations&nbsp;»</strong>
                                        entraine la suppression définitive du compte de la famille ou du professionnel
                                        qui l’accompagne de la plate-forme <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                                        <ul>
                                            <li>
                                                Envoyer un email à famille@PlaceMaisonDeRetraite.fr ou à
                                                pro@PlaceMaisonDeRetraite.fr avec&nbsp;les informations suivantes :
                                                <ul>
                                                    <li>Objet&nbsp;: Suppression de mon compte famille (ou
                                                        professionnel)
                                                    </li>
                                                    <li>Identifiant&nbsp;: xxx</li>
                                                    <li>E-mail de contact&nbsp;: xxxx@xxx</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h3>2 - Informations contenues dans «&nbsp;Mes demandes de place »</h3>

                        <p>Ces informations sont enregistrées à l’initiative de la famille ou du professionnel qui
                            l’accompagne lors de la création et la publication de ses demandes.</p>

                        <ul>
                            <li>
                                Modification
                                <ul>
                                    <li>L’ensemble des informations est modifiable à tout moment par la famille ou le
                                        professionnel qui l’accompagne en se connectant à <strong>«&nbsp;Mon compte&nbsp;»</strong> puis
                                        en cliquant sur <strong>«&nbsp;Mes demandes de place auprès des établissements&nbsp;»</strong>
                                        puis en cliquant sur le bouton <strong>«&nbsp;MODIFIER&nbsp;»</strong> de la demande à modifier.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                Suppression
                                <ul>
                                    <li>Une annonce est supprimable à tout moment par la famille ou le professionnel qui
                                        l’accompagne en se connectant à <strong>«&nbsp;Mon compte&nbsp;»</strong> puis en cliquant sur
                                        <strong>«&nbsp;Mes demandes de place auprès des établissements&nbsp;»</strong> puis en cliquant
                                        sur le bouton <strong>«&nbsp;SUPPRIMER&nbsp;»</strong> de la demande à supprimer.
                                    </li>
                                    <li>La demande est également automatiquement supprimée au bout de 30 jours de
                                        parution et peut être prolonger à nouveau pour 30 jours si la famille ou le
                                        professionnel qui l’accompagne l’accepte en répondant positivement à la question
                                        «&nbsp;Voulez-vous la réactiver pour un mois ?&nbsp;» envoyé par e-mail.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h3>Informations contenues dans «&nbsp;Mes Alertes-dispo&nbsp;»</h3>

                        <ul>
                            <li>
                                3.1 Modification
                                <ul>
                                    <li>
                                        Via «&nbsp;Mon Compte&nbsp;»
                                        <ul>
                                            <li>En se connectant à «&nbsp;Mon compte&nbsp;» puis «&nbsp;Mes
                                                Alertes-dispo&nbsp;» puis en cliquant sur «&nbsp;Modifier&nbsp;» de l’
                                                «&nbsp;Alerte-Dispo&nbsp;» choisie.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                3.2 Suppression
                                <ul>
                                    <li>
                                        Via «&nbsp;Mon Compte&nbsp;»
                                        <ul>
                                            <li>En se connectant à «&nbsp;Mon compte&nbsp;» puis «&nbsp;Mes
                                                Alertes-dispo&nbsp;» puis en cliquant sur «&nbsp;Supprimer&nbsp;» de
                                                l’«&nbsp;Alerte-Dispo&nbsp;» choisie.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Via les e-mails «&nbsp;Alerte-Dispo » reçus
                                        <ul>
                                            <li>En cliquant sur le bouton «&nbsp;Supprimer&nbsp;votre Alerte-Dispo »
                                                disponible en bas de chaque e-mail d’«&nbsp;Alerte-dispo&nbsp;» reçu
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Via l’e-mail de confirmation de la création de l’«&nbsp;Alerte-Dispo&nbsp;»
                                        <ul>
                                            <li>En cliquant sur le bouton «&nbsp;Supprimer&nbsp;votre Alerte-Dispo»
                                                disponible en bas de l’e-mail
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>


                    </div>
                </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Donnees_Personelles)