import React, { Component } from 'react';
import { connect } from 'react-redux';

class Recherche_Accueil extends Component {

    constructor(props){
        super(props)


    }

    render(){
        return (
            <section className="homeBloc2" >
                <hr style={{alignSelf: 'center', width: '80%', border: '1.5px solid rgb(241, 244, 250)'}}/>
                <div className="container">
                    <h2>Qui <span>sommes-nous</span> ?</h2>
                    <div className="col-xs-12">
                        <p className="col-xs-12"><span className="urltitre2">Place<span className="rouge">Maison</span>De<span
                            className="rouge">Retraite</span>.fr</span><strong>... C’est une idée Simple…</strong><br />
                            Mettre en relation les établissements qui ont une solution à proposer avec les familles qui recherchent une place en maison de retraite.</p>
                        <p className="col-xs-12"><strong><span className="urltitre2">Place<span
                            className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>…
                            C’est un Outil utile…</strong><br/>
                            Qui permet aux maisons de retraite de communiquer rapidement avec les familles et les professionnels, quand elles ont une place à leur proposer.<br/>
                            Qui facilite le travail des professionnels quand ils doivent trouver un hébergement adapté pour une personne âgée,<br/>
                            Qui aide les familles dans leurs recherches en leur donnant accès, en quelques clics, aux places disponibles dans les maisons de retraite proches de chez elles.</p>
                        <p className="col-xs-12"><span className="urltitre2">Place<span className="rouge">Maison</span>De<span
                            className="rouge">Retraite</span>.fr</span><strong>... C’est une plate-forme Gratuite et Ouverte à tous…</strong><br />
                            Notre plate-forme est intégralement gratuite pour les familles, les professionnels qui les accompagnent ainsi que pour toutes les maisons de retraite,<br/>
                            qu’elles soient publiques, privées ou associatives.</p>
                        <p className="col-xs-12"><span className="urltitre2">Place<span className="rouge">Maison</span>De<span
                            className="rouge">Retraite</span>.fr</span><strong>... C’est un site qui a des Principes…</strong><br />
                            <strong>Simple</strong> : La seule saisie d’un code postal suffit pour accéder aux places disponibles.<br/>
                            <strong>Direct</strong> : Les établissements publient eux-mêmes leurs annonces.<br/>
                            <strong>À jour</strong> : Les annonces sont mises à jour quotidiennement.</p>
                        <p className="col-xs-12"><span className="urltitre2">Place<span
                            className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr...</span>
                            est le premier site d’annonces pour les maisons de retraite en France qui permet, enfin, de répondre à la question essentielle : </p>

                        <strong>
                            <p className="col-xs-12 text-center">«&nbsp;Où puis-je trouver une place en maison de retraite &nbsp;?&nbsp;»</p>
                        </strong>
                    </div>
                </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Recherche_Accueil)
