import React, {Component} from 'react';
import {connect} from 'react-redux';

import {etablissementActions} from "../../_actions/etablissement.actions";

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";

class Email_annonce extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

        const { action, annonce_id } = this.props.match.params;

        if (parseInt(action) === 1) {
            this.props.dispatch(etablissementActions.delAnnonce(annonce_id))
        } else if (parseInt(action) === 2) {
            this.props.dispatch(etablissementActions.activateAnnonce(annonce_id, 1))
        }
    }

    render() {
        const {alert} = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="listeDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/etablissements/">Mon compte</a> &gt; Mes annonces</p>
                            <h1>Mes <span>annonces</span></h1>
                            <br/>
                        {
                            alert.message ?
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                            :
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                            <br/>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, etablissement, alert} = state;
    const {user} = authentication;
    const { unactivated } = etablissement;
    return {
        user,
        alert,
        unactivated
    }
}

export default connect(mapStateToProps)(Email_annonce)
