import { infoConstants } from '../_constants';

export function info(state = {}, action) {
    switch (action.type) {
        case infoConstants.CREATE_REQUEST:
            return {
                creatingInfo: true,
            };
        case infoConstants.CREATE_SUCCESS:
            return {
                createdInfo: true,
            };
        case infoConstants.CREATE_FAILURE:
            return {
                error: action.error
            };
        case infoConstants.LIST_REQUEST:
            return {
                gettingInfos: true,
            };
        case infoConstants.LIST_SUCCESS:
            return {
                list: action.list,
            };
        case infoConstants.LIST_FAILURE:
            return {
              error: action.error,
            };
        case infoConstants.DELETE_FAILURE:
            return {
                error: action.error,
            };
        default:
            return state
    }
}