import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ReCaptcha} from "react-recaptcha-google";

import {contactActions} from "../../_actions";
import lib from "../../lib/lib";

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";
import {Alert} from "react-st-modal";

class Contact extends Component {

    constructor(props){
        super(props)

        this.state = {
            nom: '',
            prenom: '',
            telephone: '',
            entite: '',
            email: '',
            message: '',
            recaptchaToken: 'yes',
            choix: false,
            submitted: false
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        //console.log(recaptchaToken, "<= your recaptcha token")
        //this.setState({recaptchaToken})
        return recaptchaToken;
    }

    handleChange(e) {
        const {name, value} = e.target;

        this.setState({ submitted: false });

        if (name === 'choix') {
            this.setState({
                choix : !this.state.choix
            })
        } else {
            this.setState({
                [name] : value
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const {nom, prenom, telephone, email, message, entite, recaptchaToken, choix} = this.state;
        this.setState({ submitted: true });

        if (recaptchaToken.length === 0)
            Alert('Veuillez cocher la case "Je ne suis pas un robot"');
        else if (!choix) {
            Alert('Veuillez accpeter les "conditions générales d’utilisation"')
        } else if (
            lib.isValidName(nom) &&
            lib.isValidName(prenom) &&
            lib.isValidPhone(telephone) &&
            lib.isValidEmail(email) &&
            lib.protectEntry(message)
        ) {
            this.props.dispatch(contactActions.postMessage(nom, prenom, telephone, entite, email, message))
        } else {
            Alert("Verifier que vous avez bien renseigné tous les champs nécessaires")
        }
    }

    render(){
        const {alert} = this.props;
        const { nom, prenom, telephone, entite, email, message, submitted } = this.state;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>


                <section className="demandeEtablissementBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/">Accueil</a> &gt; Contactez-nous</p>
                        <h1>Formulaire <span>de contact</span></h1>

                        <div className="encadreForm">
                            <form id="formContact" onSubmit={this.handleSubmit}>
                                <div className="formBloc2">
                                    <div className=" col-xs-12">
                                        <div className="row">
                                            <div className="inputInfos">
                                                <div className="infos col-xs-12 col-sm-6">
                                                    <div className="question">
                                                        <p>Votre nom <span className="red"> *</span></p>
                                                    </div>
                                                    <input type="text" name="nom" value={nom} required="" onChange={this.handleChange}/>
                                                    {submitted && !lib.isValidName(nom) &&
                                                    <div className="red">Veuillez renseigner correctement votre nom </div>
                                                    }
                                                </div>
                                                <div className="infos col-xs-12 col-sm-6">
                                                    <div className="question">
                                                        <p>Votre prenom <span className="red"> *</span></p>
                                                    </div>
                                                    <input type="text" name="prenom" value={prenom} required="" onChange={this.handleChange}/>
                                                    {submitted && !lib.isValidName(prenom) &&
                                                    <div className="red">Veuillez renseigner correctement votre prenom </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formBloc2_1 col-xs-12">
                                        <div className="row">
                                            <div className="inputInfos">
                                                <div className="infos col-xs-12 col-sm-6">
                                                    <div className="question">
                                                        <p>Votre téléphone <span className="red"> *</span></p>
                                                    </div>
                                                    <input type="tel" name="telephone"  required="" value={telephone} onChange={this.handleChange}/>
                                                    {submitted && !lib.isValidPhone(telephone) &&
                                                    <div className="red">Veuillez renseigner correctement votre numéro de téléphone</div>
                                                    }
                                                </div>
                                                <div className="infos col-xs-12 col-sm-6">
                                                    <div className="question">
                                                        <p>Votre entité (pour les pros)</p>
                                                    </div>
                                                    <input type="text" name="entite" value={entite} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formBloc2_1 col-xs-12">
                                        <div className="row">
                                            <div className="inputInfos">
                                                <div className="infos col-xs-12">
                                                    <div className="question">
                                                        <p>Votre e-mail <span className="red"> *</span></p>
                                                    </div>
                                                    <input type="email" name="email" value={email} required="" onChange={this.handleChange}/>
                                                    {submitted && !lib.isValidEmail(email) &&
                                                    <div className="red">Veuillez renseigner correctement votre email </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc2_7 col-xs-12">
                                        <div className="question">
                                            <p>Votre message <span className="red"> *</span></p>
                                        </div>
                                        <div className="inputCom">
                                            <textarea
                                                name="message"
                                                value={message}
                                                className="col-xs-12"
                                                id="messageText"
                                                required=""
                                                maxLength="400"
                                                onChange={this.handleChange}>
                                            </textarea>
                                        </div>

                                        <div>{message.length}/400</div>
                                    </div>
                                    <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input id="condition" type="checkbox" name="choix" value="1" required="" onChange={this.handleChange}/>
                                            <label htmlFor="condition"><strong>j’accepte les <a href="/conditions-generales">conditions générales
                                                d’utilisation.</a></strong></label>
                                    </div>
                                </div>
                                {/*
                                    <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                        <div style={{width: "304px", height: "78px"}}>
                                            <ReCaptcha
                                                ref={(el) => {
                                                    this.captchaDemo = el;
                                                }}
                                                hl="fr"
                                                size="normal"
                                                data-theme="dark"
                                                render="explicit"
                                                sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                onloadCallback={this.onLoadRecaptcha}
                                                verifyCallback={this.verifyCallback}
                                            />
                                            <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                      className="g-recaptcha-response"
                                                      style={{
                                                          width: "250px",
                                                          height: "40px",
                                                          border: "1px solid rgb(193, 193, 193)",
                                                          margin: "10px 25px",
                                                          padding: "0px",
                                                          resize: "none",
                                                          display: "none"
                                                      }}>
                                        </textarea>
                                        </div>
                                    </div>
                                */}
                                <div className="errorFirstStep" style={{color: "red"}}>
                                </div>
                                <br />
                                {
                                   alert && alert.message ?
                                       <div className="bouton annuler">
                                           <a href="/">
                                               Message envoyé - Retournez à l'accueil
                                           </a>
                                       </div>
                                       :
                                       <div className="bouton submit">
                                           <input type="submit" id="submitContact" value="Envoyer mon message"/>
                                       </div>
                                }

                            </form>
                        </div>
                    </div>
                </section>
                </main>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const {alert} = state;

    return {
        alert
    }
}

export default connect(mapStateToProps)(Contact)
