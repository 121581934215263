import {contactConstants} from '../_constants';

export function contact(state = {}, action) {
    switch (action.type) {
        case contactConstants.MESSAGES_REQUEST:
            return {
                getMessages: true
            };
        case contactConstants.MESSAGES_SUCCESS:
            return {
                getMessages: true,
                list: action.list
            };
        case contactConstants.MESSAGES_FAILURE:
            return {
                error: action.error
            };
        case contactConstants.DELETE_REQUEST:
            return {
                delete: true
            };
        case contactConstants.DELETE_SUCCESS:
            return {
                delete: true,
                list: action.list
            }
        case contactConstants.DELETE_FAILURE:
            return {
                error: action.error
            }
        default:
            return state
    }
}