import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'icheck/skins/all.css'; // or single skin css
import { Row, Col } from 'react-bootstrap';
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import { alertActions, etablissementActions, groupesActions } from "../../../../_actions";
import lib from "../../../../lib/lib";
import {withRouter} from "react-router";
import { Confirm } from 'react-st-modal';

class Etablissements_control extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            etablissements: this.props.list,
            alert0: false,
            message: "",
            screenWidth: null,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.handleEmailControl = this.handleEmailControl.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));

        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.etablissements !== this.state.etablissements) {
            let etablissements = this.props.list;

            this.setState({
                etablissements
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.list !== prevState.etablissements) {

            return {etablissements: nextProps.list};
        } else return null;
    }

    async handleEmailControl(e, all, control, id) {
        e.preventDefault();
        this.setState({alert3: false});
        let response;
        if (all) {
            if (control)
                response = await Confirm("C'est le gestionnaire des annonces qui recevra les emails d’activation et désactivation pour toutes les annonces du Groupe, continuer?", "", "Continuer", "Fermer");
            else
                response = await Confirm("Vos établissements recevront à nouveau les emails d'activation et désactivation pour leurs annonces, continuer?", "", "Continuer", "Fermer");
        }

        const { email2 } = this.state.user;

        if (response) {
            this.setState({alert3: true});
            await this.props.dispatch(groupesActions.takeEmailControl(all, control, email2, this.props.user.user.groupe_id, id));
            await this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
        }
    }

    render(){
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const {
            etablissements,
            alert0,
            screenWidth,
        } = this.state;
        const { alert } = this.props;

        const controledList = etablissements?.etablissements?.filter((item) => item.emailGroupe?.length > 0).length ?? 0;
        const notControledList = etablissements?.etablissements?.filter((item) => !item.emailGroupe).length ?? 0;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    <section className="listeDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/groupes">Mon compte</a> &gt; Statuts de vos Etablissements
                            </p>
                            <h1>Gestion de la prise de controle de vos établissements</h1>
                            <h1>
                                <span>Ici, vous pouvez choisir de <strong>controler</strong> la gestion des annonces
                                    <br /> (emails d’activation et désactivation)<br />
                                    de tous vos établissements ou <strong>laisser</strong> ces derniers s'auto-gérer
                                </span>
                            </h1>
                            <h3>Nombre total d'établissements: {etablissements?.etablissements?.length ?? 0}</h3>
                            <br/>
                            <table className="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th scope="col" style={{textAlign: "center"}}>Établissements Controlés</th>
                                    <th scope="col" style={{textAlign: "center"}}>Établissements Auto-gérés</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{textAlign: "center", color: "rgb(92, 183, 132)"}}><strong>{controledList}</strong></td>
                                    <td style={{textAlign: "center", color: "#c4574e"}}><strong>{notControledList}</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="inputInfos">
                                            <div className="bouton submit" style={{textAlign: "center"}}>
                                                <p>Vous pouvez <strong>laisser</strong> <span style={{color: "#c4574e"}}>tous</span> les établissements s'auto-gérer</p>
                                                <input type="reset" name="désactiver"
                                                       value="Laisser"
                                                       id={-2}
                                                       style={{
                                                           marginTop: "10px",
                                                           alignSelf: "center",
                                                           marginRight: "auto",
                                                           marginLeft: "auto"
                                                       }}
                                                       onClick={(e) => this.handleEmailControl(e, true, false, null)}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inputInfos">
                                            <div className="bouton submit" style={{textAlign: "center"}}>
                                                <p>Vous pouvez <strong>controler</strong> la gestion de <span style={{color: "#c4574e"}}>tous</span> vos établissements</p>
                                                <input type="reset" name="activer"
                                                       value="Controler"
                                                       id={-1}
                                                       style={{
                                                           marginTop: "10px",
                                                           alignSelf: "center",
                                                           marginRight: "auto",
                                                           marginLeft: "auto"
                                                       }}
                                                       onClick={(e) => this.handleEmailControl(e, true, true, null)}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    {
                        alert.message ?
                            <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                    }
                    <div></div>
                </main>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { alert, authentication, etablissement, groupes } = state;
    const { user } = authentication;
    const { list } = etablissement;

    return  {
        user,
        alert,
        list,
    }
}

export default withRouter(connect(mapStateToProps)(Etablissements_control))
