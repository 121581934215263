import { authHeader } from '../_helpers';
import {userActions} from "../_actions";

export const infoService = {
    updateInfoDispo,
    delInfoDispo,
    createInfo,
    getInfos
};

/*********************/
/* Create Info Dispo */
/*********************/

function createInfo(user, listType) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ user, listType })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/createInfo`, requestOptions)
        .then(handleResponse)
        .then(result => {

            return result;
        });
}

/******************/
/* Get Info Dispo */
/******************/

function getInfos() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/getInfosDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {

            return result;
        });
}

/**********************/
/* Update Alert Dispo */
/**********************/

function updateInfoDispo(info) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({info})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/updateInfoDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/**********************/
/* Delete Alert Dispo */
/**********************/

function delInfoDispo(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/delInfoDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/**************************/
/* Call Back Api Response */
/**************************/

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
