import {groupesConstants} from "../_constants";
import {groupesService} from "../_services";
import {alertActions} from "./alert.actions";

export const groupesActions = {
    getAllGroupes,
    getSingleGroupe,
    createGroupe,
    updateGroupe,
    createGroupeAccount,
    updateGroupeAccount,
    activateGroupeAccount,
    takeEmailControl,
    deleteGroupe
}

/*******************/
/* Get All Groupes */
/*******************/

function getAllGroupes() {
    return dispatch => {
        dispatch(request());

        groupesService.getGroupesList()
            .then(
                list => {
                    dispatch(success(list.groupes));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error.message));
                }
            );
    };

    function request() { return { type: groupesConstants.LIST_REQUEST } }
    function success(list) { return { type: groupesConstants.LIST_SUCCESS, list } }
    function failure(error) { return { type: groupesConstants.LIST_FAILURE, error } }
}

/****************************/
/* Get One Groupe by its Id */
/****************************/

function getSingleGroupe(id) {
    return dispatch => {
        dispatch(request());

        groupesService.getSingleGroupe(id)
            .then(
                result => {
                    dispatch(success(result.groupe));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error.message));
                }
            );
    };

    function request() { return { type: groupesConstants.SINGLE_REQUEST } }
    function success(groupe) { return { type: groupesConstants.SINGLE_SUCCESS, groupe } }
    function failure(error) { return { type: groupesConstants.SINGLE_FAILURE, error } }
}


/*********************/
/* Create new Groupe */
/*********************/

function createGroupe(groupe) {
    return dispatch => {
        groupesService.createGroupe(groupe)
            .then(
                result => {
                    dispatch(success(result.message));
                    if (result.success === true)
                        dispatch(alertActions.success(result.message));
                    else
                        dispatch(alertActions.error(result.message));
                },
                error => {
                    dispatch(failure(error.message));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function success(message) { return { type: groupesConstants.CREATE_SUCCESS, message } }
    function failure(error) { return { type: groupesConstants.CREATE_FAILURE, error } }
}

/*********************/
/* Update new Groupe */
/*********************/

function updateGroupe(groupe) {
    return dispatch => {
        groupesService.updateGroupe(groupe)
            .then(
                result => {
                    dispatch(success(result.message));
                    if (result.success === true)
                        dispatch(alertActions.success(result.message));
                    else
                        dispatch(alertActions.error(result.message));
                },
                error => {
                    dispatch(failure(error.message));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function success(message) { return { type: groupesConstants.UPDATE_SUCCESS, message } }
    function failure(error) { return { type: groupesConstants.UPDATE_FAILURE, error } }
}

/*****************************/
/* Create new Groupe Account */
/*****************************/

function createGroupeAccount(groupe) {
    return dispatch => {
        groupesService.createGroupeAccount(groupe)
            .then(
                result => {
                    dispatch(success(result.message));
                    if (result.success === true)
                        dispatch(alertActions.success(result.message));
                    else
                        dispatch(alertActions.error(result.message));
                },
                error => {
                    dispatch(failure(error.message));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function success(message) { return { type: groupesConstants.ACCOUNT_SUCCESS, message } }
    function failure(error) { return { type: groupesConstants.ACCOUNT_FAILURE, error } }
}

/*****************************/
/* Update new Groupe Account */
/*****************************/

function updateGroupeAccount(groupe) {
    return dispatch => {
        groupesService.updateGroupeAccount(groupe)
            .then(
                result => {
                    dispatch(success(result.message));
                    if (result.success === true)
                        dispatch(alertActions.success(result.message));
                    else
                        dispatch(alertActions.error(result.message));
                },
                error => {
                    dispatch(failure(error.message));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function success(message) { return { type: groupesConstants.UPDATE_SUCCESS, message } }
    function failure(error) { return { type: groupesConstants.UPDATE_FAILURE, error } }
}

/***************************/
/* Activate Groupe Account */
/***************************/

function activateGroupeAccount(groupe) {
    return dispatch => {
        groupesService.activateGroupeAccount(groupe)
            .then(
                result => {
                    dispatch(success(result.message));
                    if (result.success === true)
                        dispatch(alertActions.success(result.message));
                    else
                        dispatch(alertActions.error(result.message));
                },
                error => {
                    dispatch(failure(error.message));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function success(message) { return { type: groupesConstants.ACTIVATE_SUCCESS, message } }
    function failure(error) { return { type: groupesConstants.ACCOUNT_FAILURE, error } }
}

/**********************/
/* Take Email Control */
/**********************/

function takeEmailControl(all, control, emailGroupe, groupe_id, id) {
    return dispatch => {
        groupesService.takeEmailControl(all, control, emailGroupe, groupe_id, id)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            );
    };

    function failure(error) { return { type: groupesConstants.ACCOUNT_FAILURE, error } }
}

/***************************/
/* Delete Groupe by its id */
/***************************/

function deleteGroupe(id) {
    return dispatch => {
        groupesService.deleteGroupe(id)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function failure(error) { return { type: groupesConstants.DELETE_FAILURE, error } }
}
