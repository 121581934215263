import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import moment               from 'moment';
//import Autocomplete from "react-google-autocomplete";
import Autosuggest from 'react-autosuggest';
import ScrollUpButton from "react-scroll-up-button";
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router';
import {etablissementActions} from '../../_actions';

import Navbar  from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import projectList from "../../communes/communes.json";
//import parse from "less/lib/less/parse";
//import {history} from "../../_helpers";
import {Alert} from "react-st-modal";
import 'icheck/skins/all.css'; // or single skin css

import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';


const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class ListeAnnonce extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.location.state && this.props.location.state.ville && this.props.location.state.codePostal ? this.props.location.state.ville+" "+this.props.location.state.codePostal : '',
            suggestions: [],
            activated: this.props.activated,
            codePostal: this.props.location.state ? this.props.location.state.codePostal : '',
            ville: this.props.location.state ? this.props.location.state.ville : '',
            placeholder: this.props.location.state ? this.props.location.state.ville+" "+this.props.location.state.codePostal : 'Entrez un Code Postal ou une Ville',
            lat: this.props.location.state ? this.props.location.state.lat : '',
            lng: this.props.location.state ? this.props.location.state.lng : '',
            rayon: this.props.location.state ? this.props.location.state.rayon : '',
            distance: this.props.location.state ? this.props.location.state.distance : false,
            dep: this.props.location.state ? this.props.location.state.dep : false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            start: 0,
            end: 15,
            selected: 0,
            active: 1,
            screenWidth: null,
        };

        this.clickCheckBox   = this.clickCheckBox.bind(this);
        this.handleChange    = this.handleChange.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);
        this.renderSpecs     = this.renderSpecs.bind(this);

        this.goBackDepartement = this.goBackDepartement.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getAllActivatedAnnonces(this.props.history));
        this.setState({activated: this.props.activated});

        window.addEventListener("resize", this.updateWindowDimensions());
        window.scrollTo(0,0)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activated !== this.state.activated) {
            let activated = this.props.activated;

            this.setState({
                activated
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.activated !== prevState.activated) {
            return {activated: nextProps.activated};
        } else return null;
    }


    _renderTime(time) {
        let now = moment().toISOString();
        let diff = moment.duration(moment(time).diff(moment(now)));

        let days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité dans { days } jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité immédiate  <span className="green-dot"></span>
                </p>
            )
        }
    }

    clickCheckBox(e) {
        const { name, type, checked } = e.target;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    [name]: true,
                    start: 0,
                    end: 15
                })
            } else {
                this.setState({
                    [name]: false,
                    start: 0,
                    end: 15
                })
            }
        }
    }

    handleChange(event) {
        event.preventDefault();

        const { name, value, type } = event.target;


        if (name === "rayon") {
            if (!this.state.lat && !this.state.lng) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    rayon: value,
                    distance: true,
                    start: 0,
                    end: 15
                })
            }
        } else {
            this.setState({
                [name]: value,
                start: 0,
                end: 15
            })
        }

    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            codePostal: '',
            ville: '',
            placeholder: 'Entrez un Code Postal ou une Ville',
            lat: '',
            lng: '',
            rayon: '',
            distance: false,
            dep: false,
            start: 0,
            end: 15,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {

        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            codePostal: suggestion.value.codePostal,
            ville: suggestion.value.nom,
            lat: suggestion.value.geoLoc.coordinates[1],
            lng: suggestion.value.geoLoc.coordinates[0],
            rayon: "5",
            distance: true
        })

    };

    supprimerFiltre() {
        this.props.dispatch(etablissementActions.getAllActivatedAnnonces(this.props.history));
        this.setState({
            value: '',
            suggestions: [],
            activated: '',
            codePostal: '',
            ville: '',
            placeholder: 'Entrez un Code Postal ou une Ville',
            lat: '',
            lng: '',
            rayon: '',
            distance: false,
            dep: false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            start: 0,
            end: 15,
            active: 1,
        })
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { lat, lng } = this.state;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(lat, lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(lat, lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    renderFilter(item) {
        const {lat, lng, distance} = this.state;
        const codePostal = this.state.codePostal ?
            (this.state.codePostal.indexOf(item.codePostal) >= 0) :
            this.state.ville ?
                (this.state.ville.indexOf(item.ville) >= 0) :
                this.state.dep ?
                    this.state.dep === item.codePostal.slice(0, 2)
                    : true;
        const rayon = distance === true  ? (this.distanceInKmBetweenEarthCoordinates(lat, lng, item.latitude, item.longitude) <= parseInt(this.state.rayon, 10)) : true;
        let type;

        switch (item.titre) {
            case "EHPAD"                        :   type = this.state.type1; break;
            case "USLD"                         :   type = this.state.type2; break;
            case "Résidence autonomie"          :   type = this.state.type3; break;
            case "Résidence services seniors"   :   type = this.state.type4; break;
            default :   type = true;
        }
        const locality = (codePostal || distance);

        return (locality && rayon && type);
    }

    getImages(item) {
        let images = [];

        if (item.image_un)
            images.push({
                original: "/img/annonces/" + item.image_un
            });
        if (item.image_deux)
            images.push({
                original: "/img/annonces/" + item.image_deux
            });
        if (item.image_trois)
            images.push({
                original: "/img/annonces/" + item.image_trois
            });
        if (item.image_quatre)
            images.push({
                original: "/img/annonces/" + item.image_quatre
            });

        return images
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15, selected})
        window.scrollTo(0,0)
    };

    renderSpecs(item) {
        let specs = this.state.activated.specs.filter(elem => parseInt(elem.id_annonce) === parseInt(item.id));

        specs.map(item => {
           if  (item.info_hebergement === 'Possibilité de court séjour / séjour temporaire')
               item.info_hebergement = "Possibilité de court séjour / temporaire"
        });

        if (specs.length > 0)
        return (
            <p className="subTitleResultat" id="type">
                <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités : </strong><br/>
                    {"- " + specs[0].info_hebergement}<br/>
                    {specs[1] ? "- " + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? "- " + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? "- " + specs[3].info_hebergement : null}
            </p>
        );
        else
            return null
    }

    goBackDepartement() {
        this.props.history.push({
            pathname: '/',
            state: { dep: true }
        })
    }

    changeInput(name, value) {
        this.setState({
            [name]: !value,
            start: 0,
            end: 15
        })
    }

    render() {
        const {screenWidth, lat, lng, start, end, selected, rayon, value, suggestions, type1, type2, type3, type4, distance} = this.state;

        let filtredAnnonces = this.state.activated.list ? this.state.activated.list.filter(item => this.renderFilter(item)) : [];
        filtredAnnonces = distance ? filtredAnnonces.sort((a, b) => this.sortDistance(a, b)) : filtredAnnonces;

        const inputProps = {
            placeholder: 'Entrez un code postal ou une ville',
            value,
            onChange: this.onChange
        };

        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                {
                    !(this.state.activated) ?
                        <div className="container">
                            <hr/>
                            <h1><span>Chargement</span> des <span>Annonces</span></h1>
                            <img
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        </div>
                        :
                        <main>
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/">Accueil</a> &gt; Annonces des établissements</p>
                                    <h1>Annonces <span>des établissements</span></h1>
                                    <span style={{textAlign: "center", display: "block", fontWeight: "bold", margin: "2rem 0", color: "#c4574e"}}>Les annonces sont déposées directement par les établissements qui ont une place disponible à vous proposer</span>
                                    <form>
                                        <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "0.1rem", margin: "0 0 0.5rem 0"}}>
                                            <div className="infoSpecial">
                                                {
                                                    screenWidth > 763 ?
                                                    <div className="listeEtablissementTri">
                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce1.png"
                                                                     alt="EHPAD"
                                                                     onClick={() => this.changeInput("type1", type1)}
                                                                     title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_1"
                                                                       style={{fontSize: '1.4rem'}}>EHPAD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{transform: 'scale(1.5)', width: '30px'}}
                                                                   id="appbundle_alertedispo_etablissement_1"
                                                                   name="type1"
                                                                   value={type1}
                                                                   checked={type1}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce3.png"
                                                                     alt="USLD"
                                                                     onClick={() => this.changeInput("type2", type2)}
                                                                     title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_2"
                                                                       style={{fontSize: '1.4rem'}}>USLD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{transform: 'scale(1.5)', width: '30px'}}
                                                                   id="appbundle_alertedispo_etablissement_2"
                                                                   name="type2"
                                                                   value={type2}
                                                                   checked={type2}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce4.png"
                                                                     alt="Résidence autonomie"
                                                                     onClick={() => this.changeInput("type3", type3)}
                                                                     title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_3"
                                                                       style={{fontSize: '1.4rem'}}>Résidence
                                                                    autonomie</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{transform: 'scale(1.5)', width: '30px'}}
                                                                   id="appbundle_alertedispo_etablissement_3"
                                                                   name="type3"
                                                                   value={type3}
                                                                   checked={type3}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce5.png"
                                                                     alt="Résidence services seniors"
                                                                     onClick={() => this.changeInput("type4", type4)}
                                                                     title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_4"
                                                                       style={{fontSize: '1.4rem'}}>Résidence
                                                                    services
                                                                    seniors</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{transform: 'scale(1.5)', width: '30px'}}
                                                                   id="appbundle_alertedispo_etablissement_4"
                                                                   name="type4"
                                                                   value={type4}
                                                                   checked={type4}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>
                                                    </div>
                                                        :
                                                        <div style={{justifyContent: 'center'}}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce1.png"
                                                                                 alt="EHPAD"
                                                                                 onClick={() => this.changeInput("type1", type1)}
                                                                                 title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type1"
                                                                                checked={type1}
                                                                                name="type1"
                                                                                value={type1}
                                                                                onChange={() => this.changeInput("type1", type1)}
                                                                            />
                                                                            <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce3.png"
                                                                                 alt="USLD"
                                                                                 onClick={() => this.changeInput("type2", type2)}
                                                                                 title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type2"
                                                                                checked={type2}
                                                                                name="type2"
                                                                                value={type2}
                                                                                onChange={() => this.changeInput("type2", type2)}
                                                                            />
                                                                            <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce4.png"
                                                                                 alt="Résidence autonomie"
                                                                                 onClick={() => this.changeInput("type3", type3)}
                                                                                 title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type3"
                                                                                checked={type3}
                                                                                name="type3"
                                                                                value={type3}
                                                                                onChange={() => this.changeInput("type3", type3)}
                                                                            />
                                                                            <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce5.png"
                                                                                 alt="Résidence services seniors"
                                                                                 onClick={() => this.changeInput("type4", type4)}
                                                                                 title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type4"
                                                                                checked={type4}
                                                                                name="type4"
                                                                                value={type4}
                                                                                onChange={() => this.changeInput("type4", type4)}
                                                                            />
                                                                            <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                        </div>
                                                }
                                                <div className="secondFiltre">
                                                    <div className="villeInput triVille"
                                                         style={{position: "relative", width: "100%", margin: "0 auto"}}>

                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                            style={{alignSelf: 'center'}}
                                                        />
                                                    </div>
                                                    <a style={{marginTop: '4%'}}><div style={{textAlign: 'center'}} onClick={this.goBackDepartement}>ou choisir un département ?</div></a>

                                                    <div className="listeKm" style={{marginRight: "1rem"}}>
                                                        <button className={rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChange}>
                                                            5km
                                                        </button>
                                                        <button className={rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChange}>
                                                            15km
                                                        </button>
                                                        <button className={rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChange}>
                                                            30km
                                                        </button>
                                                        <button className={rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChange}>
                                                            50km
                                                        </button>
                                                        <button className={rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChange}>
                                                            100km
                                                        </button>
                                                    </div>

                                                    <div className="bouton submit subConf">
                                                        <input type="reset" name="supprimer"
                                                               value="Supprimer les Filtres"
                                                               style={{marginTop: "10px"}}
                                                               onClick={this.supprimerFiltre}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <p className="text-center col-xs-12" style={{margin: "0"}}>
                                    </p>

                                    <div id="listeDemande">
                                        <p className="text-center col-xs-12" style={{textAlign: "center", display: "block", fontWeight: "bold", fontSize: "17px", margin: "2rem 0", color: "#c4574e"}}>
                                            {
                                                filtredAnnonces.length === 0 ?
                                                    <b>
                                                        Aucun résultat ne correspond à votre demande. Vous pouvez néanmoins augmenter votre zone de recherche en modifiant les kilomètres ou en changeant de ville
                                                    </b>
                                                    :
                                                    <b>
                                                        {filtredAnnonces.length} {filtredAnnonces.length === 1 ? "annonce correspond" : "annonces correspondent"} à
                                                        votre recherche.
                                                    </b>
                                            }
                                        </p>


                                        {
                                            filtredAnnonces.slice(start, end).map((item) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={item.id}>
                                                    <div className="singleDemande listeannonceAdmin">
                                                        {
                                                            this.state.distance ? <div className="distSentenceCont">À moins de {this.distanceInKmBetweenEarthCoordinates(lat, lng, item.latitude, item.longitude)}km. <span style={{float: 'right', color: 'grey'}}><strong>Publiée le: {moment(item.createdAt).format('DD/MM/YYYY')}</strong></span></div> : <div><span style={{float: 'right', color: 'grey'}}><strong>Publiée le: {moment(item.createdAt).format('DD/MM/YYYY')}</strong></span></div>
                                                        }
                                                        <div className="">
                                                            <div className="question">
                                                                <p className="titleSingleResultat" id="nbPlace">
                                                                    {item.nbPLace} place(s) disponible(s) à {item.ville} {item.codePostal}
                                                                </p></div>
                                                        </div>

                                                        {
                                                            this.getImages(item).length > 0 ?
                                                                <ImageGallery additionalClass="ImageGallery"
                                                                              showThumbnails={false}
                                                                              showPlayButton={false}
                                                                              showBullets={false}
                                                                              showFullscreenButton={false}
                                                                              items={this.getImages(item)}
                                                                />
                                                                :
                                                                <img className="img-responsive" style={{margin:"2rem auto"}}
                                                                     src={ item.titre === 'EHPAD' ?
                                                                         "/img/home/homeBloc1/icone-annonce1.png" :
                                                                         item.titre === 'USLD' ?
                                                                             "/img/home/homeBloc1/icone-annonce3.png" :
                                                                             item.titre === 'Résidence autonomie' ?
                                                                                 "/img/home/homeBloc1/icone-annonce4.png" :
                                                                                 "/img/home/homeBloc1/icone-annonce5.png"}
                                                                     alt="icone-maison"/>
                                                        }
                                                        <div className="infoActivation">
                                                            { this._renderTime(item.datePlace) }

                                                            <p className="subTitleResultat" id="type">
                                                                <i className="fa fa-building-o  img-responsive" aria-hidden="true"> </i>
                                                                {item.titre}
                                                            </p>
                                                            <p className="subTitleResultat" id="type">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-budget.png"
                                                                     alt="icone-budget"/>
                                                                Budget mensuel : {item.prix}
                                                            </p>
                                                            { this.renderSpecs(item)}
                                                            <hr/>
                                                            <p className="subTitleResultat" id="type">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                     alt="icone-type-etablissement"/>
                                                                <strong style={{fontSize: '1.3em'}}>{item.nom_etablissement}</strong>
                                                            </p>
                                                            <p className="subTitleResultat" id="adresse">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-secteur.png"
                                                                     alt="icone-secteur"/>
                                                                {item.rue}, {item.codePostal} {item.ville}
                                                            </p>
                                                            <p className="subTitleResultat">
                                                                <i className="fa fa-phone img-responsive" aria-hidden="true"></i>
                                                                <a style={{fontSize: '1.5rem'}} href={`tel:${item.telephone_etablissement}`}>{item.telephone_etablissement}</a>
                                                            </p>
                                                            <p className="subTitleResultat mail">
                                                                <i className="fa fa-at" aria-hidden="true"></i>
                                                                <a href={"mailto:"+item.email_etablissement}>{item.email_etablissement}</a>
                                                            </p>
                                                            {
                                                                item.site_etablissement ?
                                                                <div className="subTitleResultat website">
                                                                    <i className="fa fa-internet-explorer" aria-hidden="true"> </i>
                                                                    <a rel="noopener noreferrer"
                                                                       href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                                       target="_blank">Site de l'établissement</a>
                                                                </div>
                                                                    : null
                                                            }
                                                            <br/>
                                                            {
                                                                item.descriptif ?
                                                                <p className="subTitleResultat description">
                                                                    <img className="img-responsive"
                                                                         src="/img/visualisation-demande/icone-commentaires.png"
                                                                         alt="icone-commentaires"/>
                                                                    {item.descriptif}
                                                                </p>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {/*
                                            this.renderPages(filtredAnnonces.length)
                                        */}
                                    </div>
                                    <div className="col-xs-12 pages">
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={Math.ceil(filtredAnnonces.length/15)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            forcePage={start === 0 ? 0 : selected}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'btn-page'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </div>

                                <div className="actions" style={{display: "flex"}}>
                                    <div className="bouton col-xs-12">
                                        <a href="/demande-etablissement">déposer une demande de place</a>
                                    </div>
                                    <div className="bouton col-xs-12">
                                        <a href="/alerte-dispo">créer une alerte dispo</a>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer/>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { activated } = state.etablissement;

    return {
        activated
    };
}

/*const connectedListeAnnonce =*/ export default withRouter(connect(mapStateToProps)(ListeAnnonce));
//export { connectedListeAnnonce as ListeAnnonce };
