import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {Link} from "react-router-dom";
import {contactActions} from "../../../../_actions";


class Admin extends Component {
    constructor(props){
        super(props)

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user : false)
        }
    }



    render() {
        const { alert } = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            <p className="chemin"><Link to="/">Accueil</Link> &gt; Mon compte</p>
                            <h1>Mon compte</h1>
                            <br/>
                            {
                                alert.message ?
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                    :
                                    null
                            }
                            <br/>
                            <div className="buttonMonCompte">
                                <div className="bouton submit">
                                    <Link to="/administrateur/activations">Activation</Link>
                                    <p>Activer les annonces des établissements et les demandes des familles.</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/alerte-dispo">Alerte dispo</Link>
                                    <p>Visualiser, modifier et supprimer les Alerte-dispo.</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/demande-etablissement">Demande des familles / Pros</Link>
                                    <p>Visualiser, modifier et supprimer les Demandes des familles.</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/annonce-etablissement">Annonces des établissements</Link>
                                    <p>Visualiser, modifier et supprimer les annonces des établissements.</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/etablissements">établissements</Link>
                                    <p>Visualiser, modifier et supprimer les établissements</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/groupes">groupes</Link>
                                    <p>Créer, visualiser et gérer les groupes</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/familles">familles</Link>
                                    <p>Visualiser, modifier et supprimer les familles</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/assistantes">pro</Link>
                                    <p>Visualiser, modifier et supprimer les pros</p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/administrateur/mes-messages" >Mes messages</Link>
                                    <p>Consulter mes messages</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>

            </div>
        )
    }
}

function mapStateToProps(state){
    const { authentication, alert } = state;
    const { user } = authentication;
    const { contact } = state;
    const { list } = contact;
    return {
        user,
        list,
        alert
    }
}

export default connect(mapStateToProps)(Admin)
