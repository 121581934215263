export const groupesConstants = {
    LIST_REQUEST: 'GROUPES_LIST_REQUEST',
    LIST_SUCCESS: 'GROUPES_LIST_SUCCESS',
    LIST_FAILURE: 'GROUPES_LIST_FAILURE',

    CREATE_REQUEST: 'GROUPES_CREATE_REQUEST',
    CREATE_SUCCESS: 'GROUPES_CREATE_SUCCESS',
    CREATE_FAILURE: 'GROUPES_CREATE_FAILURE',

    ACCOUNT_REQUEST: 'GROUPES_ACCOUNT_REQUEST',
    ACCOUNT_SUCCESS: 'GROUPES_ACCOUNT_SUCCESS',
    ACCOUNT_FAILURE: 'GROUPES_ACCOUNT_FAILURE',

    UPDATE_REQUEST: 'GROUPES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GROUPES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GROUPES_UPDATE_FAILURE',

    ACTIVATE_REQUEST: 'GROUPES_ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'GROUPES_ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'GROUPES_ACTIVATE_FAILURE',

    SINGLE_REQUEST: 'GROUPES_SINGLE_REQUEST',
    SINGLE_SUCCESS: 'GROUPES_SINGLE_SUCCESS',
    SINGLE_FAILURE: 'GROUPES_SINGLE_FAILURE',

    DELETE_REQUEST: 'GROUPES_DELETE_REQUEST',
    DELETE_SUCCESS: 'GROUPES_DELETE_SUCCESS',
    DELETE_FAILURE: 'GROUPES_DELETE_FAILURE',
}
