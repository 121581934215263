import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from "../../_components/Footer";
import Navbar from "../../_components/Navbar";

class Conditions_Generales extends Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                    <section className="placeMaisonBloc1">
                        <div className="container">
                            <div style={{textAlign: 'center', fontSize: '5rem'}}>
                                <b><span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b>
                                <h1><b>1er Réseau d’annonces pour les maisons de retraite</b></h1>
                                <hr style={{border: '0.9px solid #464e6d'}}/>
                                <h3 style={{color: '#c4574e'}}>I. PREAMBULE</h3>
                                <h3 style={{color: '#c4574e'}}>II. Conditions d’utilisation</h3>
                                <h3 style={{color: '#c4574e'}}>III. Charte d’utilisation</h3>
                                <h3 style={{color: '#c4574e'}}>IV. Données personnelles</h3>
                            </div>
                            <hr style={{border: '0.9px solid #464e6d'}}/>
                            <h3 style={{color: '#c4574e'}} refProp={this.Preambule}><b>I. PREAMBULE</b></h3>
                            <p>
                                <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> a été conçu sur le principe d’une plate-forme la moins contraignante possible pour ses utilisateurs et donc la plus facilement accessible. Notre objectif n’est pas la collecte de « datas » (informations) aussi nous nous efforçons de demander et donc de stocker le strict minimum d’informations. Notre seul engagement est de mettre en relation les personnes qui sont à la recherche d’une place en maison de retraite, à savoir les familles et/ou les professionnels qui accompagnent quotidiennement les familles, avec des établissements qui ont des réelles solutions à leurs proposer.
                            </p>
                            <h4 style={{color: '#c4574e'}}>
                                <b>Concept</b>
                            </h4>
                            <p>
                                <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> est une plate-forme à destination :
                            </p>
                            <ul>
                                <li>Des établissements, de type maison de retraite, qui ont une place disponible à proposer aux familles,</li>
                                <li>Des familles qui recherchent une place en maison de retraite,</li>
                                <li>Des professionnels qui informent et conseillent quotidiennement les familles dans leurs recherches.</li>
                            </ul>
                            <p></p>

                            <h4 style={{color: '#c4574e'}}>
                                <b>utilisations</b>
                            </h4>
                            <p>
                                1 - Les établissements créent et publient des annonces de places disponibles immédiatement ou dans les 30 jours. Ces annonces sont consultables par tous, à tout moment sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span><br />
                                2 - Les familles et/ou les professionnels qui les accompagnent créent et publient des demandes de place auprès des établissements pour une place disponible immédiatement ou dans les 3 mois. Ces demandes sont consultables dans leur intégralité uniquement par les établissements inscrits sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                            </p>

                            <h4 style={{color: '#c4574e'}}>
                                <b>Signification des mots clés utilisés sur la plate-forme</b>
                            </h4>
                            <p>
                            </p>
                            <ul>
                                <li>
                                    <strong>Familles</strong>
                                    <ul>
                                        <li>Personnes âgées, ses proches, son entourage</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Professionnels qui accompagnent les familles </strong>
                                    <ul>
                                        <li>
                                            Professionnels qui ont un rôle de conseil et d’information envers les
                                            familles.
                                            Sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> seuls ont accès à notre service les
                                            professionnels qui informent,
                                            conseillent et accompagnent les familles <b>sans contrepartie financière auprès
                                            des familles ou des établissements</b><br/>.
                                            <u> Exemples:</u>
                                            <ul>
                                                <li>Par métiers : Assistant(e) social(e), tuteur, médecin, infirmière…
                                                </li>
                                                <li>Par structures : CLIC, CCAS, cliniques, hôpitaux, SSR,
                                                    Département…
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Établissements</strong>
                                    <ul>
                                        <li>
                                            <strong>Établissements d’hébergement qui accueillent des personnes
                                                âgées</strong>
                                            <ul>
                                                <li>EHPAD (Établissement d’Hébergement pour Personnes Âgées
                                                    Dépendantes)
                                                </li>
                                                <li>USLD (Établissement ou Unité de Soins Longue Durée)
                                                </li>
                                                <li>Résidence autonomie (RA)</li>
                                                <li>Résidence Services Seniors (RSS)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Annonce de place disponible</strong>
                                    <ul>
                                        <li>
                                            Annonce créée et publiée par les établissements quand ils ont une place
                                            disponible à proposer, immédiatement ou dans les 30 jours à venir
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Demande de place</strong>
                                    <ul>
                                        <li>
                                            Demande créée et publiée par les familles ou les professionnels qui les
                                            accompagnent dans le cadre de leurs recherches de places disponibles
                                            immédiatement ou dans les 3 mois à venir.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Alerte-dispo</strong>
                                    <ul>
                                        <li>
                                            Dispositif permettant à une famille et/ou un professionnel qui l’accompagne
                                            de recevoir par email une annonce de place disponible à chaque fois qu’une
                                            nouvelle place est disponible dans sa zone de recherche et sur le type
                                            d’établissement choisi.
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                            <p></p>
                            <br/>
                            <h3 style={{color: '#c4574e'}} refProp={this.Conditions}><b>II. CONDITIONS D’UTILISATION</b></h3>

                            <p>
                                Vous trouverez ci-après les conditions d’utilisation de <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> classées en 2 parties :
                            </p>
                            <p>
                                - Pour les établissements : <strong>« Je suis un établissement »</strong><br />

                                - Pour les familles et les professionnels qui les accompagnent <strong>« Je suis une
                                famille ou un professionnel qui accompagne les familles »</strong>
                            </p>
                            <h4 style={{color: '#c4574e'}}><b>1 - « Je suis un établissement »</b></h4>
                            <p>Votre contact : <a href="mailto:etablissement@PlaceMaisonDeRetraite.fr">etablissement@PlaceMaisonDeRetraite.fr</a>
                            </p>
                            <p><u>Types d’établissement pouvant s’inscrire sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></u></p>
                            <p>Pour vous inscrire sur le site vous devez être un établissement de type :</p>
                            <ul>
                                <li>EHPAD (Établissement d’Hébergement pour Personnes Âgées Dépendantes)</li>
                                <li>USLD (Établissement ou Unités de Soins Longue Durée)</li>
                                <li>Résidence autonomie</li>
                                <li>Résidence Services Seniors</li>
                            </ul>
                            <p><u>Statut juridique des établissements pouvant s’inscrire sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> :</u></p>
                            <ul>
                                <li>Public</li>
                                <li>Privé à but non lucratif = Associatif</li>
                                <li>Privé commercial</li>
                            </ul>
                            <br/>
                            <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                                En sa qualité de propriétaire de la plate-forme <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, la société Silver Conseils pourra, à tout moment et sans condition, supprimer le compte des établissements qui ne respecteraient pas les Conditions Générales d’Utilisation de la plate-forme ou qui nuiraient à l’image et la réputation de la société Silver Conseils.
                            </p>
                            <br/>
                            <h4>I. Création d’un compte établissement sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></h4>
                            <h5>1 - Objectifs de l’inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></h5>
                            <p>L’inscription d’un établissement sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> lui permet :</p>

                            <p>
                                - De consulter sur le site les demandes des familles ou professionnels qui les
                                accompagnent, quand ces
                                demandes correspondent à son type d’établissement (EHPAD, USLD, RA, RSS) et à sa zone
                                géographique.<br />
                                - De créer et publier des annonces de places disponibles sur le site, consultables par
                                tous (sans inscription) à tout moment.
                            </p>

                            <h5>2 - Coûts de l’inscription et de l’utilisation de <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></h5>
                            <p>(Depuis janvier 2020)  <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> est intégralement <strong>gratuite</strong> pour les établissements</p>

                            <h5>3 - Comment créer son compte</h5>
                            <p>Cliquez sur « Inscription » puis cochez « Un établissement »</p>


                            <p>2.1 Données nécessaires pour l’inscription</p>
                            <ul>
                                <li>Identifiant (obligatoire)</li>
                                <li>Mot de passe (obligatoire 4 à 19 caractères)</li>
                            </ul>

                            <p>2.2 Informations pour l’administrateur du site = contact</p>
                            <ul>
                                <li>Civilité (facultatif)</li>
                                <li>Nom (facultatif)</li>
                                <li>Prénom (facultatif)</li>
                                <li>Téléphone Responsable (obligatoire)</li>
                                <li>E-mail Responsable (obligatoire) = Permet d’envoyer les informations au responsable de
                                    l’établissement et communiquer avec <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                                </li>
                                <li>E-mail de Secours et/ou de Groupe (facultatif) = Permet à une 2ème personne de recevoir les e-mails à destination du Responsable</li>
                            </ul>

                            <p>2.3 Informations sur l’établissement (certaines informations seront utilisées pour créer
                                les annonces)</p>
                            <ul>
                                <li>Nom de l’établissement (obligatoire) – <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>Groupe (facultatif)</li>
                                <li>Type d’établissement (obligatoire) – <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>Statut (obligatoire)</li>
                                <li>N° Finess (obligatoire)</li>
                                <li>Adresse (obligatoire) - <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>Ville / code postal (obligatoire) - <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>Téléphone Établissement (obligatoire) - <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>E-mail Établissement (obligatoire) - <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>Site Internet (facultatif) - <span style={{color: '#c4574e'}}>Visible sur l’annonce</span></li>
                                <li>
                                    Spécificités (facultatif)
                                    <ul>
                                        <li>Hébergement permanent</li>
                                        <li>Hébergement temporaire</li>
                                        <li>Accueil de jour</li>
                                        <li>Unité Alzheimer</li>
                                        <li>Aide sociale à l’hébergement</li>
                                    </ul>
                                </li>
                            </ul>

                            <p>2.4 Photos (Via le bouton « Mes photos » dans « Mon Compte ») </p>
                            <ul>
                                <li>
                                    4 photos représentants l’établissement (facultatif) – <span style={{color: '#c4574e'}}>Visible sur l’annonce</span>
                                    <ul>
                                        <li>
                                            Dimension maximale des photos : 2MB chacune
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h5>4 - Comment modifier son compte</h5>

                            <p>En se connectant, puis en cliquant sur « Mon compte » puis cliquant sur « Mes
                                informations » et sur « Mes photos ».
                                Faire les modifications puis cliquer sur « Enregistrer mes informations » et « Enregistrer mes images »</p>

                            <h5>5 - Comment supprimer son compte</h5>
                            <p>En envoyant un email à <a
                                href="mailto:etablissement@PlaceMaisonDeRetraite.fr">etablissement@PlaceMaisonDeRetraite.fr</a>
                            </p>

                            <h4>II. Fonctionnalités sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour un établissement</h4>

                            <h5>1- Créer et publier des annonces de places disponibles</h5>

                            <p>Pour publier une annonce, il faut avoir au <strong>moins une place à
                                proposer</strong> aux familles et/ou aux professionnels qui les accompagnent. Cette
                                place doit être disponible <strong>immédiatement ou dans les 30 jours à venir.</strong>
                            </p>

                            <ul>
                                <li><strong>Les annonces sont accessibles sans restriction à toute personne qui se
                                    connecte sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></strong></li>
                                <li><strong>Il n’est pas nécessaire d’avoir un compte pour accéder à ces
                                    informations.</strong></li>
                            </ul>

                            <h6>1-1 Rédaction de l’annonce de place disponible </h6>
                            <ul>
                                <li>Allez sur votre espace dédié « Mon compte » ou via la page d’accueil via « J’ai une
                                    place disponible » puis « Je dépose une annonce de place disponible »
                                </li>
                            </ul>

                            <p>Informations nécessaires pour une annonce</p>

                            <ul>

                                <li>Nombre de place = 1 pré-saisie, places maximums par annonce 10</li>
                                <li>A partir de quand ? = Date du jour pré-saisie, date futur maximum + 30 jours</li>
                                <li>Budget ? = Case à cocher (facultatif), si pas d’information alors NC sera inscrit
                                    <ul>

                                        <li>Budget global que vous estimez pour votre établissement et que vous
                                            souhaitez communiquer (facultatif)
                                            <ul>
                                                <li>Moins de 2000€</li>
                                                <li>De 2000€ à 2500€</li>
                                                <li>De 2500€ à 3000€</li>
                                                <li>De 3000€ à 3500€</li>
                                                <li>De 3500€ à 4000€</li>
                                                <li>Plus de 4000€</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                <li>Spécificités = Case à cocher, plusieurs choix possibles (facultatif)
                                    <ul>
                                        <li>Place en unité Alzheimer / protégée</li>
                                        <li>Possibilité de court séjour / séjour temporaire</li>
                                        <li>ASH possible (Aide Sociale à l’Hébergement)</li>
                                        <li>Place en accueil de jour</li>
                                    </ul>
                                </li>
                                </li>
                                <li>
                                    Informations supplémentaires = Champ de saisie libre pour des informations
                                    complémentaires que vous souhaitez transmettre aux familles et/ou les professionnels
                                    qui les informent.
                                    <ul>
                                        <li>Ces informations seront relues par le modérateur du site et pourront être
                                            supprimées si elles ne correspondent pas à la
                                            destination / objectif du site, si elles ne correspondent pas à une
                                            communication traditionnelle dans le secteur des maisons
                                            de retraite. Néanmoins l’établissement restera pleinement responsable de ses
                                            informations.
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <h6>1-2 Visualisation de l’annonce</h6>

                            <ul>
                                <li>
                                    Permet de voir l’ensemble les informations qui seront diffusées sur le site et
                                    accessibles à tous les visiteurs de la plate-forme même sans compte.
                                </li>
                            </ul>


                            <h6>1-3 Validation</h6>

                            <ul>
                                <li>
                                    Chaque annonce est relue par un modérateur pour être autorisée à sa diffusion sur le
                                    site. Cette relecture n’enlève pas la responsabilité de son rédacteur sur les
                                    informations et photos diffusées.
                                </li>
                            </ul>


                            <h6>1-4 Présence sur le site</h6>

                            <ul>
                                <li>
                                    Chaque annonce est présente sur le site, sans modification par son rédacteur,
                                    <strong> pendant 7 jours</strong>.
                                </li>
                                <li>
                                    Au bout de 7 jours, vous recevrez un email pour prolonger ou pas votre annonce pour
                                    une période de 7 jours.
                                </li>
                            </ul>


                            <h6>1-5 Modification</h6>

                            <ul>
                                <li>
                                    Vous pouvez modifier votre annonce à tout moment, par l’intermédiaire de l’accès «
                                    Mon compte » puis « Mes annonces ». Si vous modifiez votre annonce alors elle ne
                                    sera plus visible sur le site, le temps qu’elle soit à nouveau relue et validée par
                                    le modérateur pour sa rediffusion.
                                </li>
                            </ul>


                            <h6>1-6 Suppression</h6>

                            <ul>
                                <li>
                                    Vous pouvez supprimer votre annonce à tout moment via votre espace « Mon compte »
                                    puis « Mes annonces » puis en cliquant sur le bouton supprimer de votre annonce.
                                </li>
                            </ul>

                            <h5>2 - Consulter les annonces publiées par les familles ou les professionnels qui les
                                accompagnent</h5>

                            <p>
                                En qualité « d’établissement » inscrit sur la plate-forme <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, vous
                                avez accès aux « demandes de place » publiées par les familles ou les professionnels qui
                                les accompagnent.
                            </p>

                            <h5>2-1 Quelles demandes ?</h5>

                            <ul>
                                <li>
                                    Les demandes qui correspondent à votre type d’établissement défini dans « Mon compte
                                    » puis dans « Type d’établissement » (EHPAD ou RSS, USLD, Résidence Autonomie).
                                </li>
                                <li>
                                    Les demandes pour le secteur géographique où vous êtes localisés.
                                </li>
                            </ul>


                            <h5>2-2 Utilisation des informations issues de la demande de place</h5>

                            <ul>
                                <li>
                                    Vous vous engagez à utiliser les données / informations des familles ou des
                                    professionnels qui les accompagnent, uniquement dans le cadre de la demande de place
                                    que vous avez reçue. Ces données ne peuvent être utilisées que pour le seul compte
                                    de votre établissement. Elles ne peuvent pas être transmises à tout autre
                                    établissement inscrit ou non sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, ou à tout autre
                                    fournisseur ou entreprise.
                                </li>
                                <li>
                                    Si la demande de place est supprimée de votre espace « Mes demandes de familles »,
                                    c’est à l’initiative de la famille ou du professionnel qui l’accompagne aussi ces
                                    informations ne sont plus exploitables.
                                </li>
                            </ul>
                            <br/>
                            <h4 style={{color: '#c4574e'}}><b>2 - « Je suis une famille ou un professionnel qui accompagne les familles »</b></h4>

                            <p>
                                Votre contact si vous êtes une famille : <a href="mailto:famille@PlaceMaisonDeRetraite.fr">famille@PlaceMaisonDeRetraite.fr</a><br />
                                Votre contact si vous êtes un professionnel : <a href="mailto:pro@PlaceMaisonDeRetraite.fr">pro@PlaceMaisonDeRetraite.fr</a>
                            </p>

                            <strong style={{color: '#c4574e'}}>Préambule</strong>
                            <p>
                                « Un professionnel qui accompagne les familles » sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> est un
                                professionnel qui informe et conseille les familles sans contrepartie financière auprès
                                d’elles ou des établissements.
                            </p>

                            <h4>
                                I. Création d’un compte famille ou professionnel qui accompagne les familles sur
                                <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                            </h4>

                            <h5>
                                1 - Inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                            </h5>

                            <p>
                                L’inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> est gratuite pour les familles et les
                                professionnels qui les accompagnent.
                            </p>

                            <h5>
                                2 - Comment créer son compte
                            </h5>

                            <p>Cliquez sur « Inscription » puis cochez « Famille ou un proche » ou « Un professionnel »</p>

                            <p>Données nécessaires pour l’inscription</p>
                            <ul>
                                <li>
                                    Identifiant (obligatoire)
                                </li>
                                <li>Email (obligatoire)</li>
                                <li>Mot de passe (obligatoire)</li>
                            </ul>

                            <h5>3 - Comment modifier son compte</h5>

                            <p>
                                En se connectant, puis en cliquant sur « Mon compte » puis cliquant sur « Mes
                                informations ». Faire les modifications puis cliquer sur « Enregistrer mes informations
                                »
                            </p>

                            <h5>4 - Comment supprimer son compte</h5>

                            <p>En envoyant un email à <a
                                href="mailto:famille@PlaceMaisonDeRetraite.fr">famille@PlaceMaisonDeRetraite.fr</a> ou
                                à <a href="mailto:pro@PlaceMaisonDeRetraite.fr">pro@PlaceMaisonDeRetraite.f</a></p>

                            <h4>II. Fonctionnalités sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour une famille ou un professionnel
                                qui accompagne les familles</h4>

                            <h5>1- Créer et publier des demandes de places disponibles auprès des établissements</h5>

                            <p>Pour publier une demande de place, il faut être à la recherche d’une place en maison de
                                retraite pour une personne âgée. Cette demande peut être pour une demande à effet
                                immédiat jusqu’à 3 mois.</p>

                            <h6>1-1 Rédaction d’une demande de place auprès des établissements</h6>

                            <h6>1-1-1 Informations visibles par tous</h6>

                            <ul>
                                <li>
                                    Les informations ci-dessous sont accessibles sans restriction à toute personne qui
                                    se connecte sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>. Il n’est pas nécessaire d’avoir un
                                    compte pour accéder à ces informations.
                                    <ul>
                                        <li>
                                            Type d’établissement recherché - plusieurs choix possibles (obligatoire)
                                            <ul>
                                                <li>EHPAD</li>
                                                <li>USLD</li>
                                                <li>Résidence Autonomie</li>
                                                <li>Résidence Services Seniors</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Secteur géographique (obligatoire)
                                            <ul>
                                                <li>Ville</li>
                                                <li>Rayon : 15km, 30km, 50km, 100km</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Pour qui (obligatoire)
                                            <ul>
                                                <li>Sexe : Une femme, un homme, ou couple</li>
                                                <li>Âge</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h6>
                                1-1-2 Informations uniquement visibles par les établissements inscrits sur le site
                            </h6>

                            <ul>
                                <li>
                                    Les informations ci-dessous <span style={{color: '#c4574e', fontWeight: 'bold'}}>ne sont accessibles que par les établissements enregistrés sur le site</span> <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>

                                    <ul>
                                        <li>Ces établissements doivent être présents sur la zone de recherche déterminée
                                            par le rédacteur de la demande de place.
                                        </li>
                                        <li>Ces établissements doivent correspondre au type d’établissement choisi par
                                            le rédacteur de la demande de place.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                     Où se trouve la personne âgée ? (Obligatoire)
                                    <ul>
                                        <li>Domicile</li>
                                        <li>Famille</li>
                                        <li>Hôpital/Clinique</li>
                                        <li>Autre établissement</li>
                                    </ul>
                                </li>
                                <li>

                                    Quel est le budget mensuel ? (Obligatoire)
                                    <ul>
                                        <li>ASH (Aide Sociale à l’Hébergement)</li>
                                        <li>Moins de 2000€</li>
                                        <li>De 2000€ à 2500€</li>
                                        <li>De 2500€ à 3000€</li>
                                        <li>De 3000€ à 3500€</li>
                                        <li>De 3500€ à 4000€</li>
                                        <li>Plus de 4000€</li>
                                    </ul>
                                </li>
                                <li>
                                     Type d’hébergement
                                    <ul>
                                        <li>Hébergement permanent</li>
                                        <li>Hébergement temporaire</li>
                                        <li>Accueil de jour</li>
                                        <li>Unité Alzheimer</li>
                                    </ul>
                                </li>
                                <li>
                                     Personne à contacter (obligatoire)
                                    <ul>
                                        <li>
                                            Civilité
                                            <ul>
                                                <li>Madame, Monsieur, Mademoiselle</li>
                                            </ul>
                                        </li>
                                        <li>Nom (obligatoire)</li>
                                        <li>Entité pour les pros (facultatif)</li>
                                        <li>Email (obligatoire)</li>
                                        <li>Téléphone (obligatoire)</li>
                                        <li>
                                            Autres Informations
                                            <ul>
                                                <li>Champ de saisie libre pour des informations complémentaires que vous
                                                    souhaitez transmettre aux établissements.
                                                </li>
                                                <li>Ces informations seront relues par le modérateur du site et pourront
                                                    être supprimées si elles ne correspondent pas à la destination /
                                                    objectif du site, si elles ne correspondent pas à une communication
                                                    traditionnelle dans le secteur des maisons de retraite.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <h6>1-2 Visualisation de la demande</h6>
                            <ul>
                                <li>
                                    Permet de voir l’ensemble les informations qui seront diffusées sur le site.
                                    <ul>
                                        <li>Accessibles à tous les visiteurs de la plate-forme même sans compte pour la
                                            partie 1-1-1 ci-dessus.
                                        </li>
                                        <li>Accessibles uniquement par les établissements pour la partie 1-1-2
                                            ci-dessus.
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h6>1-3 Validation</h6>

                            <ul>
                                <li>
                                    Chaque demande est relue par un modérateur pour être autorisée à sa diffusion sur le
                                    site. Cette relecture n’enlève pas la responsabilité de son rédacteur sur les
                                    informations diffusées.
                                </li>
                            </ul>
                            <h6>1-4 Présence sur le site</h6>

                            <ul>
                                <li>Chaque demande est présente sur le site, sans modification par son rédacteur,
                                    <strong> pendant 30 jours</strong>.
                                </li>
                                <li>Au bout de 30 jours, vous recevrez un email pour prolonger ou pas votre demande de
                                    place.
                                </li>
                            </ul>


                            <h6>1-5 Modification</h6>
                            <ul>
                                <li>
                                    Vous pouvez modifier votre demande de place à tout moment, par l’intermédiaire de
                                    l’accès « Mon compte » puis « Mes demandes de places ». Si vous modifiez votre
                                    demande de place alors elle ne sera plus visible sur le site, le temps qu’elle soit
                                    à nouveau relue et validée par le modérateur pour sa rediffusion.
                                </li>
                            </ul>

                            <h6>1-6 Suppression</h6>
                            <ul>
                                <li>
                                    Vous pouvez supprimer votre demande de place à tout moment via votre espace « Mon
                                    compte » puis « Mes demandes de places » puis en cliquant sur le bouton supprimer de
                                    votre demande de place.
                                </li>
                            </ul>

                            <h5>2 - Consulter les annonces de places disponibles publiées par les établissements</h5>

                            <p>
                                Les annonces de places disponibles publiées par les établissements sont disponibles dans
                                leur intégralité et sans restriction par tous sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>. Il
                                n’est pas nécessaire d’être connecté à son compte pour visualiser les annonces.
                            </p>

                            <h5>3 - Créer une « Alerte-Dispo »</h5>

                            <p>Dispositif permettant à une famille et/ou un professionnel qui l’accompagne de recevoir
                                par email une annonce de place disponible à chaque fois qu’une nouvelle place est
                                disponible dans sa zone de recherche et sur le type d’établissement choisi.</p>

                            <h6>3-1 Création de l’« Alerte-dispo »</h6>

                            <h6>3-1.1 Informations sur la recherche</h6>

                            <ul>
                                <li>
                                    Type d’établissement - plusieurs choix possibles (obligatoire)
                                    <ul>

                                        <li>EHPAD</li>
                                        <li>USLD</li>
                                        <li>Résidence Autonomie</li>
                                        <li>Résidence Services Seniors</li>
                                    </ul>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    Secteur géographique (obligatoire)
                                    <ul>
                                        <li>Ville</li>
                                        <li>Rayon : 10km, 20km, 30km, 50km</li>
                                    </ul>
                                </li>
                            </ul>

                            <h6>3-1.2 Information personnelle</h6>

                            <ul>
                                <li>E-mail (obligatoire) : Permet de vous envoyer vos alertes par e-mail. Cette adresse
                                    est à destination uniquement de <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>.
                                </li>
                            </ul>

                            <h6>3-2 Suppression de l’« Alerte-dispo »</h6>

                            <h6>3-2.1 Via votre « Compte »</h6>

                            <ul>
                                <li>
                                    Si vous avez un compte : via « Mon compte » puis « Mes Alertes-dispo » puis en
                                    cliquant sur « supprimer »
                                </li>
                            </ul>


                            <h6>3-2.2 Via les e-mails « Alerte-Dispo » reçues</h6>

                            <ul>
                                <li>
                                    En cliquant sur le bouton « Supprimer » disponible en bas de chaque e-mail d’«
                                    Alerte-dispo » reçu
                                </li>
                            </ul>


                            <h6>3-2.3 Via l’e-mail de confirmation de la création de votre « Alerte-Dispo »</h6>

                            <ul>
                                <li>
                                    En cliquant sur le bouton « Supprimer votre Alerte-Dispo » disponible en bas de
                                    l’e-mail
                                </li>
                            </ul>
                            <br/>
                            <h3 style={{color: '#c4574e'}} refProp={this.Charte}><b>III. CHARTE D’UTILISATION</b></h3>


                            <h4 style={{color: '#c4574e', fontWeight: 'bold'}}>Pour les Établissements</h4>

                            <strong>- Inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></strong>

                            <p>
                                1. Pour être inscrit et utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, l’établissement doit
                                être soit un EHPAD, USLD, une Résidence Autonomie ou une Résidence Services
                                Seniors.<br />
                                2. L’inscription et l’utilisation du site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> sont gratuites pour les établissements,<br />
                                3. L’établissement est pleinement responsable des informations et photos qu’il
                                enregistre sur son compte.
                            </p>

                            <strong>- Publication d’annonces de places disponibles</strong>

                            <p>
                                4. L’établissement est pleinement responsable des annonces qu’il publie sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> même si celles-ci sont relues par un modérateur.<br />
                                5. L’établissement certifie que chaque annonce correspond à une disponibilité qu’il en
                                mesure de proposer à une famille.<br />
                                6. L’établissement s’engage à supprimer ou ne pas prolonger  son annonce quand celle-ci n’a plus d’objet.<br />
                                7. L’établissement peut avoir au maximum 3 annonces simultanément en ligne sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>.<br />
                            </p>

                            <strong>- Consultation des demandes de places disponibles des familles et/ou professionnels
                                qui les accompagnent</strong>

                            <p>
                                8. L’établissement s’engage à contacter les familles ou les professionnels qui les
                                accompagnent suite à une demande de place disponible de leur part, uniquement si
                                l’établissement a concrètement une solution à leur proposer.<br />
                                9. L’établissement s’engage à utiliser les données, informations des familles ou des
                                professionnels qui les accompagnent, uniquement dans le cadre de l’annonce pour laquelle
                                il a été contacté ou dans le cadre de la demande de place qu’il a reçue. Ces données ne
                                peuvent être utilisées que pour le seul compte de l’établissement. Elles ne peuvent pas
                                être transmises à tout autre établissement inscrit ou non sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> ou autre prestataire ou entreprise.
                            </p>

                            <strong>- Communication avec <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></strong>

                            <p>
                                10. L’établissement accepte de recevoir par e-mail, les informations, recommandations,
                                conseils envoyés par le gestionnaire du site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                            </p>

                            <p>Dans le cas du non-respect de l’un des articles de la présente charte d’utilisation,
                                alors l’établissement incriminé verra son compte supprimé sans délais et sans condition.</p>

                            <br/>
                            <h4 style={{color: '#c4574e', fontWeight: 'bold'}}>Pour les familles ou pour les professionnels qui les accompagnent informent et
                                conseillent les familles sans contrepartie financière auprès d’elles ou des
                                établissements </h4>


                            <strong>- Inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></strong>

                            <p>
                                1 - Pour être inscrit et/ou utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, le professionnel
                                qui accompagne les familles doit les informer et les conseiller sans contrepartie
                                financière auprès d’elles ou des établissements.
                                2 - Pour être inscrit et/ou utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, la famille ou le
                                professionnel qui l’accompagne doit être en situation de recherche effective
                                d’établissement pour une personne âgée.
                                3 - La famille ou le professionnel qui l’accompagne est pleinement responsable des
                                informations qu’il enregistre sur son compte.
                            </p>
                            <strong>- Publication des demandes de places auprès des établissements</strong>
                            <p>
                                4 - La famille ou le professionnel qui l’accompagne est pleinement responsable des demandes
                                de places qu’il publie sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> à destination des établissements
                                inscrits sur le site, même si celles-ci sont relues par un modérateur.<br />
                                5 - La famille ou le professionnel qui l’accompagne certifie que chaque « demande de place
                                auprès des établissements » correspond à une recherche effective qu’il adresse aux
                                établissements.<br />
                                6 - La famille ou le professionnel qui l’accompagne s’engage à supprimer ou ne pas prolonger sa demande de place
                                quand celle-ci n’a plus d’objet.
                            </p>

                            <strong>- Consultation des annonces de places disponibles des établissements</strong>

                            <p>
                                7 - La famille ou le professionnel qui l’accompagne s’engage à utiliser les données,
                                informations des établissements, uniquement dans le cadre de leur recherche. Ces données
                                ne peuvent être utilisées que pour le seul compte de la famille ou du professionnel qui
                                l’accompagne.
                            </p>
                            <strong>- Inscription à une « Alerte-Dispo »</strong>

                            <p>
                                8 - La famille ou le professionnel qui l’accompagne est pleinement responsable de son
                                inscription à une « Alerte-Dispo » sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>.
                            </p>

                            <strong>- Communication avec <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></strong>

                            <p>
                                9 - La famille ou le professionnel qui l’accompagne accepte de recevoir par e-mail, les
                                informations, recommandations, conseils envoyés par le gestionnaire du site
                                <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                            </p>

                            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Dans le cas du non-respect de l’un des articles de la présente charte d’utilisation,
                                alors la famille ou le professionnel qui l’accompagne incriminé verra son compte
                                supprimé sans délais, sans condition.</p>

                            <br/>
                            <h3 style={{color: '#c4574e'}} refProp={this.Donnees}><b>IV. DONNEES PERSONNELLES</b></h3>

                            <p><strong>Conformément au Règlement Général sur la Protection des Données (RGPD) et dans la
                                continuité de la Loi française « Informatique et Libertés » de 1978, vous trouverez
                                ci-dessous les informations vous permettant d’accéder à vos données pour les modifier ou
                                les supprimer.</strong></p>

                            <br/>
                            <h4 style={{color: '#c4574e', fontWeight: 'bold'}}>Pour les Établissements</h4>

                            <p><strong>Adresse de contact = <a
                                href="mailto:etablissement@PlaceMaisonDeRetraite.fr">etablissement@PlaceMaisonDeRetraite.fr</a></strong>
                            </p>

                            <h4>1- Informations contenues dans « Mes Informations »</h4>

                            <p>Ces informations sont enregistrées à l’initiative de l’établissement lors de son
                                inscription.</p>

                            <h5>1-1 Modification</h5>
                            <ul>
                                <li>

                                    L’ensemble des informations est modifiable à tout moment par l’établissement en se
                                    connectant à « Mon compte » puis en cliquant sur « Mes informations » puis en allant
                                    sur :
                                    <ul>
                                        <li>« Mon établissement »</li>
                                        <li>« Mes identifiants »</li>
                                    </ul>
                                </li>
                            </ul>

                            <h5>1-2 Suppression</h5>

                            <ul>
                                <li>
                                    La suppression des données contenues dans « Mes Informations » entraine la
                                    suppression définitive du compte de l’établissement de la plate-forme
                                    <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                                    <ul>
                                        <li>
                                            Envoyer un email à etablissement@PlaceMaisonDeRetraite.fr avec les
                                            informations suivantes :
                                            <ul>
                                                <li>Objet : Suppression de mon compte établissement</li>
                                                <li>Identifiant : xxx</li>
                                                <li>E-mail du Responsable : xxxx@xxx</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h4>2- Informations contenues dans « Mes annonces »</h4>

                            <p>Ces informations sont enregistrées à l’initiative de l’établissement lors de la création
                                et la publication de ses annonces.</p>

                            <h5>2-1 Modification</h5>
                            <ul>
                                <li>
                                    L’ensemble des informations est modifiable à tout moment par l’établissement en se
                                    connectant à <strong>« Mon compte »</strong> puis en cliquant sur <strong>« Mes Annonces »</strong> puis en cliquant
                                    sur le bouton <strong>« MODIFIER »</strong> de l’annonce à modifier.
                                </li>
                            </ul>

                            <h5>2-2 Suppression</h5>

                            <ul>
                                <li>
                                    Une annonce est supprimable à tout moment par l’établissement en se connectant à <strong>«
                                    Mon compte »</strong> puis en cliquant sur <strong>« Mes Annonces »</strong> puis en cliquant sur le bouton <strong>«
                                    SUPPRIMER »</strong> de l’annonce à supprimer.
                                </li>
                                <li>
                                    L’annonce est également automatiquement supprimée au bout de 7 jours de parution et
                                    peut être prolonger à nouveau pour 7 jours si l’établissement l’accepte en répondant
                                    positivement à la question « Voulez-vous la réactiver pour une semaine ? » envoyée
                                    par e-mail.
                                </li>
                            </ul>

                            <br/>
                            <h4 style={{color: '#c4574e', fontWeight: 'bold'}}>Pour les familles ou les professionnels qui les accompagnent</h4>

                            <p>
                                <strong>Adresse de contact pour les familles =</strong> <a href="mailto:famille@PlaceMaisonDeRetraite.fr">famille@PlaceMaisonDeRetraite.fr</a><br/>
                                <strong>Adresse de contact pour les professionnels =</strong> <a href="mailto:pro@PlaceMaisonDeRetraite.fr">pro@PlaceMaisonDeRetraite.fr</a>
                            </p>

                            <h4>1- Informations contenues dans « Mes Informations »</h4>

                            <p>Ces informations sont enregistrées à l’initiative de la famille ou du professionnel qui
                                l’accompagne lors de son inscription.</p>

                            <h5>1-1 Modification</h5>

                            <ul>
                                <li>
                                    L’ensemble des informations est modifiable à tout moment par la famille ou le
                                    professionnel qui l’accompagne en se connectant à <strong>« Mon compte »</strong> puis en cliquant
                                    sur <strong>« Mes informations »</strong>
                                </li>
                            </ul>

                            <h5>1-2 Suppression</h5>
                            <ul>
                                <li>
                                    La suppression des données contenues dans « Mes Informations » <strong>entraine la
                                    suppression définitive du compte de la famille ou du professionnel qui l’accompagne
                                    de la plate-forme</strong> <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                                    <ul>
                                        <li>
                                            Envoyer un email à famille@PlaceMaisonDeRetraite.fr ou à
                                            pro@PlaceMaisonDeRetraite.fr avec les informations suivantes :
                                            <ul>
                                                <li>Objet : Suppression de mon compte famille (ou professionnel)</li>
                                                <li>Identifiant : xxx</li>
                                                <li>E-mail de contact : xxxx@xxx</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <h4>2 - Informations contenues dans « Mes demandes de place »</h4>

                            <p>
                                Ces informations sont enregistrées à l’initiative de la famille ou du professionnel qui
                                l’accompagne lors de la création et la publication de ses demandes.
                            </p>

                            <h5>2-1 Modification</h5>
                            <ul>
                                <li>
                                    L’ensemble des informations est modifiable à tout moment par la famille ou le
                                    professionnel qui l’accompagne en se connectant à <strong>« Mon compte »</strong> puis en cliquant
                                    sur <strong>« Mes demandes de place auprès des établissements »</strong> puis en cliquant sur le
                                    bouton <strong>« MODIFIER »</strong> de la demande à modifier.
                                </li>
                            </ul>

                            <h5>2-2 Suppression</h5>

                            <ul>
                                <li>
                                    Une annonce est supprimable à tout moment par la famille ou le professionnel qui
                                    l’accompagne en se connectant à <strong>« Mon compte »</strong> puis en cliquant sur <strong>« Mes demandes
                                    de place auprès des établissements »</strong> puis en cliquant sur le bouton <strong>« SUPPRIMER »</strong> de
                                    la demande à supprimer.
                                </li>
                                <li>
                                    La demande est également automatiquement supprimée au bout de 30 jours de parution
                                    et peut être prolonger à nouveau pour 30 jours si la famille ou le professionnel qui
                                    l’accompagne l’accepte en répondant positivement à la question « Voulez-vous la
                                    réactiver pour un mois ? » envoyé par e-mail.
                                </li>
                            </ul>


                            <h4>3- Informations contenues dans « Mes Alertes-dispo »</h4>

                            <h5>3-1 Modification</h5>

                            <ul>
                                <li>
                                    Via « Mon Compte »
                                    <ul>
                                        <li>
                                            En se connectant à « Mon compte » puis « Mes Alertes-dispo » puis en
                                            cliquant sur « Modifier » de l’ « Alerte-Dispo » choisie.
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <h5>3-2 Suppression</h5>

                            <ul>
                                <li>
                                    Via « Mon Compte »
                                    <ul>
                                        <li>
                                            En se connectant à « Mon compte » puis « Mes Alertes-dispo » puis en
                                            cliquant sur « Supprimer » de l’« Alerte-Dispo » choisie.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Via les e-mails « Alerte-Dispo » reçus
                                    <ul>
                                        <li>
                                            En cliquant sur le bouton « Supprimer votre Alerte-Dispo » disponible en bas
                                            de chaque e-mail d’« Alerte-dispo » reçu
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Via l’e-mail de confirmation de la création de l’« Alerte-Dispo »
                                    <ul>
                                        <li>
                                            En cliquant sur le bouton « Supprimer votre Alerte-Dispo» disponible en bas
                                            de l’e-mail
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Conditions_Generales)