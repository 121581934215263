import { authHeader } from '../_helpers';
import {userActions} from "../_actions";

export const dispoService = {
    postAlertDispo,
    delAlertDispo,
    getAlerteDispo,
    getAlerteDispoById,
    updateAlertDispo
};

/********************/
/* Post Alert Dispo */
/********************/

function postAlertDispo(dispo) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({dispo})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/postAlertDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/**********************/
/* Update Alert Dispo */
/**********************/

function updateAlertDispo(dispo) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({dispo})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/updateAlertDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/**********************/
/* Delete Alert Dispo */
/**********************/

function delAlertDispo(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/delAlertDispo`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/***********************/
/* Get All Alert Dispo */
/***********************/

function getAlerteDispo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/getAlertDispo`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/*************************/
/* Get Alert Dispo By Id */
/*************************/

function getAlerteDispoById(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/dispo/getAlertDispoById`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/***************************************/
/* Call BAck handle Response from Back */
/***************************************/

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
