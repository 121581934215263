import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {Confirm} from "react-st-modal";
import Autosuggest from 'react-autosuggest';

import {history} from "../../../../_helpers";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {etablissementActions} from "../../../../_actions";
import projectList from "../../../../communes/communes.json";
import {withRouter} from "react-router";
//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Personne_Annonces extends Component {

    constructor(props){
        super(props)

        this.state = {
            value: '',
            suggestions: [],
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            demandes: {},
            single: {},
            preview: false,
            deleted: [],
            filter: {
                Codepostal: '',
                ville: '',
                Type: '',
                direc: 'asc',
                suppress: false
            }
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getDemandeById(this.props.user.user.id))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.demandes !== this.state.demandes) {
            let demandes = this.props.demandes;

            this.setState({
                demandes
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.demandes !== prevState.demandes) {

            return {demandes: nextProps.demandes};
        } else return null;
    }

    handlePreview(event) {
        event.preventDefault();

        const array = this.props.demandes.demandes.filter(item => item.id == event.target.id)

        this.setState({
            single: array[0],
            preview: true
        })
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false})
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        let response = await Confirm("Voulez-vous vraiment supprimer cette demande?", "", "Oui", "Fermer");
        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            this.props.dispatch(etablissementActions.delDemande(id))
        }
    }

    handleChangeFilter(e) {
        const {name, value} = e.target;
        const {filter, demandes} = this.state;

        if (name === 'direc') {
            this.setState({
                demande: {
                    ...demandes,
                    demandes: demandes.demandes.reverse()
                },
                filter: {
                    ...filter,
                    direc: value,
                    suppress: value === 'desc'
                }
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value
                }
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            filter: {
                ...this.state.filter,
                Codepostal: '',
                ville: '',
            }
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {filter} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            filter: {
                ...filter,
                Codepostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
            }

        })

    };

    renderFilter(item) {
        const {filter, deleted} = this.state;
        const {types} = this.props.demandes;

        let t_final = []
        types.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );

        const Codepostal = filter.Codepostal  !== '' ? (filter.Codepostal === item.Codepostal) : filter.ville !== '' ? (filter.ville === item.ville) : true;
        const Type = filter.Type  !== '' ? t_final.includes(parseInt(filter.Type)) : true;
        const notDel = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;

        return (Codepostal && Type && notDel)
    }

    renderActive(item) {
        switch (item.activate) {
            case 1 : return <div>Activée <span className="green-dot"> </span></div>;
            case -1: return <div>En attente d'activation <span className="orange-dot"> </span></div>;
            case 0 : return <div>Désactivée système<span className="orange-dot"> </span></div>;
            default: return <div>Désactivée utilisateur<span className="orange-dot"> </span></div>;
        }
    }

    fill_types(types, item) {
        let t_final = [];

        types.filter(function(e) {
            return (e.demande_etablissement_id == item.id)
        }).map(el =>
            t_final.push(String(el.type_etablissement_id))
        );

        return t_final
    }

    fill_specs(specs, item) {
        let s_final = [];

        specs.filter(function(e) {
            return (e.demande_etablissement_id == item.id)
        }).map(el =>
            s_final.push(String(el.information_hebergement_id))
        );

        return s_final
    }

    redirectToModif(e, demandes, item){
        e.preventDefault();

        let demande = item;
        let types = this.fill_types(demandes.types, item);
        let specs = this.fill_specs(demandes.infos, item);


        this.props.history.push({pathname: '/demande-modification', state: {demandes: {demande, types, specs}}});
    }

    handleActivate = (item, val) => async (e) => {
        e.preventDefault();
        await this.props.dispatch(etablissementActions.activateDemande(item.id, val));
        await this.props.dispatch(etablissementActions.getDemandeById(this.props.user.user.id));
        window.location.reload()
    }

    supprimerFiltre() {
        const {filter, demandes} = this.state;
        this.setState({
            demande: {
                ...demandes,
                demandes: filter.suppress ? demandes.demandes.reverse() : demandes.demandes
            },
            filter: {
                Codepostal: '',
                ville: '',
                Type: '',
                direc: 'asc',
                suppress: false
            },
            value: ''
        })
    }

    render(){
        moment.locale('fr');
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const specs = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];
        const {preview, single, demandes, value, suggestions, filter} = this.state;

        let t_final = [];
        let s_final = [];

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        const { alert } = this.props;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                    {
                        !preview ?
                        <section className="listeDemandeBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/personnes">Mon compte</a> &gt; Mes demandes de places
                                </p>
                                <h1>Mes demandes <span>de places</span></h1>

                                <div className="col-xs12 bouton voir">
                                    <a href="/demande-etablissement">Ajouter une demande de place</a>
                                </div>
                                <br/>
                                <div className="filtres col-xs-12" style={{border: "#bec8c9 .1rem solid", borderRadius: '5%', padding: "1rem", margin: "0 0 1rem 0"}}>
                                    <p>Filtrer par</p>
                                    <div className="inputInfos col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                            highlightFirstSuggestion={true}
                                        />
                                    </div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <select id="order" name="direc" onChange={this.handleChangeFilter} value={filter.direc}>
                                            <option value="asc">Du + ancien au plus récent</option>
                                            <option value="desc">Du + récent au plus ancien</option>
                                        </select>
                                    </div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <select id="etablissement" name="Type" onChange={this.handleChangeFilter} value={filter.Type}>
                                            <option value="">Type d'établissement</option>
                                            <option value="1">EHPAD</option>
                                            <option value="2">USLD</option>
                                            <option value="3">Résidence autonomie</option>
                                            <option value="4">Résidence services seniors</option>
                                        </select>
                                    </div>
                                    <div className="bouton submit subConf" style={{float: 'right'}}>
                                        <input type="reset" name="supprimer"
                                               value="Supprimer les Filtres"
                                               style={{margin: "10px"}}
                                               className="rainbow rainbow-1"
                                               onClick={this.supprimerFiltre}/>
                                    </div>
                                </div>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        null
                                }
                                <br/>
                                <div id="listeDemande">
                                    {!this.props.demandes.demandes ?
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                        :
                                        demandes.demandes.filter(item => this.renderFilter(item)).map((item, index) =>
                                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                <div className="singleDemande">
                                                    {this.renderActive(item)}
                                                    <p>Demande publiée le {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                    <p>par: <strong>{item.username}</strong></p>
                                                    <div className="col-xs-12">
                                                        <div className="question">
                                                            <p>Cherche place à {item.ville} ({item.Codepostal})</p>
                                                        </div>
                                                    </div>

                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-sexe.png"
                                                             alt="icone-sexe"/>
                                                        {!item.is_couple ?
                                                            <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age} ans</strong></p>
                                                            :
                                                            <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age_premier} ans</strong> et <strong>{item.age_second} ans</strong></p>
                                                        }
                                                    </div>

                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-type-etablissement.png"
                                                             alt="icone-type-etablissement"/>
                                                        <p>Type d'établissement : <br/>
                                                            {
                                                                demandes.types.map((t, index) => {
                                                                    if (t.demande_etablissement_id === item.id) {
                                                                        return (
                                                                            <strong key={index}>
                                                                                {types[t.type_etablissement_id - 1]}
                                                                                <br/>
                                                                            </strong>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-secteur.png"
                                                             alt="icone-secteur"/>
                                                        <p>Secteur géographique : <br/><strong>{item.ville}, dans un rayon
                                                            de {item.rayon}
                                                            km</strong></p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-echeance.png"
                                                             alt="icone-echeance"/>
                                                        <p>Echéance : <strong>{item.echeance}</strong></p>
                                                    </div>
                                                    <div className="col-xs12 bouton voir">
                                                        <button onClick={(e) => this.handlePreview(e, item)} id={item.id}>Voir</button>
                                                    </div>

                                                    <div className="col-xs12 bouton supprimer">
                                                        <button onClick={(e) => this.handleDelete(e, item.id)} id={item.id}>Supprimer</button>
                                                    </div>
                                                    <div className="col-xs12 bouton voir">
                                                        <button onClick={(e) => this.redirectToModif(e, demandes, item)}>Modifier</button>
                                                    </div>
                                                    {
                                                        item.activate === -2 || item.activate === 0?
                                                            <div className="col-xs12 bouton supprimer">
                                                                <button onClick={this.handleActivate(item, 1)} id={item.id}>Activer</button>
                                                            </div>
                                                            :
                                                            item.activate === 1 ?
                                                                <div className="col-xs12 bouton supprimer">
                                                                    <button onClick={this.handleActivate(item, -2)} id={item.id}>Désactiver</button>
                                                                </div>
                                                                :
                                                                null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </section>
                        :
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a
                                        href="/personnes/mes-annonces">Mes demandes de places</a> &gt; Je
                                        visualise la demande</p>
                                    <h1>Je visualise <span>la demande</span></h1>

                                    <p className="titleForm">Informations visibles par tous</p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">Cherche place à {single.ville}</p>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-sexe.png"
                                                         alt="icone-sexe"/>
                                                    {!single.is_couple ?
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age} ans</strong></p>
                                                        :
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age_premier} ans</strong> et <strong>{single.age_second} ans</strong></p>
                                                    }
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-type-etablissement.png"
                                                         alt="icone-type-etablissement"/>
                                                        <p>Type d'établissement : <br/>
                                                            {
                                                                demandes.types.map((t, index) => {
                                                                    if (t.demande_etablissement_id === single.id) {
                                                                        return (
                                                                            <strong key={index}>
                                                                                {types[t.type_etablissement_id - 1]}
                                                                                <br/>
                                                                            </strong>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-secteur"/>
                                                        <p>Secteur géographique : <strong>{single.ville}, dans un rayon de {single.rayon}
                                                            km</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-echeance"/>
                                                        <p>Echéance : <strong>{single.echeance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="titleForm">Informations visibles uniquement par les établissements
                                        identifiés sur le site <span>Placemaisonderetraite.fr</span></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_2 col-xs-12">
                                            <div className="row">
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-lieu.png"
                                                         alt="icone-lieu"/>
                                                        <p>La personne se trouve : <strong>{single.logemennt}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-budget"/>
                                                        <p>Budget mensuel : <strong>{single.budget}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                                                        <p>Type d’hébergement  : <br />
                                                            {
                                                                demandes.infos.map((i, index) => {
                                                                    if (i.demande_etablissement_id === single.id) {
                                                                        return (
                                                                            <strong key={index}>
                                                                                {specs[i.information_hebergement_id - 1]}
                                                                                <br/>
                                                                            </strong>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                        <ul>
                                                            <li><strong>{single.civilite} {single.nom}</strong></li>
                                                            <li>Tél : <strong>{single.telephone}</strong></li>
                                                            <li>Email : <strong>{single.email}</strong></li>
                                                        </ul>
                                                </div>
                                                {
                                                    single.entite ?
                                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                            <img className="img-responsive"
                                                                 src="/img/mails/building.png"
                                                                 alt="icone-budget"/>
                                                            <p>Entité : <strong>{single.entite}</strong></p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <br/>
                                                {
                                                    single.commentaires ?
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img
                                                                src="/img/visualisation-demande/icone-commentaires.png"
                                                                alt="icone-commentaires"/>
                                                            <p>Autres informations: <br/><strong>{single.commentaires}</strong></p>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="boutons col-xs-12">
                                            <div className="row">
                                                <div className="bouton modifier">
                                                    <button onClick={this.handleBack}>Retour</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { authentication, etablissement, alert } = state;
    const { user } = authentication;
    const { demandes } = etablissement;
    return  {
        user,
        alert,
        demandes
    }
}

export default withRouter(connect(mapStateToProps)(Personne_Annonces))
