import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ReCaptcha} from 'react-recaptcha-google'
import Autosuggest from 'react-autosuggest';

import lib from '../../lib/lib';
import Navbar from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import {etablissementActions} from "../../_actions";
import projectList from "../../communes/communes.json";
import {withRouter} from "react-router";
//import Autocomplete from "react-google-autocomplete";
import {Alert} from "react-st-modal";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

const local_user = JSON.parse(localStorage.getItem('user'));

class Modif_Demande extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.location.state.demandes.demande.ville+' '+this.props.location.state.demandes.demande.Codepostal,
            suggestions: [],
            demande: {
                user_id: local_user ? local_user.user.id : 0,
                titre: '',
                id: this.props.location.state.demandes.demande.id,
                Codepostal: this.props.location.state.demandes.demande.Codepostal,
                code_insee: this.props.location.state.demandes.demande.code_insee,
                rayon: this.props.location.state.demandes.demande.rayon,
                echeance: this.props.location.state.demandes.demande.echeance,
                sexe: this.props.location.state.demandes.demande.sexe,
                age: this.props.location.state.demandes.demande.age,
                is_couple: this.props.location.state.demandes.demande.is_couple,
                age_premier: this.props.location.state.demandes.demande.age_premier,
                age_second: this.props.location.state.demandes.demande.age_second,
                logemennt: this.props.location.state.demandes.demande.logemennt,
                budget: this.props.location.state.demandes.demande.budget,
                nb_piece: this.props.location.state.demandes.demande.nb_piece,
                civilite: this.props.location.state.demandes.demande.civilite,
                nom: this.props.location.state.demandes.demande.nom,
                email: this.props.location.state.demandes.demande.email,
                telephone: this.props.location.state.demandes.demande.telephone,
                commentaires: this.props.location.state.demandes.demande.commentaires,
                entite: this.props.location.state.demandes.demande.entite,
                ville: this.props.location.state.demandes.demande.ville,
                latitude: this.props.location.state.demandes.demande.latitude,
                longitude: this.props.location.state.demandes.demande.longitude,
                activate: -1,
                token: this.props.location.state.demandes.demande.token,
                types: this.props.location.state.demandes.types,
                specs: this.props.location.state.demandes.specs,
            },
            maxLength: 400,
            preview: false,
            condition: false,
            message: "",
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeSpec = this.handleChangeSpec.bind(this);
        this.changeInput = this.changeInput.bind(this);

        this.handlePreview  = this.handlePreview.bind(this);
        this.goBack         = this.goBack.bind(this);

        this.handleChangeCond = this.handleChangeCond.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        const {demande} = this.state;
        // Here you will get the final recaptchaToken!!!
        if (recaptchaToken.length > 0) {
            this.setState({
                demande: {
                    ...demande,
                    token: this.props.user.user.confirmation_token
                }
            })
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        const {demande} = this.state;

        if (name === 'sexe' && value === 'Un couple') {
            this.setState({
                demande: {
                    ...demande,
                    [name]: value,
                    is_couple: 1,
                }
            })
        } else if (name === 'sexe' && value !== 'Un couple') {
            this.setState({
                demande: {
                    ...demande,
                    [name]: value,
                    is_couple: 0,
                }
            })
        } else {
            this.setState({
                demande: {
                    ...demande,
                    [name]: lib.protectEntry(value)
                }
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            demande: {
                ...this.state.demande,
                Codepostal: '',
                code_insee: '',
                ville: '',
                latitude: '',
                longitude: ''
            }
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {demande} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            demande: {
                ...demande,
                Codepostal: suggestion.value.codePostal,
                code_insee: suggestion.value.codePostal,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0]
            }
        })

    };

    handleChangeType(event) {
        const {value, checked} = event.target;
        const {demande} = this.state;

        if (!checked) {
            this.setState({
                demande: {
                    ...demande,
                    types: demande.types.filter(function (item) {
                        return item != value;
                    })
                }
            })
        } else {
            this.setState({
                demande: {
                    ...demande,
                    types: [...demande.types, value]
                }
            })
        }
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {demande} = this.state;

        if (!checked) {
            this.setState({
                demande: {
                    ...demande,
                    specs: demande.specs.filter(function (item) {
                        return item != value;
                    })
                }
            })
        } else {
            this.setState({
                demande: {
                    ...demande,
                    specs: [...demande.specs, value]
                }
            })
        }
    }

    handleChangeCond() {
        const {condition} = this.state;

        this.setState({condition: !condition})
    }

    handlePreview(e) {
        e.preventDefault();

        const {demande, preview} = this.state;
        if (
            demande.types.length > 0 &&
            demande.ville.length > 0 &&
            demande.echeance.length > 0 &&
            demande.sexe.length > 0 &&
            demande.logemennt.length > 0 &&
            demande.budget.length > 0 &&
            demande.civilite.length > 0 &&
            demande.nom.length > 0 &&
            demande.email.length > 0 &&
            demande.telephone.length > 0
        ) {
            this.setState({preview: !preview})
            window.scrollTo(0, 0)
        } else {
            Alert("Merci de remplir tous les champs requis")
        }
    }

    goBack(e) {
        e.preventDefault();

        this.setState({preview: false})
        window.scrollTo(0, 0)
    }

    handleSubmit(e) {
        e.preventDefault();
        const {demande, condition} = this.state;
        if (condition) {
            this.props.dispatch(etablissementActions.updateDemande(demande, this.props.history));
            this.setState({message: "mise à jour effectuée - retour à vos demandes"});
        } else {
            Alert("Veuillez lire et accepter les conditions générales d'utilisation")
        }
    }

    renderTypes(array) {
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];

        return (
            <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <img className="img-responsive"
                     src="/img/visualisation-demande/icone-type-etablissement.png"
                     alt="icone-type-etablissement"/>
                <p>Type d'établissement:
                    {array.map(id =>
                        <strong key={id}><br/>{types[id - 1]}</strong>
                    )
                    }
                </p>
            </div>
        )

    }

    renderSpecs(array) {
        const specs = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];

        return (
            <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                <p>Type d'hebergement:
                    {array.map(id =>
                        <strong key={id}><br/>{specs[id - 1]}</strong>
                    )}
                </p>
            </div>
        )

    }

    changeInput(value) {
        const {demande} = this.state;

        if (demande.types.includes(value)) {
            this.setState({
                demande: {
                    ...demande,
                    types: demande.types.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                demande: {
                    ...demande,
                    types: [...demande.types, value]
                }
            })
        }
    }

    render() {
        const {demande, preview, condition, message, value, suggestions} = this.state;
        const {alert} = this.props;

        // Log error status and clear dropdown when Google Maps API returns an error.
        const onError = (status, clearSuggestions) => {
            clearSuggestions()
        };

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    {
                        !preview ?
                            <section className="demandeEtablissementBloc1">
                                <div className="container">
                                    {
                                        this.props.user && (this.props.user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || this.props.user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                                            <p className="chemin">
                                                <a href="/personnes">Mon compte</a> > <a href="/personnes/mes-annonces">Mes demandes de places</a> > Modifier une demande
                                            </p>
                                            :
                                            <p className="chemin"><a href="/">Accueil</a> &gt; <a href="/place-maison">Je
                                                cherche
                                                une
                                                place
                                                en maison de retraite</a> &gt; Je modifie ma demande auprès des
                                                établissements
                                            </p>
                                    }
                                    <h1>Je modifie une demande <span>auprès des établissements</span></h1>
                                    <p>1ère étape = Rédigez votre demande en quelques clics </p>
                                    <p>2ème étape = Visualisez et validez votre demande</p>
                                    <p>3ème étape = Créez votre accès ou identifiez vous (le cas échéant)</p>

                                    <p>Votre demande sera ensuite validée par <span className="urltitre2">Place<span
                                        className="rouge">Maison</span>De<span
                                        className="rouge">Retraite</span>.fr</span>
                                    </p>
                                    <p>A tout moment, vous pourrez modifier et/ou supprimer votre demande.</p>
                                    <p>Dans un mois vous recevrez un email pour savoir si vous souhaitez prolonger ou
                                        supprimer
                                        votre demande en fonction des résultats de votre demande.</p>


                                    <div className="encadreForm">

                                        <div className="text-center text-success validationDemandeEtablissement">
                                        </div>
                                        <form name="appbundle_demandeetablissement" className="form-horizontal">

                                            <div className="formBloc1">
                                                <div className="formBloc1_1 col-xs-12">
                                                    <div className="row">
                                                        <p className="titleForm">Informations visibles par tous</p>
                                                    </div>
                                                </div>

                                                <div className="formBloc1_2 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Quel type d’établissement recherchez-vous ? <span
                                                                className="rouge">*</span></p>
                                                            <p>Sélection multiple possible</p>
                                                        </div>

                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3" >
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive" style={{marginLeft: 'auto', marginRight: 'auto', width: '50%'}}
                                                                     src="/img/home/homeBloc1/icone-annonce1.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("1")}
                                                                     title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_1" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>EHPAD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                                   id="appbundle_alertedispo_etablissement_1"
                                                                   name="etablissement"
                                                                   value="1"
                                                                   checked={demande.types.includes("1")}
                                                                   onChange={this.handleChangeType}/>
                                                        </div>

                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive" style={{marginLeft: 'auto', marginRight: 'auto', width: '50%'}}
                                                                     src="/img/home/homeBloc1/icone-annonce3.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("2")}
                                                                     title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>

                                                                <label htmlFor="appbundle_alertedispo_etablissement_2" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>USLD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                                   id="appbundle_alertedispo_etablissement_2"
                                                                   name="etablissement"
                                                                   value="2"
                                                                   checked={demande.types.includes("2")}
                                                                   onChange={this.handleChangeType}/>
                                                        </div>

                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive" style={{marginLeft: 'auto', marginRight: 'auto', width: '50%'}}
                                                                     src="/img/home/homeBloc1/icone-annonce4.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("3")}
                                                                     title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_3" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence autonomie</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                                   id="appbundle_alertedispo_etablissement_3"
                                                                   name="etablissement"
                                                                   value="3"
                                                                   checked={demande.types.includes("3")}
                                                                   onChange={this.handleChangeType}/>
                                                        </div>

                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive" style={{marginLeft: 'auto', marginRight: 'auto', width: '40%'}}
                                                                     src="/img/home/homeBloc1/icone-annonce5.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("4")}
                                                                     title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_4" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence
                                                                    services
                                                                    seniors</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                                   id="appbundle_alertedispo_etablissement_4"
                                                                   name="etablissement"
                                                                   value="4"
                                                                   checked={demande.types.includes("4")}
                                                                   onChange={this.handleChangeType}/>
                                                        </div>

                                                    </div>
                                                    <br/>
                                                </div>

                                                <div className="formBloc1_3 col-xs-12 col-sm-6">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Dans quel secteur géographique recherchez-vous ?<span
                                                                className="rouge">*</span></p>
                                                        </div>
                                                        <div className="inputSecteur">
                                                            <div className="inputInfos col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                                                <label>Code postal de la ville</label>
                                                                <Autosuggest
                                                                    suggestions={suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                                    getSuggestionValue={getSuggestionValue}
                                                                    renderSuggestion={renderSuggestion}
                                                                    inputProps={inputProps}
                                                                    highlightFirstSuggestion={true}
                                                                />
                                                            </div>
                                                            <div className="selectRayon ">
                                                                <label>Dans un rayon de</label>
                                                                <select id="appbundle_demandeetablissement_rayon"
                                                                        name="rayon"
                                                                        value={demande.rayon}
                                                                        onChange={this.handleChange}
                                                                        selected={demande.rayon}>
                                                                    <option value="5" >5 km</option>
                                                                    <option value="15" >15 km</option>
                                                                    <option value="30" >30 km</option>
                                                                    <option value="50" >50 km</option>
                                                                    <option value="100" >100 km</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc1_4 col-xs-12 col-sm-6">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>A quelle échéance ?<span className="rouge">*</span></p>
                                                        </div>
                                                        <div className="inputEcheance">
                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_echeance_0"
                                                                       name="echeance"
                                                                       required="required"
                                                                       value="Dès maintenant"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.echeance === "Dès maintenant"}
                                                                />
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_echeance_0"
                                                                    className="required">Dès maintenant</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_echeance_1"
                                                                       name="echeance"
                                                                       required="required"
                                                                       value="D'ici 1 mois"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.echeance === "D'ici 1 mois"}
                                                                />
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_echeance_1"
                                                                    className="required">D'ici 1 mois</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_echeance_2"
                                                                       name="echeance"
                                                                       required="required"
                                                                       value="D'ici 3 mois"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.echeance === "D'ici 3 mois"}
                                                                />
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_echeance_2"
                                                                    className="required">D'ici 3 mois</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc1_5 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Pour qui ?<span className="rouge">*</span></p>
                                                        </div>
                                                        <label>Une femme ? Un homme ? Un couple ?<br/></label>
                                                        <div className="inputQui">
                                                            <select id="appbundle_demandeetablissement_sexe"
                                                                    name="sexe" onChange={this.handleChange}
                                                                    defaultValue={demande.sexe}>
                                                                <option value="Une femme" >femme</option>
                                                                <option value="Un homme" >homme</option>
                                                                <option value="Un couple" >couple</option>
                                                            </select>
                                                            {
                                                                demande.is_couple === 0?
                                                                    <div className="singleForm">
                                                                        <div className="inputAge">
                                                                            <label><label
                                                                                htmlFor="appbundle_demandeetablissement_age">Age</label><span
                                                                                className="rouge">*</span></label>
                                                                            <input type="number"
                                                                                   id="appbundle_demandeetablissement_age"
                                                                                   name="age"
                                                                                   min="50"
                                                                                   max="120" value={demande.age}
                                                                                   onChange={this.handleChange}/>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="coupleForm">
                                                                        <div className="inputAge"
                                                                             style={{display: "inline"}}>
                                                                            <label><label
                                                                                htmlFor="appbundle_demandeetablissement_agePremier">Age</label><span
                                                                                className="rouge">*</span></label>
                                                                            <input type="number"
                                                                                   id="appbundle_demandeetablissement_agePremier"
                                                                                   name="age_premier"
                                                                                   min="50" max="120"
                                                                                   value={demande.age_premier}
                                                                                   onChange={this.handleChange}/>
                                                                        </div>
                                                                        <div className="inputAge"
                                                                             style={{display: "inline"}}>
                                                                            <label><label
                                                                                htmlFor="appbundle_demandeetablissement_ageSecond">et</label></label>
                                                                            <input type="number"
                                                                                   id="appbundle_demandeetablissement_ageSecond"
                                                                                   name="age_second"
                                                                                   min="50"
                                                                                   max="120" value={demande.age_second}
                                                                                   onChange={this.handleChange}/>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formBloc2">
                                                <div className="formBloc2_1 col-xs-12">
                                                    <div className="row">
                                                        <p className="titleForm">Informations visibles uniquement par
                                                            les
                                                            établissements identifiés sur le site <span>Place<span
                                                                className="rouge">maison</span>de<span
                                                                className="rouge">retraite</span>.fr</span></p>
                                                        <div className="question">
                                                            <p>
                                                                Où se trouve la personne âgée ? <span
                                                                className="rouge">*</span>
                                                            </p>
                                                        </div>
                                                        <div className="inputLieu">

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_logemennt_0"
                                                                       name="logemennt"
                                                                       required="required"
                                                                       value="Domicile"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.logemennt === "Domicile"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_logemennt_0"
                                                                    className="required">Domicile</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_logemennt_1"
                                                                       name="logemennt"
                                                                       required="required"
                                                                       value="Famille"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.logemennt === "Famille"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_logemennt_1"
                                                                    className="required">Famille</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_logemennt_2"
                                                                       name="logemennt"
                                                                       required="required"
                                                                       value="Hôpital/Clinique"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.logemennt === "Hôpital/Clinique"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_logemennt_2"
                                                                    className="required">Hôpital/Clinique</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_logemennt_3"
                                                                       name="logemennt"
                                                                       required="required"
                                                                       value="Autre établissement"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.logemennt === "Autre établissement"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_logemennt_3"
                                                                    className="required">Autre établissement</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc2_2 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Quel est le budget mensuel ?<span
                                                                className="rouge">*</span>
                                                            </p>
                                                        </div>
                                                        <div className="inputBudget">
                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_0"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="ASH (Aide Sociale à l’Hébergement)"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "ASH (Aide Sociale à l’Hébergement)"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_0"
                                                                       className="required">ASH (Aide Sociale à l’Hébergement)</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_0"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="Moins de 2000€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "Moins de 2000€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_0"
                                                                       className="required">Moins de 2000€</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_1"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="De 2000€ à 2500€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "De 2000€ à 2500€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_1"
                                                                       className="required">De 2000€ à 2500€</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_2"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="De 2500€ à 3000€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "De 2500€ à 3000€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_2"
                                                                       className="required">De 2500€ à 3000€</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_3"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="De 3000€ à 3500€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "De 3000€ à 3500€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_3"
                                                                       className="required">De 3000€ à 3500€</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_4"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="De 3500€ à 4000€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "De 3500€ à 4000€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_4"
                                                                       className="required">De 3500€ à 4000€</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_budget_5"
                                                                       name="budget"
                                                                       required="required"
                                                                       value="Plus de 4000€"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.budget === "Plus de 4000€"}/>
                                                                <label htmlFor="appbundle_demandeetablissement_budget_5"
                                                                       className="required">Plus de 4000€</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc2_3 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Type d’hébergement</p>
                                                            <p>Sélection multiple possible</p>
                                                        </div>
                                                        <div
                                                            className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row">
                                                                <input type="checkbox"
                                                                       id="appbundle_demandeetablissement_informationEtablissement_1"
                                                                       name="informationEtablissement"
                                                                       value="1" onChange={this.handleChangeSpec}
                                                                       checked={demande.specs.find(el => el == "1")}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_informationEtablissement_1">Hebergement
                                                                    permanent</label>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row">
                                                                <input type="checkbox"
                                                                       id="appbundle_demandeetablissement_informationEtablissement_2"
                                                                       name="informationEtablissement"
                                                                       value="2" onChange={this.handleChangeSpec}
                                                                       checked={demande.specs.find(el => el == "2")}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_informationEtablissement_2">Hebergement
                                                                    temporaire</label>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row">
                                                                <input type="checkbox"
                                                                       id="appbundle_demandeetablissement_informationEtablissement_3"
                                                                       name="informationEtablissement"
                                                                       value="3" onChange={this.handleChangeSpec}
                                                                       checked={demande.specs.find(el => el == "3")}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_informationEtablissement_3">Accueil
                                                                    de jour</label>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row">
                                                                <input type="checkbox"
                                                                       id="appbundle_demandeetablissement_informationEtablissement_4"
                                                                       name="informationEtablissement"
                                                                       value="4" onChange={this.handleChangeSpec}
                                                                       checked={demande.specs.find(el => el == "4")}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_informationEtablissement_4">Unité
                                                                    alzheimer</label>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row">
                                                                <input type="checkbox"
                                                                       id="appbundle_demandeetablissement_informationEtablissement_5"
                                                                       name="informationEtablissement"
                                                                       value="5" onChange={this.handleChangeSpec}
                                                                       checked={demande.specs.find(el => el == "5")}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_informationEtablissement_5">Aide
                                                                    social à l’hébergement</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*
                                                    <div className="formBloc2_4 col-xs-12">
                                                        <div className="row">
                                                            <div className="question">
                                                                <p>Pour les résidences autonomie et résidences seniors
                                                                    services</p>
                                                            </div>
                                                            <div className="inputResidence">

                                                                <div className="btnRadio">
                                                                    <input type="radio"
                                                                           id="appbundle_demandeetablissement_nbPiece_placeholder"
                                                                           name="nb_piece"
                                                                           value=""/>
                                                                    <label
                                                                        htmlFor="appbundle_demandeetablissement_nbPiece_placeholder">None</label>
                                                                </div>

                                                                <div className="btnRadio">
                                                                    <input type="radio"
                                                                           id="appbundle_demandeetablissement_nbPiece_0"
                                                                           name="nb_piece"
                                                                           value="1 pièce"
                                                                           checked={demande.nb_piece == "1 pièces"}/>
                                                                    <label
                                                                        htmlFor="appbundle_demandeetablissement_nbPiece_0">1
                                                                        pièce</label>
                                                                </div>

                                                                <div className="btnRadio">
                                                                    <input type="radio"
                                                                           id="appbundle_demandeetablissement_nbPiece_1"
                                                                           name="nb_piece"
                                                                           value="2 pièces"
                                                                           onChange={this.handleChange}
                                                                           checked={demande.nb_piece == "2 pièces"}/>
                                                                    <label
                                                                        htmlFor="appbundle_demandeetablissement_nbPiece_1">2
                                                                        pièces</label>
                                                                </div>

                                                                <div className="btnRadio">
                                                                    <input type="radio"
                                                                           id="appbundle_demandeetablissement_nbPiece_2"
                                                                           name="nb_piece"
                                                                           value="3 pièces et plus"
                                                                           onChange={this.handleChange}
                                                                           checked={demande.nb_piece == "3 pièces et plus"}/>
                                                                    <label
                                                                        htmlFor="appbundle_demandeetablissement_nbPiece_2">3
                                                                        pièces et plus</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                */}
                                                <div className="formBloc2_5 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Personne à contacter pour la demande <span
                                                                className="rouge">*</span></p>
                                                        </div>
                                                        <div className="inputCivilite">

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_civilite_0"
                                                                       name="civilite"
                                                                       required="required"
                                                                       value="Monsieur"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.civilite === "Monsieur"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_civilite_0"
                                                                    className="required">Monsieur</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_civilite_1"
                                                                       name="civilite"
                                                                       required="required"
                                                                       value="Madame"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.civilite === "Madame"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_civilite_1"
                                                                    className="required">Madame</label>
                                                            </div>

                                                            <div className="btnRadio">
                                                                <input type="radio"
                                                                       id="appbundle_demandeetablissement_civilite_2"
                                                                       name="civilite"
                                                                       required="required"
                                                                       value="Mademoiselle"
                                                                       onChange={this.handleChange}
                                                                       checked={demande.civilite === "Mademoiselle"}/>
                                                                <label
                                                                    htmlFor="appbundle_demandeetablissement_civilite_2"
                                                                    className="required">Mademoiselle</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc2_6 col-xs-12">
                                                    <div className="row">
                                                        <div className="inputInfos">
                                                            <div className="infos">
                                                                <div className="question">
                                                                    <p>Nom<span className="rouge">*</span></p>
                                                                </div>

                                                                <input type="text"
                                                                       id="appbundle_demandeetablissement_nom"
                                                                       name="nom"
                                                                       required="required" maxLength="255"
                                                                       value={demande.nom}
                                                                       placeholder="vos nom et prenom"
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                            <div className="infos">
                                                                <div className="question">
                                                                    <p>Entité (pour les pros)</p>
                                                                </div>

                                                                <input type="text"
                                                                       id="appbundle_demandeetablissement_entite"
                                                                       name="entite"
                                                                       maxLength="255"
                                                                       value={demande.entite}
                                                                       placeholder="ex : Hôpital de Coutin "
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                            <div className="infos">
                                                                <div className="question">
                                                                    <p>Email <span className="rouge">*</span></p>
                                                                </div>

                                                                <input type="text"
                                                                       id="appbundle_demandeetablissement_email"
                                                                       name="email"
                                                                       required="required" maxLength="255"
                                                                       placeholder="Pour être contacté par les établissement"
                                                                       value={demande.email}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                            <div className="infos">
                                                                <div className="question">
                                                                    <p>Téléphone<span className="rouge">*</span></p>
                                                                </div>

                                                                <input type="text"
                                                                       id="appbundle_demandeetablissement_telephone"
                                                                       name="telephone"
                                                                       required="required" maxLength="255"
                                                                       placeholder="Pour être contacté par les établissement"
                                                                       value={demande.telephone}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="formBloc2_7 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Autres Informations que vous souhaitez transmettre aux
                                                                établissements</p>
                                                        </div>
                                                        <div className="inputCom">
                                                            <textarea id="appbundle_demandeetablissement_commentaires"
                                                                      name="commentaires"
                                                                      maxLength="400"
                                                                      value={demande.commentaires}
                                                                      onChange={this.handleChange}>
                                                            </textarea>
                                                        </div>
                                                        <div id="counter">
                                                            {demande.commentaires.length} / 400
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                                    <div style={{width: "304px", height: "78px"}}>
                                                        <div>
                                                            <ReCaptcha
                                                                ref={(el) => {
                                                                    this.captchaDemo = el;
                                                                }}
                                                                hl="fr"
                                                                size="normal"
                                                                data-theme="dark"
                                                                render="explicit"
                                                                sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                                onloadCallback={this.onLoadRecaptcha}
                                                                verifyCallback={this.verifyCallback}
                                                            />
                                                        </div>
                                                        <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                                  className="g-recaptcha-response"
                                                                  style={{
                                                                      width: "250px",
                                                                      height: "40px",
                                                                      border: "1px solid rgb(193, 193, 193)",
                                                                      margin: "10px 25px",
                                                                      padding: "0px",
                                                                      resize: "none",
                                                                      display: "none"
                                                                  }}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            }
                                            <div className="bouton submit">
                                                <p className="text-danger error"></p>
                                                <button type="submit" id="appbundle_demandeetablissement_previus"
                                                        name="appbundle_demandeetablissement[previus]"
                                                        label="Visualiser ma demande" onClick={this.handlePreview}
                                                >Visualiser ma demande</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </section>
                            :
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    {
                                        this.props.user && (this.props.user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || this.props.user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                                            <p className="chemin">
                                                <a href="/personnes">Mon compte</a> > <a href="/personnes/mes-annonces">Mes demandes de places</a> > Modifier une demande
                                            </p>
                                            :
                                            <p className="chemin"><a href="/">Je cherche une place en
                                                maison de retraite</a> &gt; <a href="/place-maison">Je
                                                modifie une demande auprès des établissements</a> &gt; Je visualise
                                                ma demande</p>
                                    }

                                    <h1>Je visualise <span>ma demande</span></h1>

                                    <p className="titleForm">Informations visibles par tous</p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">Cherche place à {demande.ville}</p>
                                                <div
                                                    className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-sexe.png"
                                                         alt="icone-sexe"/>
                                                    {demande.is_couple ?
                                                        <p>Pour <strong>{demande.sexe}</strong> de <strong> {demande.age_premier} ans</strong> et <strong>{demande.age_second} ans</strong>
                                                        </p>
                                                        :
                                                        <p>Pour <strong>{demande.sexe}</strong> de <strong>{demande.age} ans</strong>
                                                        </p>
                                                    }
                                                </div>

                                                {this.renderTypes(demande.types)}

                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-secteur"/>
                                                    <p>Secteur géographique: <strong>{demande.ville}, dans un
                                                        rayon de {demande.rayon} km</strong></p>
                                                </div>
                                                <div
                                                    className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-echeance"/>
                                                    <p>Echéance: <strong>{demande.echeance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="titleForm">Informations visibles uniquement par les
                                        établissements identifiés sur le
                                        site <span>PlaceMaisonDeRetraite.fr</span></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_2 col-xs-12">
                                            <div className="row">
                                                <div
                                                    className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-lieu.png"
                                                         alt="icone-lieu"/>
                                                    <p>La personne se trouve: <strong>{demande.logemennt}</strong>
                                                    </p>
                                                </div>
                                                <div
                                                    className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-budget"/>
                                                    <p>Budget mensuel: <strong>De {demande.budget}</strong></p>
                                                </div>

                                                {this.renderSpecs(demande.specs)}

                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li><strong>{demande.civilite} {demande.nom}</strong></li>
                                                        <li>Tél : <strong>{demande.tel}</strong></li>
                                                        <li>Email
                                                            : <strong>{demande.email}</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    demande.entite ?
                                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                            <img className="img-responsive"
                                                                 src="/img/mails/building.png"
                                                                 alt="icone-budget"/>
                                                            <p>Entité : <strong>{demande.entite}</strong></p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <br/>
                                                {
                                                    demande.commentaires ?
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img
                                                                src="/img/visualisation-demande/icone-commentaires.png"
                                                                alt="icone-commentaires"/>
                                                            <p>Autres informations: <br/><strong>{demande.commentaires}</strong></p>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <form name="appbundle_demandeetablissement"
                                              className="form-horizontal">

                                            <div
                                                className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <input type="checkbox" name="choix" value="1" required="" checked={condition} onChange={this.handleChangeCond}/>
                                                <label>j’accepte les <a href="/conditions-generales"
                                                                        target="_blank">conditions
                                                    générales d’utilisation.</a></label>
                                            </div>
                                            <div className="boutons col-xs-12">
                                                {
                                                    message !== "" ?
                                                        <a className="alert alert-success" href="/personnes/mes-annonces">{message}</a>
                                                        :
                                                        <div className="row">
                                                            <div className="bouton modifier">
                                                                <button type="submit"
                                                                        id="appbundle_demandeetablissement_update"
                                                                        name="appbundle_demandeetablissement[update]"
                                                                        label="Modifier ma demande"
                                                                        onClick={this.handlePreview}>Retour
                                                                </button>
                                                            </div>

                                                            <div className="bouton">
                                                                <button type="submit"
                                                                        id="appbundle_demandeetablissement_save"
                                                                        name="appbundle_demandeetablissement[save]"
                                                                        label="Enregistrer ma demande"
                                                                        onClick={this.handleSubmit}>Mettre à jour
                                                                </button>
                                                            </div>
                                                        </div>
                                                }
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {user} = state.authentication;
    const {alert} = state.alert;
    return {
        user,
        alert
    }
}

export default withRouter(connect(mapStateToProps)(Modif_Demande))
