import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";

class Personnes extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user : false)
        }
    }

    render(){
        const {alert} = this.props;
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                <section className="inscriptionStep1">
                    <div className="container">
                        <h1>Mon compte</h1>
                        <br/>
                        {
                            alert.message ?
                                <div className={`alert ${alert.type}`}>{alert.message}</div>
                                :
                                null
                        }
                        <br/>
                        <div className="buttonMonCompte">
                            <div className="bouton submit">
                                <a href="/personnes/mes-informations">Mes informations</a>
                                <p>Modifier mon identifiant, email, mot de passe</p>
                            </div>
                            <div className="bouton submit">
                                <a href="/personnes/mes-annonces">Mes demandes de place auprès des établissements</a>
                                <p>Consulter mes demandes de places, les modifier, les supprimer, ajouter une
                                    demande</p>
                            </div>
                            <div className="bouton submit">
                                <a href="/personnes/mes-alertes-dispo">Alerte-dispo</a>
                                <p>Consulter mes alertes, les modifier, les supprimer, ajouter une alerte</p>
                            </div>
                        </div>
                    </div>
                </section>
                </main>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { authentication, alert } = state;
    const { user } = authentication;
    return {
        user,
        alert
    }
}

export default connect(mapStateToProps)(Personnes)