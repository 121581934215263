import {contactConstants, userConstants} from '../_constants';
import {contactService, userService} from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import lib from "../lib/lib";

export const userActions = {
    register,
    login,
    updateUser,
    adminUpdateUser,
    logout,
    delUser,
    getAllPros,
    getAllPerso,
    getMessages
};

/****************/
/*** Register ***/
/****************/

function register(user, form, history) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user, form)
            .then(
                result => {
                    dispatch(success(result));
                    let path = "/";
                    if (form === 2) {
                        if (result.user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
                            path = "/etablissements";
                        }
                    } else {
                        if (JSON.parse(localStorage.getItem('demande')) !== null) {
                            path = "/demande-etablissement";
                        } else if (result.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}") {
                            path = "/personnes";
                        } else if (result.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
                            path = "/personnes";
                        }
                    }
                    dispatch(alertActions.success('Inscription effectuée avec succès'));
                    history.push(path);
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

/*************/
/*** Login ***/
/*************/

function login(username, password, history) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    let path = "";
                    if (JSON.parse(localStorage.getItem('demande')) !== null) {
                        path = "/demande-etablissement";
                    } else if (user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}") {
                        path = "/personnes";
                    } else if (user.user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
                        path = "/etablissements";
                    } else if (user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
                        path = "/personnes";
                    } else if (user.user.roles === "a:1:{i:0;s:11:\"ROLE_GROUPE\";}") {
                        path = "/groupes";
                    } else if (user.user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                        path = "/administrateur";
                    }
                    history.push(path);
                },
                error => {
                    //dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(param) { return { type: userConstants.LOGIN_REQUEST, param } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

/**************/
/*** Update ***/
/**************/

function updateUser(user, type, history) {
    return dispatch => {
        dispatch(request());

        let userRole = JSON.parse(localStorage.getItem('user')).user.roles;
        userService.update(user, type)
            .then(
                async newUser => {
                    if (newUser.success) {
                        dispatch(success(newUser));
                        let path = "";
                        if (userRole === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}") {
                            path = "/personnes";
                        } else if (userRole === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
                            path = "/etablissements";
                        } else if (userRole === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
                            path = "/personnes";
                        } else if (userRole === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                            path = "/administrateur";
                        }

                        if (userRole !== "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                            history.push(path);
                        }
                        dispatch(alertActions.success('Mise à jour effectuée avec succès'));
                    } else {
                        dispatch(failure(newUser.message));
                        dispatch(alertActions.error(newUser.message));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(newUser) { return { type: userConstants.UPDATE_SUCCESS, newUser } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

/**************/
/*** Update ***/
/**************/

function adminUpdateUser(user, type) {
    return dispatch => {
        dispatch(request());

        userService.update(user, type)
            .then(
                newUser => {
                    //dispatch(success(newUser));
                    dispatch(alertActions.success('Mise à jour effectuée avec succès'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_REQUEST } }
    function success(newUser) { return { type: userConstants.UPDATE_SUCCESS, newUser } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}


/**************/
/*** Logout ***/
/**************/

function logout() {
    userService.logout();
    history.push('/');
    return { type: userConstants.LOGOUT };
}

/*********************/
/* Delete User By Id */
/*********************/

function delUser(id) {
    return dispatch => {
        //dispatch(request());

        userService.delUser(id)
            .then(
                () => {
                    //dispatch(success(list));
                    //history.push('/administrateur/mes-messages');
                    dispatch(alertActions.success("utilisateur supprimé"));
                },
                error => {
                    //dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}

/********************/
/*** Get all Pros ***/
/********************/

function getAllPros() {
    return dispatch => {
        dispatch(request());

        userService.getAllPros()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

/*********************/
/*** Get all Perso ***/
/*********************/

function getAllPerso() {
    return dispatch => {
        dispatch(request());

        userService.getAllPerso()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

/*************************/
/*** Get Messages list ***/
/*************************/

function getMessages(history) {
    return dispatch => {
        dispatch(request());

        contactService.getMessages()
            .then(
                list => {
                    dispatch(success(list));
                    history.push('/administrateur/mes-messages');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}
