export const infoConstants = {
    CREATE_REQUEST: 'INFO_CREATE_REQUEST',
    CREATE_SUCCESS: 'INFO_CREATE_SUCCESS',
    CREATE_FAILURE: 'INFO_CREATE_FAILURE',

    LIST_REQUEST: 'INFO_LIST_REQUEST',
    LIST_SUCCESS: 'INFO_LIST_SUCCESS',
    LIST_FAILURE: 'INFO_LIST_FAILURE',

    DELETE_FAILURE: 'INFO_DELETE_FAILURE'
};