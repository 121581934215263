import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//const user = JSON.parse(localStorage.getItem('user'))

export const PersonneRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user') &&
        (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}")
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);
