import {authHeader} from '../_helpers';
import { userActions }  from "../_actions";

export const etablissementService = {
    getGroupeEtablissement,
    delEtablissementGroupe,
    activateMultiAnnonces,
    getUnactiveDemandes,
    getAllEtablissement,
    getAnnoncesByGroupe,
    getAllUnactivated,
    getLatestAnnonces,
    getActiveDemandes,
    getEtablissement,
    delEtablissement,
    getAllActivated,
    getAnnoncesById,
    activateDemande,
    activateAnnonce,
    getAllAnnonces,
    getAllDemandes,
    getDemandeById,
    getDemandeByCP,
    updateDemande,
    postDemande,
    delAnnonce,
    delDemande,
    update,
};

/****************************/
/* Get Single Etablissement */
/****************************/

function getEtablissement(etablissement_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ etablissement_id })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getEtablissement`, requestOptions)
        .then(handleResponse)
        .then(result => {

            return result;
        });
};

/************************/
/* Delete Etablissement */
/************************/

function delEtablissement(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/delEtablissement`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/************************************/
/* Delete Etablissement From Groupe */
/************************************/

function delEtablissementGroupe(id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            id,
            user_id
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/delete-from-groupe`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/*************************/
/* Get All Etablissement */
/*************************/

function getAllEtablissement() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllEtablissement`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/*************************/
/* Get All Etablissement */
/*************************/

function getGroupeEtablissement(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getGroupeEtablissement`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

};

/**************************/
/* Get Activated Annonces */
/**************************/

function getAllActivated() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllActivatedAnnonces`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/****************************/
/* Get UnActivated Annonces */
/****************************/

function getAllUnactivated() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllUnactivatedAnnonces`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

 /********************/
 /* Get All Annonces */
 /********************/

 function getAllAnnonces() {
     const requestOptions = {
         method: 'GET',
         headers: authHeader(),
     };

     return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllAnnonces`, requestOptions)
         .then(handleResponse)
         .then(list => {
             return list;
         });

 };

/**************************************/
/* Get Annonces By Etablissement's Id */
/**************************************/

function getAnnoncesById(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getAnnoncesById`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        })
        .catch(err => {
            console.log(err)
        });

};

/*******************************/
/* Get Annonces By Groupe's Id */
/*******************************/

function getAnnoncesByGroupe(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getAnnoncesByIdGroupe`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        })
        .catch(err => {
            console.log(err)
        });

};

/***********************/
/* Get Latest Annonces */
/***********************/

function getLatestAnnonces() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/latestAnnonces`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/********************/
/* Activate Annonce */
/********************/

function activateAnnonce(id, val) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id, val})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/activateAnnonce`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/******************************/
/* Activate Multiple Annonces */
/******************************/

function activateMultiAnnonces(ids, val) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ids, val})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/activateMultiAnnonce`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/******************/
/* Delete Annonce */
/******************/

function delAnnonce(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/delAnnonce`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/************************/
/* Update Etablissement */
/************************/

function update(etablissement, user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({etablissement, user})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/update`, requestOptions)
            .then(handleResponse)
            .then(async result => {
                const userRole = JSON.parse(localStorage.getItem('user')).user.roles;
                if (userRole === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
                    await localStorage.setItem('user', JSON.stringify({user: result.user}));
                }
                return result;
            });
}

/******************************/
/* Post Demande Etablissement */
/******************************/

function postDemande(demande) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({demande})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/postDemande`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/********************************/
/* Update Demande Etablissement */
/********************************/

function updateDemande(demande) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({demande})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/updateDemande`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/********************/
/* Get All Demandes */
/********************/

function getAllDemandes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getDemandes`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/**************************/
/* Get Activated Demandes */
/**************************/

function getActiveDemandes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/activeDemandes`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/****************************/
/* Get Unactivated Demandes */
/****************************/

function getUnactiveDemandes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/unactiveDemandes`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/****************************/
/* Get Demande By user's Id */
/****************************/

function getDemandeById(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getDemandeById`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/******************************/
/* Get Demande By Code Postal */
/******************************/

function getDemandeByCP(etablissement_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({etablissement_id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/getDemandeByCP`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/*************************/
/* Delete Single Demande */
/*************************/

function delDemande(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/etablissements/delDemande`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/********************/
/* Activate Demande */
/********************/

function activateDemande(id, val) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id, val})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/activateDemande`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err)
        });
}

/*********************/
/* CallBack function */
/*********************/

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
