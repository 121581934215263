import React from 'react';
import {connect} from 'react-redux';

import {alertActions, userActions} from '../../_actions';

import lib from '../../lib/lib';

import Navbar from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import {withRouter} from "react-router";


class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        localStorage.removeItem('user');

        this.state = {
            username: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (lib.isValidUsername(lib.protectEntry(username)) && lib.isValidPassword(lib.protectEntry(password))) {
            dispatch(userActions.login(lib.protectEntry(username), lib.protectEntry(password), this.props.history));
        }
    }

    render() {
        const { loggingIn, alert } = this.props;
        const { username, password, submitted } = this.state;
        return (
            <div style={{minHeight:"100% !important"}}>
            <Navbar />
            <main>
                <section className="inscriptionStep2 connexion">
                    <div className="container">
                        <h1>Connexion</h1>
                        {alert.message ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                        <div className="titleForm">
                            <p>Entrez vos identifiants de connexion</p>
                        </div>
                        <form name="form" onSubmit={this.handleSubmit}>

                                <div className="encadreForm col-xs-12">
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidUsername(username) ? ' has-error' : '')}>
                                        <label htmlFor="userName">Identifiant *</label>
                                        <input type="text" id="username" name="username" className="form-control" value={username} required="required" onChange={this.handleChange}/>
                                        {submitted && !lib.isValidUsername(username) &&
                                        <div className="help-block">L'identifiant peut contenir (lettres/chiffres/_) </div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidPassword(password) ? ' has-error' : '')}>
                                        <label htmlFor="userPassword">Mot de passe *</label>
                                        <input type="password" id="password" name="password" className="form-control" value={password} required="required" onChange={this.handleChange}/>
                                        {submitted && !lib.isValidPassword(password) &&
                                        <div className="help-block">le Mot de passe doit contenir au moins 4 caractères </div>
                                        }
                                    </div>

                                    <input type="hidden" name="_target_path" value="/"/>
                                    <div className="bouton submit">
                                        <input type="submit" name="_submit" value="Valider" />
                                        {loggingIn &&
                                             <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                    </div>
                                    <div className="text-center"><a href="/resetting">Mot de passe oublié ?</a></div>
                                </div>
                        </form>
                        <div className="text-center" >
                            <a href="/register" style={{fontSize: '18px'}}><b>Pas encore inscrit ? Créez votre compte sur</b> <p className="text-center" style={{fontSize: '18px'}}><span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></p></a>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { loggingIn, user } = state.authentication;
    const { alert } = state;
    return {
        loggingIn,
        alert,
        user
    };
}

const connectedLoginPage = withRouter(connect(mapStateToProps)(LoginPage));
export { connectedLoginPage as LoginPage };
