import React, { Component } from 'react';
import { connect } from 'react-redux';

class Type_Maison extends Component {

    constructor(props){
        super(props)


    }

    render(){
        return (
            <section className="homeBlocEtablissement">
                <div className="container">
                    <h2>Les différentes <span>Maisons de retraite</span></h2>
                    <div className="listeEtablissement">
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <img className="img-responsive"
                                 src="/img/home/homeBloc1/icone-annonce1.png"
                                 alt="icone-etablissement"/>
                                <h3>EHPAD</h3>
                                <p>Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de
                                    retraite médicalisées.</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <img className="img-responsive"
                                 src="/img/home/homeBloc1/icone-annonce3.png"
                                 alt="icone-etablissement"/>
                                <h3>USLD</h3>
                                <p>Unités de soins de longue durée, ce sont les lieux d’accueil les plus
                                    médicalisés</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <img className="img-responsive"
                                 src="/img/home/homeBloc1/icone-annonce4.png"
                                 alt="icone-etablissement"/>
                                <h3>Résidence autonomie</h3>
                                <p>Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans
                                    des petits appartements</p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <img className="img-responsive"
                                 src="/img/home/homeBloc1/icone-annonce5.png"
                                 alt="icone-etablissement"/>
                                <h3>Résidence services seniors</h3>
                                <p>Résidences avec services qui accueillent des personnes âgées autonomes seules ou en
                                    couple en T1 jusqu’au T3.</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Type_Maison)