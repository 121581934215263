let lib = {
    protectEntry : function(message) {
        if (message) {
            // Return a the same string with escaped "<script>" tags to avoid code injection
            message = message.replace(/&/g, '&amp;');
            message = message.replace(/</g, '&lt;');
            message = message.replace(/>/g, '&gt;');
            return (message);
        }
        return message;
    },

    isValidName : function(name) {
        // A valid firstname and lastname should only contain alphabetic characters and special unicode locale characters
        let regex = /^([a-zA-Z\-èêéàôîïùñç][^#&<>\"~;$^%{}?]{2,50})$/;
        return regex.test(name);
    },

    isValidProName: function(name) {
        // A valid Pro name could contain alphabetic characters and special unicode locale characters and even spaces
        let regex = /^[A-Z]([a-z]+|\.)(?:\s+[A-Z]([a-z]+|\.))*(?:\s+[a-z][a-z\-]+){0,2}\s+[A-Z]([a-z]+|\.)/;
        return regex.test(name);
    },

    isValidNomGroupe: function (name) {
        let regex = /^([a-zA-Z\-0-9èêéàôîïùñç_\ .#$^+=!*()@%&]{2,50})$/;
        return regex.test(name);
    },

    isValidEmail : function(email) {
        // This function check the entry is a real mail address
        //let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email) {
            let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return regex.test(email);
        }
        return false
    },

    isValidUsername : function(login) {
        // A valid username can only contain alpahnumeric characters, "-" and "_"
        let regex = /^([a-zA-Z\-0-9_.#$^+=!*()@%&]{2,50})$/;
        return regex.test(login);
    },

    isValidPassword : function(password) {
        // This function check if a password entered by a user is not a simple word without at least one number
        let lowercase = /^([a-zA-Z\-0-9_.#$^+=!*()@%&]{2,20})$/,
            number = /^(?=.*[0-9]).+$/;

        if (password.length >= 2 && password.length < 20 && lowercase.test(password))
            return (true);
        return (false);
    },

    isValidUpdatePassword : function(password) {
        // This function check if a password entered by a user is not a simple word without at least one number
        let lowercase = /([a-zA-Z\-0-9_.#$^+=!*()@%&]{2,20})$/,
            number = /^(?=.*[0-9]).+$/;
        if (!password) {
            return true
        } else {
            if (password.length >= 2 && password.length < 20 /*&& number.test(password)*/ && lowercase.test(password))
                return (true);
        }
        return (false);
    },

    passwordMatch: function(password, password2) {
        return (password === password2 || !password && !password2)
    },

    isValidPhone : function(phone) {
        let regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4}$/;
        if (regex.test(phone))
            return (true)
        return (false)
    },

    isValidFiness : function(finess) {
        let regex = /^(([0-8][0-9])|(9[0-5])|97|98|2A|2B)[0-9]{7}$/;
        if (regex.test(finess))
            return (true)
        return (false)
    },

    isValidAdress : function(address) {
        let regex = /^(((\d{1,3}([-\/]?\d{1,3})) | \w+)?(\w+)?)(\s\w+)*/;
        if (regex.test(address))
            return (true)
        return (false)
    },

    isValidCP : function(codePostal) {
        let regex = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/;
        if (regex.test(codePostal))
            return (true)
        return (false)
    },

    isValidURL : function(url) {
        let regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        if (regex.test(url))
            return (true)
        return (false)
    },

    hasValidExtension : function(file) {
        let allowedTypes = ['png', 'jpg', 'jpeg', 'gif'],
            fileType = file.name.split('.').pop().toLowerCase();

        if (allowedTypes.indexOf(fileType) !== -1)
            return (true);
        return(false);
    }
};

module.exports = lib;
