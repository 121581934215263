import {authHeader} from '../_helpers';
import {userActions} from "../_actions";

export const groupesService = {
    getGroupesList,
    getSingleGroupe,
    createGroupe,
    createGroupeAccount,
    updateGroupe,
    updateGroupeAccount,
    activateGroupeAccount,
    takeEmailControl,
    deleteGroupe
}

/*******************/
/* Get All Groupes */
/*******************/

function getGroupesList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/get-groupes`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

/******************/
/* Get One Groupe */
/******************/

function getSingleGroupe(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/get-groupe`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/*****************/
/* Create Groupe */
/*****************/

function createGroupe(groupe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            nom: groupe.nom,
            emailResponsable: groupe.emailResponsable,
            nomResponsable: groupe.nomResponsable,
            prenomResponsable: groupe.prenomResponsable,
            telephoneResponsable: groupe.telephoneResponsable
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/create_groupe`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/*****************/
/* Update Groupe */
/*****************/

function updateGroupe(groupe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            id: groupe.id,
            nom: groupe.nomGroupe,
            nomOld: groupe.nomOld,
            emailResponsable: groupe.emailResponsable,
            emailAnnonce: groupe.emailAnnonce,
            nomResponsable: groupe.nomResponsable,
            prenomResponsable: groupe.prenomResponsable,
            telephoneResponsable: groupe.telephoneResponsable
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/update_groupe`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/*************************/
/* Create Groupe Account */
/*************************/

function createGroupeAccount(groupe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            roles: groupe.roles,
            groupe_id: groupe.groupe_id,
            username: groupe.username,
            emailResponsable: groupe.emailResponsable,
            emailAnnonce: groupe.emailAnnonce
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/create_groupe_account`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/*************************/
/* Update Groupe Account */
/*************************/

function updateGroupeAccount(groupe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            id: groupe.user_id,
            username: groupe.username,
            password: groupe.password,
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/update_groupe_account`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (JSON.parse(localStorage.getItem('user')).user.roles !== "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                localStorage.setItem('user', JSON.stringify(result));
            }
            return result;
        })
        .catch(error => {
            return error
        });
}

/***************************/
/* Activate Groupe Account */
/***************************/

function activateGroupeAccount(groupe) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            id: groupe.id,
            email: groupe.email,
            enabled: groupe.enabled,
            username: groupe.username,
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/activate_groupe_account`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/**********************/
/* take Email Control */
/**********************/

function takeEmailControl(all, control, emailGroupe, groupe_id, id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            all,
            control,
            emailGroupe,
            groupe_id,
            id,
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/email-control`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/***********************/
/* Delete Groupe by id */
/***********************/

function deleteGroupe(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            id,
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/delete-groupe`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error
        });
}

/*********************/
/* CallBack function */
/*********************/

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
