import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar  from '../../_components/Navbar';
import Footer from '../../_components/Footer';

class Place_Etablissement extends Component {

    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        const {user} = this.props;
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    <section className="placeMaisonBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/">Accueil</a> &gt; J'ai une place dans mon établissement</p>
                            <h1>J’AI UNE PLACE <span>DANS MON ETABLISSEMENT</span></h1>
                            <div className="placeMaisonIcone col-xs-12">
                                <div className="contentPlaceMaison">
                                    <div className="titrePlaceMaison">
                                        <a href={localStorage.getItem('user') ?
                                                user.user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}" ?
                                                    "/etablissements/ajouter-annonce" :
                                                        "/login" : "/login"
                                        }>
                                            <p>Je publie une annonce</p>
                                            <p>de place disponible</p>
                                        </a>
                                    </div>
                                    <a href={localStorage.getItem('user') ?
                                            user.user.roles == "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}" ?
                                                "/etablissements/ajouter-annonce" :
                                                "/login" : "/login"
                                        }
                                        className="lienImg">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-demande.png"
                                             alt="icone-maison"/>
                                    </a>
                                </div>
                                <div className="contentPlaceMaison">
                                    <a href={localStorage.getItem('user') ?
                                            user.user.roles == "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}" ?
                                                "/etablissements/demandes-familles" :
                                                "/login" : "/login"
                                        } className="lienImg">
                                        <img className="img-responsive"
                                             src="/img/place-maison/icone-places-dispo.png"
                                             alt="icone-maison"/>
                                    </a>
                                    <div className="titrePlaceMaison">
                                        <a href={localStorage.getItem('user') ?
                                                user.user.roles == "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}" ?
                                                    "/etablissements/demandes-familles" :
                                                    "/login" : "/login"
                                        }>
                                            <p>Je consulte les</p>
                                            <p> demandes des familles</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 contentPlaceMaisonText">
                                <p>
                                    <strong>Publiez votre annonce de place disponible pour être visible sur la plate-forme et être contacté directement par
                                            les familles et professionnels qui les informent quotidiennement.</strong>
                                </p>
                            </div>
                            <div className="col-md-6 col-xs-12 contentPlaceMaisonText">
                                <p>
                                    <strong>Consultez les demandes postées par les familles ou les personnes qui les
                                        accompagnent, sur votre zone géographique et contactez les si vous avez une solution à leur proposer.</strong>
                                </p>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    const { authentication } = state;
    const { user } = authentication;

    return {
        user,
    }
}

export default connect(mapStateToProps)(Place_Etablissement)
