import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from "../../_components/Footer";
import Navbar from "../../_components/Navbar";

class Forgoten_Password extends Component {

    constructor(props){
        super(props)

        this.state = {
            submitted: false,
            email: "",
            message: "Votre requête a bien été prise en compte, un administrateur va l'étudier et revenir vers vous rapidement"
        }
    }

    handleChange(e) {
        const {name, value} = e.target;

        this.setState({[name]: value});
    }

    async sendResetMail(e) {
        e.preventDefault();
        let options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: this.state.email})
        }
        let resp = await fetch('https://backend.placemaisonderetraite.fr/reset-password', options);
        let result = await resp.json()

        this.setState({message: result.message, submitted: true});
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    <section className="inscriptionStep2 connexion">
                        <div className="container">

                            <h1>Mot de passe oublié</h1>
                            <div className="titleForm">
                                <p>Récupérer mon mot de passe</p>
                            </div>
                            {
                                !this.state.submitted ?
                                    <div className="encadreForm col-xs-12">

                                        <div className="inputConnexion col-xs-12">
                                            <label htmlFor="username">Identifiant ou adresse e-mail</label>
                                            <input type="text"
                                                   id="email"
                                                   name="email"
                                                   required="required"
                                                   onChange={this.handleChange.bind(this)}/>
                                        </div>

                                        <div className="bouton submit">
                                            <button type="button" className="btn btn-primary" onClick={this.sendResetMail.bind(this)}>
                                                Réinitialiser le mot de passe
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="encadreForm col-xs-12">
                                        <div style={{textAlign: 'center', fontSize: '2rem', color: '#c4574e'}}>
                                            <b>{this.state.message}</b>
                                        </div>
                                    </div>
                            }
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Forgoten_Password)
