import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from "react-st-modal";

import {alertActions, groupesActions} from '../../../../_actions';

import lib from '../../../../lib/lib';


import Navbar from '../../../../_components/Navbar';
import Footer from '../../../../_components/Footer';

class Groupes_Create extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupe: {
                roles: "a:1:{i:0;s:11:\"ROLE_GROUPE\";}",
                nom: "",
                emailResponsable: "",
                nomResponsable: "",
                prenomResponsable: "",
                telephoneResponsable: "",
                rue: "",
                ville: "",
                latitude: "",
                longitude: "",
                codePostal: "",
            },
            submitted: false,
        };

        this.handleChangeUser = this.handleChangeUser.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(groupesActions.getAllGroupes())
    }

    handleChangeUser(event) {
        const {name, value} = event.target;
        const {groupe} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({
            groupe: {
                ...groupe,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({submitted: true});
        const {groupe} = this.state;
        const {dispatch} = this.props;

        if (lib.isValidNomGroupe(groupe.nom)) {
            dispatch(groupesActions.createGroupe(groupe));
        } else {
            Alert("Veullez renseigner un nom de groupe correcte");
        }
    }

    _renderForm(user, submitted, registering) {
        const {groupe} = this.state;

        return (
            <div className="encadreForm col-xs-12">
                <div className="question">
                    <p><strong>Veuillez renseigner le nom du Groupe à ajouter sur la plateforme</strong></p>
                    <p>Le nom du Groupe doit être unique. Vous devez faire attention aux doublons (ex: colis<b style={{color: 'red'}}>é</b>e | colis<b style={{color: 'red'}}>e</b>e)</p>
                    <p>En cas de doute, faites une recherche dans la <a href="/administrateur/groupes/list-groupe"><b> -- liste des Groupes -- </b></a> pour vérifier l'existence d'un Groupe</p>
                    <p>Le nom peut être composé de caractères AlphaNumériques et spéciaux</p>
                    <p style={{color: 'red'}}>NB: il est inutile de mettre le mot <b>Groupe</b> avant le nom du Groupe</p>
                </div>

                <div className={'inputInfos col-xs-12 col-sm-12 col-md-12 form-group' + (submitted && !groupe.nom ? ' has-error' : '')}>
                    <label>Nom du groupe <span className="rouge">*</span></label>
                    <input type="text"
                           name="nom"
                           className="form-control"
                           value={groupe.nom}
                           style={{boxShadow: !groupe.nom ? '0 0 3px #CC0000' : ''}}
                           onChange={this.handleChangeUser} placeholder="Ex&nbsp;: colisee"/>
                </div>

                <div className="errorFirstStep" style={{color: "red"}}> </div>

                <div>
                    <div className="bouton submit"
                         style={{display: "inline-block", float: "right", margin: "2px"}}>
                        <input type="submit" name="envoyer" id="valideEtablissement" value="Valider"/>
                        <div className="error text-danger"></div>
                        {registering &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </div>
                </div>
                <div style={{display: "inline-block", float: "right", margin: "2px"}}>
                    <div className="bouton annuler">
                        <a href="/administrateur/groupes">
                            Annuler
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    renderHeader() {
        return <h1>Ajouter un Nouveau Groupe</h1>;
    }

    render() {
        const {registering, alert} = this.props;
        const {user, submitted} = this.state;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            <p className="chemin"><Link to="/administrateur">Mon compte</Link> &gt; <Link to="/administrateur/groupes">Groupes</Link> &gt; Ajouter un nouveau Groupe</p>
                            {this.renderHeader()}
                            <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                <input type="text" autoFocus="autofocus" style={{display: 'none'}}/>
                                <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}}/>
                                <div className="titleForm">
                                    <p className="active" id="vousEtes">informations Groupe</p>
                                </div>
                                {this._renderForm(user, submitted, registering)}
                            </form>
                            {alert.message ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {message} = state.groupes;
    const {registering} = state.registration;
    const {alert} = state;
    return {
        registering,
        message,
        alert
    };
}

export default withRouter(connect(mapStateToProps)(Groupes_Create));
