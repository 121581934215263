import React, {Component} from 'react';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {dispoActions, alertActions} from "../../../../_actions";
import moment from "moment";
import {ReCaptcha} from "react-recaptcha-google";
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import {withRouter} from "react-router";
import {Confirm, Alert} from "react-st-modal";
//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Personne_Alertes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            deleted: [],
            modif: false,
            choix_email: false,
            choix_condition: false,
            recaptcha: '',
            dispo: {},
            filter: {
                codepostal: '',
                ville: '',
                Type: '',
                direc: 'asc',
                suppressFilter: false
            },
            single: {
                id: 0,
                etablissement: [],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '15',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
                code_insee: ''
            }
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleModif = this.handleModif.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.handleChoix = this.handleChoix.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(dispoActions.getDispoById(this.props.user.user.id));
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.dispo !== this.state.dispo) {
            let dispo = this.props.dispo;

            this.setState({
                dispo
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.dispo !== prevState.dispo) {

            return {dispo: nextProps.dispo};
        } else return null;
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            recaptcha: recaptchaToken
        });
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {single} = this.state;

        this.setState({
            single: {
                ...single,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChangeType(event) {
        const {value, checked} = event.target;
        const {single} = this.state;

        if (!checked) {
            this.setState({
                single: {
                    ...single,
                    etablissement: single.etablissement.filter(function (item) {
                        return item !== parseInt(value);
                    })
                }
            })
        } else {
            this.setState({
                single: {
                    ...single,
                    etablissement: [...single.etablissement, parseInt(value)]
                }
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {single, filter} = this.state;

        if (sectionIndex === 2)
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                single: {
                    ...single,
                    codepostal: suggestion.value.codePostal,
                    code_insee: suggestion.value.code_insee,
                    ville: suggestion.value.nom,
                    latitude: suggestion.value.geoLoc.coordinates[1],
                    longitude: suggestion.value.geoLoc.coordinates[0]
                }
            });
        else
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                filter: {
                    ...filter,
                    codepostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                }
            })

    };

    handleChoix(e) {
        const {name} = e.target;
        const {choix_email, choix_condition} = this.state;

        if (name === 'choix_email') {
            this.setState({
                [name]: !choix_email
            })
        } else {
            this.setState({
                [name]: !choix_condition
            })
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        const {choix_email, choix_condition, recaptcha, single, modif} = this.state;

        if (!choix_email || lib.isValidEmail(single.email) === false) {
            Alert("Nous avons besoin d'un email valid ainsi que de votre consentement pour pouvoir vous alerter des disponibiltés")
        } else if (!choix_condition) {
            Alert("Veuillez lire et accepter les conditions générales d'utilisation")
        //} else if (recaptcha.length <= 0) {
        //    Alert("veuillez confirmer que vous n'êtes pas un robot")
        } else if (single.etablissement.length <= 0) {
            Alert("Veuillez choisir, au moins, un type d'établissement")
        } else {
            this.props.dispatch(dispoActions.updateDispo(single, this.props.history));
            //this.props.dispatch(dispoActions.getDispoById(this.props.user.user.id));

            this.setState({
                modif: false,
                single: {
                    ...single,
                    etablissement: [],
                    personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                    codepostal: '',
                    rayon: '10',
                    nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                    entite: '',
                    email: localStorage.getItem('user') ? this.props.user.user.email : '',
                    telephone: '',
                    latitude: '',
                    longitude: '',
                    ville: '',
                    code_insee: '',
                },
                choix_email: false,
                choix_condition: false,
                value: ''
            });
        }
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;

        var response = await Confirm("Voulez-vous vraiment supprimer cette alerte?", "", "Oui", "Fermer");

        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            await this.props.dispatch(dispoActions.delDispo(id));
            await this.props.dispatch(dispoActions.getDispoById(this.props.user.user.id));
        }
    }

    handleModif(e) {
        e.preventDefault();
        alertActions.clear();
        const {modif, single} = this.state;

        const array = this.props.dispo.alert.dispo.filter(item => item.id == e.target.id);
        var t_final = [];
        this.props.dispo.alert.types.filter(function(t) {
            return (t.alerte_dispo_id == e.target.id)
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );
        this.setState({
            modif: !modif,
            single: {
                ...single,
                id: array[0].id,
                personnes_id: array[0].personnes_id,
                codepostal: array[0].codepostal,
                code_insee: array[0].code_insee,
                rayon: array[0].rayon,
                nom: array[0].nom ? array[0].nom : this.props.user.user.username,
                entite: array[0].entite,
                email: array[0].email,
                telephone: array[0].telephone,
                latitude: array[0].latitude,
                longitude: array[0].longitude,
                ville: array[0].ville,
                etablissement: t_final
            },
            value: array[0].ville + " " + array[0].codepostal
        })
    }

    handleGoBack(e) {
        e.preventDefault();

        const {single} = this.state;

        this.setState({
            modif: false,
            single: {
                ...single,
                etablissement: [],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '10',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
                code_insee: '',
            },
            choix_email: false,
            choix_condition: false,
            value: ''
        })
    }

    handleChangeFilter(e) {
        const {name, value} = e.target;
        const {filter, dispo} = this.state;

        if (name === 'direc') {
            this.setState({
                dispo: {
                    alert: {
                        ...dispo.alert,
                        dispo: dispo.alert.dispo.reverse()
                    }
                },
                filter: {
                    ...filter,
                    direc: value,
                    suppressFilter: value === 'desc'
                }
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value
                }
            })
        }
    }

    renderFilter(item) {
        const {filter} = this.state;
        const {types} = this.props.dispo.alert;

        var t_final = []
        types.filter(function(e) {
            return (e.alerte_dispo_id === item.id)
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );

        const Codepostal = filter.codepostal  !== '' ? (filter.codepostal === item.codepostal) : filter.ville !== '' ? (filter.ville === item.ville) : true;
        const Type = filter.Type  !== '' ? t_final.includes(parseInt(filter.Type)) : true;

        return (Codepostal && Type)
    }

    supprimerFiltre() {
        const {filter, dispo} = this.state;
        this.setState({
            dispo: {
                alert: {
                    ...dispo.alert,
                    dispo: filter.suppressFilter ? dispo.alert.dispo.reverse() : dispo.alert.dispo
                }
            },
            filter: {
                codepostal: '',
                ville: '',
                Type: '',
                direc: 'asc',
                suppressFilter: false
            },
            value: ''
        })
    }

    render() {
        moment.locale('fr');
        const {alert} = this.props;
        const {deleted, modif, single, choix_email, choix_condition, value, suggestions, filter, dispo} = this.state;
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const searchOptions = {
            componentRestrictions: { country: "fr",  }
        };
        // Log error status and clear dropdown when Google Maps API returns an error.
        const onError = (status, clearSuggestions) => {
            clearSuggestions()
        };

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    {!modif ?
                        <section className="listeDemandeBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/personnes">Mon compte</a> &gt; Mes Alertes dispo</p>
                                <h1>Mes <span>alertes-dispo</span></h1>
                                <div className="col-xs12 bouton voir">
                                    <a href="/alerte-dispo">Ajouter une alerte-dispo</a>
                                </div>
                                <br/>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        null
                                }
                                <br/>
                                <div className="filtres col-xs-12" style={{border: "#bec8c9 .1rem solid", borderRadius: '5%', padding: "1rem", margin: "0 0 1rem 0"}}>
                                    <p>Filtrer par</p>
                                    <div className="inputInfos col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                            sectionIndex={1}
                                            highlightFirstSuggestion={true}
                                        />
                                    </div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <select id="order" name="direc" onChange={this.handleChangeFilter} value={filter.direc}>
                                            <option value="asc">Du + ancien au plus récent</option>
                                            <option value="desc">Du + récent au plus ancien</option>
                                        </select>
                                    </div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <select id="etablissement" name="Type" onChange={this.handleChangeFilter} value={filter.Type}>
                                            <option value="">Type d'établissement</option>
                                            <option value="1">EHPAD</option>
                                            <option value="2">USLD</option>
                                            <option value="3">Résidence autonomie</option>
                                            <option value="4">Résidence services seniors</option>
                                        </select>
                                    </div>
                                    <div className="bouton submit subConf" style={{float: 'right'}}>
                                        <input type="reset" name="supprimer"
                                               value="Supprimer les Filtres"
                                               style={{margin: "10px"}}
                                               onClick={this.supprimerFiltre}/>
                                    </div>
                                </div>

                                <div id="listeDemande">

                                        {!dispo.alert ?
                                            <div>
                                                <h2>0 <span>alertes-dispo</span> créée</h2>
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            </div>
                                            :
                                            dispo.alert.dispo.filter(item => this.renderFilter(item)).map((item, index) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                <div className="singleDemande" >
                                                    <div className="col-xs-12">
                                                        <div className="question">
                                                            <p>Alerte-Dispo {item.nom.length > 0 ? "de " + item.nom : null}</p>
                                                        </div>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-echeance.png"
                                                             alt="icone-sexe"/>
                                                        <p>Publiée
                                                            le <strong> {moment(item.created_at).format('DD/MM/YYYY')} </strong>
                                                        </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-secteur.png"
                                                             alt="icone-sexe"/>
                                                        <p>A <strong> {item.ville} </strong> ({item.codepostal}) dans
                                                            une rayon
                                                            de <strong>{item.rayon}km</strong></p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-type-etablissement.png"
                                                             alt="icone-type-etablissement"/>
                                                        <p>Type d'établissement :<br/>
                                                            {
                                                                dispo.alert.types.map((a, i) => {
                                                                    if (a.alerte_dispo_id === item.id) {
                                                                        return (
                                                                            <strong
                                                                                key={i}>
                                                                                {types[a.type_etablissement_id - 1]}
                                                                                <br/>
                                                                            </strong>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <i className="fa fa-at" aria-hidden="true"> </i>
                                                        <p>Email : {' '}
                                                            <a href={`mailto:`+item.email}>
                                                                {item.email}
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.handleModif} id={item.id}>Modifier
                                                            </button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.handleDelete} id={item.id}>Supprimer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            )

                                        }

                                </div>

                            </div>
                        </section>
                        :
                        <section className="alerteDispoBloc1">
                            <div className="container">
                                {this.props.user && (this.props.user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || this.props.user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                                    <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a href="/personnes/mes-alertes-dispo">Mes Alertes dispo</a> &gt; Je modifie mon "Alerte-dispo"</p>
                                    :
                                    <p className="chemin"><a href="/">Accueil</a> &gt; Je modifie mon "Alerte-dispo"</p>
                                }
                                <h1>Je modifie mon <span>"Alerte-dispo"</span></h1>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <strong>Pour être informé par email dès qu’une nouvelle place est disponible sur votre
                                        zone
                                        de recherche </strong>
                                </div>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="encadreForm">

                                        <form name="appbundle_alertedispo" className="form-horizontal">

                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                        ALERTE-DISPO
                                                        » ?</p>
                                                    <p>Sélection multiple possible</p>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3" >
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                             alt="icone-etablissement"
                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_1" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>EHPAD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="appbundle_alertedispo_etablissement_1"
                                                           name="etablissement"
                                                           value="1"
                                                           checked={single.etablissement.includes(1)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                             alt="icone-etablissement"
                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>

                                                        <label htmlFor="appbundle_alertedispo_etablissement_2" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>USLD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="appbundle_alertedispo_etablissement_2"
                                                           name="etablissement"
                                                           value="2"
                                                           checked={single.etablissement.includes(2)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                             alt="icone-etablissement"
                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_3" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence autonomie</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="appbundle_alertedispo_etablissement_3"
                                                           name="etablissement"
                                                           value="3"
                                                           checked={single.etablissement.includes(3)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '46%'}}
                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                             alt="icone-etablissement"
                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_4" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence
                                                            services
                                                            seniors</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="appbundle_alertedispo_etablissement_3"
                                                           name="etablissement"
                                                           value="4"
                                                           checked={single.etablissement.includes(4)}
                                                           onChange={this.handleChangeType}/>
                                                </div>


                                            </div>
                                            <br /><br /><br /><br />
                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Où recherchez-vous une place en maison de retraite ?</p>
                                                </div>

                                                <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label>Dans la ville de</label>
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onSuggestionSelected}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        sectionIndex={2}
                                                        highlightFirstSuggestion={true}
                                                    />
                                                </div>
                                                <div className="inputInfos col-xs-12 col-sm-6 col-md-3" style={{float: 'center'}}>
                                                    <label>Dans un rayon de</label>
                                                    <select id="appbundle_alertedispo_rayon"
                                                            name="rayon"
                                                            onChange={this.handleChange}
                                                            value={single.rayon}
                                                            selected={single.rayon}>
                                                        <option value="15">15 km</option>
                                                        <option value="30">30 km</option>
                                                        <option value="50">50 km</option>
                                                        <option value="100">100 km</option>
                                                    </select>
                                                </div>


                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre email <span>*</span></label>
                                                        <input type="text" id="appbundle_alertedispo_email"
                                                               name="email" required="required"
                                                               value={single.email}
                                                               maxLength="255" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="checkbox" name="choix_email" value="1" onChange={this.handleChoix} required=""/>
                                                    <label><strong>J’accepte de recevoir par email une « Alerte-dispo » dès
                                                        qu’une place est publiée, selon mes critères de recherche
                                                        ci-dessus.</strong></label>
                                                </div>
                                            </div>


                                            <br/>
                                            <p>Les informations ci-dessus seront utilisées que par l’administrateur du site
                                                pour vous envoyer les places disponibles et ne seront pas visibles sur le
                                                site, ni diffusées aux établissements. </p><br/>

                                            <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <input id="condition" type="checkbox" name="choix_condition" value="1" onChange={this.handleChoix} required=""/>
                                                <label htmlFor="condition"><strong>J'accepte les <a href="/conditions-generales">conditions générales d'utilisation.</a></strong></label>
                                            </div>
                                            <br/>
                                            {
                                                <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                                    <div style={{width: "304px", height: "78px"}}>
                                                        <div>
                                                            <ReCaptcha
                                                                ref={(el) => {
                                                                    this.captchaDemo = el;
                                                                }}
                                                                hl="fr"
                                                                size="normal"
                                                                data-theme="dark"
                                                                render="explicit"
                                                                sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                                onloadCallback={this.onLoadRecaptcha}
                                                                verifyCallback={this.verifyCallback}
                                                            />
                                                        </div>
                                                        <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                                  className="g-recaptcha-response"
                                                                  style={{
                                                                      width: "250px",
                                                                      height: "40px",
                                                                      border: "1px solid rgb(193, 193, 193)",
                                                                      margin: "10px 25px",
                                                                      padding: "0px",
                                                                      resize: "none",
                                                                      display: "none"
                                                                  }}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            }
                                            <br/>

                                            <div className="bouton submit">
                                                <p className="text-danger hide error">Vous devez choisir un
                                                    etablissement</p>

                                                <button type="submit" id="appbundle_alertedispo_save"
                                                        name="save" onClick={this.handleSubmit}>Modifier
                                                </button>

                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <img className="img-responsive image-alert"
                                         src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                                </div>
                            </div>
                        </section>
                        /*
                        <section className="alertesingleBloc1">
                            <div className="container">
                                {this.props.user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || this.props.user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}" ?
                                    <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a href="/personnes/mes-alertes-dispo">Mes Alertes dispo</a> &gt; Je modifie mon "Alerte-dispo"</p>
                                    :
                                    <p className="chemin"><a href="/">Accueil</a> &gt; Je crée mon "Alerte-dispo"</p>
                                }
                                <h1>Je crée mon <span>"Alerte-dispo"</span></h1>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <strong>Pour être informé par email dès qu’une nouvelle place est disponible sur
                                        votre
                                        zone
                                        de recherche </strong>
                                </div>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="encadreForm">

                                        <form name="appbundle_alertedispo" className="form-horizontal">

                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                        ALERTE-DISPO
                                                        » ?</p>
                                                    <p>Sélection multiple possible</p>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3" >
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                             alt="icone-etablissement"
                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_1" style={{marginLeft: 'auto', marginRight: 'auto'}}>EHPAD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%'}}
                                                           id="appbundle_alertedispo_etablissement_1"
                                                           name="etablissement"
                                                           value="1"
                                                           checked={single.etablissement.includes(1)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                             alt="icone-etablissement"
                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>

                                                        <label htmlFor="appbundle_alertedispo_etablissement_2" style={{marginLeft: 'auto', marginRight: 'auto'}}>USLD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%'}}
                                                           id="appbundle_alertedispo_etablissement_2"
                                                           name="etablissement"
                                                           value="2"
                                                           checked={single.etablissement.includes(2)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                             alt="icone-etablissement"
                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_3" style={{marginLeft: 'auto', marginRight: 'auto'}}>Résidence autonomie</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%'}}
                                                           id="appbundle_alertedispo_etablissement_3"
                                                           name="etablissement"
                                                           value="3"
                                                           checked={single.etablissement.includes(3)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '46%'}}
                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                             alt="icone-etablissement"
                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_4" style={{marginLeft: 'auto', marginRight: 'auto'}}>Résidence
                                                            services
                                                            seniors</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%'}}
                                                           id="appbundle_alertedispo_etablissement_3"
                                                           name="etablissement"
                                                           value="4"
                                                           checked={single.etablissement.includes(4)}
                                                           onChange={this.handleChangeType}/>
                                                </div>


                                            </div>

                                                <div className="formBloc2">
                                                    <div className="question">
                                                        <p>Dans quel secteur géographique recherchez-vous ?</p>
                                                    </div>
                                                    <div className="inputSecteur">
                                                        <div className="inputVille">
                                                            <label>Saisissez un code postal</label>
                                                            <Autosuggest
                                                                suggestions={suggestions}
                                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                onSuggestionSelected={this.onSuggestionSelected}
                                                                getSuggestionValue={getSuggestionValue}
                                                                renderSuggestion={renderSuggestion}
                                                                inputProps={inputProps}
                                                                highlightFirstSuggestion={true}
                                                            />
                                                        </div>
                                                        <div className="selectRayon">
                                                            <label>Dans un rayon de</label>
                                                            <select id="appbundle_alertedispo_rayon"
                                                                    name="rayon"
                                                                    onChange={this.handleChange}
                                                                    value={single.rayon}
                                                                    selected={single.rayon}>
                                                                <option value="15">15 km</option>
                                                                <option value="30">30 km</option>
                                                                <option value="50">50 km</option>
                                                                <option value="100">100 km</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc3">
                                                    <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <div className="row">
                                                            <label>Votre email <span>*</span></label>
                                                            <input type="text" id="appbundle_alertedispo_email"
                                                                   name="email" required="required" value={single.email}
                                                                   maxLength="255" onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <br/><br/>

                                                </div>
                                            <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{display: 'flex'}}>
                                                <input type="checkbox" name="choix_email" value={choix_email}
                                                       onChange={this.handleChoix} required=""/>
                                                <label><strong>J’accepte de recevoir par email une « Alerte-dispo » dès qu’une place se libère, selon mes critères de recherche ci-dessus.</strong></label>
                                            </div>
                                                <br/>
                                                <p>Les informations ci-dessus seront utilisées que par l’administrateur du site pour vous envoyer les places disponibles et ne seront pas visibles sur le site. </p>
                                                <br/>

                                                <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{display: 'flex'}}>
                                                    <input type="checkbox" name="choix_condition" value={choix_condition}
                                                           onChange={this.handleChoix} required=""/>
                                                    <label><strong>j’accepte les </strong><a href="/conditions-generales">conditions générales d’utilisation.</a></label>
                                                </div>
                                                <br/>
                                                {/*
                                                    <div className="g-recaptcha"
                                                         style={{display: "table", margin: "0 auto"}}>
                                                        {window.grecaptcha ?
                                                            <div style={{width: "304px", height: "78px"}}>
                                                                <ReCaptcha
                                                                    ref={(el) => {
                                                                        this.captchaDemo = el;
                                                                    }}
                                                                    size="normal"
                                                                    data-theme="dark"
                                                                    render="explicit"
                                                                    sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                                    onloadCallback={this.onLoadRecaptcha}
                                                                    verifyCallback={this.verifyCallback}
                                                                />
                                                                <textarea id="g-recaptcha-response"
                                                                          name="g-recaptcha-response"
                                                                          className="g-recaptcha-response"
                                                                          style={{
                                                                              width: "250px",
                                                                              height: "40px",
                                                                              border: "1px solid rgb(193, 193, 193)",
                                                                              margin: "10px 25px",
                                                                              padding: "0px",
                                                                              resize: "none",
                                                                              display: "none"
                                                                          }}>
                                                                </textarea>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                }
                                                <br/>


                                                <div className="bouton submit">
                                                    <p className="text-danger hide error">Vous devez choisir un etablissement</p>
                                                    <button type="submit" id="appbundle_alertedispo_save"
                                                            name="save" onClick={this.handleSubmit}>Modifier
                                                    </button>
                                                    <br/>
                                                    <button type="submit" id="appbundle_alertedispo_save"
                                                            name="cancel" onClick={this.handleGoBack}>Annuler
                                                    </button>

                                                </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <img className="img-responsive image-alert"
                                         src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                                </div>
                            </div>
                        </section>*/
                    }
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, dispo, alert} = state;
    const {user} = authentication;

    return {
        user,
        dispo,
        alert
    }
}

export default withRouter(connect(mapStateToProps)(Personne_Alertes))
