import React, {Component} from 'react';
import {connect} from 'react-redux';

import {etablissementActions} from "../../_actions";

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";
import lib from "../../lib/lib";

class PassForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirm: '',
            message: '',
            valid: false,
            loggingIn: false,
            submitted: false,
            user_id: null
        }
    }

    componentDidMount() {
        const { user_id } = this.props.match.params;

        this.setState({user_id})

    }

    handleChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value,
            submitted: false
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        const {password, confirm, user_id} = this.state;
        this.setState({loggingIn: true})
        let options = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: user_id, password: password})
        }
        if (password === confirm) {
            let resp = await fetch('https://backend.placemaisonderetraite.fr/users/password', options)
            let result = await resp.json()

            this.setState({message: result.message, submitted: true, valid: true, loggingIn: false})
        } else {
            this.setState({submitted: true, loggingIn: false})
        }
    }

    render() {
        const {submitted, password, confirm, loggingIn, valid, message, user_id} = this.state;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep2 connexion">
                        <div className="container">
                            <h1>Modifier votre mot de passe</h1>
                            <div className="titleForm">
                                <p>Saisissez un mot de passe entre 4 et 19 caractères...</p>
                            </div>
                            {
                                !valid ?
                                <form name="form" onSubmit={this.onSubmit.bind(this)}>

                                    <div className="encadreForm col-xs-12">
                                        <div
                                            className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidPassword(password) ? ' has-error' : '')}>
                                            <label htmlFor="userName">Nouveau mot de passe <span
                                                className='red'>*</span></label>
                                            <input type="password" id="password" name="password" className="form-control"
                                                   value={password} required="required"
                                                   onChange={this.handleChange.bind(this)}/>
                                            {submitted && !lib.isValidPassword(password) &&
                                            <div className="help-block">Saisissez un mot de passe entre 4 et 19
                                                caractères...</div>
                                            }
                                        </div>
                                        <div
                                            className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted && !lib.isValidPassword(password) ? ' has-error' : '')}>
                                            <label htmlFor="userPassword">Confirmer le mot de passe <span
                                                className='red'>*</span></label>
                                            <input type="password" id="password" name="confirm" className="form-control"
                                                   value={confirm} required="required"
                                                   onChange={this.handleChange.bind(this)}/>
                                            {submitted && !lib.passwordMatch(password, confirm) &&
                                            <div className="help-block">le mot de passe ne correspond pas !</div>
                                            }
                                        </div>

                                        <input type="hidden" name="_target_path" value="/"/>
                                        <div className="bouton submit">
                                            <input type="submit" name="_submit" value="Modifier"/>
                                            {loggingIn &&
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            }
                                        </div>
                                    </div>
                                </form>
                                :
                                    <div className="encadreForm col-xs-12">
                                        <div className="encadreForm col-xs-12">
                                            <div style={{textAlign: 'center', fontSize: '2rem', color: '#c4574e'}}>
                                                <b>{message}</b>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, etablissement, alert} = state;
    const {user} = authentication;
    const { unactivated } = etablissement;
    return {
        user,
        alert,
        unactivated
    }
}

export default connect(mapStateToProps)(PassForm)
