import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from "react-st-modal";

import {alertActions, groupesActions} from '../../../../_actions';

import lib from '../../../../lib/lib';

import Navbar from '../../../../_components/Navbar';
import Footer from '../../../../_components/Footer';

class Groupes_Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: {
                roles: "a:1:{i:0;s:11:\"ROLE_GROUPE\";}",
                username: "",
                password: "",
                password2: "",
                civilite: "",
                emailResponsable: "",
                emailAnnonce: "",
                nomResponsable: "",
                prenomResponsable: "",
                telephoneResponsable: "",
                groupe_id: null,
                index: null,
                rue: "",
                ville: "",
                latitude: "",
                longitude: "",
                codePostal: "",
                nomGroupe: "",
            },
            submitted: false,
        };

        this.handleChangeUser = this.handleChangeUser.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(groupesActions.getAllGroupes())
    }

    handleChangeUser(event) {
        const {name, value} = event.target;
        const {user} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        if (name === 'groupe') {
            this.setState({
                user: {
                    ...user,
                    index: value,
                    nomGroupe: this.props.list[value]?.nom ?? user.nomGroupe,
                    emailResponsable: this.props.list[value]?.emailResponsable ?? user.emailResponsable,
                    emailAnnonce: this.props.list[value]?.emailAnnonce ?? user.emailAnnonce,
                    nomResponsable: this.props.list[value]?.nomResponsable ?? user.nomResponsable,
                    prenomResponsable: this.props.list[value]?.prenomResponsable ?? user.prenomResponsable,
                    telephoneResponsable: this.props.list[value]?.telephoneResponsable ?? user.telephoneResponsable,
                    groupe_id: this.props.list[value]?.id ?? user.groupe_id,
                }
            })
        } else
            this.setState({
                user: {
                    ...user,
                    [name]: lib.protectEntry(value)
                }
            })
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({submitted: true});
        const {user} = this.state;
        const {dispatch} = this.props;

        if (!user.groupe_id)
            Alert("Veuillez selectioner un Groupe")
        else if (
            lib.isValidUsername(user.username) &&
            lib.isValidEmail(user.emailResponsable) &&
            lib.isValidEmail(user.emailAnnonce)
        ) {
            dispatch(groupesActions.createGroupeAccount(user));
        } else {
            Alert("informations incorrectes");
        }
    }

    _renderForm(user, submitted, registering) {

        return (
            <div className="encadreForm col-xs-12 first">
                <div className="question">
                    <p><strong>Choisissez un identifiant de connexion</strong></p>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-6 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                    <label htmlFor="username">Identifiant <span className="rouge">*</span></label>
                    <input type="text"
                           className="form-control"
                           name="username"
                           value={user.username}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidUsername(user.username) ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidUsername(user.username) &&
                    <div className="help-block" style={{color: 'red'}}>Identifiant requis, sans espaces</div>
                    }
                </div>

                <div className="question">
                    <p><strong>Selection du Groupe</strong> (Selectionnez un groupe enregistré dans la base de données)</p>
                </div>
                <div className={'inputInfos col-xs-12 col-sm-6 col-md-6 form-group' + (submitted && !user.groupe_id ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Choisissez un Groupe <span className="rouge">*</span></label>
                    <select name="groupe"
                            value={user.index}
                            onChange={this.handleChangeUser}
                            style={{boxShadow: !user.groupe_id ? '0 0 3px #CC0000' : ''}}>
                        <option value="">Choisir</option>
                        {
                            this.props.list ? this.props.list.map((item, index) =>
                                <option key={index} value={index}>{item.nom}</option>
                            ) : null
                        }
                    </select>
                    {submitted && !user.groupe_id &&
                    <div className="help-block" style={{color: 'red'}}>selection d'un Groupe requise</div>
                    }
                </div>
                <div className={'inputInfos col-xs-12 col-sm-6 col-md-6 form-group' + (submitted && !lib.isValidEmail(user.emailAnnonce) ? ' has-error' : '')}>
                    <label>Email gestion des annonces <span className="rouge">*</span></label>
                    <input type="email"
                           name="emailAnnonce"
                           className="form-control"
                           value={user.emailAnnonce}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidEmail(user.emailAnnonce) ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidEmail(user.emailAnnonce) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'Email</div>
                    }
                </div>
                <div className={'inputInfos col-xs-12 col-sm-6 col-md-6 form-group' + (submitted && !lib.isValidEmail(user.emailResponsable) ? ' has-error' : '')}>
                    <label>Email contact Responsable <span className="rouge">*</span></label>
                    <input type="email"
                           name="emailResponsable"
                           className="form-control"
                           value={user.emailResponsable}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidEmail(user.emailResponsable) ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidEmail(user.emailResponsable) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'Email</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-6 form-group'}>
                    <label>Téléphone Responsable (facultatif)</label>
                    <input type="tel" name="telephoneResponsable" className="form-control" value={user.telephoneResponsable}
                           pattern="(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4}"
                           onChange={this.handleChangeUser}
                           style={{boxShadow: user.telephoneResponsable && !lib.isValidPhone(user.telephoneResponsable) ? '0 0 3px #CC0000' : ''}}
                           placeholder="Ne pas mettre de point ou de tiret"/>
                    <small>(Format Ex: 01 12 34 56 78)</small>
                    {submitted && user.telephoneResponsable && !lib.isValidPhone(user.telephoneResponsable) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format de N° de téléphone</div>
                    }
                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-6">
                    <label>Prénom du Responsable (facultatif)</label>
                    <input type="text" name="prenomResponsable" value={user.prenomResponsable} onChange={this.handleChangeUser}/>
                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-6">
                    <label>Nom du Responsable (facultatif)</label>
                    <input type="text" name="nomResponsable" value={user.nomResponsable} onChange={this.handleChangeUser}/>
                </div>
                <div className="errorFirstStep" style={{color: "red"}}> </div>
                <div>
                    <div className="bouton submit"
                         style={{display: "inline-block", float: "right", margin: "2px"}}>
                        <input type="submit" name="envoyer" id="valideEtablissement" value="Valider"/>
                        <div className="error text-danger"></div>
                        {registering &&
                        <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </div>
                </div>
                <div style={{display: "inline-block", float: "right", margin: "2px"}}>
                    <div className="bouton annuler">
                        <a href="/administrateur/groupes">
                            Annuler
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    renderHeader() {
        return <h1>Créer un Compte Groupe</h1>;
    }

    render() {
        const {registering, alert} = this.props;
        const {user, submitted} = this.state;
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            <p className="chemin"><Link to="/administrateur">Mon compte</Link> &gt; <Link to="/administrateur/groupes">Groupes</Link> &gt; Créer un compte Groupe</p>
                            {this.renderHeader()}
                            {alert.message ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                            <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                <input type="text" autoFocus="autofocus" style={{display: 'none'}}/>
                                <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}}/>
                                <div className="titleForm">
                                    <p className="active" id="vousEtes">informations du Compte Groupe</p>
                                </div>
                                {this._renderForm(user, submitted, registering)}
                            </form>
                            {alert.message ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {registering} = state.registration;
    const {list} = state.groupes;
    const {alert} = state;
    return {
        registering,
        list,
        alert
    };
}

export default withRouter(connect(mapStateToProps)(Groupes_Account));
