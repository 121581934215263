import React, {Component}   from "react";
import {connect}            from "react-redux";
import moment               from 'moment';
import {Confirm, Alert} from "react-st-modal";
import Autosuggest from 'react-autosuggest';

import {infoActions} from "../../../../_actions";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import {ReCaptcha} from "react-recaptcha-google";

//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Infos_Dispo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            info: this.props.info ? this.props.info : false,
            filter: {
                email: '',
                ville: '',
                codepostal: '',
                rayon: '',
                nom: '',
            },
            deleted: [],
            modif: false,
            choix_email: false,
            choix_condition: false,
            recaptcha: '',
            single: {
                etablissement: [],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '10',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleModif = this.handleModif.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);

        this.handleChangeType = this.handleChangeType.bind(this);

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChoix = this.handleChoix.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(infoActions.getInfos());
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            recaptcha: recaptchaToken
        });
    }

    handleChangeFilter(event) {
        const { name, value } = event.target;
        const { filter } = this.state;

        this.setState({
            filter: {
                ...filter,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {single} = this.state;

        this.setState({
            single: {
                ...single,
                [name]: lib.protectEntry(value)
            }
        })
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        var response = await Confirm("Voulez-vous vraiment supprimer cette alerte?", "", "Oui", "Fermer")

        if (response == true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            })
            this.props.dispatch(infoActions.delInfo(id))
        }
    }

    handleChangeType(event) {
        const {value, checked} = event.target;
        const {single} = this.state;

        if (!checked) {
            this.setState({
                single: {
                    ...single,
                    etablissement: single.etablissement.filter(function (item) {
                        return item != value;
                    })
                }
            })
        } else {
            this.setState({
                single: {
                    ...single,
                    etablissement: [...single.etablissement, value]
                }
            })
        }
    }
/*
    handleSelect(place) {
        const { filter } = this.state;

        place.address_components.map(item => {
            if (item.types[0] == "postal_code") {
                this.setState({
                    filter: {
                        ...filter,
                        codepostal: item.long_name
                    }
                })
            } else if (item.types[0] == "locality") {
                this.setState({
                    filter: {
                        ...filter,
                        ville: item.long_name
                    }
                })
            }
        });
    };
*/

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {filter} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            filter: {
                ...filter,
                codepostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
            }
        })

    };

    handleChoix(e) {
        const {name} = e.target;
        const {choix_email, choix_condition} = this.state;

        if (name == 'choix_email') {
            this.setState({
                [name]: !choix_email
            })
        } else {
            this.setState({
                [name]: !choix_condition
            })
        }

    }

    renderFilter(item) {
        const {deleted, filter} = this.state;

        const email     = filter.email  != '' ? (filter.email.toLowerCase().indexOf(item.email)       >= 0 ? true : false) : true;
        const ville     = filter.ville  != '' ? (filter.ville.toLowerCase().indexOf(item.codepostal)  >= 0 ? true : false) : true;
        const rayon     = filter.rayon  != '' ? (filter.rayon.toLowerCase().indexOf(item.rayon)       >= 0 ? true : false) : true;
        const nom       = filter.nom    != '' ? (filter.nom.indexOf(item.nom) >= 0 ? true : false ) : true;
        const notDel    = deleted.length != 0 ? (deleted.indexOf(item.id) < 0) : true;

        return (email && ville && rayon && notDel && nom)
    }

    handleModif(e) {
        e.preventDefault();
        const {modif, single} = this.state;

        const array = this.props.info.list.dispo.filter(item => item.id == e.target.id);
        var t_final = []
        this.props.info.list.types.filter(function(t) {
            return (t.info_dispo_id == e.target.id)
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );
        this.setState({
            modif: !modif,
            single: {
                ...single,
                id: e.target.id,
                personnes_id: array[0].id,
                codepostal: array[0].codepostal,
                rayon: array[0].rayon,
                nom: array[0].nom,
                entite: array[0].entite,
                email: array[0].email,
                telephone: array[0].telephone,
                latitude: array[0].latitude,
                longitude: array[0].longitude,
                ville: array[0].ville,
                etablissement: t_final
            }
        })
    }

    handleGoBack(e) {
        e.preventDefault();

        this.setState({modif: false})
    }

    handleSubmit(e) {
        e.preventDefault();
        const {choix_email, choix_condition, recaptcha, single} = this.state;

        if (!choix_email || !lib.isValidEmail(single.email)) {
            Alert("Nous avons besoin d'un email valid ainsi que de votre consentement pour pouvoir vous alerter des disponibiltés")
        } else if (!choix_condition) {
            Alert("Veuillez lire et accepter les conditions générales d'utilisation")
        } else if (single.etablissement.length <= 0) {
            Alert("Veuillez choisir, au moins, un type d'établissement")
        } else if (recaptcha.length <= 0){
            Alert("veuillez confirmer que vous n'êtes pas un robot")
        } else {
            this.props.dispatch(infoActions.updateInfo(single))
        }
    }

    render() {

        const { filter, modif, single, choix_email, choix_condition, value, suggestions } = this.state;
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        const {alert, info} = this.props
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    {   !modif ?
                        <section className="listeDemandeBloc1 blocAlertesingle">
                            <div className="container">
                                <p className="chemin"><a href="/administrateur">Mon compte</a> &gt;  Alerte dispo</p>
                                <h1>Alerte <span>dispo</span></h1>
                                <div className="col-xs12 bouton voir">
                                    <a href="/alerte-dispo">Créer une alerte-dispo</a>
                                </div>
                                <div className="filtres col-xs-12">
                                    <p>Filtrer par</p>
                                    <input type="text" name="nom" value={filter.nom} id="nom" placeholder="Nom"
                                           onChange={this.handleChangeFilter}/>
                                    <input type="text" name="email" value={filter.email}
                                           onChange={this.handleChangeFilter} id="email" placeholder="E-mail"/>

                                    <div className="villeInput" style={{width: "90%"}}>
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                            highlightFirstSuggestion={true}
                                        />
                                    </div>
                                    <select id="rayon" name="rayon" value={filter.rayon}
                                            onChange={this.handleChangeFilter}>
                                        <option value="">Dans un rayon de...</option>
                                        <option value="5">5km</option>
                                        <option value="10">10km</option>
                                        <option value="20">20km</option>
                                        <option value="30">30km</option>
                                        <option value="50">50km</option>
                                    </select>
                                    <hr/>
                                    <br/>
                                </div>

                                {
                                    !info.list ?

                                        <div className="container">
                                            <hr/>
                                            <h1><span>En attente</span> d'Alerte <span>dispo</span></h1>
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                        </div>

                                        :

                                        <div id="listeDemande">


                                            {info.list.dispo.filter(dispo => this.renderFilter(dispo)).map((item) =>

                                                <div className="singleDemande" key={item.id}>
                                                    <div className="col-xs-16">
                                                        <div className="question">
                                                            <p>Alerte-Dispo  {item.nom != "undefined" && item.nom.length != 0 ? "de "+item.nom : "de ANONYME"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-echeance.png"
                                                             alt="icone-sexe"/>
                                                        <p>
                                                            Posté le <strong> {moment(item.created_at).calendar()} </strong>
                                                        </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-secteur.png"
                                                             alt="icone-sexe"/>
                                                        <p>À <strong> {item.ville} </strong> ({item.codepostal}) dans un
                                                            rayon de <strong>{item.rayon}km</strong></p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-type-etablissement.png"
                                                             lt="icone-type-etablissement"/><p>Type d'établissement :
                                                        {
                                                            info.list.types.filter(t => t.info_dispo_id == item.id).map(t =>
                                                            <strong key={Math.floor(Math.random() * Math.floor(1000))}>
                                                                <br/>
                                                                {types[t.type_etablissement_id]}
                                                            </strong>
                                                        )}
                                                    </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <i className="fa fa-at" aria-hidden="true"> </i>
                                                        <p>Email : <strong>{item.email}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.handleModif} id={item.id}>Modifier</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                }
                            </div>
                        </section>
                        :
                        <section className="alertesingleBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/">Accueil</a> &gt; Modifier "Alerte-dispo"</p>
                                <h1>Je crée mon <span>"Alerte-dispo"</span></h1>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <strong>Pour être informé par email dès qu’une nouvelle place est disponible sur
                                        votre
                                        zone
                                        de recherche </strong>
                                </div>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="encadreForm">

                                        <form name="appbundle_alertedispo" className="form-horizontal">

                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                        ALERTE-DISPO
                                                        » ?</p>
                                                    <p>Sélection multiple possible</p>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_alertedispo_etablissement_1"
                                                               name="etablissement"
                                                               value="1"
                                                               defaultChecked={single.etablissement.includes(1)}
                                                               onChange={this.handleChangeType}/>
                                                        <label
                                                            htmlFor="appbundle_alertedispo_etablissement_1">EHPAD</label>
                                                        <p>
                                                            Etablissements d'hébergement pour personnes âgées
                                                            dépendantes,
                                                            ce
                                                            sont
                                                            des maisons de retraite médicalisées.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_alertedispo_etablissement_2"
                                                               name="etablissement"
                                                               value="2"
                                                               defaultChecked={single.etablissement.includes(2)}
                                                               onChange={this.handleChangeType}/>
                                                        <label
                                                            htmlFor="appbundle_alertedispo_etablissement_2">USLD</label>
                                                        <p>
                                                            Unités de soins de longue durée, ce sont les lieux
                                                            d'accueil
                                                            les
                                                            plus médicalisés.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_alertedispo_etablissement_3"
                                                               name="etablissement"
                                                               value="3"
                                                               defaultChecked={single.etablissement.includes(3)}
                                                               onChange={this.handleChangeType}/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_3">Résidence
                                                            autonomie</label><p>
                                                        Ex-logements-foyers, elles accueillent majoritairement des
                                                        personnes
                                                        autonomes dans des petits appartements
                                                    </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_alertedispo_etablissement_4"
                                                               name="etablissement"
                                                               value="4"
                                                               defaultChecked={single.etablissement.includes(4)}
                                                               onChange={this.handleChangeType}/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_4">Résidence
                                                            services
                                                            seniors</label><p>
                                                        Résidences avec services qui accueillent des personnes âgées
                                                        autonomes
                                                        seules ou en couple en T1 jusqu’au T3.
                                                    </p>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="formBloc2">
                                                <div className="question">
                                                    <p>Dans quel secteur géographique recherchez-vous ?</p>
                                                </div>
                                                <div className="inputSecteur">
                                                    <div className="inputVille">
                                                        <label>Saisissez un code postal</label>
                                                        {/*
                                                            <Autocomplete
                                                                style={{width: '90%'}}
                                                                onPlaceSelected={(place) => {
                                                                    this.handleSelect(place)
                                                                }}
                                                                types={['(regions)']}
                                                                componentRestrictions={{country: "fr"}}
                                                                placeholder="indiquez un code postal ou une ville"
                                                            />
                                                        */}
                                                    </div>
                                                    <div className="selectRayon">
                                                        <label>Dans un rayon de</label>
                                                        <select id="appbundle_alertedispo_rayon"
                                                                name="rayon" onChange={this.handleChange}>
                                                            <option value="10">10 km</option>
                                                            <option value="20">20 km</option>
                                                            <option value="30">30 km</option>
                                                            <option value="50">50 km</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formBloc3">
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre email <span>*</span></label>
                                                        <input type="text" id="appbundle_alertedispo_email"
                                                               name="email" required="required" value={single.email}
                                                               maxLength="255" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div
                                                    className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="checkbox" name="choix_email" value={choix_email}
                                                           onChange={this.handleChoix} required=""/>
                                                    <label><strong>J’accepte de recevoir par email une « Alerte-dispo »
                                                        dès
                                                        qu’une place se libère, selon mes critères de recherche
                                                        ci-dessus.</strong></label>
                                                </div>
                                            </div>
                                            <br/>
                                            <p>Les informations ci-dessus seront utilisées que par l’administrateur du
                                                site
                                                pour vous envoyer les places disponibles et ne seront pas visibles sur
                                                le
                                                site. </p><br/>

                                            <div
                                                className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <input type="checkbox" name="choix_condition" value={choix_condition}
                                                       onChange={this.handleChoix} required=""/>
                                                <label>j’accepte les <a href="/conditions-generales">conditions
                                                    générales d’utilisation.</a></label>
                                            </div>
                                            <br/>
                                            <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                                    <div style={{width: "304px", height: "78px"}}>
                                                        <ReCaptcha
                                                            ref={(el) => {
                                                                this.captchaDemo = el;
                                                            }}
                                                            size="normal"
                                                            data-theme="dark"
                                                            render="explicit"
                                                            sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                            onloadCallback={this.onLoadRecaptcha}
                                                            verifyCallback={this.verifyCallback}
                                                        />
                                                        <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                                  className="g-recaptcha-response"
                                                                  style={{
                                                                      width: "250px",
                                                                      height: "40px",
                                                                      border: "1px solid rgb(193, 193, 193)",
                                                                      margin: "10px 25px",
                                                                      padding: "0px",
                                                                      resize: "none",
                                                                      display: "none"
                                                                  }}>
                                                        </textarea>
                                                    </div>
                                            </div>
                                            <br/>
                                            {
                                                alert.message ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                    :
                                                    <div className="bouton submit">
                                                        <p className="text-danger hide error">Vous devez choisir un
                                                            etablissement</p>
                                                        <button type="submit" id="appbundle_alertedispo_save"
                                                                name="save" onClick={this.handleSubmit}>Modifier
                                                        </button>
                                                        <br/>
                                                        <button type="submit" id="appbundle_alertedispo_save"
                                                                name="save" onClick={this.handleGoBack}>Annuler
                                                        </button>
                                                    </div>

                                            }
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <img className="img-responsive image-alert"
                                         src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                                </div>
                            </div>
                        </section>
                    }
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {info, alert, authentication} = state;
    const {user} = authentication;
    return {
        info,
        alert,
        user

    }
}

export default connect(mapStateToProps)(Infos_Dispo)
