import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import AdminRoute from '../../../../_components/AdminRoute'

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import Activations_Etablissement from './Activations.Etablissement';

class Activations extends Component {
    constructor(props) {
        super(props)



        this.state = {
            user: (localStorage.getItem('user') ? this.props.user : false)
        }
    }


    render() {
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="listeDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><Link to="/administrateur">Mon compte</Link> &gt; Activation</p>
                            <h1>Activation</h1>
                            <div className="col-xs12 bouton voir">
                                <Link to="/administrateur/activations/etablissements">Annonces des établissements</Link>
                            </div>
                            <br/>
                            <br/>
                            <div className="col-xs12 bouton voir">
                                <Link to="/administrateur/activations/demande-etablissement">Demandes des familles/pros</Link>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state){
    const { authentication } = state;
    const { user } = authentication;
    return {
        user,
    }
}

export default connect(mapStateToProps)(Activations)