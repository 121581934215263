import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar  from '../../_components/Navbar';
import Footer from '../../_components/Footer';

class Place_Maison extends Component {

    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>


                <section className="placeMaisonBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/">Accueil</a> &gt; Je cherche une place en maison de retraite</p>
                        <h1>Je cherche une place <span>en maison de retraite</span></h1>
                        <div className="placeMaisonIcone">
                            <div className="contentPlaceMaison">
                                <div className="titrePlaceMaison">
                                    <a href="/liste-annonce">
                                        <p>Je consulte les</p>
                                        <p>places disponibles</p>
                                    </a>
                                </div>
                                <a href="/liste-annonce" className="lienImg">
                                    <img className="img-responsive"
                                         src="/img/place-maison/icone-places-dispo.png"
                                         alt="icone-maison"/>
                                </a>
                            </div>
                            <div className="contentPlaceMaison">
                                <a href="/demande-etablissement" className="lienImg">
                                    <img className="img-responsive"
                                         src="/img/place-maison/icone-demande.png"
                                         alt="icone-maison"/>
                                </a>
                                <div className="titrePlaceMaison">
                                    <a href="/demande-etablissement">
                                        <p>Je dépose une demande</p>
                                        <p>auprès des établissements</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 contentPlaceMaisonText">
                            <p><strong>Consultez librement toutes les places disponibles en maison de retraite publiées directement par les établissements de votre zone de recherche.</strong></p>
                        </div>
                        <div className="col-xs-12 col-sm-6 contentPlaceMaisonText">
                            <p><strong>Déposez gratuitement une demande de place disponible auprès des maisons de retraite présentes sur votre zone de recherche.</strong></p>
                        </div>
                    </div>
                    <br/><br/>
                    <div className="container" style={{float: 'right'}}>
                        <div className="placeMaisonIcone">
                            <div className="contentPlaceMaison">
                                <div className="titrePlaceMaison">
                                    <a href="/alerte-dispo">
                                        <p>Je crée mon</p>
                                        <p>Alerte-dispo</p>
                                    </a>
                                </div>
                                <a href="/alerte-dispo" className="lienImg">
                                    <img className="img-responsive"
                                         src="/img/alerte-dispo/alerte-dispo.png"
                                         alt="icone-alerte"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 contentPlaceMaisonText" style={{textAlign: 'center'}}>
                            <p style={{display: 'inline-block'}}><strong>Soyez informé par email dès qu’une nouvelle place est disponible dans une maison de retraite présente sur votre zone de recherche.</strong></p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Place_Maison)
