import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import ScrollUpButton from "react-scroll-up-button";
import moment from "moment";
import 'icheck/skins/all.css'; // or single skin css
import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import {alertActions, etablissementActions, groupesActions, userActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import {authHeader, history} from "../../../../_helpers";
import {etablissement} from "../../../../_reducers/etablissement.reducer";
import {withRouter} from "react-router";
import ReactPaginate from "react-paginate";
import { Confirm, Alert } from 'react-st-modal';
import DatePicker from "react-datepicker";

//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Etablissements extends Component {

    constructor(props){
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            codePostal: '',
            ville: '',
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            etablissements: this.props.list,
            etablissement: {
                civilite: '',
                codePostal: '',
                code_insee: '',
                email_etablissement: '',
                groupe_etablissement: '',
                groupe_id: null,
                id: 0,
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
                latitude: '',
                longitude: '',
                nom: '',
                nom_etablissement: '',
                numerofinesse: '',
                prenom: '',
                rue: '',
                site_etablissement: '',
                specs: [],
                statut: '',
                telephone: '',
                telephone_etablissement: '',
                type_etablissement_id: '',
                ville: '',
                username: '',
                email: '',
                email2: '',
                emailGroupe: '',
                password: '',
                password2: ''
            },
            createAnnonce: false,
            previewAnnonce: false,
            annonce: {
                user_id: null,
                email: '',
                email2: null,
                site: '',
                telephone: '',
                nom: '',
                nbPLace: 1,
                codePostal: '',
                ville: '',
                rue: '',
                type: '',
                createdAt: '',
                datePlace: new Date(),
                prix: 'NC',
                descriptif: '',
                equipements: 'aucun',
                activate: -1,
                token: '',
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
                specificite: []
            },
            id: null,
            date_state: null,
            maxLength: 400,
            CGU: false,
            form: 1,
            form_etablissement: {},
            form_user: {display: "none"},
            chk1: "",
            chk2: "",
            chk3: "",
            chk4: "",
            chk5: "",
            submitted: false,
            pictures: [],
            btn1: true,
            btn2: true,
            btn3: true,
            imagePreviewUrl: [],
            image_un: '',
            image_deux: '',
            image_trois: '',
            image_quatre: '',
            condition: true,
            alert0: false,
            alert1: false,
            alert2: false,
            alert3: false,
            preview: false,
            deleted: [],
            filter: {
                nom: '',
                numero: '',
                codePostal: '',
                Type: [1, 2, 3, 4],
                direc: 'asc',
                ville: '',
                search: '',
                lat: '',
                lng: '',
                rayon: '',
                finess: '',
                status: '',
                control: '',
                distance: false
            },
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            display: "none",
            message: "",
            screenWidth: null,
            start: 0,
            end: 15,
            selected: 0
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.renderParticularite = this.renderParticularite.bind(this);
        this.changeInput = this.changeInput.bind(this);

        this._handleChangeForm = this._handleChangeForm.bind(this);

        this._handleChangeUser = this._handleChangeUser.bind(this);
        this._handleChangeInfos = this._handleChangeInfos.bind(this);
        this._handleChangeSpec = this._handleChangeSpec.bind(this);

        this.selectImages = this.selectImages.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.deleteImage  = this.deleteImage.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.sortDistance = this.sortDistance.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        //this.handleAnnonces = this.handleAnnonces.bind(this);

        this.distanceInKmBetweenEarthCoordinates = this.distanceInKmBetweenEarthCoordinates.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.handleEmailControl = this.handleEmailControl.bind(this);

        this.handleAnnonce = this.handleAnnonce.bind(this);
        this.handleChangeCGU = this.handleChangeCGU.bind(this);
        this.handleModifAnnonce = this.handleModifAnnonce.bind(this);
        this.handlePreviewAnnonce = this.handlePreviewAnnonce.bind(this)
        this.handleChangeDateAnnonce = this.handleChangeDateAnnonce.bind(this);
        this.handleChangeSpecAnnonce = this.handleChangeSpecAnnonce.bind(this);
        this.renderCreateAnnonce = this.renderCreateAnnonce.bind(this);
        this.handleSubmitAnnonce = this.handleSubmitAnnonce.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));

        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.etablissements !== this.state.etablissements) {
            let etablissements = this.props.list;

            this.setState({
                etablissements
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.list !== prevState.etablissements) {

            return {etablissements: nextProps.list};
        } else return null;
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15, selected})
        window.scrollTo(0,0)
    };
/*
    handleAnnonces(e) {
        e.preventDefault();
        const {id} = e.target;
        localStorage.setItem('user_id', id);
        this.props.history.push({pathname: '/administrateur/annonces-actives'}); //to be reviewed
    }
*/
    handlePreview(event) {
        event.preventDefault();
        const {etablissement} = this.state;

        const array = this.props.list.etablissements.filter(item => parseInt(item.id) === parseInt(event.target.id));
        let t_final = [];
        this.props.list.specs.filter(function(e) {
            return (parseInt(e.etablissement_id) === parseInt(event.target.id))
        }).map(el =>
            t_final.push(el)
        );

        this.setState({
            etablissement: {
                ...etablissement,
                civilite: array[0].civilite,
                codePostal: array[0].codePostal,
                code_insee: array[0].code_insee,
                email_etablissement: array[0].email_etablissement,
                groupe_etablissement: array[0].groupe_etablissement,
                groupe_id: this.props.user.user.groupe_id,
                id: array[0].id,
                image_deux: array[0].image_deux,
                image_quatre: array[0].image_quatre,
                image_trois: array[0].image_trois,
                image_un: array[0].image_un,
                latitude: array[0].latitude,
                longitude: array[0].longitude,
                nom: array[0].nom,
                nom_etablissement: array[0].nom_etablissement,
                numerofinesse: array[0].numerofinesse,
                prenom: array[0].prenom,
                rue: array[0].rue,
                site_etablissement: array[0].site_etablissement,
                specs: t_final,
                statut: array[0].statut,
                telephone: array[0].telephone,
                telephone_etablissement: array[0].telephone_etablissement,
                type_etablissement_id: array[0].type_etablissement_id,
                ville: array[0].ville,
                username: array[0].username,
                user_id: array[0].user_id,
                email: array[0].email,
                email2: array[0].email2,
                emailGroupe: array[0].emailGroupe ?? null,
                password: '',
                password2: ''
            },
            value: array[0].ville + ' ' + array[0].codePostal,
            preview: true
        });
        window.scrollTo(0,0)
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false, alert1: false, alert2: false})
    }


    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        const item = this.props.list.etablissements.find((item) => parseInt(item.id) === parseInt(id));
        let response = await Confirm("Êtes-vous sûr de vouloir retirer cet établissement de votre Groupe?", "", "Oui", "Fermer");
        if (response === true) {
            this.setState({
                deleted: [...deleted, item.id]
            });

            const index = item.id;
            const user_id = item.user_id;
            this.props.dispatch(etablissementActions.delEtablissementGroupe(index, user_id));
            this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
        }
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;
        const {filter} = this.state;

        if (name === "status" || name === "control") {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value,
                },
                start: 0,
                end: 15,
            })
        } else if (name === "rayon") {
            if (filter.lat.length === 0 && filter.lng.length === 0) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: value,
                        distance: true,
                    },
                    start: 0,
                    end: 15,
                })
            }
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value
                },
                start: 0,
                end: 15,
            })
        }
    }

    changeInput(name, value, type) {
        const { filter } = this.state;
        const { Type } = filter;

        if (value) {
            this.setState({
                filter: {
                    ...filter,
                    Type: Type.filter(item => item !== parseInt(type))
                },
                [name]: !value,
                alert1: false
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    Type: [...Type, parseInt(type)]
                },
                [name]: !value,
                alert1: false
            })
        }
    }

    clickCheckBox(e) {
        const { name, value, type, checked } = e.target;
        const { filter } = this.state;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        Type: [...filter.Type, parseInt(value)]
                    },
                    [name]: true,
                    alert1: false,
                    start: 0,
                    end: 15,
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        Type: filter.Type.filter(function (item) {
                            return item !== parseInt(value)
                        })
                    },
                    [name]: false,
                    alert1: false,
                    start: 0,
                    end: 15,
                })
            }
        }
    }

    supprimerFiltre() {
        const {filter} = this.state;
        this.setState({
            filter: {
                ...filter,
                codePostal: '',
                lat: '',
                lng: '',
                ville: '',
                rayon: '',
                distance: false,
                Type: [1, 2, 3, 4]
            },
            value: '',
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            alert0: false,
            alert1: false,
            alert2: false,
            start: 0,
            end: 15,
        })
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { filter } = this.state;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;

        const codePostal    = filter.codePostal  !== '' ? (item.codePostal.includes(filter.codePostal)) : filter.ville !== '' ? (filter.ville === item.ville) : true;
        const rayon         = filter.distance === true  ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon, 10)) : true;
        const nom           = filter.nom  !== '' ? item.nom_etablissement.toLowerCase().includes(filter.nom.toLocaleLowerCase()) :  true;
        const finess        = filter.finess ? item.numerofinesse ? item.numerofinesse.includes(filter.finess) : false : true;
        const numero        = filter.numero  !== '' ? item.telephone_etablissement.includes(filter.numero) : true;
        const notDel        = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;
        const Type          = filter.Type.includes(item.type_etablissement_id);
        const locality      = (codePostal || filter.distance);

        let status = true;
        switch (filter.status) {
            case "NoAnnonces": status = item.nbAnnonceTot === 0; break;
            case "AnnonceTot": status = true; break;
            case "AnnonceAct": status = item.nbAnnonceAct > 0; break;
            case "AnnonceUnaS": status = item.nbAnnonceUnaS > 0; break;
            case "AnnonceUnaU": status = item.nbAnnonceUnaU > 0; break;
            case "AnnonceUnaV": status = item.nbAnnonceUnaV > 0; break;
            default: break;
        }
        let control = true;
        switch (filter.control) {
            case "oui": control = item.emailGroupe?.length > 0; break;
            case "non": control = !item.emailGroupe; break;
            default: control = true; break;
        }

        return (nom && numero && notDel && locality && Type && rayon && finess && status && control);
    }

    renderParticularite(item) {
        const {specs} = this.props.list;
        let infos = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];

        let tab = specs.filter(function(spec) { return parseInt(spec.etablissement_id) === parseInt(item.id)});

        return (
            tab.length > 0 ?
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{marginRight: '0.5rem'}}>
                    <i className="fa fa-check-square-o img-responsive" style={{color: '#c4574e'}} aria-hidden="true"> </i><strong>Spécificités: </strong><br/>

                        {'- ' + infos[parseInt(tab[0].information_hebergement_id) - 1]}<br/>
                        {tab[1] ? '- ' + infos[parseInt(tab[1].information_hebergement_id) - 1] : null}<br/>
                        {tab[2] ? '- ' + infos[parseInt(tab[2].information_hebergement_id) - 1] : null}<br/>
                        {tab[3] ? '- ' + infos[parseInt(tab[3].information_hebergement_id) - 1] : null}<br/>
                        {tab[4] ? '- ' + infos[parseInt(tab[4].information_hebergement_id) - 1] : null}<br/>

                </div>
                :
                null

        )
    }

    _handleChangeForm(e) {
        const {name} = e.target;
        switch (name) {
            case "form1":
                this.setState({form_user: {display: "none"}, form_etablissement: {}, form: 1, alert0: false, alert1: false, alert2: false});
                break;
            case "form2":
                this.setState({form_user: {}, form_etablissement: {display: "none"}, form: 2, alert0: false, alert1: false, alert2: false});
                break;
            default:
                this.setState({form_etablissement: {}, form_user: {display: "none"}, form: 1, alert0: false, alert1: false, alert2: false});
        }
    }

    _handleChangeUser(e) {
        const {name, value} = e.target;

        const {etablissement} = this.state;

        this.setState({
            btn2: false,
            btn1: false,
            etablissement: {
                ...etablissement,
                [name]: lib.protectEntry(value)
            },
            alert1: false,
            alert0: false,
        })
    }

    _handleChangeInfos(e) {
        const {name, value} = e.target;

        const {etablissement} = this.state;

        this.setState({
            etablissement: {
                ...etablissement,
                [name]: lib.protectEntry(value)
            },
            btn2: false,
            alert1: false,
            alert0: false,
        })
    }

    selectImages(event) {
        const {dispatch} = this.props;
        const {name} = event.target;
        const {etablissement, pictures} = this.state;

        dispatch(alertActions.clear());

        let file = event.target.files[0];

        if ( file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/) && file.size < 2000000) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    pictures: [...pictures, {'file': file, 'id': etablissement.id, 'pic': name}],
                    etablissement: {
                        ...etablissement,
                        [name]: reader.result
                    },
                    [name]: reader.result,
                    btn3: false,
                    display: "none",
                    alert2: false
                });
            };
            reader.readAsDataURL(file)
        } else if (file.size > 2000000) {
            this.setState({
                message: "l'image est trop Volumineuse (supérieure à 2MB)",
                display: ""
            })
        } else {
            this.setState({
                message: "Formats d'images acceptés: .jpg, .jpeg, .png ou .gif ",
                display: ""
            })
        }
    }

    uploadImages(e) {
        e.preventDefault();

        const {condition, user, etablissement} = this.state;
        const {dispatch} = this.props;

        let i = 0;
        this.state.pictures.map(image => {
            i = i + 1;
            const data = new FormData();
            data.append("image", image.file, image.file.name);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + user.confirmation_token,
                    'id': image.id,
                    'pic': image.pic
                },
                body: data
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/upload`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    if (result.success) {
                        this.setState({
                            condition: condition && result.success,
                            alert2: true,
                            etablissement: {
                                ...etablissement,
                                [result.position]: result.filename
                            },
                            //[result.position]: ""
                        })
                    }
                    if (condition && i === this.state.pictures.length) {
                        this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
                        dispatch(alertActions.success('Image(s) téléchargée(s) avec succès'))
                    }
                });
        });
    }

    deleteImage(name, position) {
        const {etablissement, user} = this.state;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization' : 'Bearer ' + user.confirmation_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: etablissement.id,
                name,
                position
            })
        };

        return fetch(`https://backend.placemaisonderetraite.fr/etablissements/deleteImage`, requestOptions)
            .then(this.handleResponse)
            .then(result => {
                if (result.success) {
                    this.setState({
                        etablissement : {
                            ...etablissement,
                            [position]: ""
                        },
                        [position]: "",
                        btn3: true,
                    });
                    Alert(result.message);
                    this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id))
                } else {
                    Alert(result.message);
                }
            });

    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    async handleDeleteImage(event) {
        event.preventDefault();

        this.props.dispatch(alertActions.clear())
        const {etablissement} = this.state;
        const { id } = event.target;

        let response = await Confirm("Voulez-vous vraiment supprimer cette image?", "", "Oui", "Fermer");
        if (response === true) {
            switch (id) {
                case "1":
                    return this.deleteImage(etablissement.image_un, "image_un");
                case "2":
                    return this.deleteImage(etablissement.image_deux, "image_deux");
                case "3":
                    return this.deleteImage(etablissement.image_trois, "image_trois");
                case "4":
                    return this.deleteImage(etablissement.image_quatre, "image_quatre");
                default:
                    return null
            }
        }
    }

    _handleChangeSpec(e) {
        const {value, checked} = e.target;
        const {etablissement, chk1, chk2, chk3, chk4, chk5} = this.state;

        this.setState({btn2: false});

        switch (value) {
            case "1":
                this.setState({chk1: !chk1});
                break;
            case "2":
                this.setState({chk2: !chk2});
                break;
            case "3":
                this.setState({chk3: !chk3});
                break;
            case "4":
                this.setState({chk4: !chk4});
                break;
            case "5":
                this.setState({chk5: !chk5});
                break;
            default:
                return null
        }

        if (!checked) {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: etablissement.specs.filter(function (item) {
                        return item.information_hebergement_id !== parseInt(value);
                    })
                },
                alert0: false,
                alert1: false
            })
        } else {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: [...etablissement.specs, {etablissement_id: parseInt(etablissement.id), information_hebergement_id: parseInt(value)}],
                },
                alert0: false,
                alert1: false
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            filter: {
                ...this.state.filter,
                codePostal: '',
                lat: '',
                lng: '',
                ville: '',
                rayon: '',
                distance: false
            },
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {etablissement, filter, preview} = this.state;

        if (preview) {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                etablissement: {
                    ...etablissement,
                    codePostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                    latitude: suggestion.value.geoLoc.coordinates[1],
                    longitude: suggestion.value.geoLoc.coordinates[0],
                    rayon: "5",
                    distance: true
                },
                btn2: false,
            })
        } else {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                filter: {
                    ...filter,
                    codePostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                    lat: suggestion.value.geoLoc.coordinates[1],
                    lng: suggestion.value.geoLoc.coordinates[0]
                },
                start: 0,
                end: 15,
            })
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {user, etablissement, pictures, password, password2, form} = this.state;
        const {dispatch} = this.props;

        if (parseInt(etablissement.type_etablissement_id) === 4) {
            etablissement.numerofinesse = "";
        }

        if (form === 2) {
            if (etablissement.password && lib.isValidPassword(etablissement.password) && lib.passwordMatch(etablissement.password, etablissement.password2)) {
                if (lib.isValidUsername(lib.protectEntry(etablissement.username))) {
                    dispatch(userActions.updateUser(etablissement, "personne", this.props.history));
                    this.setState({alert0: true});
                    this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id))
                }
            } else if (lib.isValidUsername(lib.protectEntry(etablissement.username))) {
                dispatch(userActions.updateUser(etablissement, "personne", this.props.history));
                this.setState({alert0: true});
                this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id))
            } else {
                Alert("Assurez vous que les informations personnelles sont correctes")
            }
        } else if (form === 1) {
            if (
                (etablissement.nom ? lib.isValidName(etablissement.nom) : true) &&
                (etablissement.prenom ? lib.isValidName(etablissement.prenom) : true) &&
                lib.isValidEmail(etablissement.email) &&
                lib.isValidPhone(etablissement.telephone) &&
                (etablissement.numerofinesse ? lib.isValidFiness(etablissement.numerofinesse) : true) &&
                etablissement.rue &&
                lib.isValidCP(etablissement.codePostal) &&
                lib.isValidPhone(etablissement.telephone_etablissement) &&
                lib.isValidEmail(etablissement.email_etablissement) &&
                (etablissement.site_etablissement ? lib.isValidURL(etablissement.site_etablissement) : true)

            ) {
                dispatch(etablissementActions.updateEtablissement(etablissement, etablissement, this.props.history));
                this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
                this.setState({alert1: true})
            } else {
                Alert("Assurez vous de renseigner correctement les informations à mettre à jour")
            }
        }
    }

    async handleEmailControl(e, all, control, id) {
        e.preventDefault();
        this.setState({alert3: false});
        let response;
        if (all) {
            if (control)
                response = await Confirm("Voulez-vous prendre le contrôle des annonces pour tous vos établissement?", "", "Oui", "Fermer");
            else
                response = await Confirm("Voulez-vous laisser vos établissements gérer leurs annonces?", "", "Oui", "Fermer");
        } else {
            if (!control)
                response = await Confirm("Voulez-vous laisser le contrôle des annonces à cet établissement?", "", "Oui", "Fermer");
            else
                response = await Confirm("Voulez-vous prendre le contrôle des annonces de cet établissement?", "", "Oui", "Fermer");
        }

        const {email2} = this.state.user;

        if (response) {
            this.setState({alert3: true});
            await this.props.dispatch(groupesActions.takeEmailControl(all, control, email2, this.props.user.user.groupe_id, id));
            await this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
        }
    }

    handleAnnonce(e) {
        const item = this.props.list.etablissements.find((item) => parseInt(item.id) === parseInt(e.target.id));
        this.setState({
            createAnnonce: true,
            annonce: {
                ...this.state.annonce,
                user_id: item.user_id,
                email: item.email,
                email2: item.email2 ?? null,
                email_etablissement: item.email_etablissement,
                token: item.confirmation_token,
                codePostal: item.codePostal,
                ville: item.ville,
                rue: item.rue,
                type: item.type_etablissement_id,
                nom: item.nom_etablissement,
                telephone: item.telephone_etablissement,
                site: item.site_etablissement,
                image_un: item.image_un,
                image_deux: item.image_deux,
                image_trois: item.image_trois,
                image_quatre: item.image_quatre,
            }
        })
    }

    handleModifAnnonce(e) {
        const {name, value} = e.target;
        const {annonce, maxLength} = this.state;

        if (name === "descriptif" && annonce.descriptif.length <= maxLength ) {
            this.setState({
                annonce: {
                    ...annonce,
                    descriptif: lib.protectEntry(value)
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    [name]: value
                }
            })
        }
    }

    handleChangeSpecAnnonce(event) {
        const {value, checked} = event.target;
        const {annonce} = this.state;

        if (!checked) {
            this.setState({
                annonce: {
                    ...annonce,
                    specificite: annonce.specificite.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    specificite: [...annonce.specificite, value]
                }
            })
        }
    }

    handleChangeDateAnnonce(date) {
        const {annonce, date_state} = this.state;

        this.setState({
            annonce: {
                ...annonce,
                datePlace: date
            },
            date_state: date,
        });
    }

    handleChangeCGU(e) {
        const {name, value} = e.target;

        this.setState({CGU: !this.state.CGU})
    }

    _renderTime(time) {
        let now = moment().toISOString();
        let diff = moment.duration(moment(time).diff(moment(now)));

        let days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    Disponibilité dans { days } jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    Disponibilité immédiate  <span className="green-dot"></span>
                </p>
            )
        }
    }

    handlePreviewAnnonce(e) {
        e.preventDefault();

        const {previewAnnonce} = this.state;

        this.setState({
            previewAnnonce: !previewAnnonce,
            CGU: false
        })
        window.scrollTo(0,0)
    }

    handleSubmitAnnonce(e) {
        e.preventDefault();
        const {annonce, success, CGU} = this.state;
        const {dispatch} = this.props;

        if (CGU) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({annonce})
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/postAnnonce`, requestOptions)
                .then(this.handleResponse)
                .then(async result => {
                    if (result.success) {
                        await this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));
                        dispatch(alertActions.success("Votre annonce est en attente de validation par notre modérateur. Une fois validée, elle sera publiée pour une période de 7 jours"));
                        this.setState({previewAnnonce: false, createAnnonce: false});
                        //window.location.reload()
                    } else {
                        dispatch(alertActions.error("Une erreur est survenue! veuillez contacter l'administrateur"));
                    }
                })
                .catch(err => {
                    dispatch(alertActions.error(err));
                });
        } else {
            Alert("Veuillez lire et accepter les conditions générales d’utilisation." )
        }
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    renderCreateAnnonce() {
        const {annonce, previewAnnonce, date_state, CGU} = this.state;
        const { alert } = this.props;
        let date = new Date();

        return (
        !previewAnnonce ?
            <main>
                <section className="demandeEtablissementBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/groupes">Mon compte</a> &gt; <a href="/groupes/etablissements">Mes établissements</a> &gt; Je publie une annonce pour mon établissement</p>
                        <h1>Je publie une annonce <span>pour mon établissement</span></h1>

                        <div className="encadreForm">

                            <div className="formBloc1_1 col-xs-12 col-sm-4" style={{marginBottom: 25}}>
                                <div className="row">
                                    <div className="question">
                                        <p>Pour l'établissement ! <span style={{color: '#c4574e'}}>*</span></p>
                                    </div>
                                    <div className="inputDemande">
                                        <input type="text" id="appbundle_annonce_nbPLace"
                                               name="etablissement" required="required"
                                               value={`${annonce.nom} (${annonce.codePostal} - ${annonce.ville})`}
                                               readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center text-success validationDemandeEtablissement">
                            </div>
                            <form name="appbundle_annonce" onSubmit={this.handlePreviewAnnonce}>
                                <div className="formBloc1">
                                    <div className="formBloc1_1 col-xs-12 col-sm-4">
                                        <div className="row">
                                            <div className="question">
                                                <p>Nombre de places</p>
                                            </div>
                                            <div className="inputDemande">
                                                <input type="number" id="appbundle_annonce_nbPLace"
                                                       name="nbPLace" required="required" min="1"
                                                       max="10" value={annonce.nbPLace}
                                                       onChange={this.handleModifAnnonce}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc1_2 col-xs-12 col-sm-6 datePlace">
                                        <div className="form-group row">
                                            <div className="question">
                                                <p>Quand vos places sont-elles disponibles ?<span> *</span></p>
                                            </div>
                                            <div className="hasDatepicker">
                                                <DatePicker
                                                    selected={annonce.datePlace}
                                                    onChange={this.handleChangeDateAnnonce}
                                                    minDate={date}
                                                    maxDate={date.addDays(30)}
                                                    placeholderText="Veuillez selectionner une date dans un inervalle de 30 jours"
                                                    dateFormat="dd-MM-yyyy"
                                                    locale="fr"
                                                    name="datePlace"
                                                    //className="hasDatepicker"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formBloc1_3 col-xs-12">
                                        <div className="row">
                                            <div className="question">
                                                <p><strong>Quel est le niveau de budget mensuel ?</strong> (facultatif)</p>
                                            </div>
                                            <div className="inputBudget">
                                                <div id="appbundle_annonce_prix">
                                                    <input type="radio" id="appbundle_annonce_prix_NC"
                                                           name="prix" value="NC"
                                                           onChange={this.handleModifAnnonce}
                                                           checked={annonce.prix === "NC"}/>
                                                    <label htmlFor="appbundle_annonce_prix_NC">Pas de Budget</label>
                                                    <input type="radio" id="appbundle_annonce_prix_0"
                                                           name="prix" value="Moins de 2000€"
                                                           onChange={this.handleModifAnnonce}
                                                           checked={annonce.prix === "Moins de 2000€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_0">Moins de 2000€</label>
                                                    <input type="radio" id="appbundle_annonce_prix_1"
                                                           name="prix" value="2000€ à 2500€"
                                                           onChange={this.handleModifAnnonce}
                                                           checked={annonce.prix === "2000€ à 2500€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_1">De 2000€ à 2500€</label>
                                                    <input type="radio" id="appbundle_annonce_prix_2"
                                                           name="prix" onChange={this.handleModifAnnonce}
                                                           value="2500€ à 3000€"
                                                           checked={annonce.prix === "2500€ à 3000€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_2">De 2500€ à 3000€</label>
                                                    <br/>
                                                    <input type="radio" id="appbundle_annonce_prix_3"
                                                           name="prix" onChange={this.handleModifAnnonce}
                                                           value="3000€ à 3500€"
                                                           checked={annonce.prix === "3000€ à 3500€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_3">De 3000€ à 3500€</label>
                                                    <input type="radio" id="appbundle_annonce_prix_4"
                                                           name="prix" onChange={this.handleModifAnnonce}
                                                           value="3500€ à 4000€"
                                                           checked={annonce.prix === "3500€ à 4000€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_4">De 3500€ à 4000€</label>
                                                    <input type="radio" id="appbundle_annonce_prix_5"
                                                           name="prix" onChange={this.handleModifAnnonce}
                                                           value="Plus de 4000€"
                                                           checked={annonce.prix === "Plus de 4000€"}/>
                                                    <label htmlFor="appbundle_annonce_prix_5">Plus de 4000€</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc1_4 col-xs-12">
                                        <br/>
                                        <div className="row">
                                            <div className="question">
                                                <p><strong> Spécificités </strong> (Facultatif - Plusieurs choix possibles)</p>
                                            </div>
                                            <div className="inputInfos col-xs-12">

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                        <input id="spec1" type="checkbox" style={{transform: 'scale(1.4)'}} checked={annonce.specificite.includes("Place en unité Alzheimer / protégée")} name="specificite" value="Place en unité Alzheimer / protégée" onChange={this.handleChangeSpecAnnonce}/>
                                                        <label htmlFor="spec1" style={{fontSize: "1em", marginTop: '1%'}}>Place en unité Alzheimer / protégée</label>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                        <input id="spec2" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("Possibilité de court séjour / séjour temporaire")} value="Possibilité de court séjour / séjour temporaire" onChange={this.handleChangeSpecAnnonce}/>
                                                        <label htmlFor="spec2" style={{fontSize: "1em", marginTop: '1%'}}>Possibilité de court séjour / séjour temporaire</label>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                        <input id="spec3" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("ASH possible (Aide Sociale à l’Hébergement)")} value="ASH possible (Aide Sociale à l’Hébergement)" onChange={this.handleChangeSpecAnnonce}/>
                                                        <label htmlFor="spec3" style={{fontSize: "1em", marginTop: '1%'}}>ASH possible (Aide Sociale à l’Hébergement)</label>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                        <input id="spec4" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("Place en accueil de jour")} value="Place en accueil de jour" onChange={this.handleChangeSpecAnnonce}/>
                                                        <label htmlFor="spec4" style={{fontSize: "1em", marginTop: '1%'}}>Place en accueil de jour</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formBloc2">
                                    <div className="formBloc2_2 col-xs-12">
                                        <div className="row">
                                            <div className="question">
                                                <p><strong>Informations supplémentaires</strong> (que vous
                                                    souhaitez
                                                    transmettre aux familles et/ou aux personnes qui informent
                                                    les
                                                    familles)</p><p></p>
                                            </div>
                                            <div className="inputBudget">
                                                    <textarea id="appbundle_annonce_descriptif"
                                                              name="descriptif" onChange={this.handleModifAnnonce}
                                                              value={annonce.descriptif}
                                                              placeholder="Exemple&nbsp;: «&nbsp;La résidence se situe dans une rue calme proche du centre-ville. Nous avons un grand jardin. La chambre est au 2 ème étage, elle est meublée mais personnalisable selon vos choix (sauf le lit). N’hésitez pas à nous appeler pour venir visiter (visite possible le week-end)&nbsp;»."
                                                              maxLength="400">
                                                    </textarea>
                                            </div>
                                            <div id="counter">
                                                {annonce.descriptif.length} / 400
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bouton submit">
                                        <button type="submit" id="appbundle_annonce_previus"
                                                name="appbundle_annonce[previus]"
                                                label="Prévisualiser"> Je visualise… </button>
                                    </div>
                                    <input type="hidden" id="appbundle_annonce_equipements"
                                           name="appbundle_annonce[equipements]" value="vide"/>
                                </div>
                            </form>
                            <div className="bouton submit">
                                <button type="submit"
                                        id="appbundle_annonce_update"
                                        name="appbundle_annonce[update]"
                                        onClick={() => this.setState({createAnnonce: false})}
                                        label="Annuler"> Annuler </button>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            :
            <main>
                <section className="visualisationDemandeBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/groupes">Mon compte</a> &gt; Je visualise mon annonce</p>
                        <h1>Je visualise <span>mon annonce</span></h1>

                        <p className="titleForm"></p>
                        <div className="encadreForm">
                            <div className="visualisationDemandeBloc1_1 col-xs-12">
                                <div className="row">
                                    <p className="titleLieu">{annonce.nbPLace} place(s) disponible(s) à {annonce.ville} {annonce.codePostal}</p>
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <img className="img-responsive"
                                             src="/img/visualisation-demande/icone-echeance.png"
                                             alt="icone-sexe"/>
                                        { this._renderTime(annonce.datePlace) }
                                    </div>
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <img className="img-responsive"
                                             src="/img/visualisation-demande/icone-budget.png"
                                             alt="icone-sexe"/>
                                        Niveau de budget mensuel de {annonce.prix}
                                    </div>
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <img className="img-responsive"
                                             src="/img/visualisation-demande/icone-type-etablissement.png"
                                             alt="icone-sexe"/>
                                        {annonce.nom}
                                    </div>
                                    {
                                        annonce.specificite.length > 0 ?
                                            <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <i className="fa fa-check-square-o " aria-hidden="true" > </i>
                                                Spécificités : <br />
                                                {'- ' + annonce.specificite[0]}<br/>
                                                {annonce.specificite[1] ? '- ' + annonce.specificite[1] : null}<br/>
                                                {annonce.specificite[2] ? '- ' + annonce.specificite[2] : null}<br/>
                                                {annonce.specificite[3] ? '- ' + annonce.specificite[3] : null}
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <img className="img-responsive"
                                             src="/img/visualisation-demande/icone-secteur.png"
                                             alt="icone-sexe"/>
                                        {annonce.rue} {annonce.ville}
                                    </div>

                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                        {annonce.telephone}
                                    </div>
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <i className="fa fa-at" aria-hidden="true"> </i>
                                        {annonce.email_etablissement}
                                    </div>
                                    <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <i className="fa fa-internet-explorer" aria-hidden="true"> </i>
                                        <a href={annonce.site} target="_blank">{annonce.site}</a>
                                    </div>
                                    <div className="visualisationInfo description col-xs-12">
                                        <img className="img-responsive"
                                             src="/img/visualisation-demande/icone-commentaires.png"
                                             alt="icone-commentaires"/>
                                        <p>Description :
                                        </p><p>{annonce.descriptif}</p><p></p>
                                    </div>
                                </div>
                            </div>
                            <form name="appbundle_annonce" className="form-horizontal">
                                <div
                                    className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="CGU" value={CGU} required="required" onChange={this.handleChangeCGU}/>
                                    <label>j’accepte les <a href="/conditions-generales"
                                                            target="_blank">conditions générales
                                        d’utilisation.</a></label>
                                </div>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        <div className="boutons col-xs-12">
                                            <div className="row">
                                                <div className="bouton modifier">
                                                    <button type="submit" id="appbundle_annonce_update"
                                                            name="appbundle_annonce[update]"
                                                            onClick={this.handlePreviewAnnonce}
                                                            label="Modifier ma demande">Modifier
                                                    </button>
                                                </div>
                                                <div className="bouton">
                                                    <button type="submit" id="appbundle_annonce_save"
                                                            name="appbundle_annonce[save]"
                                                            onClick={this.handleSubmitAnnonce}
                                                            label="Enregistrer">Valider
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </form>
                        </div>
                    </div>
                </section>

            </main>
        );
    }

    render(){
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const {
            preview,
            createAnnonce,
            etablissement,
            etablissements,
            filter,
            value,
            suggestions,
            form,
            alert0, alert1, alert2, alert3,
            type1, type2, type3, type4,
            chk1, chk2, chk3, chk4,
            screenWidth,
            start, end, selected
        } = this.state;
        const {alert} = this.props;

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        let filtredEtablissements = etablissements.etablissements ? etablissements.etablissements.filter(item => this.renderFilter(item)) : [];
        filtredEtablissements = filter.distance ? filtredEtablissements.sort((a, b) => this.sortDistance(a, b)) : filtredEtablissements;
        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                {
                    createAnnonce ? this.renderCreateAnnonce() :
                    <main>
                        {
                            !preview ?
                                <section className="listeDemandeBloc1">
                                    <div className="container">
                                        <p className="chemin"><a href="/groupes">Mon compte</a> &gt; Etablissements
                                        </p>
                                        <h1>mes<span> établissements</span></h1>

                                        <br/>
                                        <form>
                                            <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                                <div className="infoSpecial">
                                                    {
                                                        screenWidth > 763 ?
                                                            <div className="listeEtablissementTri">
                                                                <div
                                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                    <div className="row" style={{textAlign: 'center'}}>
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                                             alt="icone-etablissement"
                                                                             onClick={() => this.changeInput("type1", type1, "1")}
                                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                        <label
                                                                            htmlFor="appbundle_alertedispo_etablissement_1"
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: 'auto',
                                                                                fontSize: '1.3rem'
                                                                            }}>EHPAD</label>
                                                                    </div>
                                                                    <input type="checkbox"
                                                                           style={{
                                                                               marginLeft: 'auto',
                                                                               marginRight: 'auto',
                                                                               transform: 'scale(1.5)',
                                                                               width: '30px'
                                                                           }}
                                                                           id="appbundle_alertedispo_etablissement_1"
                                                                           name="type1"
                                                                           value="1"
                                                                           checked={type1}
                                                                           onChange={this.clickCheckBox}
                                                                    />
                                                                </div>

                                                                <div
                                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                    <div className="row" style={{textAlign: 'center'}}>
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                                             alt="icone-etablissement"
                                                                             onClick={() => this.changeInput("type2", type2, "2")}
                                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                        <label
                                                                            htmlFor="appbundle_alertedispo_etablissement_2"
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: 'auto',
                                                                                fontSize: '1.3rem'
                                                                            }}>USLD</label>
                                                                    </div>
                                                                    <input type="checkbox"
                                                                           style={{
                                                                               marginLeft: 'auto',
                                                                               marginRight: 'auto',
                                                                               transform: 'scale(1.5)',
                                                                               width: '30px'
                                                                           }}
                                                                           id="appbundle_alertedispo_etablissement_2"
                                                                           name="type2"
                                                                           value="2"
                                                                           checked={type2}
                                                                           onChange={this.clickCheckBox}
                                                                    />
                                                                </div>

                                                                <div
                                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                    <div className="row" style={{textAlign: 'center'}}>
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                                             alt="icone-etablissement"
                                                                             onClick={() => this.changeInput("type3", type3, "3")}
                                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                        <label
                                                                            htmlFor="appbundle_alertedispo_etablissement_3"
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: 'auto',
                                                                                fontSize: '1.3rem'
                                                                            }}>Résidence autonomie</label>
                                                                    </div>
                                                                    <input type="checkbox"
                                                                           style={{
                                                                               marginLeft: 'auto',
                                                                               marginRight: 'auto',
                                                                               transform: 'scale(1.5)',
                                                                               width: '30px'
                                                                           }}
                                                                           id="appbundle_alertedispo_etablissement_3"
                                                                           name="type3"
                                                                           value="3"
                                                                           checked={type3}
                                                                           onChange={this.clickCheckBox}
                                                                    />
                                                                </div>

                                                                <div
                                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-8 col-lg-3">
                                                                    <div className="row" style={{textAlign: 'center'}}>
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                                             alt="icone-etablissement"
                                                                             onClick={() => this.changeInput("type4", type4, "4")}
                                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                        <label
                                                                            htmlFor="appbundle_alertedispo_etablissement_4"
                                                                            style={{
                                                                                marginLeft: 'auto',
                                                                                marginRight: 'auto',
                                                                                fontSize: '1.3rem'
                                                                            }}>Résidence
                                                                            services
                                                                            seniors</label>
                                                                    </div>
                                                                    <input type="checkbox"
                                                                           style={{
                                                                               marginLeft: 'auto',
                                                                               marginRight: 'auto',
                                                                               transform: 'scale(1.5)',
                                                                               width: '30px'
                                                                           }}
                                                                           id="appbundle_alertedispo_etablissement_4"
                                                                           name="type4"
                                                                           value="4"
                                                                           checked={type4}
                                                                           onChange={this.clickCheckBox}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{justifyContent: 'center'}}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce1.png"
                                                                                 alt="EHPAD"
                                                                                 onClick={() => this.changeInput("type1", filter.Type.includes(1),"1")}
                                                                                 title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type1"
                                                                                checked={type1}
                                                                                name="type1"
                                                                                value="1"
                                                                                onChange={() => this.changeInput("type1", filter.Type.includes(1),"1")}
                                                                            />
                                                                            <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce3.png"
                                                                                 alt="USLD"
                                                                                 onClick={() => this.changeInput("type2", filter.Type.includes(2),"2")}
                                                                                 title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type2"
                                                                                checked={type2}
                                                                                name="type2"
                                                                                value="2"
                                                                                onChange={() => this.changeInput("type2", filter.Type.includes(2),"2")}
                                                                            />
                                                                            <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce4.png"
                                                                                 alt="Résidence autonomie"
                                                                                 onClick={() => this.changeInput("type3", filter.Type.includes(3),"3")}
                                                                                 title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type3"
                                                                                checked={type3}
                                                                                name="type3"
                                                                                value="3"
                                                                                onChange={() => this.changeInput("type3", filter.Type.includes(3),"3")}
                                                                            />
                                                                            <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                        </div>
                                                                        <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                            <img className="img-responsive"
                                                                                 src="/img/home/homeBloc1/icone-annonce5.png"
                                                                                 alt="Résidence services seniors"
                                                                                 onClick={() => this.changeInput("type4", filter.Type.includes(4),"4")}
                                                                                 title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                            <Checkbox
                                                                                checkboxClass="icheckbox_flat-blue"
                                                                                increaseArea="20%"
                                                                                id="label_type4"
                                                                                checked={type4}
                                                                                name="type4"
                                                                                value="4"
                                                                                onChange={() => this.changeInput("type4", filter.Type.includes(4),"4")}
                                                                            />
                                                                            <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                    }
                                                    <div className="secondFiltre">
                                                        <div className="villeInput triVille" style={{position: "relative", width: "100%", margin: "0 auto"}}>
                                                            <Autosuggest
                                                                suggestions={suggestions}
                                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                onSuggestionSelected={this.onSuggestionSelected}
                                                                getSuggestionValue={getSuggestionValue}
                                                                renderSuggestion={renderSuggestion}
                                                                inputProps={inputProps}
                                                                highlightFirstSuggestion={true}
                                                            />
                                                        </div>

                                                        <div className="listeKm" style={{marginRight: "1rem"}}>
                                                            <button className={filter.rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                                5km
                                                            </button>
                                                            <button className={filter.rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                                15km
                                                            </button>
                                                            <button className={filter.rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                                30km
                                                            </button>
                                                            <button className={filter.rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                                50km
                                                            </button>
                                                            <button className={filter.rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                                100km
                                                            </button>
                                                        </div>

                                                        <div className="bouton submit subConf">
                                                            <input type="reset" name="supprimer"
                                                                   value="Supprimer les Filtres"
                                                                   style={{marginTop: "10px"}}
                                                                   onClick={this.supprimerFiltre}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                                <select id="etablissement" className="hide" onSelect={this.handleChangeFilter}>
                                                    <option value="">Type d'établissement</option>
                                                    <option value="1">EHPAD</option>
                                                    <option value="2">USLD</option>
                                                    <option value="3">Résidence autonomie</option>
                                                    <option value="4">Résidence services seniors</option>
                                                </select>

                                            </div>
                                        </form>
                                        <div className="filtres col-xs-12">
                                            <p>Filtrer par</p>
                                            <hr/>
                                            <input type="text" name="nom" value={filter.nom} onChange={this.handleChangeFilter} placeholder="recherche par nom d'etablissement" autoComplete="off"/>
                                            <select id="etablissement" name="status" onChange={this.handleChangeFilter} value={filter.status} style={{alignSelf: 'center'}}>
                                                <option value="AnnonceTot">Tous types d'annonces</option>
                                                <option value="AnnonceAct">Activées</option>
                                                <option value="AnnonceUnaV">En attente</option>
                                                <option value="AnnonceUnaS">Désactivée système</option>
                                                <option value="AnnonceUnaU">Désactivée utilisateur</option>
                                                <option value="NoAnnonces">Sans Annonce</option>
                                            </select>
                                            <select id="etablissement" name="control" onChange={this.handleChangeFilter} value={filter.control} style={{alignSelf: 'center'}}>
                                                <option value="">Tous statuts d'établissement</option>
                                                <option value="oui">Controlés par groupe</option>
                                                <option value="non">Auto-gérés</option>
                                            </select>
                                        </div>
                                        <h2><span>Nombre d'établissements: {filtredEtablissements.length}</span></h2>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col" style={{textAlign: "center"}}>Statuts d'annonces</th>
                                                <th scope="col" style={{textAlign: "center"}}>Nombre d'établissements</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td style={{textAlign: "center", color: "#474D6D"}}>Avec Annonces actives</td>
                                                <td style={{textAlign: "center"}}><strong>{this.props.list.nbEtsAnnonceAct || 0}</strong></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center", color: "#474D6D"}}>Avec Annonces désactivées système</td>
                                                <td style={{textAlign: "center"}}><strong>{this.props.list.nbEtsAnnonceUnaS || 0}</strong></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center", color: "#474D6D"}}>Avec Annonces désactivées utilisateur</td>
                                                <td style={{textAlign: "center"}}><strong>{this.props.list.nbEtsAnnonceUnaU || 0}</strong></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center", color: "#474D6D"}}>Avec Annonces en attente de validation</td>
                                                <td style={{textAlign: "center"}}><strong>{this.props.list.nbEtsAnnonceUnaV || 0}</strong></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: "center", color: "#474D6D"}}>Sans aucune annonce</td>
                                                <td style={{textAlign: "center"}}><strong>{this.props.list.nbEtsNoAnnonces || 0}</strong></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {
                                            alert.message && alert3 ?
                                                <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                        }
                                        <div id="listeDemande" style={{wordBreak: 'break-word'}}>
                                            {!this.props.list ?
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                                :
                                                filtredEtablissements.slice(start, end).filter(item => this.renderFilter(item)).map((item, index) =>
                                                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                        <div className="singleDemande">
                                                            <p>dernière connexion: <strong>{moment(item.last_login).format('DD/MM/YYYY HH:mm:ss')} </strong></p>
                                                            <p style={{textAlign: "center"}}>{item.emailGroupe ? <strong style={{color: "rgb(92, 183, 132)"}}>Vous gérez les annonces de cet établissement</strong> : <strong style={{color: "#c4574e"}}>Cet établissement gère ses annonces</strong>}</p>
                                                            <div className="col-xs-12">
                                                                <div className="question">
                                                                    <p>{item.nom_etablissement}</p>
                                                                </div>
                                                            </div>
                                                            {
                                                                item.groupe_etablissement ?
                                                                    <div className="visualisationInfo col-xs-12">
                                                                        <img className="img-responsive"
                                                                             src="/img/visualisation-demande/groupe.png"
                                                                             alt="icone-sexe"
                                                                             style={{color: '#c4574e'}}
                                                                        />
                                                                        <p>Groupe <strong>{item.groupe_etablissement.toUpperCase()} </strong></p>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-building-o  img-responsive" aria-hidden="true"> </i>
                                                                <p>Type d'établissement : <strong>{types[item.type_etablissement_id - 1]} </strong></p>
                                                            </div>
                                                            {this.renderParticularite(item)}
                                                            <hr style={{border: '1px solid #ccc', clear: 'both'}}/>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-secteur.png"
                                                                     alt="icone-sexe"/>
                                                                <p>Situé à <strong>{item.ville} </strong>({item.codePostal}) <strong>{item.rue}</strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                                                <p>Téléphone : <strong
                                                                    className="phoneNumber">{item.telephone} </strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-at" aria-hidden="true"> </i>
                                                                <p>Email : <strong>{item.email} </strong></p>
                                                            </div>
                                                            {
                                                                item.site_etablissement ?
                                                                    <div className="visualisationInfo col-xs-12">
                                                                        <i className="fa fa-internet-explorer"
                                                                           aria-hidden="true"> </i>
                                                                        <p>
                                                                            <strong>

                                                                                <a href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                                                   target="_blank"> Site de l'Établissement </a>
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="visualisationInfo col-xs-12">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                     alt="icone-type-etablissement"/>
                                                                <p>Numéro finess : <strong>{item.numerofinesse} </strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                <p style={{wordBreak: 'break-word', color: 'green'}}>annonces activées: <strong style={{color: 'black'}}>{item.nbAnnonceAct}</strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                <p style={{wordBreak: 'break-word', color: 'orange'}}>annonces en Attente: <strong style={{color: 'black'}}>{item.nbAnnonceUnaV}</strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                <p style={{wordBreak: 'break-word', color: 'red'}}>annonces désactivées système: <strong style={{color: 'black'}}>{item.nbAnnonceUnaS}</strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                <p style={{wordBreak: 'break-word', color: 'orange'}}>annonces désactivées utilisateur: <strong style={{color: 'black'}}>{item.nbAnnonceUnaU}</strong></p>
                                                            </div>
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-book" aria-hidden="true"></i>
                                                                <p style={{wordBreak: 'break-word', color: 'rgb(71, 77, 109)'}}>Total annonces: <strong style={{color: item.nbAnnonceTot > 0 ? 'black' : 'red'}}>{item.nbAnnonceTot}</strong></p>
                                                            </div>
                                                            <hr />
                                                            <div className="col-xs12 bouton voir">
                                                                <button onClick={this.handlePreview} id={item.id}>Modifier</button>
                                                            </div>
                                                            <div className="col-xs12 bouton voir">
                                                                <button onClick={this.handleAnnonce} id={item.id}>Créer annonce</button>
                                                            </div>
                                                            {
                                                                item.emailGroupe ?
                                                                    <div className="col-xs12 bouton voir">
                                                                        <button onClick={(e) => this.handleEmailControl(e, false, false, item.user_id)} id={item.user_id}>Laisser le controle</button>
                                                                    </div>
                                                                    :
                                                                    <div className="col-xs12 bouton voir">
                                                                        <button onClick={(e) => this.handleEmailControl(e, false, true, item.user_id)} id={item.user_id}>Prendre le controle</button>
                                                                    </div>
                                                            }
                                                            <div className="col-xs12 bouton supprimer">
                                                                <button onClick={this.handleDelete} id={item.id}>Supprimer du Groupe</button>
                                                            </div>
                                                            {/*
                                                            <div className="col-xs12 bouton supprimer">
                                                                <button onClick={this.handleAnnonces}
                                                                        id={item.user_id}>Annonces
                                                                </button>
                                                            </div>
                                                        */}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                    <div className="col-xs-12 pages">
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={Math.ceil(filtredEtablissements.length/15)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            forcePage={start === 0 ? 0 : selected}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'btn-page'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                                </section>
                                :
                                <section className="inscriptionStep1">
                                    <div className="container">

                                        <p className="chemin"><a href="/groupes">Mon compte</a> &gt; <a href="/groupes/etablissements">Etablissements</a> &gt;Mise à jour informations
                                        </p>
                                        <h1>Mise à jour informations</h1>

                                        <div className="titleForm">
                                            <button id="formEtablisssement" name="form1" value="1"
                                                    className={form !== 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"} onClick={this._handleChangeForm}>
                                                établissement
                                            </button>
                                            <button id="CancelMaj" value="1"
                                                    className={form === 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"} onClick={this.handleBack}>
                                                Annuler
                                            </button>
                                            <button id="formCoordonnees" name="form2" value="2"
                                                    className="formCoordonnees btn btn-primary"
                                                    onClick={this._handleChangeForm}>identifiants
                                            </button>
                                        </div>

                                        <form name="adminbundle_user" onSubmit={this.handleSubmit}
                                              className="formCoordonnees"
                                              style={this.state.form_user}>
                                            {
                                                alert.message && alert0 ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                            }
                                            <div className="encadreForm col-xs-12">
                                                <div className="question">
                                                    <p>Modifiez les identifiants de connexion</p>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label>Identifiant <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeUser}
                                                           name="username" required="required" maxLength="180"
                                                           pattern=".{2,}" value={etablissement.username}/>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label htmlFor="userPassword">Mot de passe <span>*</span></label>
                                                    <input type="password" onChange={this._handleChangeUser}
                                                           name="password" value={etablissement.password}
                                                           className="password-field"/>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label htmlFor="userPassword">Retapez votre mot de
                                                        passe <span>*</span></label>
                                                    <input type="password" onChange={this._handleChangeUser }
                                                           name="password2" value={etablissement.password2}
                                                           className="password-field"/>
                                                </div>
                                                <div className="bouton submit">
                                                    <input type="submit" name="envoyer" id="valider1"
                                                           value="Modifier mes informations" disabled={this.state.btn1}/>
                                                    <div className="error text-danger"> </div>
                                                </div>
                                            </div>
                                        </form>

                                        <form name="appbundle_etablissement" onSubmit={this.handleSubmit}
                                              style={this.state.form_etablissement}>
                                            <div className="encadreForm col-xs-12">
                                                <div className="question">
                                                    <p><strong>Responsable</strong> (Informations pour <span
                                                        className="urltitre2">Place<span
                                                        className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour
                                                        la gestion des annonces, pour recevoir vos alertes. Ces informations
                                                        ne
                                                        sont
                                                        pas à destination des familles et n’apparaitront pas dans vos
                                                        annonces)
                                                    </p>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label>Civilite</label>
                                                    <select id="civilite" onChange={this._handleChangeInfos}
                                                            name="civilite" value={etablissement.civilite}>
                                                        <option value=" "></option>
                                                        <option value="Mademoiselle">Mademoiselle</option>
                                                        <option value="Madame">Madame</option>
                                                        <option value="Monsieur">Monsieur</option>
                                                    </select>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label>Nom</label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="nom" value={etablissement.nom}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label>Prenom</label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="prenom"
                                                           value={etablissement.prenom}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label>Téléphone Résponsable<span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="telephone" required="required"
                                                           value={etablissement.telephone}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label htmlFor="adminbundle_user_email" className="required">Email Responsable<span>*</span></label>
                                                    <input type="email" onChange={this._handleChangeUser}
                                                           id="adminbundle_user_email"
                                                           name="email"
                                                           required="required"
                                                           maxLength="180"
                                                           pattern=".{2,}"
                                                           value={etablissement.email}/>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label htmlFor="adminbundle_user_email" className="required">Email de Secours et/ou de Groupe (facultatif)</label>
                                                    <input type="email" onChange={this._handleChangeUser}
                                                           id="adminbundle_user_email"
                                                           name="email2"
                                                           maxLength="180"
                                                           pattern=".{2,}"
                                                           value={etablissement.email2}/>
                                                </div>
                                                <div className="question">
                                                    <p><strong>Établissement</strong> (les informations en rouge serviront à
                                                        créer
                                                        vos annonces)</p>
                                                </div>

                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label style={{color: "red"}}>Nom de votre etablissement <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="nom_etablissement" required="required"
                                                           value={etablissement.nom_etablissement}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label>Groupe</label>
                                                    <input type="text"
                                                           name="nom_etablissement" required="required"
                                                           value={etablissement.groupe_etablissement}
                                                           disabled={true}
                                                    />
                                                </div>
                                                {/*
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label>Groupe (le cas échéant)</label>
                                                    <Autosuggest
                                                        suggestions={suggestionsGroupe}
                                                        onSuggestionsFetchRequested={this.onGroupeSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onGroupeSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onGroupeSuggestionSelected}
                                                        getSuggestionValue={getGroupeSuggestionValue}
                                                        renderSuggestion={renderGroupeSuggestion}
                                                        inputProps={inputGroupeProps}
                                                        highlightFirstSuggestion={true}
                                                    />
                                                </div>
                                            */}
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label style={{color: "red"}}>Type
                                                        d'établissement <span>*</span></label>
                                                    <select onChange={this._handleChangeInfos}
                                                            name="type_etablissement_id"
                                                            value={etablissement.type_etablissement_id}
                                                    >
                                                        <option value="1">EHPAD</option>
                                                        <option value="2">USLD</option>
                                                        <option value="3">Résidence autonomie</option>
                                                        <option value="4">Résidence services seniors</option>
                                                    </select>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label>Statut <span>*</span></label>
                                                    <select onChange={this._handleChangeInfos}
                                                            name="statut"
                                                            value={etablissement.statut}
                                                    >
                                                        <option value="public">PUBLIC</option>
                                                        <option value="privé non lucratif (association)">PRIVE NON LUCRATIF (association)</option>
                                                        <option value="privé">PRIVE</option>
                                                    </select>
                                                </div>
                                                {
                                                    parseInt(etablissement.type_etablissement_id) !== 4 ?
                                                        <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 finessField">
                                                            <label>Numéro finess <span>*</span></label>
                                                            <input type="text" onChange={this._handleChangeInfos}
                                                                   name="numerofinesse" required="required"
                                                                   value={etablissement.numerofinesse}
                                                                   style={{boxShadow: alert.message === "ce numéro finess est déjà attribué !" ? '0 0 3px #CC0000' : ''}}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label style={{color: "red"}}>N° et nom de la
                                                        voie <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="rue" required="required"
                                                           value={etablissement.rue}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 villeInput">
                                                    <label style={{color: "red"}}>Ville <span>*</span></label>
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onSuggestionSelected}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        highlightFirstSuggestion={true}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                    <label style={{color: "red"}}>Téléphone établissement<span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="telephone_etablissement"
                                                           value={etablissement.telephone_etablissement}
                                                    />
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label style={{color: "red"}}>Email de
                                                        l'établissement <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="email_etablissement"
                                                           value={etablissement.email_etablissement}
                                                           required="required"/>
                                                </div>
                                                <div className="inputConnexion col-xs-12 col-sm-6">
                                                    <label style={{color: "red"}}>Site de l'établissement</label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="site_etablissement"
                                                           value={etablissement.site_etablissement}
                                                    />
                                                </div>
                                            </div>

                                            <div className="encadreForm col-xs-12">
                                                <div
                                                    className="inputConnexion inputSpecificite col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label htmlFor="userPassword">SPECIFICITES <span>*</span> (plusieurs
                                                        choix
                                                        possible)</label>
                                                    <div id="appbundle_etablissement_informationHebergement">
                                                        <input type="checkbox"
                                                               id="appbundle_etablissement_informationHebergement_1"
                                                               name="appbundle_etablissement[informationHebergement][]"
                                                               value="1"
                                                               checked={etablissement.specs.some(value => value.information_hebergement_id === 1)}
                                                               onChange={this._handleChangeSpec}
                                                        />
                                                        <label htmlFor="appbundle_etablissement_informationHebergement_1">
                                                            Hebergement permanent
                                                        </label>
                                                        <input type="checkbox"
                                                               id="appbundle_etablissement_informationHebergement_2"
                                                               name="appbundle_etablissement[informationHebergement][]"
                                                               value="2"
                                                               checked={etablissement.specs.some(value => value.information_hebergement_id === 2)}
                                                               onChange={this._handleChangeSpec}
                                                        />
                                                        <label htmlFor="appbundle_etablissement_informationHebergement_2">
                                                            Hebergement temporaire</label>
                                                        <input type="checkbox"
                                                               id="appbundle_etablissement_informationHebergement_3"
                                                               name="appbundle_etablissement[informationHebergement][]"
                                                               value="3"
                                                               checked={etablissement.specs.some(value => value.information_hebergement_id === 3)}
                                                               onChange={this._handleChangeSpec}
                                                        />
                                                        <label htmlFor="appbundle_etablissement_informationHebergement_3">
                                                            Accueil de jour
                                                        </label>
                                                        <input type="checkbox"
                                                               id="appbundle_etablissement_informationHebergement_4"
                                                               name="appbundle_etablissement[informationHebergement][]"
                                                               value="4"
                                                               checked={etablissement.specs.some(value => value.information_hebergement_id === 4)}
                                                               onChange={this._handleChangeSpec}
                                                        />
                                                        <label htmlFor="appbundle_etablissement_informationHebergement_4">
                                                            Unité alzheimer</label>
                                                        <input type="checkbox"
                                                               id="appbundle_etablissement_informationHebergement_5"
                                                               name="appbundle_etablissement[informationHebergement][]"
                                                               value="5"
                                                               checked={etablissement.specs.some(value => value.information_hebergement_id === 5)}
                                                               onChange={this._handleChangeSpec}
                                                        />
                                                        <label htmlFor="appbundle_etablissement_informationHebergement_5">
                                                            Aide social à l’hébergement
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="bouton submit col-xs-12">
                                                    <input type="submit" id="valider2" name="envoyer"
                                                           value="Enregistrer mes informations"
                                                           disabled={this.state.btn2}/>
                                                    < div className="error text-danger"> </div>
                                                    {
                                                        alert.message && alert1 ?
                                                            <div className={`alert ${alert.type}`}>{alert.message}</div> :
                                                            alert.message && alert0 ?
                                                                <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        <form name="appbundle_etablissement" onSubmit={this.uploadImages}
                                              encType="multipart/form-data" style={this.state.form_etablissement}>
                                            <div className="encadreForm">
                                                <div style={{textAlign: "center"}}>
                                                    <label>Images (maximum 2MB par photo)</label>
                                                    <div id="errorSize" style={{
                                                        borderRadius: "5px",
                                                        border: "2px solid #D55",
                                                        width: "60%",
                                                        display: this.state.display,
                                                        margin: "10px 20%",
                                                        backgroundColor: "#FCC",
                                                    }}>{this.state.message}
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                    <input type="file" onChange={this.selectImages}
                                                           name="image_un" accept="image/*"/>
                                                    <br/>
                                                    {
                                                        (etablissement.image_un) || this.state.image_un ?
                                                            <div className="row col-lg-12">

                                                                <div className="col-lg-12">
                                                                    <img src={this.state.image_un  ? this.state.image_un : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_un} className="img-rounded img-responsive"
                                                                         alt="traitement en cours..."
                                                                    />
                                                                    <br/>
                                                                    {
                                                                        etablissement.image_un.length > 255 || this.state.image_un ? null :
                                                                            <button type="button"
                                                                                    className="btn btn-danger btn-xs" id="1"
                                                                                    onClick={this.handleDeleteImage}>Supprimer</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                    <input type="file" onChange={this.selectImages}
                                                           name="image_deux" accept="image/*"/>
                                                    <br/>
                                                    {
                                                        (etablissement.image_deux) || this.state.image_deux ?
                                                            <div className="row col-lg-12">

                                                                <div className="col-lg-12">
                                                                    <img src={this.state.image_deux ? this.state.image_deux : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_deux} className="img-rounded img-responsive"
                                                                         alt="traitement en cours..."
                                                                    />
                                                                    <br/>
                                                                    {
                                                                        etablissement.image_deux.length > 255 || this.state.image_deux ? null :
                                                                            <button type="button"
                                                                                    className="btn btn-danger btn-xs" id="2"
                                                                                    onClick={this.handleDeleteImage}>Supprimer</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                    <input type="file" onChange={this.selectImages}
                                                           name="image_trois" accept="image/*"/>
                                                    <br/>
                                                    {
                                                        (etablissement.image_trois) || this.state.image_trois?
                                                            <div className="row col-lg-12">

                                                                <div className="col-lg-12">
                                                                    <img src={this.state.image_trois ? this.state.image_trois : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_trois} className="img-rounded img-responsive"
                                                                         alt="traitement en cours..."
                                                                    />
                                                                    <br/>
                                                                    {
                                                                        etablissement.image_trois.length > 255 || this.state.image_trois ? null :
                                                                            <button type="button"
                                                                                    className="btn btn-danger btn-xs" id="3"
                                                                                    onClick={this.handleDeleteImage}>Supprimer</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                    <input type="file" onChange={this.selectImages}
                                                           name="image_quatre" accept="image/*"/>
                                                    <br/>
                                                    {
                                                        (etablissement.image_quatre) ?
                                                            <div className = "row col-lg-12" >
                                                                <div className="col-lg-12">
                                                                    <img src={this.state.image_quatre ? this.state.image_quatre : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_quatre} className="img-rounded img-responsive"
                                                                         alt="traitement en cours..."
                                                                    />
                                                                    <br/>
                                                                    {
                                                                        etablissement.image_quatre.length > 255 || this.state.image_quatre ? null :
                                                                            <button type="button"
                                                                                    className="btn btn-danger btn-xs" id="4"
                                                                                    onClick={this.handleDeleteImage}>Supprimer</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="bouton submit col-xs-12">
                                                    <input type="submit" id="valider" name="envoyer"
                                                           value="Enregistrer mes Photos"
                                                           disabled={this.state.btn3}/>

                                                    {alert.message && alert2 ?
                                                        <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                                                    < div className="error text-danger"> </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </section>
                        }
                    </main>
                }
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { alert, authentication, etablissement, groupes } = state;
    const { user } = authentication;
    const { list } = etablissement;

    return  {
        user,
        alert,
        list,
    }
}

export default withRouter(connect(mapStateToProps)(Etablissements))
