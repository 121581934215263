import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from "react-st-modal";
import Autosuggest from 'react-autosuggest';
import {ReCaptcha} from 'react-recaptcha-google';
//import Autocomplete from 'react-google-autocomplete';


import {alertActions, groupesActions, userActions} from '../../_actions';

import lib from '../../lib/lib';


import Navbar from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import projectList from "../../communes/communes.json";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

let GroupeList = [];

const getGroupeSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.value.nom.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getGroupeSuggestionValue = suggestion => suggestion.label;

const renderGroupeSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label}</b></div>

);

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            valueGroupe: '',
            suggestions: [],
            suggestionsGroupe: [],
            activeForm: 0,
            form: 0,
            user: {
                username: '',
                password: '',
                password2: '',
                email: '',
                email2: '',
                civilite: '',
                nom: '',
                prenom: '',
                telephone: '',
                nomEtablissement: '',
                groupe: '',
                groupe_id: null,
                typeEtablissement: 0,
                statut: 'PUBLIC',
                finess: '',
                rue: '',
                ville: '',
                codePostal: '',
                longitude: '',
                latitude: '',
                telephoneEtablissement: '',
                emailEtablissement: '',
                siteEtablissement: '',
                specificite: [],
                code_insee: '  ',
                roles: "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}",
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
            },
            imagePreviewUrl: [],
            pictures: [],
            rue: '',
            ville: '',
            codePostal: '',
            submitted: false,
            condition: false,
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleChangeSpec = this.handleChangeSpec.bind(this);
        this.handleChangeCGU = this.handleChangeCGU.bind(this);
        //this.handleSelect = this.handleSelect.bind(this);
        this.selectImages = this.selectImages.bind(this);
        this.uploadImages = this.uploadImages.bind(this);

        this._changeForm = this._changeForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        this.props.dispatch(groupesActions.getAllGroupes());
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        //console.log(recaptchaToken, "<= your recaptcha token")
        return recaptchaToken;
    }

    onChangeGroupe = (event, { newValue }) => {
        this.setState({
            valueGroupe: newValue,
            user: {
                ...this.state.user,
                groupe: newValue,
                groupe_id: null,
            }
        });
    };

    onGroupeSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestionsGroupe: getGroupeSuggestions(value)
        });
    };

    onGroupeSuggestionsClearRequested = () => {
        this.setState({
            suggestionsGroupe: []
        });
    };

    onGroupeSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {user} = this.state;

        this.setState({
            valueGroupe: suggestion.value.nom,
            user: {
                ...user,
                groupe_id: suggestion.value.id,
                groupe: suggestion.value.nom,
            }
        })
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {user} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());

        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            user : {
                ...user,
                codePostal: suggestion.value.codePostal,
                code_insee: suggestion.value.code_insee,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0]
            }
        })

    };

    handleChangeUser(event) {
        const {name, value} = event.target;
        const {user} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {user} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());

        if (!checked) {
            this.setState({
                user: {
                    ...user,
                    specificite: user.specificite.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                user: {
                    ...user,
                    specificite: [...user.specificite, value]
                }
            })
        }
    }

    selectImages(event) {
        const {dispatch} = this.props;
        const {name} = event.target;
        const {user, pictures} = this.state;

        dispatch(alertActions.clear());

        let file = event.target.files[0];

        if ( file.name.match(/\.(jpg|jpeg|png|gif)$/) && file.size < 2000000) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    pictures: [...pictures, {'file': file, 'pic': name}],
                    user: {
                        ...user,
                        [name]: reader.result
                    },
                    [name]: name,
                    btn3: false
                });
            };
            reader.readAsDataURL(file)
        } else if (file.size > 2000000) {
            this.setState({
                [name]: "l'image est trop Volumineuse (supérieure à 2Mo)"
            })
        } else {
            this.setState({
                [name]: "Veuillez choisir des images parmi les formats suivants: .jpg, .jpeg, .png ou .gif "
            })
        }
    }

    uploadImages(e) {
        e.preventDefault();

        const {condition, user} = this.state;
        const {dispatch} = this.props;

        let i = 0;
        this.state.pictures.map(image => {
            i = i + 1;
            const data = new FormData();
            data.append("image", image.file, image.file.name);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + user.confirmation_token,
                    //'id': image.id,
                    'pic': image.pic
                },
                body: data
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/upload`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    if (result.success) {
                        this.setState({condition: condition && result.success, alert2: true})
                    }
                    if (condition && i === this.state.pictures.length)
                        dispatch(alertActions.success('Image(s) téléchargée(s) avec succès'))
                });
        });
    }

    handleChangeForm(e) {
        const {user} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({
            activeForm: e.currentTarget.value
        });

        if (e.currentTarget.value === '1') {
            this.setState({
                user: {
                    ...user,
                    roles: "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}"
                }
            })
        } else if (e.currentTarget.value === '2') {
            this.setState({
                user: {
                    ...user,
                    roles: "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}"
                }
            })
        } else if (e.currentTarget.value === '3') {
            this.setState({
                user: {
                    ...user,
                    roles: "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}"
                }
            })
        }
    }

    handleChangeCGU() {
        const {condition} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({condition: !condition})
    }

    _changeForm() {
        const {activeForm} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        this.setState({form: parseInt(activeForm)})
        if (activeForm === '2') {
            this.props.groupesList.map((item) => {
                GroupeList.push({
                    value: {
                        id: item.id,
                        nom: item.nom,
                    },
                    label: item.nom
                })
            });
        } else {
            GroupeList = [];
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({submitted: true});
        const {user, form, pictures} = this.state;
        const {dispatch} = this.props;

        user.rue = user.rue.replace(/'/g, "\\'");
        let finess = parseInt(user.typeEtablissement) === 4 ? true : user.finess;
        if (!this.state.condition) {
          Alert("Veuillez accepter les conditions générales")
        } else if (form === 1) {
            if (lib.isValidUsername(user.username) &&
                lib.isValidEmail(user.email) &&
                lib.isValidPassword(user.password) &&
                lib.passwordMatch(user.password, user.password2)
            ) {
                dispatch(userActions.register(user, form, this.props.history));
            } else {
                console.log("hello fail")
            }
        } else if (form === 2) {
            if (user.groupe && !user.groupe_id) {
                if (this.props.groupesList.find(item => item.nom === user.groupe.toLowerCase())) {
                    Alert("Veuillez selectionner votre groupe dans le menu déroulant, si il est présent, à l'aide de votre souris");
                    return;
                }
            }
            if (lib.isValidUsername(user.username) &&
                lib.isValidEmail(user.email) &&
                lib.isValidPassword(user.password) &&
                lib.passwordMatch(user.password, user.password2) &&
                lib.isValidPhone(user.telephone) &&
                lib.isValidPhone(user.telephoneEtablissement) &&
                lib.isValidEmail(user.emailEtablissement) &&
                user.nomEtablissement &&
                (parseInt(user.typeEtablissement) === 4 ? true : lib.isValidFiness(finess)) &&
                user.statut &&
                user.typeEtablissement &&
                user.rue &&
                user.ville
            ) {
                dispatch(userActions.register(user, form, this.props.history));
            } else {
                Alert("informations incorrectes: ");
            }
        } else if (form === 3) {
            if (lib.isValidUsername(user.username) &&
                lib.isValidEmail(user.email) &&
                lib.isValidPassword(user.password) &&
                lib.passwordMatch(user.password, user.password2)
            ) {
                dispatch(userActions.register(user, form, this.props.history));
            }
        } else {
            console.log("erreur dans le choix du formulaire")
        }
    }

    _renderForm(user, submitted, registering) {
        const { value, suggestions, valueGroupe, suggestionsGroupe } = this.state;
        const { alert } = this.props;

        const inputProps = {
            placeholder: 'Saisir une ville ou un CP et choisir dans la liste',
            value,
            onChange: this.onChange,
            type: "search"
        };

        const inputGroupeProps = {
            placeholder: 'Ex: Les Fleurs',
            value: valueGroupe,
            onChange: this.onChangeGroupe
        };

        if (this.state.form === 0) {
            return <div className="encadreForm col-xs-12 first">
                <div className="inputCheckbox col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <input type="radio" name="identite" value="1" id="famille" onChange={this.handleChangeForm}/>
                    <label htmlFor="famille">Une famille ou un proche</label>
                </div>
                <div className="inputCheckbox col-sm-4 col-md-4 col-lg-4">
                    <input type="radio" name="identite" value="2" id="etablissement" onChange={this.handleChangeForm}/>
                    <label htmlFor="etablissement">Un établissement (EHPAD, USLD, Résidence
                        Autonomie, Résidence Seniors Services)</label>
                </div>
                <div className="inputCheckbox col-sm-4 col-md-4 col-lg-4">
                    <input type="radio" name="identite" value="3" id="assistant" onChange={this.handleChangeForm}/>
                    <label htmlFor="assistant">Un professionnel (CLIC, CCAS, hôpital, clinique,
                        tuteur(rice), médecin, assistant(e) social(e)…)</label>
                </div>

                <div className="bouton submit" onClick={this._changeForm}>
                    <a> Continuer</a>
                </div>
            </div>
        } else if (this.state.form === 1) {
            return <div className="encadreForm col-xs-12 first">
                <div className="question">
                    <p>Choisissez vos identifiants de connexion</p>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                    <label htmlFor="username">Identifiant <span className="rouge">*</span></label>
                    <input type="text" className="form-control" name="username" value={user.username}
                           onChange={this.handleChangeUser} required="required"/>
                    {submitted && !lib.isValidUsername(user.username) &&
                    <div className="help-block" style={{color: 'red'}}>Identifiant requis, sans espaces</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.email ? ' has-error' : '')}>
                    <label htmlFor="firstName">Email <span className="rouge">*</span></label>
                    <input type="text" className="form-control" name="email" value={user.email}
                           onChange={this.handleChangeUser} required="required"/>
                    {submitted && !lib.isValidEmail(user.email) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'email</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.password ? ' has-error' : '')}>
                    <label htmlFor="password">Mot de passe <span className="rouge">*</span></label>
                    <input type="password" className="form-control" name="password" value={user.password}
                           onChange={this.handleChangeUser} placeholder="entre 4 et 19 caractères"/>
                    {submitted && !lib.isValidPassword(user.password) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format de Mot de passe</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.password2 ? ' has-error' : '')}>
                    <label htmlFor="password">Retapez votre mot de passe <span className="rouge">*</span></label>
                    <input type="password" className="form-control" name="password2" value={user.password2}
                           onChange={this.handleChangeUser}/>
                    {submitted && !lib.passwordMatch(user.password, user.password2) &&
                    <div className="help-block" style={{color: 'red'}}>La confirmation doit correspondre au mot de passe</div>
                    }
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}
                    className={'inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group' + (submitted && !this.state.condition ? ' has-error' : '')}>
                    <input id="condition" className="conditionRegister" type="checkbox" name="condition" value={true} onChange={this.handleChangeCGU}/>
                    <label htmlFor="condition"><strong>J’accepte les <a href="/conditions-generales">conditions générales
                        d'utilisation.</a></strong></label>
                    {submitted && !this.state.condition &&
                    <div className="help-block" style={{color: 'red'}}>Veuillez accepter les conditions générales d'utilisation</div>
                    }
                </div>
                {
                    <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                        <div style={{width: "304px", height: "78px"}}>
                            <ReCaptcha
                                ref={(el) => {
                                    this.captchaDemo = el;
                                }}
                                hl="fr"
                                size="normal"
                                data-theme="dark"
                                render="explicit"
                                sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                onloadCallback={this.onLoadRecaptcha}
                                verifyCallback={this.verifyCallback}
                            />
                            <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                      className="g-recaptcha-response"
                                      style={{
                                          width: "250px",
                                          height: "40px",
                                          border: "1px solid rgb(193, 193, 193)",
                                          margin: "10px 25px",
                                          padding: "0px",
                                          resize: "none",
                                          display: "none"
                                      }}>
                        </textarea>
                        </div>
                    </div>
                }
                <div className="errorFirstStep" style={{color: "red"}}> </div>

                <div>
                    <div className="bouton submit"
                         style={{display: "inline-block", float: "right", margin: "2px"}}>
                        <input type="submit" name="envoyer" id="validePerso" value="Valider"/>
                        <div className="error text-danger"> </div>
                        {registering &&
                        <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </div>
                </div>
                <div style={{display: "inline-block", float: "right", margin: "2px"}}>
                    <div className="bouton annuler">
                        <a href="/register">
                            Annuler
                        </a>
                    </div>
                </div>
            </div>
        } else if (this.state.form === 2) {
            return <div className="encadreForm col-xs-12 first">
                <div className="question">
                    <p><strong>Choisissez vos identifiants de connexion</strong></p>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                    <label htmlFor="username">Identifiant <span className="rouge">*</span></label>
                    <input type="text"
                           className="form-control"
                           name="username"
                           value={user.username}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidUsername(user.username) ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidUsername(user.username) &&
                    <div className="help-block" style={{color: 'red'}}>Identifiant requis, sans espaces</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidPassword(user.password) ? ' has-error' : '')}>
                    <label htmlFor="password">Mot de passe <span className="rouge">*</span></label>
                    <input type="password"
                           className="form-control"
                           name="password"
                           value={user.password}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidPassword(user.password) ? '0 0 3px #CC0000' : ''}}
                           placeholder="entre 4 et 19 caractères"/>
                    {submitted && !lib.isValidPassword(user.password) &&
                    <div className="help-block" style={{color: 'red'}}>Mot de passe requis</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && (!user.password2 || !lib.passwordMatch(user.password, user.password2)) ? ' has-error' : '')}>
                    <label htmlFor="password">Retapez votre mot de passe <span className="rouge">*</span></label>
                    <input type="password"
                           className="form-control"
                           name="password2"
                           value={user.password2}
                           style={{boxShadow: !user.password2 || !lib.passwordMatch(user.password, user.password2) ? '0 0 3px #CC0000' : ''}}
                           onChange={this.handleChangeUser}/>
                    {submitted && (!user.password2 || !lib.passwordMatch(user.password, user.password2)) &&
                    <div className="help-block" style={{color: 'red'}}>Confirmez Mot de passe</div>
                    }
                </div>

                <div className="question">
                    <p><strong>Responsable</strong> (Informations pour <span className="urltitre2">Place<span
                        className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour la gestion des annonces, pour recevoir
                        vos alertes. Ces informations ne sont pas à destination des familles et
                        n’apparaitront pas dans vos annonces)</p>
                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                    <label>Civilité (facultatif)</label>
                    <select name="civilite" value={user.civilite} onChange={this.handleChangeUser}>
                        <option value="">Choisir</option>
                        <option value="Mademoiselle">Mademoiselle</option>
                        <option value="Madame">Madame</option>
                        <option value="Monsieur">Monsieur</option>
                    </select>
                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                    <label>Nom (facultatif)</label>
                    <input type="text" name="nom" value={user.nom} onChange={this.handleChangeUser}/>
                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                    <label>Prénom (facultatif)</label>
                    <input type="text" name="prenom" value={user.prenom} onChange={this.handleChangeUser}/>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidPhone(user.telephone) ? ' has-error' : '')}>
                    <label>Téléphone Responsable <span className="rouge">*</span></label>
                    <input type="tel" name="telephone" className="form-control" value={user.telephone}
                           pattern="(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4}"
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidPhone(user.telephone) ? '0 0 3px #CC0000' : ''}}
                           placeholder="Ne pas mettre de point ou de tiret"/>
                    <small>(Format Ex: 01 12 34 56 78)</small>
                    {submitted && !lib.isValidPhone(user.telephone) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format de N° de téléphone</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidEmail(user.email) ? ' has-error' : '')}>
                    <label>Email Responsable <span className="rouge">*</span></label>
                    <input type="email"
                           name="email"
                           className="form-control"
                           value={user.email}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: (submitted && !lib.isValidEmail(user.email)) || !user.email ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidEmail(user.email) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'Email</div>
                    }
                </div>
                <div
                    className="inputInfos col-xs-12 col-sm-6 col-md-4 form-group">
                    <label>Email de Secours et/ou de Groupe (facultatif)</label>
                    <input type="email"
                           name="email2"
                           className="form-control"
                           value={user.email2}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: user.email2 && !lib.isValidEmail(user.email2) && submitted ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && user.email2 && !lib.isValidEmail(user.email2) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'Email</div>
                    }
                </div>
                <div className="question">
                    <p><strong>Établissement</strong> (les informations en rouge serviront à créer vos annonces)</p>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidProName(user.nomEtablissement) ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Nom de votre établissement <span className="rouge">*</span></label>
                    <input type="text"
                           name="nomEtablissement"
                           className="form-control"
                           value={user.nomEtablissement}
                           onChange={this.handleChangeUser}
                           placeholder="Ex&nbsp;: Résidence des Lilas"
                           style={{boxShadow: !lib.isValidProName(user.nomEtablissement) ? '0 0 3px #CC0000' : ''}}/>
                    {submitted && !lib.isValidProName(user.nomEtablissement) &&
                    <div className="help-block" style={{color: 'red'}}>Nom de votre établissement requis</div>
                    }

                </div>
                <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                    <label>Groupe (le cas échéant)</label>
                    <Autosuggest
                        suggestions={suggestionsGroupe}
                        onSuggestionsFetchRequested={this.onGroupeSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onGroupeSuggestionsClearRequested}
                        onSuggestionSelected={this.onGroupeSuggestionSelected}
                        getSuggestionValue={getGroupeSuggestionValue}
                        renderSuggestion={renderGroupeSuggestion}
                        inputProps={inputGroupeProps}
                        highlightFirstSuggestion={true}
                    />
                </div>
                <div className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.typeEtablissement ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Type d'établissement <span className="rouge">*</span></label>
                    <select name="typeEtablissement"
                            value={user.typeEtablissement}
                            onChange={this.handleChangeUser}
                            style={{boxShadow: !user.typeEtablissement ? '0 0 3px #CC0000' : ''}}>
                        <option value="0">Choisir</option>
                        <option value="1">EHPAD</option>
                        <option value="2">USLD</option>
                        <option value="3">Résidence autonomie</option>
                        <option value="4">Résidence services seniors</option>
                    </select>
                    {submitted && !user.typeEtablissement &&
                    <div className="help-block" style={{color: 'red'}}>Type de votre établissement requis</div>
                    }
                </div>
                <div className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.statut ? ' has-error' : '')}>
                    <label>Statut <span className="rouge">*</span></label>
                    <select name="statut" value={user.statut} onChange={this.handleChangeUser}>
                        <option value="public">PUBLIC</option>
                        <option value="privé non lucratif (association)">PRIVE NON LUCRATIF (association)</option>
                        <option value="privé">PRIVE</option>
                    </select>
                    {submitted && !user.statut &&
                    <div className="help-block" style={{color: 'red'}}>Statut de votre établissement requis</div>
                    }
                </div>
                {
                    parseInt(user.typeEtablissement) !== 4 ?
                    <div
                        className={'inputInfos col-xs-12 col-sm-6 col-md-4 finessField form-group' + (submitted && (!user.finess || !lib.isValidFiness(user.finess)) && user.typeEtablissement !== 4 ? ' has-error' : '')}>
                        <label>N° Finess <span className="rouge">*</span></label>
                        <input type="text" name="finess" className="form-control" value={user.finess}
                               onChange={this.handleChangeUser}
                               maxLength="9"
                               minLength="9"
                               placeholder="Ne pas mettre de point ou de tiret."
                               style={{boxShadow: !lib.isValidFiness(user.finess) ? '0 0 3px #CC0000' : ''}}/>
                        {submitted && (!user.finess || !lib.isValidFiness(user.finess)) && user.typeEtablissement !== 4 &&
                        <div className="help-block" style={{color: 'red'}}>N° Finess requis (9 caractères)</div>
                        }
                    </div>
                    :
                    <div></div>
                }
                <div className={'inputInfos villeInput col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidAdress(user.rue) ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Adresse <span className="rouge">*</span></label>
                    <input type="text"
                           name="rue"
                           className="form-control"
                           value={user.rue}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !user.rue || !lib.isValidAdress(user.rue) ? '0 0 3px #CC0000' : ''}}
                           placeholder="N° et nom de rue"/>
                    {submitted && !user.rue &&
                    <div className="help-block" style={{color: 'red'}}>Adresse de votre établissement requise</div>
                    }
                    {submitted && user.rue && !lib.isValidAdress(user.rue) &&
                    <div className="help-block" style={{color: 'red'}}>Adresse invalide</div>
                    }
                </div>

                <div className={'inputInfos villeInput col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidCP(user.codePostal) ? ' has-error' : '')}>
                    <label style={{color: "red"}} >Ville ou Code Postal *</label>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        onSuggestionSelected={this.onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        highlightFirstSuggestion={true}
                        style={{boxShadow: !user.codePostal || !lib.isValidCP(user.codePostal) ? '0 0 3px #CC0000' : ''}}
                    />
                    {submitted && !lib.isValidCP(user.codePostal) &&
                    <div className="help-block" style={{color: 'red'}}>Indiquez une ville ou un code postal, puis faites votre choix dans la liste déroulante</div>
                    }
                    <input type="hidden" name="cp" id="hidden_cp"/>
                    <input type="hidden" name="ville" id="hidden_ville"/>

                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidPhone(user.telephoneEtablissement) ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Téléphone Établissement <span className="rouge">*</span></label>
                    <input type="text"
                           name="telephoneEtablissement"
                           className="form-control"
                           value={user.telephoneEtablissement}
                           onChange={this.handleChangeUser}
                           style={{boxShadow: !lib.isValidPhone(user.telephoneEtablissement) ? '0 0 3px #CC0000' : ''}}
                           placeholder="Ne pas mettre de point ou de tiret"/>
                    <small>(Format Ex: 01 12 34 56 78)</small>
                    {submitted && !lib.isValidPhone(user.telephoneEtablissement) &&
                    <div className="help-block" style={{color: 'red'}}>mauvais format de téléphone, suivez l'exemple</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !lib.isValidEmail(user.emailEtablissement) ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Email Établissement <span className="rouge">*</span></label>
                    <input type="text"
                           name="emailEtablissement"
                           className="form-control"
                           value={user.emailEtablissement}
                           style={{boxShadow: submitted && !lib.isValidEmail(user.emailEtablissement) ? '0 0 3px #CC0000' : ''}}
                           onChange={this.handleChangeUser}/>
                    {submitted && !lib.isValidEmail(user.emailEtablissement) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format d'Email</div>
                    }
                </div>

                <div className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && user.siteEtablissement && !lib.isValidURL(user.siteEtablissement) ? ' has-error' : '')}>
                    <label style={{color: "red"}}>Site Internet</label>
                    <input type="text"
                           name="siteEtablissement"
                           className="form-control"
                           value={user.siteEtablissement}
                           style={{boxShadow: user.siteEtablissement && !lib.isValidURL(user.siteEtablissement) ? '0 0 3px #CC0000' : ''}}
                           onChange={this.handleChangeUser}/>
                    {submitted && user.siteEtablissement && !lib.isValidURL(user.siteEtablissement) &&
                    <div className="help-block" style={{color: 'red'}}>Mauvais format de site internet</div>
                    }
                </div>

                <div className="question">
                    <p><strong>SPECIFICITES</strong> (Plusieurs choix possibles)</p>
                </div>
                <div className="inputInfos col-xs-12">

                    <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="row">
                            <input type="checkbox" name="specificite" value="1" onChange={this.handleChangeSpec}/>
                            <label>HEBERGEMENT PERMANENT</label>
                        </div>
                    </div>
                    <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="row">
                            <input type="checkbox" name="specificite" value="2" onChange={this.handleChangeSpec}/>
                            <label>HEBERGEMENT TEMPORAIRE</label>
                        </div>
                    </div>
                    <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="row">
                            <input type="checkbox" name="specificite" value="3" onChange={this.handleChangeSpec}/>
                            <label>ACCUEIL DE JOUR</label>
                        </div>
                    </div>
                    <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="row">
                            <input type="checkbox" name="specificite" value="4" onChange={this.handleChangeSpec}/>
                            <label>UNITE ALZHEIMER</label>
                        </div>
                    </div>
                    <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <div className="row">
                            <input type="checkbox" name="specificite" value="5" onChange={this.handleChangeSpec}/>
                            <label>AIDE SOCIALE A L’HEBERGEMENT (ASH)</label>
                        </div>
                    </div>

                </div>



                <div style={{display: 'flex', alignItems: 'center'}}
                    className={'inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group' + (submitted && !this.state.condition ? ' has-error' : '')} style={{float: 'right'}}>
                    <br/><br/><br/>
                    <input id="condition2" type="checkbox" name="choix" value={true} onChange={this.handleChangeCGU}/>
                    <label htmlFor="condition2"><strong>J’accepte les <a href="/conditions-generales">conditions générales
                        d'utilisation.</a></strong></label>
                </div>
                {
                    <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                        <div style={{width: "304px", height: "78px"}}>
                            <div>
                                <ReCaptcha
                                    ref={(el) => {
                                        this.captchaDemo = el;
                                    }}
                                    hl="fr"
                                    size="normal"
                                    data-theme="dark"
                                    render="explicit"
                                    sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                    onloadCallback={this.onLoadRecaptcha}
                                    verifyCallback={this.verifyCallback}
                                />
                            </div>
                            <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                      className="g-recaptcha-response"
                                      style={{
                                          width: "250px",
                                          height: "40px",
                                          border: "1px solid rgb(193, 193, 193)",
                                          margin: "10px 25px",
                                          padding: "0px",
                                          resize: "none",
                                          display: "none"
                                      }}>
                        </textarea>
                        </div>
                    </div>
                }
                <div className="errorFirstStep" style={{color: "red"}}> </div>
                {alert.message ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                <div>
                    <div className="bouton submit"
                         style={{display: "inline-block", float: "right", margin: "2px"}}>
                        <input type="submit" name="envoyer" id="valideEtablissement" value="Valider"/>
                        <div className="error text-danger"></div>
                        {registering &&
                        <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </div>
                </div>
                <div style={{display: "inline-block", float: "right", margin: "2px"}}>
                    <div className="bouton annuler">
                        <a href="/register">
                            Annuler
                        </a>
                    </div>
                </div>
            </div>
        } else {
            return <div className="encadreForm col-xs-12 first">
                <div className="question">
                    <p>Choisissez vos identifiants de connexion</p>
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.username ? ' has-error' : '')}>
                    <label>Identifiant <span className="rouge">*</span></label>
                    <input type="text" name="username" className="form-control" value={user.username}
                           onChange={this.handleChangeUser}/>
                    {submitted && !lib.isValidUsername(user.username) &&
                    <div className="help-block" style={{color: 'red'}}>Identifiant requis, sans espaces</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.email ? ' has-error' : '')}>
                    <label>Email <span className="rouge">*</span></label>
                    <input type="text" name="email" className="form-control" value={user.email}
                           onChange={this.handleChangeUser}/>
                    {submitted && !user.email &&
                    <div className="help-block" style={{color: 'red'}}>Email valide requis</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.password ? ' has-error' : '')}>
                    <label htmlFor="userPassword">Mot de passe <span className="rouge">*</span></label>
                    <input id="userPassword" type="password" name="password" className="form-control"
                           value={user.password} onChange={this.handleChangeUser} placeholder="entre 4 et 19 caractères"/>
                    {submitted && !lib.isValidPassword(user.password) &&
                    <div className="help-block" style={{color: 'red'}}>Mot de passe valide requis</div>
                    }
                </div>
                <div
                    className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submitted && !user.password2 ? ' has-error' : '')}>
                    <label htmlFor="userPasswordAgain">Retapez votre mot de passe <span
                        className="rouge">*</span></label>
                    <input id="userPasswordAgain" type="password" name="password2" className="form-control"
                           value={user.password2} onChange={this.handleChangeUser}/>
                    {submitted && !lib.passwordMatch(user.password, user.password2) &&
                    <div className="help-block" style={{color: 'red'}}>Confirmation du Mot de passe requise</div>
                    }
                </div>
                <div
                    className={'inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group' + (submitted && !this.state.condition ? ' has-error' : '')}>
                    <input id="condition3" className="conditionRegister" type="checkbox" name="choix" value={true}
                           onChange={this.handleChangeCGU}/>
                    <label htmlFor="condition3"><strong>J’accepte les <a href="/conditions-generales">conditions générales
                        d'utilisation.</a></strong></label>
                </div>
                {
                <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                    <div style={{width: "304px", height: "78px"}}>
                        <div>
                            <ReCaptcha
                                ref={(el) => {
                                    this.captchaDemo = el;
                                }}
                                hl="fr"
                                size="normal"
                                data-theme="dark"
                                render="explicit"
                                sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                onloadCallback={this.onLoadRecaptcha}
                                verifyCallback={this.verifyCallback}
                            />
                        </div>
                        <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                  className="g-recaptcha-response"
                                  style={{
                                      width: "250px",
                                      height: "40px",
                                      border: "1px solid rgb(193, 193, 193)",
                                      margin: "10px 25px",
                                      padding: "0px",
                                      resize: "none",
                                      display: "none"
                                  }}>
                                        </textarea>
                    </div>
                </div>
                }
                <div className="errorFirstStep" style={{color: "red"}}> </div>
                {alert.message ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null}

                <div>
                    <div className="bouton submit"
                         style={{display: "inline-block", float: "right", margin: "2px"}}>
                        <input type="submit" name="envoyer" id="validePro" value="Valider"/>
                        <div className="error text-danger"> </div>
                        {registering &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        }
                    </div>
                </div>
                <div style={{display: "inline-block", float: "right", margin: "2px"}}>
                    <div className="bouton annuler">
                        <a href="/register">
                            Annuler
                        </a>
                    </div>
                </div>
            </div>
        }
    }

    renderHeaderForm() {
        const { form } = this.state;

        switch (form) {
            case 0: return "Vous êtes ...";
            default: return "Vos informations"

        }
    }

    renderHeader() {
        const {form} = this.state;

        switch (form) {
            case 1: return <h1>Inscription <strong>Famille</strong></h1>;
            case 2: return <h1>Inscription <strong>Etablissement</strong></h1>;
            case 3: return <h1>Inscription <strong>Professionnel</strong></h1>;
            default: return <h1>Inscription</h1>;
        }
    }

    render() {
        const {registering, alert} = this.props;
        const {user, submitted, form} = this.state;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            {this.renderHeader()}
                            {alert.message ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                            <form name="form" onSubmit={this.handleSubmit} autoComplete="off">
                                <input type="text" autoFocus="autofocus" style={{display: 'none'}}/>
                                <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}}/>
                                <div className="titleForm">
                                    <p className="active" id="vousEtes">{form === 0 ? "Vous êtes ..." : "Vos informations"}</p>
                                </div>
                                {this._renderForm(user, submitted, registering)}
                            </form>
                            {alert.message ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                            <div className="text-center"><a href="/login" style={{fontSize: '18px'}}><b> Déjà Inscrit ? suivez ce lien pour vous connecter à </b><p className="text-center" style={{fontSize: '18px'}}><span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></p></a></div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {registering} = state.registration;
    const {groupes} = state;
    const {alert} = state;
    const groupesList = groupes.list;

    return {
        groupesList,
        registering,
        alert
    };
}

const connectedRegisterPage = withRouter(connect(mapStateToProps)(RegisterPage));
export {connectedRegisterPage as RegisterPage};
