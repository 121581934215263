import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { etablissement }  from "./etablissement.reducer";
import { registration }   from './registration.reducer';
import { setUpdate }      from './setUpdate.reducer';
import { contact }        from './contact.reducer';
import { groupes }        from './groupes.reducer';
import { dispo }          from './alerte_dispo.reducer';
import { users }          from './users.reducer';
import { alert }          from './alert.reducer';
import { info }           from './info_dispo.reducer';

const rootReducer = combineReducers({
  authentication,
  etablissement,
  registration,
  setUpdate,
  contact,
  groupes,
  dispo,
  users,
  alert,
  info
});

export default rootReducer;
