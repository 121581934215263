import React, { Component } from 'react';
import { connect } from 'react-redux';

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";

class Mensions_Legales extends Component {

    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                <section className="placeMaisonBloc1">
                    <div className="container">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <article className="bandeauTitleSmall">
                                <h1>Mentions légales</h1>
                            </article>
                            <article className="texte">
                                <h3><u>Information éditeur</u></h3>
                                <p>
                                    Le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> est édité par SILVER CONSEILS.
                                    SARL&nbsp;unipersonnelle au capital de 3000 euros, 821&nbsp;006&nbsp;277 R.C.S.
                                    Nanterre
                                </p>
                                <p>
                                    Gérant&nbsp;:&nbsp;DENIS DELOBEL</p><p>Siège social : 71
                                Rue&nbsp;Jean&nbsp;Jacques&nbsp;Rousseau, &nbsp;92150 SURESNES
                            </p>
                                <p>
                                    E-mail : <a
                                    href="mailto:contact@placemaisonderetraite.fr">contact@placemaisonderetraite.fr</a>
                                </p>
                                <h3>
                                    <u>Propriété intellectuelle</u>
                                </h3>
                                <p>
                                    Conformément au code de la Propriété Intellectuelle et plus généralement à tous les
                                    accords comportant des dispositions relatives au
                                    droit d’auteur, les reproductions partielles ou totales de textes, d’images ou
                                    d’illustrations non destinées explicitement à être téléchargées
                                    par les visiteurs, sont interdites sans autorisation préalable de l’éditeur ou de
                                    tout ayant-droit.
                                </p>
                                <h3>
                                    <u>Responsabilités</u>
                                </h3>
                                <p>
                                    L’utilisation des informations contenues sur le présent site relève de la seule
                                    responsabilité de son utilisateur.
                                </p>
                                <p>Toutes les ressources contenues dans ce site, textes, visuels et illustrations
                                    (schémas, dessins, plans, photographies et
                                    animations informatiques) sont communiqués à titre purement informatif et ne peuvent
                                    en rien engager la responsabilité de
                                    l’éditeur. Aucun contenu du site ne possède de valeur contractuelle.
                                </p>
                                <h3>
                                    <u>Exercice du droit d’accès</u>
                                </h3>
                                <p>
                                    Conformément à l’article 34 de la loi « Informatique et Libertés », vous disposez
                                    d’un droit d’accès, de modification, de rectification
                                    et de suppression des données vous concernant. Pour exercer ce droit d’accès,
                                    adressez-vous à :
                                    <a href="mailto:contact@placemaisonderetraite.fr">contact@placemaisonderetraite.fr</a>
                                </p>
                                <h3>
                                    <u>Marques et logos, liens hypertextes</u>
                                </h3>
                                <p>
                                    Toute utilisation, quelle qu’elle soit, des noms de marques et logos de ce site est
                                    interdite sans l’autorisation de SILVER CONSEILS.
                                    De même tout lien hypertexte visant ce site est soumis à autorisation préalable.
                                </p>
                                <h3><u>Conception / réalisation</u></h3>
                                <p>
                                    Site Internet : <a href="http://cantem.fr/">CANTEM (cantem.fr)</a>
                                </p>
                                <h3>
                                    <u>Hébergement</u>
                                </h3>
                                <p>OVH</p>
                                <p>2 rue Kellermann –&nbsp;BP 80157 –&nbsp;59053 Roubaix Cedex 1</p>
                                <p>SIREN : 424 761 419</p>
                            </article>
                        </div>
                    </div>
                </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Mensions_Legales)