import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link, withRouter} from "react-router-dom";

import {etablissementActions, userActions} from '../_actions'

class  Navbar extends Component {

    constructor(props){
        super(props)

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            path: "",
            style_disp: false,
        };

        this._handleDropDown = this._handleDropDown.bind(this);
        this._renderDropDown = this._renderDropDown.bind(this);
        this._renderNavBar = this._renderNavBar.bind(this);
    }

    componentDidMount() {
        if (this.state.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}") {
            this.setState({path: "/personnes"});
        } else if (this.state.user.roles === "a:1:{i:0;s:11:\"ROLE_GROUPE\";}") {
                this.setState({path: "/groupes"});
        } else if (this.state.user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
            this.setState({path: "/etablissements"});
        } else if (this.state.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
            this.setState({path: "/personnes"});
        } else if (this.state.user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
            this.setState({path: "/administrateur"});
        }

        this.props.dispatch(etablissementActions.getAnnoncesCount())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).user.username !== this.state.user.username) {
            this.setState({user: JSON.parse(localStorage.getItem('user')).user})
        }
    }

    _renderAccountType() {
        if (this.state.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}") {
            return "Famille";
        } else if (this.state.user.roles === "a:1:{i:0;s:11:\"ROLE_GROUPE\";}") {
            return "Groupe";
        } else if (this.state.user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
            return "Etablissement";
        } else if (this.state.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
            return "Professionnel";
        } else if (this.state.user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
            return "Administrateur";
        }
    }

    _handleDropDown() {
        const {style_disp} = this.state;
        this.setState({style_disp: !style_disp})
    }

    _renderDropDown(){
        const { user, style_disp } = this.state;

        let disp = style_disp ? {display: 'block'} : {display: 'none'};

        if (user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") {
            return (
                <nav>
                    <a className="dropdown-toggle" title="Menu" onClick={this._handleDropDown}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </a>
                    <ul className="dropdown" style={disp}>
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/personnes/">Mon compte</a></li>
                        <li><a href="/place-maison">Je cherche une place</a></li>
                        <li><a href="/personnes/mes-annonces">Mes demandes de place</a></li>
                        <li><a href="/personnes/mes-alertes-dispo">Mes « Alerte-dispo »</a></li>
                        <li><a href="/contactez-nous">Contactez-nous</a></li>
                    </ul>
                </nav>
            )
        } else if (user.roles === "a:1:{i:0;s:18:\"ROLE_ETABLISSEMENT\";}") {
            return (
                <nav>
                    <a className="dropdown-toggle" title="Menu" onClick={this._handleDropDown}>
                        <span className="icon-bar"> </span>
                        <span className="icon-bar"> </span>
                        <span className="icon-bar"> </span>
                    </a>
                    <ul className="dropdown" style={disp}>
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/etablissements/">Mon compte</a></li>
                        <li><a href="/place-etablissement">J’ai une place disponible</a></li>
                        <li><a href="/etablissements/demandes-familles">Mes demandes de familles</a></li>
                        <li><a href="/etablissements/annonces">Mes annonces</a></li>
                        <li><a href="/alerte-dispo">Je crée mon « Alerte-dispo »</a></li>
                        <li><a href="/contactez-nous">Contactez-nous</a></li>
                    </ul>
                </nav>
            )
        } else if (user.roles === "a:1:{i:0;s:10:\"ROLE_ADMIN\";}") {
                return (
                    <nav>
                        <a className="dropdown-toggle" title="Menu" onClick={this._handleDropDown}>
                            <span className="icon-bar"> </span>
                            <span className="icon-bar"> </span>
                            <span className="icon-bar"> </span>
                        </a>
                        <ul className="dropdown" style={disp}>
                            <li><a href="/administrateur/activations">Activations</a></li>
                            <li><a href="/administrateur/alerte-dispo">Alerte dispo</a></li>
                            <li><a href="/administrateur/demande-etablissement">Demande des familles</a></li>
                            <li><a href="/administrateur/annonce-etablissement">Annonces des établissements</a></li>
                            <li><a href="/administrateur/etablissements">Les établissements</a></li>
                            <li><a href="/administrateur/groupes">Les groupes</a></li>
                            <li><a href="/administrateur/mes-messages">Mes messages</a></li>
                        </ul>
                    </nav>
                )
        } else {
            return (
                <nav>
                    <a className="dropdown-toggle" title="Menu" onClick={this._handleDropDown}>
                        <span className="icon-bar"> </span>
                        <span className="icon-bar"> </span>
                        <span className="icon-bar"> </span>
                    </a>
                    <ul className="dropdown" style={disp}>
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/place-maison">Je cherche une place</a></li>
                        <li><a href="/liste-annonce">Places disponibles</a></li>
                        <li><a href="/place-etablissement">J’ai une place disponible</a></li>
                        <li><a href="/liste-demande">Demandes de famille</a></li>
                        <li><a href="/alerte-dispo">Je crée mon « Alerte-dispo »</a></li>
                        <li><a href="/contactez-nous">Contactez-nous</a></li>
                    </ul>
                </nav>
            )
        }
    }

    _renderNavBar(user) {
        const { path } = this.state;

        return (!this.state.user
        ?   <header>
                <section className="header">
                    <div className="navLogo col-xs-12 col-sm-4 col-md-3 col-lg-4">
                        <div className="nav">
                            <div className="inscription_connexion info_perso"> </div>
                            <a className="inscription_connexion" href="/register">Inscription</a> | <a className="inscription_connexion" href="/login">Connexion</a>
                            {
                                this._renderDropDown()
                            }
                        </div>
                    </div>
                    <div className="logo col-xs-12 col-sm-8 col-md-3 col-lg-4">
                        <a href="/">
                            <img className="img-responsive" src="/img/home/logo.png" alt="logo"/>
                        </a>
                    </div>
                    <div className="infos col-xs-12 col-sm-12 col-md-6 col-lg-4">

                    </div>
                </section>
                <a href="/liste-annonce">
                    <button type="button" className="btn btn-primary placeDispo">Places disponibles <span
                        className="badge badge-pill badge-secondary">{this.props.count ? this.props.count : 0}</span></button>
                </a>
            </header>
        :   <header>
                    <section className="header">
                        <div className="navLogo col-xs-12 col-sm-4 col-md-3 col-lg-4">
                            <div className="nav">
                                <div className="inscription_connexion info_perso">
                                    { user.username } - {this._renderAccountType()}
                                </div>
                                <Link className="inscription_connexion" to={path}>Mon compte | </Link><a className="inscription_connexion" onClick={() => localStorage.removeItem('user')} href="/">Déconnexion </a>
                                {
                                    this._renderDropDown()
                                }
                            </div>
                        </div>
                        <div className="logo col-xs-12 col-sm-8 col-md-3 col-lg-4">
                            <a href="/">
                                <img className="img-responsive" src="/img/home/logo.png" alt="logo"/>
                            </a>
                        </div>
                        <div className="infos col-xs-12 col-sm-12 col-md-6 col-lg-4">

                        </div>
                    </section>
                    <a href="/liste-annonce">
                        <button type="button" className="btn btn-primary placeDispo">Places disponibles <span
                            className="badge badge-pill badge-secondary">{this.props.count ? this.props.count : 0}</span></button>
                    </a>
                </header>)
        //}
    }

    render() {

        return(
            this._renderNavBar(this.state.user)
        );
    }
}

function mapStateToProps(state){
    const { authentication, setUpdate, etablissement } = state;

    const { user } = setUpdate.user ? setUpdate : authentication;
    const { count } = etablissement;

    return {
        user,
        count
    }
}

export default withRouter(connect(mapStateToProps)( Navbar))
