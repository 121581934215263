import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ReCaptcha} from "react-recaptcha-google";
import Autosuggest from 'react-autosuggest';

import {dispoActions} from "../../_actions";
import lib from "../../lib/lib";

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";
import projectList from "../../communes/communes.json";
import {withRouter} from "react-router";
import {Alert} from "react-st-modal";
//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const renderInputComponent = inputProps => (
    <div>
        <input type="text" id="appbundle_alertedispo_email"
               required="required"
               maxLength="255"
               {...inputProps}/>
    </div>
);

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Alerte_Dispo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: localStorage.getItem('user') ? this.props.user.user : {},
            dispo: {
                etablissement: ["1", "2", "3", "4"],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '15',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                emailAccount: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
                code_insee: '',
            },
            choix_email: false,
            choix_condition: false,
            recaptcha: ''
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.changeInput = this.changeInput.bind(this);

        this.handleChoix = this.handleChoix.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            recaptcha: recaptchaToken
        });
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {dispo} = this.state;

        this.setState({
            dispo: {
                ...dispo,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChangeType(event) {
        const {value, checked} = event.target;
        const {dispo} = this.state;

        if (!checked) {
            this.setState({
                dispo: {
                    ...dispo,
                    etablissement: dispo.etablissement.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                dispo: {
                    ...dispo,
                    etablissement: [...dispo.etablissement, value]
                }
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {dispo} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            dispo: {
                ...dispo,
                codepostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0]
            }
        })

    };

    handleChoix(e) {
        const {name} = e.target;
        const {choix_email, choix_condition} = this.state;

        if (name === 'choix_email') {
            this.setState({
                [name]: !choix_email
            })
        } else {
            this.setState({
                [name]: !choix_condition
            })
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        const {choix_email, choix_condition, recaptcha, dispo} = this.state;

        if (!choix_email || lib.isValidEmail(dispo)) {
            Alert("Nous avons besoin d'un email valid ainsi que de votre consentement pour pouvoir vous alerter des Disponibiltés")
        } else if (!choix_condition) {
            Alert("Veuillez lire et accepter les conditions générales d'utilisation")
        } else if (dispo.etablissement.length <= 0) {
            Alert("Veuillez choisir, au moins, un type d'établissement")
        } else if (!dispo.codepostal) {
            Alert("Veuillez indiquer un code postal dans la liste déroulante")
        } else if (!dispo.ville) {
            Alert("Veuillez indiquer une ville dans la liste déroulante")
        } else if (recaptcha.length <= 0){
            Alert("veuillez confirmer que vous n'êtes pas un robot")
        } else {
            this.props.dispatch(dispoActions.postDispo(dispo, this.props.history))
        }
    }

    changeInput(value) {
        const {dispo} = this.state;

        if (dispo.etablissement.includes(value)) {
            this.setState({
                dispo: {
                    ...dispo,
                    etablissement: dispo.etablissement.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                dispo: {
                    ...dispo,
                    etablissement: [...dispo.etablissement, value]
                }
            })
        }
    }

    render() {
        const { dispo, value, suggestions } = this.state;
        const { alert } = this.props;
        const searchOptions = {
            componentRestrictions: { country: "fr",  }
        };
        // Log error status and clear dropdown when Google Maps API returns an error.
        const onError = (status, clearSuggestions) => {
            clearSuggestions()
        };

        const inputProps = {
            placeholder: 'Entrez un code postal ou une ville',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>

                    <section className="alerteDispoBloc1">
                        <div className="container">
                            {this.props.user && (this.props.user.user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || this.props.user.user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                                <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a href="/personnes/mes-alertes-dispo">Mes Alertes dispo</a> &gt; Je crée mon "Alerte-dispo"</p>
                                :
                                <p className="chemin"><a href="/">Accueil</a> &gt; Je crée mon "Alerte-dispo"</p>
                            }
                            <h1>Je crée mon <span>"Alerte-dispo"</span></h1>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <strong>Pour être informé par email dès qu’une nouvelle place est disponible sur votre
                                    zone
                                    de recherche </strong>
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                <div className="encadreForm">

                                    <form name="appbundle_alertedispo" className="form-horizontal">

                                        <div className="formBloc1">
                                            <div className="question">
                                                <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                    ALERTE-DISPO
                                                    » ?</p>
                                                <p>Sélection multiple possible</p>
                                            </div>

                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3" >
                                                <div className="row" style={{textAlign: 'center'}}>
                                                    <img className="img-responsive" style={{width: '50%'}}
                                                         src="/img/home/homeBloc1/icone-annonce1.png"
                                                         alt="icone-etablissement"
                                                         onClick={() => this.changeInput("1")}
                                                         title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                    <label htmlFor="appbundle_alertedispo_etablissement_1" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>EHPAD</label>
                                                </div>
                                                <input type="checkbox"
                                                       style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                       id="appbundle_alertedispo_etablissement_1"
                                                       name="etablissement"
                                                       value="1"
                                                       checked={dispo.etablissement.includes("1")}
                                                       onChange={this.handleChangeType}/>
                                            </div>

                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                <div className="row" style={{textAlign: 'center'}}>
                                                    <img className="img-responsive" style={{width: '50%'}}
                                                         src="/img/home/homeBloc1/icone-annonce3.png"
                                                         alt="icone-etablissement"
                                                         onClick={() => this.changeInput("2")}
                                                         title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                    <label htmlFor="appbundle_alertedispo_etablissement_2" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>USLD</label>
                                                </div>
                                                <input type="checkbox"
                                                       style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                       id="appbundle_alertedispo_etablissement_2"
                                                       name="etablissement"
                                                       value="2"
                                                       checked={dispo.etablissement.includes("2")}
                                                       onChange={this.handleChangeType}/>
                                            </div>

                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                <div className="row" style={{textAlign: 'center'}}>
                                                    <img className="img-responsive" style={{width: '50%'}}
                                                         src="/img/home/homeBloc1/icone-annonce4.png"
                                                         alt="icone-etablissement"
                                                         onClick={() => this.changeInput("3")}
                                                         title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                    <label htmlFor="appbundle_alertedispo_etablissement_3" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence autonomie</label>
                                                </div>
                                                <input type="checkbox"
                                                       style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                       id="appbundle_alertedispo_etablissement_3"
                                                       name="etablissement"
                                                       value="3"
                                                       checked={dispo.etablissement.includes("3")}
                                                       onChange={this.handleChangeType}/>
                                            </div>

                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                <div className="row" style={{textAlign: 'center'}}>
                                                    <img className="img-responsive" style={{width: '46%'}}
                                                         src="/img/home/homeBloc1/icone-annonce5.png"
                                                         alt="icone-etablissement"
                                                         onClick={() => this.changeInput("4")}
                                                         title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                    <label htmlFor="appbundle_alertedispo_etablissement_4" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence
                                                        services
                                                        seniors</label>
                                                </div>
                                                <input type="checkbox"
                                                       style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                       id="appbundle_alertedispo_etablissement_4"
                                                       name="etablissement"
                                                       value="4"
                                                       checked={dispo.etablissement.includes("4")}
                                                       onChange={this.handleChangeType}/>
                                            </div>


                                        </div>
                                        <br /><br /><br /><br />
                                        <div className="formBloc1">
                                            <div className="question">
                                                <p>Où recherchez-vous une place en maison de retraite ?</p>
                                            </div>

                                                <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <label>Dans la ville de</label>
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onSuggestionSelected}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        highlightFirstSuggestion={true}
                                                    />
                                                </div>
                                                <div className="inputInfos col-xs-12 col-sm-6 col-md-3" style={{float: 'center'}}>
                                                    <label>Dans un rayon de</label>
                                                    <select id="appbundle_alertedispo_rayon"
                                                            name="rayon"
                                                            onChange={this.handleChange}
                                                            value={dispo.rayon}
                                                            selected={dispo.rayon}>
                                                        <option value="15">15 km</option>
                                                        <option value="30">30 km</option>
                                                        <option value="50">50 km</option>
                                                        <option value="100">100 km</option>
                                                    </select>
                                                </div>


                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre email <span>*</span></label>
                                                        <input type="text" id="appbundle_alertedispo_email"
                                                               name="email" required="required"
                                                               value={this.state.dispo.email}
                                                               maxLength="255" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="checkbox" name="choix_email" value="1" onChange={this.handleChoix} required=""/>
                                                    <label><strong>J’accepte de recevoir par email une « Alerte-dispo » dès
                                                        qu’une place est publiée, selon mes critères de recherche
                                                        ci-dessus.</strong></label>
                                                </div>

                                        </div>


                                        <br/>
                                        <p>Les informations ci-dessus seront utilisées que par l’administrateur du site
                                            pour vous envoyer les places disponibles et ne seront pas visibles sur le
                                            site, ni diffusées aux établissements. </p><br/>

                                        <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <input id="condition" type="checkbox" name="choix_condition" value="1" onChange={this.handleChoix} required=""/>
                                            <label htmlFor="condition"><strong>J'accepte les <a href="/conditions-generales">conditions générales d'utilisation.</a></strong></label>
                                        </div>
                                        <br/>
                                        {
                                            <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                                <div style={{width: "304px", height: "78px"}}>
                                                    <div>
                                                        <ReCaptcha
                                                            ref={(el) => {
                                                                this.captchaDemo = el;
                                                            }}
                                                            hl="fr"
                                                            size="normal"
                                                            data-theme="dark"
                                                            render="explicit"
                                                            sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                            onloadCallback={this.onLoadRecaptcha}
                                                            verifyCallback={this.verifyCallback}
                                                        />
                                                    </div>
                                                    <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                              className="g-recaptcha-response"
                                                              style={{
                                                                  width: "250px",
                                                                  height: "40px",
                                                                  border: "1px solid rgb(193, 193, 193)",
                                                                  margin: "10px 25px",
                                                                  padding: "0px",
                                                                  resize: "none",
                                                                  display: "none"
                                                              }}>
                                                    </textarea>
                                                </div>
                                            </div>
                                        }
                                        <br/>

                                        <div className="bouton submit">
                                            <p className="text-danger hide error">Vous devez choisir un
                                                etablissement</p>

                                            <button type="submit" id="appbundle_alertedispo_save"
                                                    name="save" onClick={this.handleSubmit}>S'inscrire
                                            </button>

                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                <img className="img-responsive image-alert"
                                     src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, alert} = state;
    const {user} = authentication;
    return {
        user,
        alert
    }
}

export default withRouter(connect(mapStateToProps)(Alerte_Dispo))
