import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';

import {alertActions, infoActions} from "../../_actions";

import Navbar  from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import lib from "../../lib/lib";
import {history} from "../../_helpers";
import projectList from "../../communes/communes.json";
//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Info_Dispo extends Component {

    constructor(props){
        super(props)

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });

        const localUser = JSON.parse(localStorage.getItem('user'));

        this.state = {
            value: '',
            suggestions: [],
            user    : {
                codepostal: "",
                rayon: "10",
                username: localUser.user ? this.props.user.user.username : "",
                entite: "",
                email: localUser.user ? this.props.user.user.email : "",
                telephone: "",
                ville: "",
                latitude: "",
                longitude: "",
                personnes_id: localUser.user ? this.props.user.user.id : ""
            },
            listType: [],
            accept: false,
            submitted: false
        }

        this.handleChangeUser   = this.handleChangeUser.bind(this);
        this.handleChangeType   = this.handleChangeType.bind(this);
        this.handleChangeAccept = this.handleChangeAccept.bind(this);

        this.handleSubmit       = this.handleSubmit.bind(this);
    }

    handleChangeUser(event) {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChangeType(event) {
        //event.preventDefault()

        const { value, checked } = event.target;
        const { listType } = this.state;

        if (!checked) {
            this.setState({
                listType: listType.filter(function(item){ return item != value;})
            })
        } else {
            this.setState({
                listType: [...listType, value]
            })
        }
    }

    handleChangeAccept(event) {
        //event.preventDefault()

        const { accept } = this.state;
        this.setState({
            accept: !accept
        })
    }

    handleSubmit(e) {
        e.preventDefault()

        const {user, listType} = this.state

        this.setState({ submitted: true })

        this.props.dispatch(infoActions.createInfo(user, listType))
    }
/*
    handleSelect(place) {
        const {user} = this.state;

        let code_postal = "";
        let ville = "";

        place.address_components.map(item => {
            if (item.types[0] == "postal_code") {
                code_postal = item.long_name;
            } else if (item.types[0] == "locality") {
                ville = item.long_name;
            }
        });
        this.setState({
            user: {
                ...user,
                codepostal: code_postal,
                ville: ville,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            }
        })
    };
*/

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {user} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            user : {
                ...user,
                codepostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0],
            }

        })

    };

    render() {
        const{ user, submitted, value, suggestions } = this.state;
        const { alert } = this.props;

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        !(this.props.user) ? <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> :
                        <section className="alerteDispoBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/">Accueil</a> &gt; Je créé mon "Alerte-dispo"</p>
                                <h1>JE M'ABONNE À <span>"INFO-DISPO"</span></h1>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <p><b>Pour être informé par email, une fois par semaine, de toutes les places
                                        disponibles sur votre zone de recherche. <br/>
                                        Cet outil est à destination, particulièrement, des professionnels qui informent
                                        les personnes âgées et leur famille.</b></p>
                                </div>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="encadreForm">


                                        <form name="appbundle_infodispo" method="post" className="form-horizontal"
                                              autoComplete="new-password">

                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                        INFO-DISPO ?</p>
                                                    <p>Sélection multiple possible</p>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="infodispo_etablissement_1"
                                                               name="etabl1"
                                                               value="1"
                                                               onChange={this.handleChangeType}
                                                        />
                                                        <label
                                                            htmlFor="appbundle_infodispo_etablissement_1">EHPAD</label>
                                                        <p>
                                                            Etablissements d'hébergement pour personnes âgées
                                                            dépendantes, ce
                                                            sont des maisons de retraite médicalisées.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_infodispo_etablissement_2"
                                                               name="etabl2"
                                                               value="2"
                                                               onChange={this.handleChangeType}
                                                        />
                                                        <label
                                                            htmlFor="appbundle_infodispo_etablissement_2">USLD</label>
                                                        <p>
                                                            Unités de soins de longue durée, ce sont les lieux
                                                            d'accueil
                                                            les plus médicalisés.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_infodispo_etablissement_3"
                                                               name="etabl3"
                                                               value="3"
                                                               onChange={this.handleChangeType}
                                                        />
                                                        <label htmlFor="appbundle_infodispo_etablissement_3">Résidence
                                                            autonomie</label>
                                                        <p>
                                                            Ex-logements-foyers, elles accueillent majoritairement des
                                                            personnes
                                                            autonomes dans des petits appartements
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row">
                                                        <input type="checkbox"
                                                               id="appbundle_infodispo_etablissement_4"
                                                               name="etabl4"
                                                               value="4"
                                                               onChange={this.handleChangeType}
                                                        />
                                                        <label htmlFor="appbundle_infodispo_etablissement_4">Résidence
                                                            services seniors</label>
                                                        <p>
                                                            Résidences avec services qui accueillent des personnes âgées
                                                            autonomes seules ou en couple en T1 jusqu’au T3.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="formBloc2">
                                                <div className="question">
                                                    <p>Dans quel secteur géographique recherchez-vous ?</p>
                                                </div>
                                                <div className="inputSecteur">
                                                    <div className="inputVille">
                                                        <label>Code postal</label>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                        <input type="hidden" id="appbundle_infodispo_codepostal"
                                                               name="ville" value={user.ville}
                                                               placeholder="Entrez une ville ou un code postal"/>
                                                    </div>
                                                    <div className="selectRayon">
                                                        <label>Dans un rayon de</label>
                                                        <select id="appbundle_infodispo_rayon" value={user.rayon}
                                                                name="rayon" onChange={this.handleChangeUser}>
                                                            <option value="10">10 km</option>
                                                            <option value="20">20 km</option>
                                                            <option value="30">30 km</option>
                                                            <option value="50">50 km</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formBloc3">
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre nom <span>*</span></label>
                                                        <input type="text" id="appbundle_infodispo_nom"
                                                               name="username" required="required"
                                                               maxLength="255" value={user.username}
                                                               onChange={this.handleChangeUser}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre entité (pour les pros)</label>
                                                        <input type="text" id="appbundle_infodispo_entite"
                                                               name="entite" maxLength="255"
                                                               value={user.entite} onChange={this.handleChangeUser}
                                                               placeholder="ex&nbsp;: Hôpital de Coutin"/>
                                                    </div>
                                                </div>
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre email <span>*</span></label>
                                                        <input type="text" id="appbundle_infodispo_email"
                                                               name="email" onChange={this.handleChangeUser}
                                                               required="required"
                                                               maxLength="255" value={user.email}/>
                                                    </div>
                                                </div>
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre téléphone</label>
                                                        <input type="text" id="appbundle_infodispo_telephone"
                                                               name="telephone" value={user.telephone}
                                                               onChange={this.handleChangeUser} maxLength="255"/>
                                                    </div>
                                                </div>
                                                <div
                                                    className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="checkbox" name="choix" value="1"
                                                           onChange={this.handleChangeAccept} required=""/>
                                                    <label><strong>J’accepte de recevoir par email une fois par semaine
                                                        toutes les places disponibles, selon mes critères de recherche
                                                        ci-dessus.</strong></label>
                                                </div>
                                            </div>
                                            <div className="bouton submit" onClick={this.handleSubmit}>
                                                {!submitted ?
                                                <button type="submit" id="appbundle_infodispo_save"
                                                        disabled={submitted}
                                                        name="appbundle_infodispo[save]"> Valider

                                                </button> :
                                                    alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                }
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <img className="img-responsive image-alert"
                                         src="/img/info-dispo/info-dispo.png" alt="alerte-dispo"/>
                                </div>
                            </div>
                        </section>
                    }
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state){
    const { authentication, alert } = state;
    const { user } = authentication;
    return {
        user,
        alert
    };
}

export default connect(mapStateToProps)(Info_Dispo)
