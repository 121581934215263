import React, {Component} from 'react';
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';

import {etablissementActions, groupesActions, userActions} from "../../../../_actions";
import {alertActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {withRouter} from "react-router";
import {Alert} from "react-st-modal";


class Mes_Infos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                id          : 0,
                user_id     : this.props.user?.user?.id,
                username    : this.props.user?.user?.username,
                password    : '',
                password2   : '',
                nomGroupe   : '',
                emailAnnonce: this.props.user?.user?.email2,
                emailResponsable : '',
                nomResponsable   : '',
                prenomResponsable: '',
                telephoneResponsable: '',
            },
            form: 1,
            form_groupe: {},
            form_user: {display: "none"},
            submitted1: false,
            submitted2: false,
            condition: true,
        };

        this._handleChangeForm = this._handleChangeForm.bind(this);

        this._handleChangeUser = this._handleChangeUser.bind(this);

        this.handleSubmitAccount = this.handleSubmitAccount.bind(this);
        this.handleSubmitGroupe  = this.handleSubmitGroupe.bind(this);
    }

    componentDidMount(prevProps, prevState) {
        this.props.dispatch(groupesActions.getSingleGroupe(this.props.user.user.groupe_id));
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.groupe !== this.props.groupe) {
            this.setState({
                user: {
                    ...this.state.user,
                    id: this.props.groupe?.id,
                    emailResponsable: this.props.groupe?.emailResponsable,
                    emailAnnonce: this.props.groupe?.emailAnnonce ?? this.state.user.emailAnnonce,
                    nomGroupe: this.props.groupe?.nom,
                    nomResponsable: this.props.groupe?.nomResponsable,
                    prenomResponsable: this.props.groupe?.prenomResponsable,
                    telephoneResponsable: this.props.groupe?.telephoneResponsable,
                }
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.groupe !== prevState.groupe) {

            return {infos: nextProps.groupe};
        } else return null;
    }

    _handleChangeForm(e) {
        const {name} = e.target;
        switch (name) {
            case "form1":
                this.setState({form_user: {display: "none"}, form_groupe: {}, form: 1, submitted1: false, submitted2: false});
                break;
            case "form2":
                this.setState({form_user: {}, form_groupe: {display: "none"}, form: 2, submitted1: false, submitted2: false});
                break;
            default:
                this.setState({form_groupe: {}, form_user: {display: "none"}, form: 1, submitted1: false, submitted2: false});
        }
    }

    _handleChangeUser(e) {
        const {name, value} = e.target;

        const {user} = this.state;

        const {dispatch} = this.props;

        dispatch(alertActions.clear());

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            },
            submitted1: false,
            submitted2: false
        })
    }

    handleSubmitAccount(e) {
        e.preventDefault()
        this.setState({submitted1: true});
        const {user, updateUser} = this.state;
        const {dispatch} = this.props;
        if (user.password && lib.isValidPassword(user.password) && !lib.passwordMatch(user.password, user.password2)) {
            return null
        } else if (user.password && !lib.isValidPassword(user.password)) {
            return null
        } else if (user.password && lib.isValidPassword(user.password) && lib.passwordMatch(user.password, user.password2)) {
            if (lib.isValidUsername(lib.protectEntry(user.username)) && user.user_id) {
                dispatch(groupesActions.updateGroupeAccount(user));
                this.props.dispatch(groupesActions.getSingleGroupe(this.props.user.user.groupe_id));
            }
        } else if (lib.isValidUsername(lib.protectEntry(user.username)) && user.user_id) {
            dispatch(groupesActions.updateGroupeAccount(user));
            this.props.dispatch(groupesActions.getSingleGroupe(this.props.user.user.groupe_id));
        } else {
            if (!lib.isValidUsername(lib.protectEntry(user.username)))
                Alert("Identifiant incorrect");
            else if (!lib.isValidPassword(lib.protectEntry(user.emailAnnonce)))
                Alert("Email gestion des annonces incorrect");
        }
    }

    async handleSubmitGroupe(e) {
        e.preventDefault()
        this.setState({ submitted2: true });
        const { user, updateUser } = this.state;
        const { dispatch } = this.props;

        if (
            lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)) &&
            lib.isValidEmail(lib.protectEntry(user.emailResponsable)) &&
            lib.isValidEmail(lib.protectEntry(user.emailAnnonce)) &&
            lib.isValidPhone(user.telephoneResponsable)
        ) {
            await dispatch(groupesActions.updateGroupe(user));
            //await this.props.dispatch(groupesActions.getSingleGroupe(this.props.user.user.groupe_id));
        } else {
            if (!lib.protectEntry(user.nomGroupe))
                Alert("Nom de groupe incorrect");
            else if (!lib.isValidEmail(lib.protectEntry(user.emailResponsable)))
                Alert("Email Responsable incorrect");
            else if (!lib.isValidEmail(lib.protectEntry(user.emailAnnonce)))
                Alert("Email gestion des annonces incorrect");
            else if (user.telephoneResponsable && !lib.isValidPhone(lib.protectEntry(user.telephoneResponsable)))
                Alert("Téléphone Responsable incorrect");
        }
    }

    render() {
        const {user, form, submitted1, submitted2} = this.state;
        const {alert, updating} = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>

                <main>
                    <section className="inscriptionStep1">
                        <div className="container">

                            <p className="chemin"><a href="/groupes">Mon compte</a> &gt; Mes informations</p>
                            <h1>Mes informations</h1>

                            <div className="titleForm">
                                <button id="formEtablisssement"
                                        name="form1" value="1"
                                        className={form === 1 ? "btn btn-primary active" : "btn btn-primary"}
                                        onClick={this._handleChangeForm}>Mon Groupe
                                </button>
                                <button id="formCoordonnees" name="form2" value="2"
                                        className={form === 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"}
                                        onClick={this._handleChangeForm}>Mes identifiants
                                </button>
                            </div>

                            <form name="appbundle_user"
                                  autoComplete="off"
                                  style={this.state.form_user}
                                  onSubmit={(e) => this.handleSubmitAccount(e)}>
                                <div className="encadreForm col-xs-12">
                                    <div className="question" style={{textAlign: "center"}}>
                                        <p>Identifiants de Connexion</p>
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !lib.isValidUsername(user.username) ? ' has-error' : '')}>
                                        <label>Identifiant </label>
                                        <input type="text" className="form-control"
                                               id="adminbundle_user_username" name="username"
                                               placeholder="identifiant de connexion"
                                               required="required" maxLength="180" pattern=".{2,}"
                                               value={user.username} onChange={this._handleChangeUser}/>
                                        {submitted1 && !lib.isValidUsername(user.username) &&
                                        <div className="help-block">Mauvais format d'identifiant</div>
                                        }
                                    </div>
                                    <div className="question" style={{textAlign: "center"}}>
                                        <p>Modifiez le mot de passe (à remplir uniquement si vous changez de mot de passe)</p>
                                    </div>

                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !lib.isValidUpdatePassword(user.password) ? ' has-error' : '')}>
                                        <label htmlFor="userPassword">Mot de passe </label>
                                        <input type="password" id="adminbundle_user_plainPassword_first"
                                               name="password" className="form-control" placeholder="mot de passe"
                                               onChange={this._handleChangeUser}/>
                                        {submitted1 && !lib.isValidUpdatePassword(user.password) &&
                                        <div className="help-block">Mauvais format de Mot de mot de
                                            passe</div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !lib.passwordMatch(user.password, user.password2) ? ' has-error' : '')}>
                                        <label htmlFor="userPassword">Retapez votre mot de passe </label>
                                        <input type="password"
                                               id="adminbundle_user_plainPassword_second"
                                               name="password2" className="form-control"
                                               onChange={this._handleChangeUser}/>
                                        {submitted1 && !lib.passwordMatch(user.password, user.password2) &&
                                        <div className="help-block">La confirmation doit correspondre au
                                            mot de passe</div>
                                        }
                                    </div>
                                    <div>
                                        <div className="bouton submit">
                                            <input type="submit" name="envoyer" id="valider"
                                                   value="Modifier les identifiants"/>
                                            {updating &&
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            }
                                        </div>
                                    </div>
                                    {alert.message && submitted1 ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                                </div>
                            </form>

                            <form name="appbundle_groupe"
                                  autoComplete="off"
                                  style={this.state.form_groupe}
                                  onSubmit={(e) => this.handleSubmitGroupe(e)}>
                                <div className="encadreForm col-xs-12">
                                    <div className="question" style={{textAlign: "center"}}>
                                        <p>Informations du Groupe</p>
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)) ? ' has-error' : '')}>
                                        <label>Nom du Groupe <span>*</span></label>
                                        <input type="text" className="form-control" id="adminbundle_user_username" name="nomGroupe" placeholder={user.nomGroupe}
                                               required="required" maxLength="180" pattern=".{2,}" value={user.nomGroupe} readOnly={true}/>
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !user.emailAnnonce ? ' has-error' : '')}>
                                        <label>Email gestion des annonces <span>*</span></label>
                                        <input type="email" id="adminbundle_user_email" className="form-control" name="emailAnnonce"
                                               required="required" maxLength="180" pattern=".{2,}" placeholder={user.emailAnnonce}
                                               value={user.emailAnnonce} onChange={this._handleChangeUser}/>
                                        {submitted2 && !lib.isValidEmail(user.emailAnnonce) &&
                                        <div className="help-block">Mauvais format d'email</div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !user.emailResponsable ? ' has-error' : '')}>
                                        <label>Email contact Responsable <span>*</span></label>
                                        <input type="email" id="adminbundle_user_email" className="form-control" name="emailResponsable"
                                               required="required" maxLength="180" pattern=".{2,}" placeholder={user.emailResponsable}
                                               value={user.emailResponsable} onChange={this._handleChangeUser}/>
                                        {submitted2 && !lib.isValidEmail(user.emailResponsable) &&
                                        <div className="help-block">Mauvais format d'email</div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !user.telephoneResponsable ? ' has-error' : '')}>
                                        <label>Tél Responsable <span>*</span></label>
                                        <input type="tel" id="adminbundle_user_email" className="form-control" name="telephoneResponsable"
                                               required="required" maxLength="180" pattern=".{2,}" placeholder={user.telephoneResponsable}
                                               value={user.telephoneResponsable} onChange={this._handleChangeUser}/>
                                        {submitted2 && !lib.isValidPhone(user.telephoneResponsable) &&
                                        <div className="help-block">Mauvais format de tél</div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !user.prenomResponsable ? ' has-error' : '')}>
                                        <label>Prenom du Responsable</label>
                                        <input type="text" id="adminbundle_user_email" className="form-control" name="prenomResponsable"
                                               maxLength="180" pattern=".{2,}" placeholder={user.prenomResponsable}
                                               value={user.prenomResponsable} onChange={this._handleChangeUser}/>
                                        {submitted2 && !lib.isValidName(user.prenomResponsable) &&
                                        <div className="help-block">Mauvais format de prenom</div>
                                        }
                                    </div>
                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !user.nomResponsable ? ' has-error' : '')}>
                                        <label>Nom du Responsable</label>
                                        <input type="text" id="adminbundle_user_email" className="form-control" name="nomResponsable"
                                               maxLength="180" pattern=".{2,}" placeholder={user.nomResponsable}
                                               value={user.nomResponsable} onChange={this._handleChangeUser}/>
                                        {submitted2 && !lib.isValidName(user.nomResponsable) &&
                                        <div className="help-block">Mauvais format de nom</div>
                                        }
                                    </div>
                                    <div >
                                        <div className="bouton submit">
                                            <input type="submit" name="envoyer" id="valideEtablissement" value="Modifer les informations"/>
                                            {updating &&
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </div>
                                    {alert.message && submitted2 ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                                </div>
                            </form>
                        </div>

                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {user} = state.setUpdate.user ? state.setUpdate : state.authentication;
    const { updating } = state.setUpdate;
    const { groupe } = state.groupes;
    const {alert} = state;

    return {
        user,
        alert,
        groupe,
        updating
    }
}

export default withRouter(connect(mapStateToProps)(Mes_Infos))
