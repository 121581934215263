import React, {Component} from 'react';
import {connect} from 'react-redux';

import Navbar from "../../_components/Navbar";
import Footer from "../../_components/Footer";

class Forbidden extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                    <section className="banniere">
                        <img className="img-responsive" src="/img/home/banniere.jpeg" alt="banniere-accueil"/>
                        <div className="container">
                            <p className="accroche">
                                ERREUR 404 # Cette Page est inaccessible
                            </p>
                            <div className="col-xs12 bouton voir">
                                <a href="/">Retour à l'Accueil</a>
                            </div>
                        </div>
                    </section>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication} = state;
    const {user} = authentication;
    return {
        user,
    }
}

export default connect(mapStateToProps)(Forbidden)