import React, { Component } from "react";
import {connect} from "react-redux";
import moment from "moment";
import { Confirm, Alert } from "react-st-modal";
//import Autocomplete from "react-google-autocomplete";
import Autosuggest from 'react-autosuggest';
import ReactPaginate from 'react-paginate';

import {etablissementActions} from "../../../../_actions/etablissement.actions";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import projectList from "../../../../communes/communes.json";
import {history} from "../../../../_helpers";
import {withRouter} from "react-router";
import 'icheck/skins/all.css'; // or single skin css

import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Activations_Demandes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: (localStorage.getItem('user') ? this.props.user : false),
            demande: {},
            single: {},
            preview: false,
            deleted: [],
            filter: {
                Codepostal: '',
                ville: '',
                Type: [1, 2, 3, 4],
            },
            codePostal: '',
            ville: '',
            search: '',
            lat: '',
            lng: '',
            rayon: '',
            distance: false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            activate: false,
            removed: false,
            start: 0,
            end: 15,
            active: 1,
            screenWidth: null
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        //this.handleSelect = this.handleSelect.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.activate = this.activate.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getUnactiveDemandes());
        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.demandes !== this.state.demandes) {
            let demandes = this.props.demandes;

            this.setState({
                demandes
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.demandes !== prevState.demandes) {

            return {demandes: nextProps.demandes};
        } else return null;
    }

    handlePreview(event) {
        event.preventDefault();

        const array = this.props.demandes.demandes.filter(item => item.id == event.target.id)

        this.setState({
            single: array[0],
            preview: true
        })
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false})
    }

    renderActive(item) {
        switch (item.activate) {
            case 1 : return <div>Activée <span className="green-dot"> </span></div>;
            case -1: return <div>En attente d'activation <span className="orange-dot"> </span></div>;
            default: return <div>Désactivée <span className="orange-dot"> </span></div>;
        }
    }

    activate(e) {
        e.preventDefault();
        const {id, active} = e.target;
        if (parseInt(active) === 1) {
            this.props.dispatch(etablissementActions.activateDemande(id, -1))
        } else {
            this.props.dispatch(etablissementActions.activateDemande(id, 1))
        }
        this.props.dispatch(etablissementActions.getUnactiveDemandes())
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        let response = await Confirm("Voulez-vous vraiment supprimer cette demande?", "", "Oui", "Fermer");
        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)],
                removed: true
            });
            this.props.dispatch(etablissementActions.delDemande(id))
        }
    }

    changeInput(name, value, type) {
        const {filter} = this.state;
        const {Type} = filter;

        if (value) {
            this.setState({
                filter: {
                    ...filter,
                    Type: Type.filter(item => item !== parseInt(type))
                },
                [name]: !value
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    Type: [...Type, parseInt(type)]
                },
                [name]: !value
            })
        }
    }

    clickCheckBox(e) {
        const { name, type, checked, value } = e.target;
        const {filter} = this.state;

        const {Type} = filter;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        Type: [...Type, parseInt(value)]
                    },
                    [name]: true
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        Type: Type.filter(item => item !== parseInt(value))
                    },
                    [name]: false
                })
            }
        }
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;


        if (name === "rayon") {
            if (!this.state.codePostal) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    [name]: value,
                    distance: true,
                })
            }
        } else {
            this.setState({
                [name]: value,
                start: 0,
                end: 15
            })
        }

    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }
/*
    handleSelect(place) {
        let code_postal = "";
        let ville = "";

        place.address_components.map(item => {
            if (item.types[0] == "postal_code") {
                code_postal = item.long_name;
            }
            if (item.types[0] == "locality") {
                ville = item.long_name
            }
        });
        this.setState({
            codePostal: code_postal,
            ville: ville,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        })
    };
*/

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            codePostal: '',
            ville: '',
            rayon: '',
            distance: false,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {

        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            codePostal: suggestion.value.codePostal,
            ville: suggestion.value.nom,
            lat: suggestion.value.geoLoc.coordinates[1],
            lng: suggestion.value.geoLoc.coordinates[0],
            rayon: "5",
            distance: true
        })

    };

    supprimerFiltre() {
        this.props.dispatch(etablissementActions.getUnactiveDemandes());
        const {filter} = this.state;
        this.setState({
            codePostal: '',
            ville: '',
            value: '',
            rayon: '',
            distance: false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            filter: {
                ...filter,
                Type: [1, 2, 3, 4]
            },
            start: 0,
            end: 15
        })
    }

    renderFilter(item) {
        const {lat, lng, distance, deleted, filter} = this.state;
        const {types} = this.props.demandes;

        let t_mid = [];
        types.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            t_mid.push(el.type_etablissement_id)
        );

        let t_props = t_mid.filter(value => filter.Type.includes(value));


        const codePostal = this.state.codePostal  !== '' ? this.state.codePostal.indexOf(item.Codepostal) >= 0 : this.state.ville  !== '' ? this.state.ville.indexOf(item.ville)  >= 0 :  true;
        const rayon = distance === true  ? (this.distanceInKmBetweenEarthCoordinates(lat, lng, item.latitude, item.longitude) <= parseInt(this.state.rayon, 10)) : true;
        const locality = (codePostal || distance);
        const Type = filter.Type.length > 0 ? t_props.length > 0 : false;
        const notDel = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;

        return (locality && Type && rayon && notDel)
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15})
    };

    fill_types(types, item) {
        var t_final = [];

        types.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            t_final.push(String(el.type_etablissement_id))
        );

        return t_final
    }

    fill_specs(specs, item) {
        var s_final = [];

        specs.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            s_final.push(String(el.information_hebergement_id))
        );

        return s_final
    }

    redirectToModif(e, demandes, item){
        e.preventDefault();

        let demande = item;
        let types = this.fill_types(demandes.types, item);
        let specs = this.fill_specs(demandes.infos, item);


        this.props.history.push({pathname: '/demande-modification', state: {demandes: {demande, types, specs}}});
    }

    render() {
        moment.locale('fr');
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const specs = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];
        const {demandes, rayon, value, suggestions, start, end, type1, type2, type3, type4, preview, single, screenWidth, filter} = this.state;
        const {alert} = this.props;
        let filtredAnnonces = demandes.demandes ? demandes.demandes.filter(item => this.renderFilter(item)) : [];
        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        !preview ?
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin">
                                        <a href="/administrateur">Mon compte</a>
                                        <a href="/administrateur/activations/"> &gt; Activation</a> &gt; Demandes des familles/pros
                                    </p>
                                    <h1>Demandes <span>DES FAMILLES/PROS</span></h1>

                                    <br/>
                                    <div className="filtres col-xs-12">
                                        <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                            <div className="infoSpecial">
                                                {
                                                    screenWidth > 763 ?
                                                    <div className="listeEtablissementTri">
                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce1.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("type1", type1, "1")}
                                                                     title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_1"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           fontSize: '1.3rem'
                                                                       }}>EHPAD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       transform: 'scale(1.5)',
                                                                       width: '30px'
                                                                   }}
                                                                   id="appbundle_alertedispo_etablissement_1"
                                                                   name="type1"
                                                                   value="1"
                                                                   checked={type1}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce3.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("type2", type2, "2")}
                                                                     title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_2"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           fontSize: '1.3rem'
                                                                       }}>USLD</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       transform: 'scale(1.5)',
                                                                       width: '30px'
                                                                   }}
                                                                   id="appbundle_alertedispo_etablissement_2"
                                                                   name="type2"
                                                                   value="2"
                                                                   checked={type2}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce4.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("type3", type3, "3")}
                                                                     title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_3"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           fontSize: '1.3rem'
                                                                       }}>Résidence autonomie</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       transform: 'scale(1.5)',
                                                                       width: '30px'
                                                                   }}
                                                                   id="appbundle_alertedispo_etablissement_3"
                                                                   name="type3"
                                                                   value="3"
                                                                   checked={type3}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>

                                                        <div
                                                            className="inputCheckboxContent col-xs-6 col-sm-12 col-md-8 col-lg-3">
                                                            <div className="row" style={{textAlign: 'center'}}>
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce5.png"
                                                                     alt="icone-etablissement"
                                                                     onClick={() => this.changeInput("type4", type4, "4")}
                                                                     title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                <label htmlFor="appbundle_alertedispo_etablissement_4"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           fontSize: '1.3rem'
                                                                       }}>Résidence
                                                                    services
                                                                    seniors</label>
                                                            </div>
                                                            <input type="checkbox"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       transform: 'scale(1.5)',
                                                                       width: '30px'
                                                                   }}
                                                                   id="appbundle_alertedispo_etablissement_4"
                                                                   name="type4"
                                                                   value="4"
                                                                   checked={type4}
                                                                   onChange={this.clickCheckBox}
                                                            />
                                                        </div>
                                                    </div>
                                                        :
                                                        <div style={{justifyContent: 'center'}}>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                                             alt="EHPAD"
                                                                             onClick={() => this.changeInput("type1", filter.Type.includes(1), "1")}
                                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type1"
                                                                            name="type1"
                                                                            value="1"
                                                                            checked={this.state.filter.Type.includes(1)}
                                                                            onChange={() => this.changeInput("type1", this.state.filter.Type.includes(1), "1")}
                                                                        />
                                                                        <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                                             alt="USLD"
                                                                             onClick={() => this.changeInput("type2", filter.Type.includes(2), "2")}
                                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type2"
                                                                            name="type2"
                                                                            value="2"
                                                                            checked={filter.Type.includes(2)}
                                                                            onChange={() => this.changeInput("type2", filter.Type.includes(2), "2")}
                                                                        />
                                                                        <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                                             alt="Résidence autonomie"
                                                                             onClick={() => this.changeInput("type3", filter.Type.includes(3), "3")}
                                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type3"
                                                                            name="type3"
                                                                            value="3"
                                                                            checked={filter.Type.includes(3)}
                                                                            onChange={() => this.changeInput("type3", filter.Type.includes(3), "3")}
                                                                        />
                                                                        <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                                             alt="Résidence services seniors"
                                                                             onClick={() => this.changeInput("type4", filter.Type.includes(4), "4")}
                                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type4"
                                                                            name="type4"
                                                                            value="4"
                                                                            checked={filter.Type.includes(4)}
                                                                            onChange={() => this.changeInput("type4", filter.Type.includes(4), "4")}
                                                                        />
                                                                        <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                }
                                                <div className="secondFiltre">
                                                    <div className="villeInput triVille"
                                                         style={{position: "relative", width: "100%", margin: "0 auto"}}>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>

                                                    <div className="listeKm" style={{marginRight: "1rem"}}>
                                                        <button className={rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                            5km
                                                        </button>
                                                        <button className={rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                            15km
                                                        </button>
                                                        <button className={rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                            30km
                                                        </button>
                                                        <button className={rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                            50km
                                                        </button>
                                                        <button className={rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                            100km
                                                        </button>
                                                    </div>

                                                    <div className="bouton submit subConf">
                                                        <input type="submit" name="supprimer"
                                                               value="Supprimer les Filtres"
                                                               style={{marginTop: "10px"}}
                                                               onClick={this.supprimerFiltre}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    {
                                        alert.message ?
                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                            :
                                            null
                                    }
                                    <br/>
                                    {
                                        !demandes ?
                                            <div>
                                                <h1>Pas de demandes <span>en attente d'activation</span></h1>
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            </div>
                                            :
                                    <div id="listeDemande">
                                        { demandes.demandes && demandes.demandes.length <= 0 ?
                                            <div className="container">
                                                <hr/>
                                                <h1><span>Pas de</span> Demandes <span>En attente</span></h1>
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            </div>

                                            :
                                            filtredAnnonces.slice(start, end).map((item, index) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                    <div className="singleDemande">
                                                        {this.renderActive(item)}
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>Cherche place à {item.ville} ({item.Codepostal})</p>
                                                            </div>
                                                            <p>Publiée le {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                            <p>Publiée par: <strong>{item.username}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-sexe.png"
                                                                 alt="icone-sexe"/>
                                                            {!item.is_couple ?
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age} ans</strong></p>
                                                                :
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age_premier} ans</strong> et <strong>{item.age_second} ans</strong></p>
                                                            }
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                 alt="icone-type-etablissement"/>
                                                            <p>Type d'établissement : <br/>
                                                                {
                                                                    demandes.types.map(t => {
                                                                        if (t.demande_etablissement_id === item.id) {
                                                                            return (
                                                                                <strong key={Math.floor(Math.random() * Math.floor(1000))}>
                                                                                    {types[t.type_etablissement_id - 1]}
                                                                                    <br/>
                                                                                </strong>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                                 alt="icone-secteur"/>
                                                            <p>Secteur géographique : <br/><strong>{item.ville}, dans un rayon
                                                                de {item.rayon}
                                                                km</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                                 alt="icone-echeance"/>
                                                            <p>Echéance : <strong>{item.echeance}</strong></p>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={(e) => this.handlePreview(e, item)} id={item.id}>Voir</button>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={(e) => this.redirectToModif(e, demandes, item)}>Modifier</button>
                                                        </div>
                                                        <div className="row">
                                                            {
                                                                item.activate !== 1 ?
                                                                    <div className="col-xs12 bouton voir">
                                                                        <button onClick={this.activate} id={item.id} active={item.activate}>Activer</button>
                                                                    </div>
                                                                    :
                                                                    <div className="col-xs12 bouton voir">
                                                                        <button onClick={this.activate} id={item.id} active={item.activate}>Désactiver</button>
                                                                    </div>
                                                            }
                                                            <div className="col-xs12 bouton supprimer">
                                                                <button onClick={(e) => this.handleDelete(e, item.id)} id={item.id}>Supprimer</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className="col-xs-12 pages">
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={Math.ceil(filtredAnnonces.length/15)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'btn-page'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </section>
                            :
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a
                                        href="/personnes/mes-annonces">Mes demandes de places</a> &gt; Je
                                        visualise la demande</p>
                                    <h1>Je visualise <span>la demande</span></h1>

                                    <p className="titleForm">Informations visibles par tous</p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">Cherche place à {single.ville}</p>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-sexe.png"
                                                         alt="icone-sexe"/>
                                                    {!single.is_couple ?
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age} ans</strong></p>
                                                        :
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age_premier} ans</strong> et <strong>{single.age_second} ans</strong></p>
                                                    }
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-type-etablissement.png"
                                                         alt="icone-type-etablissement"/>
                                                    <p>Type d'établissement : <br/>
                                                        {
                                                            demandes.types.map((t, index) => {
                                                                if (t.demande_etablissement_id === single.id) {
                                                                    return (
                                                                        <strong key={index}>
                                                                            {types[t.type_etablissement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-secteur"/>
                                                    <p>Secteur géographique : <strong>{single.ville}, dans un rayon de {single.rayon}
                                                        km</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-echeance"/>
                                                    <p>Echéance : <strong>{single.echeance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="titleForm">Informations visibles uniquement par les établissements
                                        identifiés sur le site <span>Placemaisonderetraite.fr</span></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_2 col-xs-12">
                                            <div className="row">
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-lieu.png"
                                                         alt="icone-lieu"/>
                                                    <p>La personne se trouve : <strong>{single.logemennt}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-budget"/>
                                                    <p>Budget mensuel : <strong>{single.budget}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                                                    <p>Type d’hébergement : <br />
                                                        {
                                                            demandes.infos.map((i, index) => {
                                                                if (i.demande_etablissement_id === single.id) {
                                                                    return (
                                                                        <strong key={index}>
                                                                            {specs[i.information_hebergement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li><strong>{single.civilite} {single.nom}</strong></li>
                                                        <li>Tél : <strong>{single.telephone}</strong></li>
                                                        <li>Email : <strong>{single.email}</strong></li>
                                                    </ul>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li>Publiée par: <strong>{single.username}</strong></li>
                                                        <li>Email : <strong>{single.email_post}</strong></li>
                                                    </ul>
                                                </div>
                                                {
                                                    single.entite ?
                                                        <div
                                                            className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                            <img className="img-responsive"
                                                                 src="/img/mails/building.png"
                                                                 alt="icone-commentaires"/>
                                                            <p>Entité : <strong>{single.entite}</strong></p>
                                                        </div> : null
                                                }
                                                <br/>
                                                {
                                                    single.commentaires ?
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img
                                                                src="/img/visualisation-demande/icone-commentaires.png"
                                                                alt="icone-commentaires"/>
                                                            <p>Autres informations: <br/><strong>{single.commentaires}</strong></p>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="boutons col-xs-12">
                                            <div className="row">
                                                <div className="bouton modifier">
                                                    <button onClick={this.handleBack}>ANNULER</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {alert, authentication, etablissement} = state;
    const {user} = authentication;
    const { demandes } = etablissement;
    return {
        user,
        alert,
        demandes
    }
}

export default withRouter(connect(mapStateToProps)(Activations_Demandes))
