import React, { Component } from 'react';
import { connect } from 'react-redux';
import {etablissementActions} from "../../../_actions";
import moment from "moment";

class Annonces_Accueil extends Component {

    constructor(props){
        super(props)

    }

    componentWillMount() {
        this.props.dispatch(etablissementActions.getLatestAnnonces())
    }

    _renderTime(time) {
        var now = moment().toISOString()
        var diff = moment.duration(moment(time).diff(moment(now)));

        var days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    Disponible dans {days} jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    Disponibilité immédiate  <span className="green-dot"></span>
                </p>
            )
        }
    }

    render(){
        moment.locale('fr');
        return (
            <section className="homeBloc1">
                <div className="container">

                    <h1>Annonces <span>récentes</span></h1>
                    {
                        !(this.props.latest.list) ? <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                        this.props.latest.list.annonces.map((item, index)  =>
                            <div className="annonce col-xs-12 col-sm-6 col-md-6 col-lg-4" key={index}>
                                <a href="/liste-annonce">
                                    <img className="img-responsive"
                                         src={ item.titre === 'EHPAD' ?
                                             "/img/home/homeBloc1/icone-annonce1.png" :
                                             item.titre === 'USLD' ?
                                                 "/img/home/homeBloc1/icone-annonce3.png" :
                                                 item.titre === 'Résidence autonomie' ?
                                                     "/img/home/homeBloc1/icone-annonce4.png" :
                                                     "/img/home/homeBloc1/icone-annonce5.png"} alt="icone-maison"/>
                                    <div className="contentAnnonce">
                                        <p>
                                            {item.nbPLace} {item.nbPLace === 1 ? "place disponible" : "places disponibles"}
                                        </p>
                                        <p>
                                            à {item.ville}
                                        </p>
                                        <p>
                                            {item.codePostal}
                                        </p>
                                        <p>{item.titre}</p>
                                        { this._renderTime(item.datePlace) }
                                    </div>
                                </a>
                            </div>
                        )
                    }
                    {
                        !(this.props.latest.list) ? <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                            this.props.latest.list.demandes.map((item, index) =>
                                <div className="annonce col-xs-12 col-sm-6 col-md-6 col-lg-4" key={index}>
                                    <a href="/liste-demande">
                                        <img className="img-responsive" src="/img/home/homeBloc1/icone-annonce6.png" alt="icone-maison" />
                                        <div className="contentAnnonce">
                                            <p>Cherche place</p>
                                            <p>en <b>{item.titre}</b></p>
                                            <p>à {item.ville} {item.Codepostal}</p>
                                            <p>dans un rayon de {item.rayon} km</p>
                                            <p>{item.echeance}</p>
                                        </div>
                                    </a>
                                </div>
                            )
                    }
                    <div className="actions">
                        <div className="bouton col-xs-12">
                            <a href="/liste-annonce">Voir toutes les places disponibles</a>
                        </div>
                        <div className="bouton col-xs-12">
                            <a href="/liste-demande">Voir toutes les demandes de place</a>
                        </div>
                    </div>
                </div>
                <br />
            </section>
        )
    }
}



function mapStateToProps(state){
    const { latest } = state.etablissement;

    return {
        latest
    };
}

export default connect(mapStateToProps)(Annonces_Accueil)
