import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {etablissementActions} from "../../../../_actions";

class Etablissement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false)
        }
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
    }

    render() {
        const {alert} = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            <h1>Mon compte</h1>
                            <br/>
                            {
                                alert.message ?
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                    :
                                    null
                            }
                            <br/>
                            <div className="buttonMonCompte">
                                <div className="bouton submit">
                                    <Link to="/etablissements/mes-informations">Mes informations</Link>
                                    <p>Modifier mon identifiant, email, mot de passe, les informations sur mon établissement. </p>
                                </div>
                                <div className="bouton submit">
                                    <Link to="/etablissements/mes-photos">Mes photos</Link>
                                    <p>Ajouter et/ou modifier mes photos. </p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/etablissements/demandes-familles">Mes demandes de familles</a>
                                    <p>Consulter les demandes des familles de ma zone géographique.</p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/etablissements/annonces">Mes annonces</a>
                                    <p>Consulter Mes annonces, les modifier, les supprimer, ajouter une annonce.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, alert} = state;
    const {user} = authentication;
    return {
        user,
        alert
    }
}

export default connect(mapStateToProps)(Etablissement)