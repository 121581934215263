import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {loadReCaptcha} from 'react-recaptcha-google';

import {history} from '../_helpers';
import {alertActions, userActions} from '../_actions';

import {EtablissementRoute, PersonneRoute, AdminRoute, GroupeRoute}   from '../_components';

import {LoginPage}      from '../_views/LoginPage';
import {RegisterPage}   from "../_views/RegisterPage";

import Login_Register   from '../_views/Login_Register/Login_Register';
import Forgot           from '../_views/Login_Register/Forgoten_Password';

import Admin                        from '../_views/HomePage/Profile/Admin/Admin';
import Activations                  from '../_views/HomePage/Profile/Admin/Activations';
import Activations_Etablissement    from '../_views/HomePage/Profile/Admin/Activations.Etablissement';
import Activations_Demandes         from '../_views/HomePage/Profile/Admin/Activations.Demandes';
import Admin_Alerte_Dispo           from '../_views/HomePage/Profile/Admin/Alerte_Dispo';
import Admin_Infos_Dispo            from '../_views/HomePage/Profile/Admin/Infos_Dispo';
import Demandes_Familles            from '../_views/HomePage/Profile/Admin/Demandes_Familles';
import Annonces_Etablissements      from '../_views/HomePage/Profile/Admin/Annonces_Etablissements';
import Annonces_Etablissement_Id    from '../_views/HomePage/Profile/Admin/Annonces_Etablissement_Id';
import Admin_Etablissement          from '../_views/HomePage/Profile/Admin/Etablissements';
import Admin_Pro                    from '../_views/HomePage/Profile/Admin/Pro';
import Admin_Perso                  from '../_views/HomePage/Profile/Admin/Familles';
import Admin_Groupes                from '../_views/HomePage/Profile/Admin/Groupes';
import Admin_Groupe_Create          from '../_views/HomePage/Profile/Admin/Groupes_Create';
import Admin_Groupe_Account         from '../_views/HomePage/Profile/Admin/Groupes_Account';
import Admin_Groupe_list            from '../_views/HomePage/Profile/Admin/Groupes_list';

import Admin_Message                from '../_views/HomePage/Profile/Admin/Messages';

import Personnes            from '../_views/HomePage/Profile/Personne/Personnes';
import {Personne_Infos}     from '../_views/HomePage/Profile/Personne/Personne_Infos';
import Personne_Annonces    from '../_views/HomePage/Profile/Personne/Personne_Annonces';
import Personne_Alertes     from '../_views/HomePage/Profile/Personne/Personne_Alertes';

import Etablissement            from '../_views/HomePage/Profile/Etablissement/Etablissement';
import Etablissement_Infos      from '../_views/HomePage/Profile/Etablissement/Mes_Informations';
import Etablissement_Images     from '../_views/HomePage/Profile/Etablissement/Mes_Photos';
import Etablissement_Annonces   from '../_views/HomePage/Profile/Etablissement/Mes_Annonces';
import Etablissement_Demandes   from '../_views/HomePage/Profile/Etablissement/Mes_Demandes_Familles';
import Ajouter_Annonce          from '../_views/HomePage/Profile/Etablissement/Ajouter-Annonces';

import Groupe                           from "../_views/HomePage/Profile/Groupe/Groupe";
import Groupe_infos                     from "../_views/HomePage/Profile/Groupe/Mes_Informations";
import Groupe_annonce_ajout             from "../_views/HomePage/Profile/Groupe/Creer_annonce";
import Groupe_annonce_liste             from "../_views/HomePage/Profile/Groupe/Mes_Annonces";
import Groupe_etablissements            from "../_views/HomePage/Profile/Groupe/Etablissements";
import Groupe_etablissements_control    from "../_views/HomePage/Profile/Groupe/Etablissements_control";

import {Accueil}    from '../_views/Accueil';
import Liste        from '../_views/Liste_Annonce/ListeAnnonce';

import Place_Maison             from '../_views/Forms_Maison/Place_Maison';
import Demande_Etablissement    from '../_views/Forms_Maison/Demande_Etablissement';
import Demande_Modification     from '../_views/Forms_Maison/Demande_Modification';
import Place_Etablissement      from '../_views/Forms_Etablissement/Place_Etablissement';
import Liste_Demande            from '../_views/Liste_Demande/Liste_Demande';
import Alerte_Dispo             from '../_views/Alerte_Dispo/Alerte_Dispo';
import Info_Dispo               from '../_views/Info_Dispo/Info_Dispo';

import Contact      from '../_views/Contact/Contact';
import Mensions     from '../_views/Infos_Legales/Mensions_Legales';
import Conditions   from '../_views/Infos_Legales/Conditions_Generales';
import Charte       from '../_views/Infos_Legales/Charte_Utilisation';
import Donnees      from '../_views/Infos_Legales/Donnees_Personnelles';

import Email_Annonce from "../_views/Email_Active_Delete/Email_annonce";
import Email_Demande from "../_views/Email_Active_Delete/Email_demande";
import Email_Alerte  from "../_views/Email_Active_Delete/Email_alerte";

import PassForm from "../_views/Login_Register/passForm";

import Forbidden from '../_views/Forbidden/Forbidden';
import Admin_Messages from "../_views/HomePage/Profile/Admin/Messages";

class App extends React.Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            if (location.pathname !== "/etablissements/annonces")
                dispatch(alertActions.clear());
        });
    }

    componentDidMount() {
        loadReCaptcha();
    }

    Sub_Perso({match}) {
        switch (match.params.sub) {
            case "mes-informations":
                return <Personne_Infos/>;
            case "mes-annonces":
                return <Personne_Annonces/>;
            case "mes-alertes-dispo":
                return <Personne_Alertes/>;
            default:
                return <Forbidden/>
        }

    }

    Sub_Etabl({match}) {
        switch (match.params.sub) {
            case "mes-informations":
                return <Etablissement_Infos match={match}/>;
            case "mes-photos":
                return <Etablissement_Images match={match}/>;
            case "annonces":
                return <Etablissement_Annonces match={match}/>;
            case "ajouter-annonce":
                return <Ajouter_Annonce/>;
            case "demandes-familles":
                return <Etablissement_Demandes/>;
            default:
                return <Forbidden/>
        }
    }

    Sub_Groupe({match}) {
        switch (match.params.sub) {
            case "mes-informations":
                return <Groupe_infos match={match}/>;
            case "creer-annonce":
                return <Groupe_annonce_ajout match={match}/>;
            case "liste-annonces":
                return <Groupe_annonce_liste match={match}/>;
            case "etablissements":
                return <Groupe_etablissements match={match}/>;
            case "statuts":
                return <Groupe_etablissements_control match={match}/>;
            default:
                return <Forbidden/>
        }
    }

    Sub_Admin({match}) {
        if (match.path === "/administrateur/:sub1") {
            switch (match.params.sub1) {
                case "activations":
                    return <Activations match={match}/>;
                case "alerte-dispo":
                    return <Admin_Alerte_Dispo/>;
                case "mes-messages":
                    return <Admin_Message/>;
                case "info-dispo":
                    return <Admin_Infos_Dispo />;
                case "demande-etablissement":
                    return <Demandes_Familles />;
                case "annonce-etablissement":
                    return <Annonces_Etablissements />;
                case "annonces-actives":
                    return <Annonces_Etablissement_Id match={match}/>;
                case "etablissements":
                    return <Admin_Etablissement />;
                case "familles":
                    return <Admin_Perso />;
                case "assistantes":
                    return <Admin_Pro />;
                case "groupes":
                    return <Admin_Groupes />;
                default:
                    return <Forbidden/>
            }
        } else if (match.path === "/administrateur/:sub1/:sub2") {
            switch (match.params.sub2) {
                case "etablissements":
                    return <Activations_Etablissement match={match}/>;
                case "demande-etablissement":
                    return <Activations_Demandes/>;
                case "create-groupe":
                    return <Admin_Groupe_Create />;
                case "create-account":
                    return <Admin_Groupe_Account />;
                case "list-groupe":
                    return <Admin_Groupe_list />;
                default:
                    return <Forbidden/>
            }
        }

    }

    render() {
        const {alert} = this.props;

        return (
            <div>
                <Router history={history} basename="/placemaisonderetraite">
                    <div>
                        <Switch>
                            <PersonneRoute exact path="/personnes" component={Personnes}/>
                            <PersonneRoute path={`/personnes/:sub`} component={this.Sub_Perso}/>

                            <EtablissementRoute exact path="/etablissements" component={Etablissement}/>
                            <EtablissementRoute path={`/etablissements/:sub`} component={this.Sub_Etabl}/>

                            <GroupeRoute exact path="/groupes" component={Groupe}/>
                            <GroupeRoute path={`/groupes/:sub`} component={this.Sub_Groupe}/>

                            <AdminRoute exact path="/administrateur" component={Admin}/>
                            <AdminRoute path={`/administrateur/:sub1/:sub2`} component={this.Sub_Admin}/>
                            <AdminRoute path={`/administrateur/:sub1`} component={this.Sub_Admin}/>


                            <Route exact path="/login" component={LoginPage}/>
                            <Route exact path="/register" component={RegisterPage}/>
                            <Route exact path="/login-register" component={Login_Register}/>
                            <Route exact path="/resetting" component={Forgot}/>


                            <Route exact path="/liste-annonce" component={Liste}/>
                            <Route exact path="/liste-demande" component={Liste_Demande}/>
                            <Route exact path="/alerte-dispo" component={Alerte_Dispo}/>
                            <Route exact path="/info-dispo" component={Info_Dispo}/>

                            <Route exact path="/place-maison" component={Place_Maison}/>
                            <Route exact path="/demande-etablissement" component={Demande_Etablissement}/>
                            <Route exact path="/demande-modification" component={Demande_Modification}/>
                            <Route exact path="/place-etablissement" component={Place_Etablissement}/>

                            <Route exact path="/contactez-nous" component={Contact}/>
                            <Route exact path="/mentions-legales" component={Mensions}/>
                            <Route exact path="/conditions-generales" component={Conditions}/>
                            <Route exact path="/charte-utilisation" component={Charte}/>
                            <Route exact path="/donnees-personnelles" component={Donnees}/>

                            <Route exact path="/etablissement-annonce&:action&:annonce_id" component={Email_Annonce} />
                            <Route exact path="/etablissement-demande&:action&:demande_id" component={Email_Demande} />
                            <Route exact path="/delete-alerte&:alerte_id" component={Email_Alerte} />

                            <Route exact path="/pass-form&:user_id" component={PassForm}/>

                            <Route exact path="/" component={Accueil}/>

                            <Route path="*" component={Forbidden}/>
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
