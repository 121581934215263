import { groupesConstants } from "../_constants";

const InitialState = {
    gettingGroupes: false,
    gettingGroupe: false,
    creating: false,
    updating: false,
    groupe: {},
    list: [],
};

export function groupes(state = InitialState, action) {
    switch (action.type) {
        case groupesConstants.LIST_REQUEST:
            return {
                ...state,
                gettingGroupes: true
            };
        case groupesConstants.LIST_SUCCESS:
            return {
                ...state,
                gettingGroupes: false,
                list: action.list
            };
        case groupesConstants.LIST_FAILURE:
            return {
                ...state,
                gettingGroupes: false,
                error: action.error
            }
        case groupesConstants.SINGLE_REQUEST:
            return {
                ...state,
                gettingGroupe: true
            };
        case groupesConstants.SINGLE_SUCCESS:
            return {
                ...state,
                gettingGroupe: false,
                groupe: action.groupe
            };
        case groupesConstants.SINGLE_FAILURE:
            return {
                ...state,
                gettingGroupe: false,
                error: action.error
            }
        case groupesConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            }
        case groupesConstants.CREATE_SUCCESS:
            return {
                ...state,
                creating: false,
                message: action.message
            }
        case groupesConstants.CREATE_FAILURE:
            return {
                ...state,
                creating: false,
                message: action.error
            }
        case groupesConstants.UPDATE_REQUEST:
            return {
                ...state,
                updating: true
            }
        case groupesConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updating: false,
                message: action.message
            }
        case groupesConstants.UPDATE_FAILURE:
            return {
                ...state,
                updating: false,
                message: action.error
            }
        case groupesConstants.DELETE_FAILURE:
            return {
                ...state,
                message: action.error
            }
        default:
            return state;
    }
}
