import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {etablissementActions} from "../../../../_actions";


class Mes_Demandes extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            demande: {},
            single: {},
            preview: false,
            deleted: [],
            filter: {
                direc: 'desc',
            }
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getDemandeByCP(this.props.user.user.etablissement_id))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.demandes !== this.state.demandes) {
            let demandes = this.props.demandes;

            this.setState({
                demandes
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.demandes !== prevState.demandes) {

            return {demandes: nextProps.demandes};
        } else return null;
    }

    handlePreview(event) {
        event.preventDefault();

        const array = this.state.demandes.demandes.filter(function(item) {return item.id === parseInt(event.target.id)});

        this.setState({
            single: array[0],
            preview: true
        })
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false})
    }

    handleChangeFilter(e) {
        const {name, value} = e.target;
        const {filter, demandes} = this.state;

        if (name === 'direc') {
            this.setState({
                demande: {
                    ...demandes,
                    demandes: demandes.demandes.reverse()
                }
            })

        }
    }

    render(){
        moment.locale('fr');
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const specs = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];
        const {demandes} = this.state;
        const {preview, single} = this.state;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                    {
                        !preview ?
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/etablissements">Mon compte</a> &gt; Mes demandes de places
                                    </p>
                                    <h1>Mes demandes <span>de places</span></h1>

                                    <br/>
                                    <div className="filtres col-xs-12">
                                        <p>Filtrer par</p>
                                        <select id="order" name="direc" onChange={this.handleChangeFilter}>
                                            <option value="desc">Du plus récent au plus ancien</option>
                                            <option value="asc">Du plus ancien au plus récent</option>
                                        </select>
                                    </div>
                                    <div id="listeDemande">
                                        {!this.props.demandes.demandes ?
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            :
                                            demandes.demandes.map((item) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={item.id}>
                                                    <div className="singleDemande">
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>Cherche place à {item.ville} ({item.Codepostal})</p>
                                                            </div>
                                                            <p>Publiée le {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-sexe.png"
                                                                 alt="icone-sexe"/>
                                                            {!item.is_couple ?
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age} ans</strong></p>
                                                                :
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age_premier} ans</strong> et <strong>{item.age_second} ans</strong></p>
                                                            }
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                 alt="icone-type-etablissement"/>
                                                            <p>Type d'établissement : <br/>
                                                                {
                                                                    demandes.types.map((t, index) => {
                                                                        if (t.demande_etablissement_id === item.id) {
                                                                            return (
                                                                                <strong key={index}>
                                                                                    {'- ' + types[t.type_etablissement_id - 1]}
                                                                                    <br/>
                                                                                </strong>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                                                            <p>Type d'hébergement : <br/>
                                                                {
                                                                    demandes.infos.map((i, index) => {
                                                                        if (i.demande_etablissement_id === item.id) {
                                                                            return (
                                                                                <strong key={index}>
                                                                                    {specs[i.information_hebergement_id - 1]}
                                                                                    <br/>
                                                                                </strong>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                                 alt="icone-secteur"/>
                                                            <p>Secteur géographique : <br/><strong>{item.ville}, dans un rayon
                                                                de {item.rayon}
                                                                km</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                                 alt="icone-echeance"/>
                                                            <p>Echéance : <strong>{item.echeance}</strong></p>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={(e) => this.handlePreview(e)} id={item.id}>Voir la demande</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </section>
                            :
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/personnes">Mon compte</a> &gt; <a
                                        href="/etablissements/demandes-familles">Mes demandes de places</a> &gt; Je
                                        visualise la demande</p>
                                    <h1>Je visualise <span>la demande</span></h1>

                                    <p className="titleForm">Informations visibles par tous</p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">Cherche place à {single.ville}</p>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-sexe.png"
                                                         alt="icone-sexe"/>
                                                    {!single.is_couple ?
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age} ans</strong></p>
                                                        :
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age_premier} ans</strong> et <strong>{single.age_second} ans</strong></p>
                                                    }
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-type-etablissement.png"
                                                         alt="icone-type-etablissement"/>
                                                    <p>Type d'établissement : <br/>
                                                        {
                                                            demandes.types.map(t => {
                                                                if (t.demande_etablissement_id == single.id) {
                                                                    return (
                                                                        <strong key={Math.floor(Math.random() * Math.floor(1000))}>
                                                                            {types[t.type_etablissement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-secteur"/>
                                                    <p>Secteur géographique : <strong>{single.ville}, dans un rayon de {single.rayon}
                                                        km</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-echeance"/>
                                                    <p>Echéance : <strong>{single.echeance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="titleForm">Informations visibles uniquement par les établissements
                                        identifiés sur le site <span>Placemaisonderetraite.fr</span></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_2 col-xs-12">
                                            <div className="row">
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-lieu.png"
                                                         alt="icone-lieu"/>
                                                    <p>La personne se trouve : <strong>{single.logemennt}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-budget"/>
                                                    <p>Budget mensuel : <strong>{single.budget}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                                                    <p>Types d'hébergement : <br />
                                                        {
                                                            demandes.infos.map((i, index) => {
                                                                if (i.demande_etablissement_id === single.id) {
                                                                    return (
                                                                        <strong key={index}>
                                                                            {specs[i.information_hebergement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li><strong>{single.civilite} {single.nom}</strong></li>
                                                        <li>Tél : <strong>{single.telephone}</strong></li>
                                                        <li>Email : <strong>{single.email}</strong></li>
                                                    </ul>
                                                </div>
                                                {
                                                    single.entite ?
                                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                            <img className="img-responsive"
                                                                 src="/img/mails/building.png"
                                                                 alt="icone-budget"/>
                                                            <p>Entité : <strong>{single.entite}</strong></p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <br/>
                                                {
                                                    single.commentaires ?
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <img
                                                                src="/img/visualisation-demande/icone-commentaires.png"
                                                                alt="icone-commentaires"/>
                                                            <p>Autres informations: <br/><strong>{single.commentaires}</strong></p>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="boutons col-xs-12">
                                            <div className="row">
                                                <div className="bouton modifier">
                                                    <button onClick={this.handleBack}>Retour</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, etablissement } = state;
    const { user } = authentication;
    const { demandes } = etablissement;
    return  {
        user,
        demandes
    }
}

export default connect(mapStateToProps)(Mes_Demandes)
