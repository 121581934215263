import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment    from 'moment';
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {alertActions, etablissementActions, userActions} from "../../../../_actions";
import lib from '../../../../lib/lib'
import {authHeader, history} from "../../../../_helpers";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import {withRouter} from "react-router";
import {Alert} from "react-st-modal";

registerLocale('fr', fr);

Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

class Ajouter_Annonce extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            infos: this.props.infos,
            annonce: {
                user_id: null,
                email: '',
                email2: null,
                site: '',
                telephone: '',
                nom: '',
                nbPLace: 1,
                codePostal: '',
                ville: '',
                type: '',
                createdAt: '',
                datePlace: new Date(),
                prix: 'NC',
                descriptif: '',
                equipements: 'aucun',
                activate: -1,
                token: '',
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
                specificite: []
            },
            etablissement: null,
            id: null,
            date_state: null,
            maxLength: 400,
            CGU: false,
            preview: false,
            submitted: false,
            success: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSpec = this.handleChangeSpec.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCGU = this.handleChangeCGU.bind(this);
        this.selectEtablissement = this.selectEtablissement.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id))
        this.props.dispatch(alertActions.clear());
    }

    selectEtablissement(e) {
        e.preventDefault();
        const {value} = e.target;

        if (value) {
            const etablissement = this.props.list.etablissements[parseInt(value)];
            this.setState({
                etablissement: etablissement,
                annonce: {
                    ...this.state.annonce,
                    user_id: etablissement.user_id,
                    email: etablissement.email,
                    email2: etablissement.email2 ?? null,
                    token: etablissement.confirmation_token,
                    codePostal: etablissement.codePostal,
                    ville: etablissement.ville,
                    type: etablissement.type_etablissement_id,
                    nom: etablissement.nom_etablissement,
                    telephone: etablissement.telephone_etablissement,
                    site: etablissement.site_etablissement,
                    image_un: etablissement.image_un,
                    image_deux: etablissement.image_deux,
                    image_trois: etablissement.image_trois,
                    image_quatre: etablissement.image_quatre,
                }
            });
        } else {
            this.setState({
                etablissement: null,
                annonce: {
                    ...this.state.annonce,
                    user_id: null,
                    email: '',
                    email2: null,
                    token: '',
                    codePostal: '',
                    ville: '',
                    type: '',
                    nom: '',
                    telephone: '',
                    site: '',
                    image_un: '',
                    image_deux: '',
                    image_trois: '',
                    image_quatre: '',
                }
            });
        }
        //this.props.dispatch(etablissementActions.getEtablissement(value))
    }
/*
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.infos !== this.state.infos) {
            let infos = this.props.infos;
            this.setState({
                infos: infos,
                annonce: {
                    ...this.state.annonce,
                    user_id: infos.etablissement.user_id,
                    email: infos.etablissement.email,
                    email2: infos.etablissement.email2 ?? null,
                    token: infos.etablissement.confirmation_token,
                    codePostal: infos.etablissement.codePostal,
                    ville: infos.etablissement.ville,
                    type: infos.etablissement.type_etablissement_id,
                    nom: infos.etablissement.nom_etablissement,
                    telephone: infos.etablissement.telephone_etablissement,
                    site: infos.etablissement.site_etablissement,
                    image_un: infos.etablissement.image_un,
                    image_deux: infos.etablissement.image_deux,
                    image_trois: infos.etablissement.image_trois,
                    image_quatre: infos.etablissement.image_quatre,
                }
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.infos !== prevState.infos) {

            return {infos: nextProps.infos};
        } else return null;
    }
*/
    handleChange(e) {
        const {name, value} = e.target;
        const {annonce, maxLength} = this.state;

        if (name === "descriptif" && annonce.descriptif.length <= maxLength ) {
            this.setState({
                annonce: {
                    ...annonce,
                    descriptif: lib.protectEntry(value)
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    [name]: value
                }
            })
        }
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {annonce} = this.state;

        if (!checked) {
            this.setState({
                annonce: {
                    ...annonce,
                    specificite: annonce.specificite.filter(function (item) {
                        return item !== value;
                    })
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    specificite: [...annonce.specificite, value]
                }
            })
        }
    }

    handleChangeDate(date) {
        const {annonce, date_state} = this.state;

        this.setState({
            annonce: {
                ...annonce,
                datePlace: date
            },
            date_state: date,
        });
    }

    handleChangeCGU(e) {
        const {name, value} = e.target;

        this.setState({CGU: !this.state.CGU})
    }

    _renderTime(time) {
        let now = moment().toISOString();
        let diff = moment.duration(moment(time).diff(moment(now)));

        let days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    Disponibilité dans { days } jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    Disponibilité immédiate  <span className="green-dot"></span>
                </p>
            )
        }
    }

    handlePreview(e) {
        e.preventDefault();

        const {preview} = this.state;

        if (!this.state.etablissement) {
            Alert("Veuillez selectionner un établissement");
        } else {
            this.setState({
                preview: !preview,
                CGU: false
            })
            window.scrollTo(0,0)
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const {annonce, success, CGU} = this.state;
        const {dispatch} = this.props;

        if (CGU) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({annonce})
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/postAnnonce`, requestOptions)
                .then(this.handleResponse)
                .then(async result => {
                    if (result.success) {
                        dispatch(alertActions.success("Votre annonce est en attente de validation par notre modérateur. Une fois validée, elle sera publiée pour une période de 7 jours"));
                        this.props.history.push({pathname: "/groupes/liste-annonces", state: {message: "Votre annonce a bien été enregistrée, elle va être relue par notre modérateur pour être publiée dans les meilleurs délais."}});
                        //window.location.reload()
                    } else {
                        dispatch(alertActions.error("Une erreur est survenue! veuillez contacter l'administrateur"));
                    }
                })
                .catch(err => {
                    dispatch(alertActions.error(err));
                });
        } else {
            Alert("Veuillez lire et accepter les conditions générales d’utilisation." )
        }
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    sortPostalCode(a, b) {
        if (a > b)
            return 1;
        else if (a < b)
            return -1;
        else
            return 0
    }

    render() {
        const {etablissement, annonce, preview, date_state, CGU} = this.state;
        const { alert } = this.props;
        let date = new Date();

        let etablissements = this.props.list?.etablissements?.sort((a, b) => this.sortPostalCode(a.codePostal, b.codePostal)) ?? [];

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !preview ?
                        <main>
                            <section className="demandeEtablissementBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/groupes">Mon compte</a> &gt; <a href="/groupes/liste-annonces">Liste des annonces</a> &gt; Je publie une annonce pour mes places disponibles</p>
                                    <h1>Je publie une annonce <span>pour mes places disponibles</span></h1>

                                    <div className="encadreForm">

                                        <div className="formBloc1_1 col-xs-12 col-sm-4" style={{marginBottom: 25}}>
                                            <div className="row">
                                                <div className="question">
                                                    <p>Pour quel établissement ? <span style={{color: '#c4574e'}}>*</span></p>
                                                </div>
                                                <select id="etablissement" onChange={this.selectEtablissement}>
                                                    <option value="">Selectionnez un établissement...</option>
                                                    {
                                                        etablissements.map((item, index) =>
                                                            <option value={index} key={index}>{item.nom_etablissement} ({item.codePostal} - {item.ville})</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-center text-success validationDemandeEtablissement">
                                        </div>
                                        <form name="appbundle_annonce" onSubmit={this.handlePreview}>

                                            <div className="formBloc1">
                                                <div className="formBloc1_1 col-xs-12 col-sm-4">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p>Nombre de places</p>
                                                        </div>
                                                        <div className="inputDemande">
                                                            <input type="number" id="appbundle_annonce_nbPLace"
                                                                   name="nbPLace" required="required" min="1"
                                                                   max="10" value={annonce.nbPLace}
                                                                   onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc1_2 col-xs-12 col-sm-6 datePlace">
                                                    <div className="form-group row">
                                                        <div className="question">
                                                            <p>Quand vos places sont-elles disponibles ?<span> *</span></p>
                                                        </div>
                                                        <div className="hasDatepicker">
                                                            <DatePicker
                                                                selected={annonce.datePlace}
                                                                onChange={this.handleChangeDate}
                                                                minDate={date}
                                                                maxDate={date.addDays(30)}
                                                                placeholderText="Veuillez selectionner une date dans un inervalle de 30 jours"
                                                                dateFormat="dd-MM-yyyy"
                                                                locale="fr"
                                                                name="datePlace"
                                                                //className="hasDatepicker"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="formBloc1_3 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong>Quel est le niveau de budget mensuel ?</strong> (facultatif)</p>
                                                        </div>
                                                        <div className="inputBudget">
                                                            <div id="appbundle_annonce_prix">
                                                                <input type="radio" id="appbundle_annonce_prix_NC"
                                                                       name="prix" value="NC"
                                                                       onChange={this.handleChange}
                                                                       checked={annonce.prix === "NC"}/>
                                                                <label htmlFor="appbundle_annonce_prix_NC">Pas de Budget</label>
                                                                <input type="radio" id="appbundle_annonce_prix_0"
                                                                       name="prix" value="Moins de 2000€"
                                                                       onChange={this.handleChange}
                                                                       checked={annonce.prix === "Moins de 2000€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_0">Moins de 2000€</label>
                                                                <input type="radio" id="appbundle_annonce_prix_1"
                                                                       name="prix" value="2000€ à 2500€"
                                                                       onChange={this.handleChange}
                                                                       checked={annonce.prix === "2000€ à 2500€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_1">De 2000€ à 2500€</label>
                                                                <input type="radio" id="appbundle_annonce_prix_2"
                                                                       name="prix" onChange={this.handleChange}
                                                                       value="2500€ à 3000€"
                                                                       checked={annonce.prix === "2500€ à 3000€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_2">De 2500€ à 3000€</label>
                                                                <br/>
                                                                <input type="radio" id="appbundle_annonce_prix_3"
                                                                       name="prix" onChange={this.handleChange}
                                                                       value="3000€ à 3500€"
                                                                       checked={annonce.prix === "3000€ à 3500€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_3">De 3000€ à 3500€</label>
                                                                <input type="radio" id="appbundle_annonce_prix_4"
                                                                       name="prix" onChange={this.handleChange}
                                                                       value="3500€ à 4000€"
                                                                       checked={annonce.prix === "3500€ à 4000€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_4">De 3500€ à 4000€</label>
                                                                <input type="radio" id="appbundle_annonce_prix_5"
                                                                       name="prix" onChange={this.handleChange}
                                                                       value="Plus de 4000€"
                                                                       checked={annonce.prix === "Plus de 4000€"}/>
                                                                <label htmlFor="appbundle_annonce_prix_5">Plus de 4000€</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="formBloc1_4 col-xs-12">
                                                    <br/>
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong> Spécificités </strong> (Facultatif - Plusieurs choix possibles)</p>
                                                        </div>
                                                        <div className="inputInfos col-xs-12">

                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec1" type="checkbox" style={{transform: 'scale(1.4)'}} checked={annonce.specificite.includes("Place en unité Alzheimer / protégée")} name="specificite" value="Place en unité Alzheimer / protégée" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec1" style={{fontSize: "1em", marginTop: '1%'}}>Place en unité Alzheimer / protégée</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec2" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("Possibilité de court séjour / séjour temporaire")} value="Possibilité de court séjour / séjour temporaire" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec2" style={{fontSize: "1em", marginTop: '1%'}}>Possibilité de court séjour / séjour temporaire</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec3" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("ASH possible (Aide Sociale à l’Hébergement)")} value="ASH possible (Aide Sociale à l’Hébergement)" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec3" style={{fontSize: "1em", marginTop: '1%'}}>ASH possible (Aide Sociale à l’Hébergement)</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec4" type="checkbox" style={{transform: 'scale(1.4)'}} name="specificite" checked={annonce.specificite.includes("Place en accueil de jour")} value="Place en accueil de jour" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec4" style={{fontSize: "1em", marginTop: '1%'}}>Place en accueil de jour</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formBloc2">
                                                <div className="formBloc2_2 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong>Informations supplémentaires</strong> (que vous
                                                                souhaitez
                                                                transmettre aux familles et/ou aux personnes qui informent
                                                                les
                                                                familles)</p><p></p>
                                                        </div>
                                                        <div className="inputBudget">
                                                    <textarea id="appbundle_annonce_descriptif"
                                                              name="descriptif" onChange={this.handleChange}
                                                              value={annonce.descriptif}
                                                              placeholder="Exemple&nbsp;: «&nbsp;La résidence se situe dans une rue calme proche du centre-ville. Nous avons un grand jardin. La chambre est au 2 ème étage, elle est meublée mais personnalisable selon vos choix (sauf le lit). N’hésitez pas à nous appeler pour venir visiter (visite possible le week-end)&nbsp;»."
                                                              maxLength="400">
                                                    </textarea>
                                                        </div>
                                                        <div id="counter">
                                                            {annonce.descriptif.length} / 400
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bouton submit">
                                                    <button type="submit" id="appbundle_annonce_previus"
                                                            name="appbundle_annonce[previus]"
                                                            label="Prévisualiser"> Je visualise… </button>
                                                </div>
                                                <div className="bouton modifier">
                                                    <button type="button" id="appbundle_annonce_cancel"
                                                            name="appbundle_cancel[update]"
                                                            onClick={() => history.go(-1)}
                                                            label="Annuler">Annuler
                                                    </button>
                                                </div>
                                                <input type="hidden" id="appbundle_annonce_equipements"
                                                       name="appbundle_annonce[equipements]" value="vide"/>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </section>
                        </main>
                        :
                        <main>
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/groupes">Mon compte</a> &gt; Je visualise mon annonce</p>
                                    <h1>Je visualise <span>mon annonce</span></h1>

                                    <p className="titleForm"></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">{annonce.nbPLace} place(s) disponible(s) à {etablissement.ville} {etablissement.codePostal}</p>
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-sexe"/>
                                                    { this._renderTime(annonce.datePlace) }
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-sexe"/>
                                                    Niveau de budget mensuel de {annonce.prix}
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-type-etablissement.png"
                                                         alt="icone-sexe"/>
                                                    {etablissement.nom_etablissement}
                                                </div>
                                                {
                                                    annonce.specificite.length > 0 ?
                                                        <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                            <i className="fa fa-check-square-o " aria-hidden="true" > </i>
                                                            Spécificités : <br />
                                                            {'- ' + annonce.specificite[0]}<br/>
                                                            {annonce.specificite[1] ? '- ' + annonce.specificite[1] : null}<br/>
                                                            {annonce.specificite[2] ? '- ' + annonce.specificite[2] : null}<br/>
                                                            {annonce.specificite[3] ? '- ' + annonce.specificite[3] : null}
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-sexe"/>
                                                    {etablissement.rue} {etablissement.ville}
                                                </div>

                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                                    {etablissement.telephone_etablissement}
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <i className="fa fa-at" aria-hidden="true"> </i>
                                                    {etablissement.email_etablissement}
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <i className="fa fa-internet-explorer" aria-hidden="true"> </i>
                                                    <a href={etablissement.site_etablissement} target="_blank">{etablissement.site_etablissement}</a>
                                                </div>
                                                <div className="visualisationInfo description col-xs-12">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-commentaires.png"
                                                         alt="icone-commentaires"/>
                                                    <p>Description :
                                                    </p><p>{annonce.descriptif}</p><p></p>
                                                </div>
                                            </div>
                                        </div>
                                        <form name="appbundle_annonce" className="form-horizontal">
                                            <div
                                                className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <input type="checkbox" name="CGU" value={CGU} required="required" onChange={this.handleChangeCGU}/>
                                                <label>j’accepte les <a href="/conditions-generales"
                                                                        target="_blank">conditions générales
                                                    d’utilisation.</a></label>
                                            </div>
                                            {
                                                alert.message ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                    :
                                                    <div className="boutons col-xs-12">
                                                        <div className="row">
                                                            <div className="bouton modifier" style={{float: "left"}}>
                                                                <button type="button" id="appbundle_annonce_cancel_bis"
                                                                        name="appbundle_cancel_bis[update]"
                                                                        onClick={() => history.go(-1)}
                                                                        label="Annuler">Annuler
                                                                </button>
                                                            </div>
                                                            <div className="bouton modifier">
                                                                <button type="submit" id="appbundle_annonce_update"
                                                                        name="appbundle_annonce[update]"
                                                                        onClick={this.handlePreview}
                                                                        label="Modifier ma demande">Modifier
                                                                </button>
                                                            </div>
                                                            <div className="bouton">
                                                                <button type="submit" id="appbundle_annonce_save"
                                                                        name="appbundle_annonce[save]"
                                                                        onClick={this.handleSubmit}
                                                                        label="Enregistrer">Valider
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </section>

                        </main>
                }
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, alert, location} = state;
    const {user} = authentication;
    const {infos, list} = state.etablissement;

    return {
        user,
        list,
        infos,
        alert,
        location
    }
}

export default withRouter(connect(mapStateToProps)(Ajouter_Annonce))
