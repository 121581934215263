import React, {Component}   from "react";
import {connect}            from "react-redux";
import moment               from 'moment';
//import Autocomplete         from "react-google-autocomplete";
import { Confirm, Alert } from "react-st-modal";
import {ReCaptcha}          from "react-recaptcha-google";
import Autosuggest          from 'react-autosuggest';
import ScrollUpButton       from "react-scroll-up-button";

import {dispoActions, etablissementActions} from "../../../../_actions";

import Navbar               from "../../../../_components/Navbar";
import Footer               from "../../../../_components/Footer"
import lib                  from "../../../../lib/lib";
import projectList          from "../../../../communes/communes.json";
import {withRouter} from "react-router";
import 'icheck/skins/all.css'; // or single skin css
import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);



class Alerte_Dispo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            alert: this.props.alert ? this.props.alert : false,
            filter: {
                email: '',
                codepostal: '',
                ville: '',
                lat: '',
                lng: '',
                rayon: '',
                nom: '',
                Type: ["1", "2", "3", "4"],
            },
            deleted: [],
            modif: false,
            choix_email: false,
            choix_condition: false,
            recaptcha: 'l',
            single: {
                etablissement: [],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '10',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
                code_insee: '',
            },
            screenWidth: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleModif = this.handleModif.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);

        this.handleChangeType = this.handleChangeType.bind(this);
        this.modifInput = this.modifInput.bind(this);

        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.distanceInKmBetweenEarthCoordinates = this.distanceInKmBetweenEarthCoordinates.bind(this)

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChoix = this.handleChoix.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(dispoActions.getDispo(this.props.history));
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            recaptcha: recaptchaToken
        });
    }

    changeInput(name, value, type) {
        const {filter} = this.state;
        const {Type} = filter;

        if (value) {
            this.setState({
                filter: {
                    ...filter,
                    Type: Type.filter(item => item !== type)
                },
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    Type: [...Type, type]
                },
            })
        }
    }

    clickCheckBox(e) {
        const { name, value, type, checked } = e.target;
        const { filter } = this.state;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        Type: [...filter.Type, value]
                    },
                    [name]: true
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        Type: filter.Type.filter(function (item) {
                            return item !== value
                        })
                    },
                    [name]: false
                })
            }
        }
    }

    handleChangeFilter(event) {
        event.preventDefault();
        const { name, value } = event.target;
        const { filter } = this.state;

        if (!filter.codepostal && !filter.ville && name === 'rayon') {
            Alert("Vous devez entrer un code postal ou une ville")
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: lib.protectEntry(value)
                }
            })
        }
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {single} = this.state;

        this.setState({
            single: {
                ...single,
                [name]: lib.protectEntry(value)
            }
        })
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;

        let response = await Confirm("Voulez-vous vraiment supprimer cette alerte?", "", "Oui", "Fermer");

        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            this.props.dispatch(dispoActions.delDispo(id))
        }
        window.scrollTo(0, 0)
    }

    modifInput(value, type) {
        const {single} = this.state;

        if (!value) {
            this.setState({
                single: {
                    ...single,
                    etablissement: [...single.etablissement, parseInt(type)]
                }
            })
        } else {
            this.setState({
                single: {
                    ...single,
                    etablissement: single.etablissement.filter(function (item) {
                        return item !== parseInt(type);
                    })
                }
            })
        }
    }

    handleChangeType(event) {
        const {value, checked} = event.target;
        const {single} = this.state;

        if (!checked) {
            this.setState({
                single: {
                    ...single,
                    etablissement: single.etablissement.filter(function (item) {
                        return item !== parseInt(value);
                    })
                }
            })
        } else {
            this.setState({
                single: {
                    ...single,
                    etablissement: [...single.etablissement, parseInt(value)]
                }
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            filter: {
                ...this.state.filter,
                codepostal: '',
                ville: '',
                lat: '',
                lng: '',
                rayon: ''
            },
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {filter, single, modif} = this.state;
        if (modif) {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                single: {
                    ...single,
                    codepostal: suggestion.value.codePostal,
                    code_insee: suggestion.value.code_insee,
                    ville: suggestion.value.nom,
                    latitude: suggestion.value.geoLoc.coordinates[1],
                    longitude: suggestion.value.geoLoc.coordinates[0],
                }
            });
        } else {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                filter: {
                    ...filter,
                    codepostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                    lat: suggestion.value.geoLoc.coordinates[1],
                    lng: suggestion.value.geoLoc.coordinates[0],
                    rayon: '5'
                }
            })
        }
    };

    handleChoix(e) {
        const {name} = e.target;
        const {choix_email, choix_condition} = this.state;

        if (name === 'choix_email') {
            this.setState({
                [name]: !choix_email
            })
        } else {
            this.setState({
                [name]: !choix_condition
            })
        }

    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { filter } = this.state;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    renderFilter(item) {
        const {deleted, filter} = this.state;
        const {types} = this.props.dispo.alert;

        let anonyme = "anonyme";

        let t_final = [];
        types.filter(function (e) {
            return (parseInt(e.alerte_dispo_id) === parseInt(item.id))
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );

        const email     = filter.email  !== '' ? item.email.toLowerCase().includes(filter.email.toLowerCase()) : true;
        //const ville     = filter.ville  !== '' ? filter.ville.toLowerCase().indexOf(item.ville.toLowerCase()) >= 0 : filter.codepostal  !== '' ? filter.codepostal.indexOf(item.codepostal)  >= 0 :  true;
        const ville     = filter.ville  !== '' ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon ? filter.rayon : '5', 10)) : true;
        const rayon     = filter.rayon  !== '' ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon, 10)) : true;
        const nom       = filter.nom    !== '' ? item.nom ? item.nom.toLowerCase().includes(filter.nom.toLocaleLowerCase()) : anonyme.includes(filter.nom.toLowerCase()) : true;
        const notDel    = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;
        const type      = t_final.filter(function(value) { return filter.Type.includes(String(value))});

        return (email && ville && rayon && notDel && nom && type.length > 0)
    }

    supprimerFiltre() {
        this.props.dispatch(etablissementActions.getActiveDemandes());
        const {filter} = this.state;
        this.setState({
            filter: {
                ...filter,
                codepostal: '',
                ville: '',
                lat: '',
                lng: '',
                rayon: '',
                Type: ["1", "2", "3", "4"]
            },
            value: ''
        })
    }

    handleModif(e) {
        e.preventDefault();
        const {modif, single} = this.state;

        const array = this.props.dispo.alert.dispo.filter(item => parseInt(item.id) === parseInt(e.target.id));
        let t_final = [];
        this.props.dispo.alert.types.filter(function(t) {
            return (parseInt(t.alerte_dispo_id) === parseInt(e.target.id))
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );
        this.setState({
            modif: !modif,
            single: {
                ...single,
                id: array[0].id,
                personnes_id: array[0].personnes_id,
                codepostal: array[0].codepostal,
                rayon: array[0].rayon,
                nom: array[0].nom,
                entite: array[0].entite,
                email: array[0].email,
                telephone: array[0].telephone,
                latitude: array[0].latitude,
                longitude: array[0].longitude,
                ville: array[0].ville,
                etablissement: t_final
            },
            value: array[0].ville + " " + array[0].codepostal
        });
        window.scrollTo(0, 0)
    }

    handleGoBack(e) {
        e.preventDefault();

        const {single} = this.state;

        this.setState({
            modif: false,
            single: {
                ...single,
                etablissement: [],
                personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                codepostal: '',
                rayon: '10',
                nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                entite: '',
                email: localStorage.getItem('user') ? this.props.user.user.email : '',
                telephone: '',
                latitude: '',
                longitude: '',
                ville: '',
                code_insee: '',
            },
            choix_email: false,
            choix_condition: false,
            value: ''
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const {choix_email, choix_condition, recaptcha, single} = this.state;

        if (!choix_email || lib.isValidEmail(single.email) === false) {
            Alert("Nous avons besoin d'un email valid ainsi que de votre consentement pour pouvoir vous alerter des disponibiltés")
        } else if (!choix_condition) {
            Alert("Veuillez lire et accepter les conditions générales d'utilisation")
        } else if (single.etablissement.length <= 0) {
            Alert("Veuillez choisir, au moins, un type d'établissement")
        } else if (recaptcha.length <= 0){
            Alert("veuillez confirmer que vous n'êtes pas un robot")
        } else {
            this.props.dispatch(dispoActions.updateDispo(single, this.props.history));
            //this.props.dispatch(dispoActions.getDispo());
            this.setState({
                modif: false,
                single: {
                    ...single,
                    etablissement: [],
                    personnes_id: localStorage.getItem('user') ? this.props.user.user.id : 0,
                    codepostal: '',
                    rayon: '10',
                    nom: localStorage.getItem('user') ? this.props.user.user.username : '',
                    entite: '',
                    email: localStorage.getItem('user') ? this.props.user.user.email : '',
                    telephone: '',
                    latitude: '',
                    longitude: '',
                    ville: '',
                    code_insee: '',
                },
                choix_email: false,
                choix_condition: false,
                value: ''
            });
        }
    }

    render() {
        const { filter, modif, single, choix_email, choix_condition, value, suggestions, screenWidth } = this.state;
        const {alert, dispo} = this.props;
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        let dispSorted = dispo.alert ? filter.rayon.length > 0 ? dispo.alert.dispo.filter(dispo => this.renderFilter(dispo)).sort((a, b) => this.sortDistance(a, b)) : dispo.alert.dispo.filter(dispo => this.renderFilter(dispo)) : [];

        const inputProps = {
            placeholder: 'une ville ou un code postal',
            value,
            onChange: this.onChange
        };


        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    {   !modif ?
                        <section className="listeDemandeBloc1 blocAlertesingle">
                            <div className="container">
                                <p className="chemin"><a href="/administrateur">Mon compte</a> &gt;  Alerte dispo</p>
                                <h1>Alerte <span>dispo</span></h1>

                                <form>
                                    <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                        <div className="infoSpecial">
                                            {
                                                screenWidth > 763 ?
                                                <div className="listeEtablissementTri">
                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce1.png"
                                                                 alt="icone-etablissement"
                                                                 onClick={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                 title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_1"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       fontSize: '1.3rem'
                                                                   }}>EHPAD</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{
                                                                   marginLeft: 'auto',
                                                                   marginRight: 'auto',
                                                                   transform: 'scale(1.4)',
                                                                   width: '30px'
                                                               }}
                                                               id="appbundle_alertedispo_etablissement_1"
                                                               name="type1"
                                                               value="1"
                                                               checked={filter.Type.includes("1")}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce3.png"
                                                                 alt="icone-etablissement"
                                                                 onClick={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                 title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_2"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       fontSize: '1.3rem'
                                                                   }}>USLD</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{
                                                                   marginLeft: 'auto',
                                                                   marginRight: 'auto',
                                                                   transform: 'scale(1.4)',
                                                                   width: '30px'
                                                               }}
                                                               id="appbundle_alertedispo_etablissement_2"
                                                               name="type2"
                                                               value="2"
                                                               checked={filter.Type.includes("2")}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce4.png"
                                                                 alt="icone-etablissement"
                                                                 onClick={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                 title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_3"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       fontSize: '1.3rem'
                                                                   }}>Résidence autonomie</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{
                                                                   marginLeft: 'auto',
                                                                   marginRight: 'auto',
                                                                   transform: 'scale(1.4)',
                                                                   width: '30px'
                                                               }}
                                                               id="appbundle_alertedispo_etablissement_3"
                                                               name="type3"
                                                               value="3"
                                                               checked={filter.Type.includes("3")}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce5.png"
                                                                 alt="icone-etablissement"
                                                                 onClick={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                 title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_4"
                                                                   style={{
                                                                       marginLeft: 'auto',
                                                                       marginRight: 'auto',
                                                                       fontSize: '1.3rem'
                                                                   }}>Résidence
                                                                services
                                                                seniors</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{
                                                                   marginLeft: 'auto',
                                                                   marginRight: 'auto',
                                                                   transform: 'scale(1.4)',
                                                                   width: '30px'
                                                               }}
                                                               id="appbundle_alertedispo_etablissement_4"
                                                               name="type4"
                                                               value="4"
                                                               checked={filter.Type.includes("4")}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                    <div style={{justifyContent: 'center'}}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce1.png"
                                                                         alt="EHPAD"
                                                                         onClick={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                         title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                    <Checkbox
                                                                        checkboxClass="icheckbox_flat-blue"
                                                                        increaseArea="20%"
                                                                        id="label_type1"
                                                                        name="type1"
                                                                        value="1"
                                                                        checked={filter.Type.includes("1")}
                                                                        onChange={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                    />
                                                                    <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                </div>
                                                                <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce3.png"
                                                                         alt="USLD"
                                                                         onClick={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                         title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                    <Checkbox
                                                                        checkboxClass="icheckbox_flat-blue"
                                                                        increaseArea="20%"
                                                                        id="label_type2"
                                                                        name="type2"
                                                                        value="2"
                                                                        checked={filter.Type.includes("2")}
                                                                        onChange={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                    />
                                                                    <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                </div>
                                                                <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce4.png"
                                                                         alt="Résidence autonomie"
                                                                         onClick={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                         title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                    <Checkbox
                                                                        checkboxClass="icheckbox_flat-blue"
                                                                        increaseArea="20%"
                                                                        id="label_type3"
                                                                        name="type3"
                                                                        value="3"
                                                                        checked={filter.Type.includes("3")}
                                                                        onChange={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                    />
                                                                    <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                </div>
                                                                <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce5.png"
                                                                         alt="Résidence services seniors"
                                                                         onClick={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                         title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                    <Checkbox
                                                                        checkboxClass="icheckbox_flat-blue"
                                                                        increaseArea="20%"
                                                                        id="label_type4"
                                                                        name="type4"
                                                                        value="4"
                                                                        checked={filter.Type.includes("4")}
                                                                        onChange={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                    />
                                                                    <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                            }
                                            <div className="secondFiltre">
                                                <div className="villeInput triVille" style={{position: "relative", width: "100%", margin: "0 auto"}}>
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        onSuggestionSelected={this.onSuggestionSelected}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        highlightFirstSuggestion={true}
                                                    />
                                                </div>

                                                <div className="listeKm" style={{marginRight: "1rem"}}>
                                                    <button className={filter.rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                        5km
                                                    </button>
                                                    <button className={filter.rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                        15km
                                                    </button>
                                                    <button className={filter.rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                        30km
                                                    </button>
                                                    <button className={filter.rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                        50km
                                                    </button>
                                                    <button className={filter.rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                        100km
                                                    </button>
                                                </div>

                                                <div className="bouton submit subConf">
                                                    <input type="reset" name="supprimer"
                                                           value="Supprimer les Filtres"
                                                           style={{marginTop: "10px"}}
                                                           onClick={this.supprimerFiltre}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <select id="etablissement" className="hide" onSelect={this.handleChange}>
                                            <option value="">Type d'établissement</option>
                                            <option value="1">EHPAD</option>
                                            <option value="2">USLD</option>
                                            <option value="3">Résidence autonomie</option>
                                            <option value="4">Résidence services seniors</option>
                                        </select>

                                    </div>
                                </form>

                                <div className="filtres col-xs-12">
                                    <p>Filtrer par</p>
                                    <input type="text" name="nom" value={filter.nom} id="nom" placeholder="Nom"
                                           onChange={this.handleChangeFilter}/>
                                    <input type="text" name="email" value={filter.email}
                                           onChange={this.handleChangeFilter} id="email" placeholder="E-mail"/>

                                    <hr/>
                                </div>
                                <h2><span>Nombre d'Alerte-Dispo:</span> {dispSorted.length}</h2>
                                <br/>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        null
                                }
                                <br/>
                                {
                                    !dispo.alert ?

                                        <div className="container">
                                            <hr/>
                                            <h1><span>En attente</span> d'Alerte <span>dispo</span></h1>
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                        </div>

                                        :

                                        <div id="listeDemande">
                                            {dispSorted.map((item, index) =>
                                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                <div className="singleDemande" >
                                                    <div className="col-xs-16">
                                                        <div className="question">
                                                            <p>Alerte-Dispo  {item.nom ? "de "+item.nom : "ANONYME"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-echeance.png"
                                                             alt="icone-sexe"/>
                                                        <p>
                                                            Publiée le <strong> {moment(item.created_at).format('DD/MM/YYYY')} </strong>
                                                        </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-secteur.png"
                                                             alt="icone-sexe"/>
                                                        <p>À <strong> {item.ville} </strong> ({item.codepostal}) dans un
                                                            rayon de <strong>{item.rayon}km</strong></p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-type-etablissement.png"
                                                             lt="icone-type-etablissement"/><p>Type d'établissement : <br/>
                                                        <strong>
                                                            {
                                                                dispo.alert.types.map((a, i) => {
                                                                    if (a.alerte_dispo_id === item.id) {
                                                                        return (
                                                                            <strong
                                                                                key={i}>
                                                                                {types[a.type_etablissement_id - 1]}
                                                                                <br/>
                                                                            </strong>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </strong>
                                                    </p>
                                                    </div>
                                                    <div className="visualisationInfo col-xs-12">
                                                        <i className="fa fa-at" aria-hidden="true"> </i>
                                                        <p>Email : <strong>{item.email}</strong>
                                                        </p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.handleModif} id={item.id}>Modifier</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                }
                            </div>
                        </section>
                        :
                        <section className="alerteDispoBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/">Accueil</a> &gt; <a href="/administrateur/alerte-dispo">Alerte dispo</a> &gt; Modifier "Alerte-dispo"</p>
                                <h1>Je crée mon <span>"Alerte-dispo"</span></h1>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <strong>Pour être informé par email dès qu’une nouvelle place est disponible sur
                                        votre
                                        zone
                                        de recherche </strong>
                                </div>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="encadreForm">

                                        <form name="appbundle_alertedispo" className="form-horizontal">

                                            <div className="formBloc1">
                                                <div className="question">
                                                    <p>Pour quel type d’établissement souhaitez-vous recevoir une «
                                                        ALERTE-DISPO
                                                        » ?</p>
                                                    <p>Sélection multiple possible</p>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3" >
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.modifInput(single.etablissement.includes(1), "1")}
                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                        <label htmlFor="etablissement_1" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>EHPAD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="etablissement_1"
                                                           name="etablissement"
                                                           value="1"
                                                           checked={single.etablissement.includes(1)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.modifInput(single.etablissement.includes(2), "2")}
                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                        <label htmlFor="etablissement_2" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>USLD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="etablissement_2"
                                                           name="etablissement"
                                                           value="2"
                                                           checked={single.etablissement.includes(2)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '50%'}}
                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.modifInput(single.etablissement.includes(3), "3")}
                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                        <label htmlFor="etablissement_3" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence autonomie</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="etablissement_3"
                                                           name="etablissement"
                                                           value="3"
                                                           checked={single.etablissement.includes(3)}
                                                           onChange={this.handleChangeType}/>
                                                </div>

                                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive" style={{width: '46%'}}
                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.modifInput(single.etablissement.includes(4), "4")}
                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                        <label htmlFor="etablissement_4" style={{marginLeft: 'auto', marginRight: 'auto', fontSize: '1.4rem'}}>Résidence
                                                            services
                                                            seniors</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{marginLeft: '45%', marginRight: '50%', transform: 'scale(1.5)'}}
                                                           id="etablissement_4"
                                                           name="etablissement"
                                                           value="4"
                                                           checked={single.etablissement.includes(4)}
                                                           onChange={this.handleChangeType}/>
                                                </div>
                                            </div>

                                            <div className="formBloc2">
                                                <div className="question">
                                                    <p>Dans quel secteur géographique recherchez-vous ?</p>
                                                </div>
                                                <div className="inputSecteur">
                                                    <div className="inputVille">
                                                        <label>Saisissez une ville ou un code postal</label>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>
                                                    <div className="selectRayon">
                                                        <label>Dans un rayon de</label>
                                                        <select id="appbundle_alertedispo_rayon"
                                                                name="rayon"
                                                                value={single.rayon}
                                                                onChange={this.handleChange}>
                                                            <option value="5">5 km</option>
                                                            <option value="15">15 km</option>
                                                            <option value="30">30 km</option>
                                                            <option value="50">50 km</option>
                                                            <option value="100">100 km</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formBloc1">
                                                <div className="inputInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="row">
                                                        <label>Votre email <span>*</span></label>
                                                        <input type="text" id="appbundle_alertedispo_email"
                                                               name="email" required="required" value={single.email}
                                                               maxLength="255" onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input id="email-check" type="checkbox" name="choix_email" value="1" onChange={this.handleChoix} required=""/>
                                                    <label htmlFor="email-check"><strong>J’accepte de recevoir par email une « Alerte-dispo » dès
                                                        qu’une place est publiée, selon mes critères de recherche
                                                        ci-dessus.</strong></label>
                                                </div>
                                                <br/>
                                            </div>
                                            <br/><br/>
                                            <p>Les informations ci-dessus seront utilisées que par l’administrateur du
                                                site
                                                pour vous envoyer les places disponibles et ne seront pas visibles sur
                                                le
                                                site. </p><br/>

                                            <div className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <input type="checkbox" name="choix_condition" value={choix_condition}
                                                       onChange={this.handleChoix} required=""/>
                                                <label><strong>j’accepte les</strong> <a href="/conditions-generales">conditions
                                                    générales d’utilisation.</a></label>
                                            </div>
                                            <br/>
                                            <div className="g-recaptcha" style={{display: "table", margin: "0 auto"}}>
                                                {!modif ?
                                                    <div style={{width: "304px", height: "78px"}}>
                                                        <ReCaptcha
                                                            ref={(el) => {
                                                                this.captchaDemo = el;
                                                            }}
                                                            size="normal"
                                                            data-theme="dark"
                                                            render="explicit"
                                                            sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"
                                                            onloadCallback={this.onLoadRecaptcha}
                                                            verifyCallback={this.verifyCallback}
                                                        />
                                                        <textarea id="g-recaptcha-response" name="g-recaptcha-response"
                                                                  className="g-recaptcha-response"
                                                                  style={{
                                                                      width: "250px",
                                                                      height: "40px",
                                                                      border: "1px solid rgb(193, 193, 193)",
                                                                      margin: "10px 25px",
                                                                      padding: "0px",
                                                                      resize: "none",
                                                                      display: "none"
                                                                  }}>
                                                        </textarea>
                                                    </div>

                                                    :
                                                    null
                                                }
                                            </div>
                                            <br/>
                                            {
                                                alert.message ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                    :
                                                    <div className="bouton submit">
                                                        <p className="text-danger hide error">Vous devez choisir un
                                                            etablissement</p>
                                                        <button type="submit" id="appbundle_alertedispo_save"
                                                                name="save" onClick={this.handleSubmit}>Modifier
                                                        </button>
                                                        <br/>
                                                        <button type="submit" id="appbundle_alertedispo_save"
                                                                name="save" onClick={this.handleGoBack}>Annuler
                                                        </button>

                                                    </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <img className="img-responsive image-alert"
                                         src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                                </div>
                            </div>
                        </section>
                    }
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {dispo, alert, authentication} = state;
    const {user} = authentication;
    return {
        dispo,
        alert,
        user

    }
}

export default withRouter(connect(mapStateToProps)(Alerte_Dispo))
