import {dispoConstants, infoConstants} from '../_constants';
import {infoService} from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const infoActions = {
    createInfo,
    updateInfo,
    getInfos,
    delInfo,
};

/********************/
/* Create Info Dispo*/
/********************/

function createInfo(user, listType) {

    return dispatch => {
        dispatch(request());

        infoService.createInfo(user, listType)
            .then(
                result => {
                    dispatch(success());
                    history.push('/info-dispo');
                    dispatch(alertActions.success('Vous êtes abonné(é) à une info dispo'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: infoConstants.CREATE_REQUEST } }
    function success() { return { type: infoConstants.CREATE_SUCCESS } }
    function failure(error) { return { type: infoConstants.CREATE_FAILURE, error } }
}

/**************************/
/*   Update Info Dispo    */
/**************************/

function updateInfo(info) {
    return dispatch => {

        infoService.updateInfoDispo(info)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
//                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

//    function request() { return { type: dispoConstants.DISPO_REQUEST } }
//    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
//    function failure(error) { return { type: InfoConstants.DELETE_FAILURE, error } }
}

/*************************/
/*   Delete Info Dispo   */
/*************************/

function delInfo(id) {
    return dispatch => {

        infoService.delInfoDispo(id)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

//    function request() { return { type: dispoConstants.DISPO_REQUEST } }
//    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DELETE_FAILURE, error } }
}

/**********************/
/* Get All Info Dispo */
/**********************/

function getInfos() {

    return dispatch => {
        dispatch(request());

        infoService.getInfos()
            .then(
                result => {
                    dispatch(success(result));
                    //history.push('/info-dispo');
                    //dispatch(alertActions.success('Vous êtes abonné(é) à une info dispo'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: infoConstants.LIST_REQUEST } }
    function success(list) { return { type: infoConstants.LIST_SUCCESS, list } }
    function failure(error) { return { type: infoConstants.LIST_FAILURE, error } }
}