import React, {Component} from 'react';
import {connect} from "react-redux";

import {etablissementActions, userActions} from "../../../../_actions";
import {alertActions} from "../../../../_actions";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {Confirm, Alert} from "react-st-modal";

class Mes_Photos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            infos: this.props.infos,
            etablissement: {
                image_un: "",
                image_deux: "",
                image_trois: "",
                image_quatre: "",
            },
            submitted: false,
            pictures: [],
            btn3: true,
            imagePreviewUrl: [],
            image_un: '',
            image_deux: '',
            image_trois: '',
            image_quatre: '',
            condition: true,
            display: "none",
            alert2: false,
            message: ""
        };

        this.handleDelete   = this.handleDelete.bind(this);
        this.deleteImage    = this.deleteImage.bind(this);

        this.selectImages = this.selectImages.bind(this);
        this.uploadImages = this.uploadImages.bind(this);

    }

    componentDidMount(prevProps, prevState) {
        this.props.dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos !== this.state.infos) {
            let infos = this.props.infos;
            if (!infos) {
                console.log("null")
            } else {
                this.setState({
                    infos: this.props.infos,
                    etablissement: {
                        ...this.state.etablissement,
                        image_un: (this.props.infos && this.props.infos.etablissement.image_un ? this.props.infos.etablissement.image_un : ""),
                        image_deux: (this.props.infos && this.props.infos.etablissement.image_deux ? this.props.infos.etablissement.image_deux : ""),
                        image_trois: (this.props.infos && this.props.infos.etablissement.image_trois ? this.props.infos.etablissement.image_trois : ""),
                        image_quatre: (this.props.infos && this.props.infos.etablissement.image_quatre ? this.props.infos.etablissement.image_quatre : "")
                    }
                });
            }



        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.infos !== prevState.infos) {

            return {infos: nextProps.infos};
        } else return null;
    }

    selectImages(event) {
        const {dispatch} = this.props;
        const {name} = event.target;
        const {etablissement, user, pictures} = this.state;

        dispatch(alertActions.clear());

        let file = event.target.files[0];

        if ( file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/) && file.size < 2000000) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    pictures: [...pictures, {'file': file, 'id': user.etablissement_id, 'pic': name}],
                    etablissement: {
                        ...etablissement,
                        [name]: reader.result
                    },
                    [name]: name,
                    display: "none",
                    btn3: false
                });
            };
            reader.readAsDataURL(file)
        } else if (file.size > 2000000) {
            this.setState({
                message: "Cette photo fait plus de 2MB",
                display: ""
            })
        } else {
            this.setState({
                message: "Format accepté: .jpg, .jpeg, .png ou .gif ",
                display: ""
            })
        }
    }

    uploadImages(e) {
        e.preventDefault();

        const {condition, user, etablissement} = this.state;
        const {dispatch} = this.props;

        this.setState({alert2: true});
        let i = 0;

        this.state.pictures.map(image => {
            i = i + 1;
            const data = new FormData();
            data.append("image", image.file, image.file.name);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + user.confirmation_token,
                    'id': image.id,
                    'pic': image.pic
                },
                body: data
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/upload`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    if (result.success) {
                        this.setState({
                            condition: condition && result.success,
                            alert2: true,
                            etablissement: {
                                ...etablissement,
                                [result.position]: result.filename
                            },
                            [result.position]: false
                        });

                    }
                    if (condition && i === this.state.pictures.length) {
                        //this.setState({[image.pic]: ""});
                        dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
                        dispatch(alertActions.success('Image(s) téléchargée(s) avec succès'));
                    }
                });
        });
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    deleteImage(name, position) {
        const {etablissement, user} = this.state;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization' : 'Bearer ' + user.confirmation_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: user.etablissement_id,
                name,
                position
            })
        };

        return fetch(`https://backend.placemaisonderetraite.fr/etablissements/deleteImage`, requestOptions)
            .then(this.handleResponse)
            .then(result => {
                if (result.success) {
                    this.setState({
                        etablissement : {
                            ...etablissement,
                            [position]: ""
                        },
                        [position]: "",
                        btn3: true,
                    });
                }
                this.props.dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
                this.props.dispatch(alertActions.success(result.message));
            })
            .catch((error) => {
                Alert("une erreur est survenue, merci de réessayer")
            });

    }

    async handleDelete(e) {
        e.preventDefault();

        this.props.dispatch(alertActions.clear());
        const { id } = e.target;

        const {etablissement} = this.state;
        let response = await Confirm("Voulez-vous vraiment supprimer cette image?", "", "Oui", "Fermer");
        if (response === true) {
            switch (id) {
                case "1":
                    this.deleteImage(etablissement.image_un, "image_un");
                    break;
                case "2":
                    this.deleteImage(etablissement.image_deux, "image_deux");
                    break;
                case "3":
                    this.deleteImage(etablissement.image_trois, "image_trois");
                    break;
                case "4":
                    this.deleteImage(etablissement.image_quatre, "image_quatre");
                    break;
                default:
                    console.log("default");
            }
        }
    }

    render() {
        const {etablissement, alert2} = this.state;
        const {alert} = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !(this.props.infos) ? <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                        <main>
                            <section className="inscriptionStep1">
                                <div className="container">

                                    <p className="chemin"><a href="/etablissements">Mon compte</a> &gt; Mes Photos</p>
                                    <h1>Mes Photos/Images</h1>

                                    <div className="titleForm">
                                        <div id="formEtablisssement" name="form1"  >Selectionnez vos Photos</div>
                                    </div>

                                    <form name="appbundle_etablissement" onSubmit={this.uploadImages} encType="multipart/form-data" >
                                        <div className="encadreForm col-xs-12">
                                            <div style={{textAlign: "center"}}>
                                                <label>Images (maximum 2MB par photo)</label>
                                                <div id="errorSize" style={{
                                                    borderRadius: "5px",
                                                    border: "2px solid #D55",
                                                    width: "60%",
                                                    display: this.state.display,
                                                    margin: "10px 20%",
                                                    backgroundColor: "#FCC",
                                                }}>{this.state.message}
                                                </div>
                                            </div>
                                            <br/><br/>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate" id="imageContainer">
                                                {
                                                    (etablissement.image_un) ?
                                                        <div className="row col-lg-12">
                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_un ? etablissement.image_un : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_un}
                                                                     className="img-rounded img-responsive"
                                                                     alt="not available"/>
                                                                <br/>
                                                                {
                                                                    etablissement.image_un.length > 255 ? null :
                                                                        <button type="button"
                                                                                className="btn btn-danger btn-xs" id={1}
                                                                                onClick={this.handleDelete}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <input type="file" onChange={this.selectImages} name="image_un" accept="image/*"/>
                                                }
                                                <br/>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                {
                                                    (etablissement.image_deux) ?
                                                        <div className="row col-lg-12">

                                                            <div className="col-lg-12">
                                                                <img
                                                                    src={this.state.image_deux ? etablissement.image_deux : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_deux}
                                                                    className="img-rounded img-responsive"
                                                                    alt="not available"/>
                                                                <br/>
                                                                {
                                                                    etablissement.image_deux.length > 255 ? null :
                                                                        <button type="button"
                                                                                className="btn btn-danger btn-xs" id={2}
                                                                                onClick={this.handleDelete}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <input type="file" onChange={this.selectImages} name="image_deux" accept="image/*"/>
                                                }
                                                <br/>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">

                                                {
                                                    (etablissement.image_trois) ?
                                                        <div className="row col-lg-12">

                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_trois ? etablissement.image_trois : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_trois}
                                                                     className="img-rounded img-responsive"
                                                                     alt="not available"/>
                                                                <br/>
                                                                {
                                                                    etablissement.image_trois.length > 255 ? null :
                                                                        <button type="button"
                                                                                className="btn btn-danger btn-xs" id={3}
                                                                                onClick={this.handleDelete}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <input type="file" onChange={this.selectImages} name="image_trois" accept="image/*"/>
                                                }
                                                <br/>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">

                                                {
                                                    (etablissement.image_quatre) ?
                                                        <div className = "row col-lg-12" >
                                                            < div className="col-lg-12">
                                                                <img src={this.state.image_quatre ? etablissement.image_quatre : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_quatre}
                                                                     className="img-rounded img-responsive"
                                                                     alt="not available"/>
                                                                <br/>
                                                                {
                                                                    etablissement.image_quatre.length > 255 ? null :
                                                                        <button type="button"
                                                                                className="btn btn-danger btn-xs" id={4}
                                                                                onClick={this.handleDelete}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <input type="file" onChange={this.selectImages} name="image_quatre" accept="image/*"/>
                                                }
                                                <br/>
                                            </div>
                                            <div className="bouton submit col-xs-12">
                                                <input type="submit" id="valider3" name="envoyer"
                                                       value="Enregistrer Mes Image(s)"
                                                       disabled={this.state.btn3}/>
                                                {
                                                    alert.message && alert2 === true ?
                                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                            : null
                                                }
                                                < div className="error text-danger"> </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </section>
                        </main>
                }
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {authentication, etablissement} = state;
    const {user} = authentication;
    const {infos} = etablissement;
    const {alert} = state;

    return {
        user,
        infos,
        alert
    }
}

export default connect(mapStateToProps)(Mes_Photos)
