import { etablissementConstants } from '../_constants';

const InitialState = {
    gettingEtabl: false,
    gettingdemande: false,
    error: '',
    latest: {},
    infos: {},
    list: {},
    annonces: {},
    activated: {},
    unactivated: {},
    demandes: {}

};

export function etablissement(state = InitialState, action) {
    switch (action.type) {
        case etablissementConstants.LATEST_SUCCESS:
            return {
                ...state,
                latest: action.latest
            }; break;
        case etablissementConstants.LATEST_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.INFOS_REQUEST:
            return {
                ...state,
                gettingEtabl: true
            }; break;
        case etablissementConstants.INFOS_SUCCESS:
            return {
                ...state,
                infos: action.infos
            }; break;
        case etablissementConstants.INFOS_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.LIST_REQUEST:
            return {
                ...state,
                gettingEtabl: true
            }; break;
        case etablissementConstants.LIST_SUCCESS:
            return {
                ...state,
                list: action.list
            }; break;
        case etablissementConstants.LIST_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.ANNONCES_REQUEST:
            return {
                ...state,
                gettingAnnonces: true
            }; break;
        case etablissementConstants.ANNONCES_SUCCESS:
            return {
                ...state,
                annonces: action.annonces
            }; break;
        case etablissementConstants.ANNONCES_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.ACTIVATED_REQUEST:
            return {
                ...state,
                gettingEtabl: true
            }; break;
        case etablissementConstants.ACTIVATED_SUCCESS:
            return {
                ...state,
                activated: action.activated
            }; break;
        case etablissementConstants.ACTIVATED_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.ACTIVATED_COUNT_REQUEST:
            return {
                ...state,
                gettingCount: true
            }; break;
        case etablissementConstants.ACTIVATED_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count
            }; break;
        case etablissementConstants.ACTIVATED_COUNT_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.UNACTIVATED_REQUEST:
            return {
                ...state,
                gettingEtabl: true
            }; break;
        case etablissementConstants.UNACTIVATED_SUCCESS:
            return {
                ...state,
                unactivated: action.unactivated
            }; break;
        case etablissementConstants.UNACTIVATED_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        case etablissementConstants.DEMANDES_REQUEST:
            return {
                ...state,
                gettingdemande: true
            }; break;
        case etablissementConstants.DEMANDES_SUCCESS:
            return {
                ...state,
                demandes: action.demandes
            }; break;
        case etablissementConstants.DEMANDES_FAILURE:
            return {
                ...state,
                error: action.error
            }; break;
        default:
            return state
    }
}
