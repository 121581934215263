import {authHeader} from '../_helpers';
import {userActions} from "../_actions";

export const userService = {
    login,
    logout,
    register,
    delUser,
    getAllPerso,
    getAllPros,
    getById,
    update,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({username, password})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://backend.placemaisonderetraite.fr/users/${id}`, requestOptions).then(handleResponse);
}

function register(user, form) {
    const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                user: user,
            })
        };



    switch (form) {
        case 1 :
            return fetch(`https://backend.placemaisonderetraite.fr/users/create_person`, requestOptions)
                .then(handleResponse)
                .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    return user;
                });
            break;
        case 2 :
            return fetch(`https://backend.placemaisonderetraite.fr/users/create_etablissement`, requestOptions)
                .then(handleResponse)
                .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    return user;
                });
            break;
        case 3 :
            return fetch(`https://backend.placemaisonderetraite.fr/users/create_person`, requestOptions)
                .then(handleResponse)
                .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    return user;
                });
            break;
        default:
            console.log('Sorry, we are out of ' + form + '.');
    }
}

function update(user, type) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    switch (type) {
        case "personne" :
            return fetch(`https://backend.placemaisonderetraite.fr/users/update/perso`, requestOptions)
                .then(handleResponse)
                .then(rez => {
                    // store updated user details and jwt token in local storage to keep user logged in between page refreshes
                    if (JSON.parse(localStorage.getItem('user')).user.roles !== "a:1:{i:0;s:10:\"ROLE_ADMIN\";}")
                        localStorage.setItem('user', JSON.stringify(rez));

                    return rez;
                });
            break;
        case "etablissement" :
            return true;
            break;
        default :
            return false
    }
}

function delUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/delUser`, requestOptions).then(handleResponse)
}

function getAllPros() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllPros`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function getAllPerso() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getAllPerso`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

