import { authHeader } from '../_helpers';
import {userActions} from "../_actions";


export const contactService = {
    getMessages,
    postMessage,
    delMessage
};

function getMessages() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/getMessages`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list;
        });

};

function postMessage(nom, prenom, telephone, entite, email, message) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            nom,
            prenom,
            telephone,
            entite,
            email,
            message
        })
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/postMessage`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });

};

function delMessage(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({id})
    };

    return fetch(`https://backend.placemaisonderetraite.fr/admin/delMessage`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userActions.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
