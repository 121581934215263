import React, { Component } from 'react';
import { connect } from 'react-redux';

class Alerte_Accueil extends Component {

    constructor(props){
        super(props)


    }

    render(){
        const {alert} = this.props;
        return (
            <section className="homeBlockdispo" style={{marginTop: "2rem"}}>
                <div className="col-xs-12 infoDispo" style={{marginBottom: "5rem"}}>
                    {
                        alert.message ?
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                            :
                            null
                    }
                    <div className="col-xs-3">
                        <img className="img-responsive" style={{maxHeight: "30rem"}}
                             src="/img/alerte-dispo/alerte-dispo.png" alt="alerte-dispo"/>
                    </div>
                    <div className="col-xs-9">
                        <h2>Recevez une "Alerte-Dispo"</h2>
                        <p>Dès qu'une nouvelle place est disponible dans une maison de retraite sur votre zone de recherche, vous recevrez instantanément l'information par email.</p>
                    </div>
                    <div className="bouton col-xs-12">
                        <a href="/alerte-dispo">Je crée mon Alerte-dispo</a>
                    </div>
                </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    const {alert} = state;
    return {
        alert,
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Alerte_Accueil)
