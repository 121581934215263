import React, { Component } from 'react';
import { connect } from 'react-redux';

import projectList from '../../../communes/departements.json';
import {history} from "../../../_helpers";
import {withRouter} from "react-router";

class Recherche_Departement extends Component {

    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        const {id} = e.target;

        let dep = id;

        this.props.history.push({
            pathname: '/liste-annonce',
            state: { dep }
        })
    }

    render(){
        return (
            <section className="homeBloc2" ref={this.props.refProp}>
                <div className="container">
                    <h2>Annonces par<span> département</span> ?</h2>
                    {
                        projectList.map((item, index) =>
                            <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4" key={index}>
                                <input className="rainbow rainbow-1" id={item.num_dep} type="submit" onClick={this.handleSubmit} value={item.num_dep + " " +item.dep_name} />
                            </div>
                        )
                    }

                </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    const {alert} = state;
    return {
        alert,
        //load: state.ui.load,
    }
}

export default withRouter(connect(mapStateToProps)(Recherche_Departement))