import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {alertActions, etablissementActions, userActions} from "../../../../_actions";
import ImageGallery from "react-image-gallery";
import ScrollUpButton from "react-scroll-up-button";
import moment from "moment";
import {authHeader, history} from "../../../../_helpers";
import lib from "../../../../lib/lib";
import DatePicker, {registerLocale} from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Autosuggest from "react-autosuggest";
import ReactPaginate from "react-paginate";
import {withRouter} from "react-router";
import projectList from "../../../../communes/communes.json";
import {Col, Row} from "react-bootstrap";
import {Checkbox} from "react-icheck";
import {Confirm, Alert} from "react-st-modal";

registerLocale('fr', fr);

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Mes_Annonces extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : true),
            annonces: [],
            annonce: {
                id: 0,
                email: this.props.user.user.email,
                email2: this.props.user.user.email2,
                titre: '',
                user_id: 0,
                nbPLace: 1,
                createdAt: '',
                datePlace: new Date(),
                prix: 'NC',
                descriptif: '',
                codePostal: '',
                email_etablissement: '',
                nom_etablissement: '',
                rue: '',
                telephone_etablissement: '',
                site_etablissement: '',
                ville: '',
                activate: -1,
                specs: [],
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
            },
            filter: {
                status: 3,
                etablissement: 0,
                codePostal: "",
                ville: "",
                lat: "",
                lng: "",
                rayon: "",
                distance: false,
                type1: true,
                type2: true,
                type3: true,
                type4: true,
            },
            value: "",
            suggestions: [],
            deleted: [0],
            modif: false,
            preview: false,
            maxLength: 400,
            CGU: false,
            start: 0,
            end: 15,
            selected: 0,
            screenWidth: null,
            message: ""
            //ajout: !this.props.location.state ? '' : this.props.location.state.message
        };

        this.handleDelete       = this.handleDelete.bind(this);
        this.handleModif        = this.handleModif.bind(this);
        this.handleChange       = this.handleChange.bind(this);
        this.handleChangeDate   = this.handleChangeDate.bind(this);
        this.handlePreview      = this.handlePreview.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleChangeCGU    = this.handleChangeCGU.bind(this);
        this.handleChangeSpec   = this.handleChangeSpec.bind(this);

        this.handleActivate      = this.handleActivate.bind(this);
        this.handleDesactivate   = this.handleDesactivate.bind(this);
        this.handleMultiActivate = this.handleMultiActivate.bind(this);

        this.renderCollection   = this.renderCollection.bind(this);
        this.renderModif        = this.renderModif.bind(this);
        this.renderSpecs        = this.renderSpecs.bind(this);

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleChangeFilterDate = this.handleChangeFilterDate.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.clickCheckBox   = this.clickCheckBox.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount(prevProps, prevState) {
        this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.groupe_id));
        this.props.dispatch(etablissementActions.getGroupeEtablissement(this.props.user.user.groupe_id));

        window.addEventListener("resize", this.updateWindowDimensions());
        window.scrollTo(0,0)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.annonces !== this.state.annonces) {
            let annonces = this.props.annonces;

            if (annonces)
                this.setState({
                    annonces
                })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.annonces !== prevState.annonces) {

            return {annonces: nextProps.annonces};
        } else return null;
    }

    async handleDesactivate(e) {
        e.persist()
        e.preventDefault();

        this.props.dispatch(alertActions.clear());
        let response = await Confirm("Voulez vous désactiver cette annonce?", "Désactivation", "Oui", "Non");
        if (response) {
            this.props.dispatch(etablissementActions.activateAnnonce(e.target.id, -2));
            this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.groupe_id));
        }
    }

    async handleActivate(e) {
        e.persist()
        e.preventDefault();

        this.props.dispatch(alertActions.clear());
        let response = await Confirm("Voulez vous activer cette annonce?", "Activation", "Oui", "Non");
        if (response) {
            this.props.dispatch(etablissementActions.activateAnnonce(e.target.id, 1));
            this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.groupe_id));
        }
    }

    async handleMultiActivate(e) {
        e.persist()
        e.preventDefault();

        const { id } = e.target;
        const val = parseInt(id)
        let ids = [];

        this.setState({message: ""});

        let body = `Voulez vous ${val === 1 ? 'activer' : 'désactiver'} toutes vos annonces?`;
        let title = `${val === 1 ? 'Activation multiple' : 'Désactiver multiple'}`;
        let response = await Confirm(body, title, "Oui", "Non");

        if (response) {
            for (let i in this.props.annonces.list) {
                if (val === 1 && (this.props.annonces.list[i].activate === -2 || this.props.annonces.list[i].activate === 0)) {
                    await ids.push(this.props.annonces.list[i].id);
                } else if (val === -2 && (this.props.annonces.list[i].activate === 1 || this.props.annonces.list[i].activate === 0)) {
                    await ids.push(this.props.annonces.list[i].id);
                }
            }

            if (ids.length > 0) {
                this.props.dispatch(etablissementActions.activateMultiAnnonces(ids, val));
                this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.groupe_id));
            } else {
                if (val === 1)
                    this.setState({message: "Vous n'avez aucune annonce activable"});
                else
                    this.setState({message: "Vous n'avez aucune annonce désactivable"});
            }
        }
    }

    renderActivated(num) {
        switch (num) {
            case 1:
                return <p className="text text-success">Activée</p>;
                break;
            case 0:
                return <p className="text text-danger">Désactivée système</p>;
                break;
            case -1:
                return <p className="text text-danger">En attente de validation</p>;
                break;
            case -2:
                return <p className="text text-danger">Désactivée utilisateur</p>;
                break;
            default:
                return <p className="text text-danger">En attente de validation</p>;
        }
    }

    async handleDelete(e) {
        e.persist()
        e.preventDefault();

        const {deleted} = this.state;
        let response = await Confirm("Voulez-vous vraiment supprimer l'annonce?", "Suppression d'Annonce", "Oui", "Fermer");
        const { id } = e.target;

        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            await this.props.dispatch(etablissementActions.delAnnonce(id));
            //this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.id));
        } else {
            console.log("canceled");
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        const {annonce, maxLength} = this.state;

        if (name === "descriptif" && annonce.descriptif.length <= maxLength) {
            this.setState({
                annonce: {
                    ...annonce,
                    descriptif: lib.protectEntry(value)
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    [name]: value
                }
            })
        }
    }

    handleChangeDate(date) {
        const {annonce} = this.state;

        this.setState({
            annonce: {
                ...annonce,
                datePlace: date
            }
        });
    };

    handleChangeCGU(e) {
        const {name, value} = e.target;

        this.setState({CGU: !this.state.CGU})
    }

    _renderTime(time) {
        var now = moment().toISOString();
        var diff = moment.duration(moment(time).diff(moment(now)));

        var days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité dans {days} jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité immédiate <span className="green-dot"></span>
                </p>
            )
        }
    }

    handlePreview(e) {
        e.preventDefault();

        const {preview, annonce} = this.state;

        if (annonce.prix.length === 0) {
            Alert("Veuillez renseigner un budget")
        } else {
            this.setState({preview: !preview, CGU: false})
        }
        window.scrollTo(0,0)
    }

    handleSubmit(e) {
        e.preventDefault();
        const {annonce, CGU} = this.state;
        const {dispatch} = this.props;

        if (CGU) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({annonce})
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/updateAnnonce`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    if (result.success) {
                        dispatch(alertActions.success(result.message));
                        this.props.dispatch(etablissementActions.getAnnoncesByGroupe(this.props.user.user.groupe_id));
                        this.setState({
                            modif: false,
                            preview: false,
                            CGU: false
                        })
                    } else {
                        dispatch(alertActions.error("une erreur est survenue, veuillez contacter l'administrateur"));
                    }
                })
                .catch(err => {
                    dispatch(alertActions.error(err));
                    console.log(err)
                });
        } else {
            Alert("Veuillez lire et accepter les conditions générales d’utilisation.")
        }
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    renderSpecs(item) {
        let specs = this.props.annonces.specs.filter(elem => elem.id_annonce === item.id);

        if (specs.length > 0)
            return (
                <p className="subTitleResultat" id="type">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"></i><strong>Spécificités : </strong><br/>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}
                </p>
            );
        else
            return null
    }

    renderSpecsAnnonce() {
        let specs = this.state.annonce.specs;


        if (specs.length > 0)
            return (
                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités : </strong>{'\t'}<br/>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}
                </div>
            );
        else
            return null
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {modif ,annonce} = this.state;
        const {annonces} = this.props;


        if (!checked) {
            this.setState({
                annonce: {
                    ...annonce,
                    specs: annonce.specs.filter(function (item) {
                        return item.info_hebergement !== value;
                    })
                },
            })
        } else {
            //let item = annonces.specs.filter(function (item) {return item.info_hebergement === value});
            this.setState({
                annonce: {
                    ...annonce,
                    specs: [...annonce.specs, {id_annonce: annonce.id, info_hebergement: value}]
                },
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            filter: {
                ...this.state.filter,
                codePostal: '',
                ville: '',
                lat: '',
                lng: '',
                rayon: '',
                distance: false,
            },
            value: newValue,
            start: 0,
            end: 15,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {

        this.setState({
            filter: {
                ...this.state.filter,
                codePostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
                lat: suggestion.value.geoLoc.coordinates[1],
                lng: suggestion.value.geoLoc.coordinates[0],
                rayon: "5",
                distance: true
            },
            start: 0,
            end: 15,
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
        })

    };

    clickCheckBox(e) {
        const { filter } = this.state;
        const { name, type, checked, value } = e.target;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: true,
                    },
                    start: 0,
                    end: 15
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: false,
                    },
                    start: 0,
                    end: 15
                })
            }
        }
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value, type} = e.target;
        const {filter, annonces, lat, lng} = this.state;

        if (annonces) {
            if (name === 'status' || name === 'etablissement') {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: parseInt(value),
                    },
                    start: 0,
                    end: 15
                })
            } else if (name === "rayon") {
                if (lat && lng) {
                    Alert("Veuillez indiquer un code postal")
                } else {
                    this.setState({
                        filter: {
                            ...filter,
                            rayon: value,
                            distance: true,
                        },
                        start: 0,
                        end: 15
                    })
                }
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: value,
                    },
                    start: 0,
                    end: 15
                })
            }
        }
    };

    handleChangeFilterDate(date) {
        const {filter, annonces} = this.state;

        if (annonces)
            this.setState({
                filter: {
                    ...filter,
                    date: date,
                },
                start: 0,
                end: 15
            })
    }

    supprimerFiltre() {
        const {filter, annonces} = this.state;

        if (annonces) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    status: 3,
                    codePostal: "",
                    ville: "",
                    lat: "",
                    lng: "",
                    rayon: "",
                    distance: false,
                    type1: true,
                    type2: true,
                    type3: true,
                    type4: true,
                },
                value: "",
                suggestions: [],
                start: 0,
                end: 15
            })
        }
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { lat, lng } = this.state.filter;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(lat, lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(lat, lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;


        const codePostal = filter.codePostal
            ? (filter.codePostal.indexOf(item.codePostal) >= 0)
            : filter.ville
                ? (filter.ville.indexOf(item.ville) >= 0)
                : true;
        const rayon = filter.distance === true
            ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon, 10))
            : true;
        const locality = (codePostal || filter.distance);
        const status = filter.status !== 3 ? filter.status === item.activate : true;
        const isDeleted = deleted.indexOf(item.id) < 0;
        const etablissement = ((filter.etablissement === 0) || (filter.etablissement === item.user_id));
        let type;
        switch (item.titre) {
            case "EHPAD"                        :
                type = filter.type1;
                break;
            case "USLD"                         :
                type = filter.type2;
                break;
            case "Résidence autonomie"          :
                type = filter.type3;
                break;
            case "Résidence services seniors"   :
                type = filter.type4;
                break;
            default :
                type = true;
        }

        return (locality && rayon && type && status && isDeleted && etablissement);
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15, selected})
        window.scrollTo(0,0)
    };

    changeInput(name, value) {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: !value,
            },
            start: 0,
            end: 15
        })
    }

    sortPostalCode(a, b) {
        if (a > b)
            return 1;
        else if (a < b)
            return -1;
        else
            return 0
    }

    renderCollection() {
        moment.locale('fr');
        const {filter, start, end, selected, value, suggestions, screenWidth} = this.state;
        const {alert} = this.props;
        let siteReg = /^(http(s)?:\/\/)/;

        let filtredList = this.props.annonces && this.props.annonces.list ? this.props.annonces.list.filter(item => this.renderFilter(item)) : [];
        filtredList = filter.distance ? filtredList.sort((a, b) => this.sortDistance(a, b)) : filtredList;

        const inputProps = {
            placeholder: 'Entrez un code postal ou une ville',
            value,
            onChange: this.onChange
        };

        const etablissements = this.props.list?.etablissements ?? [];
        return (
            <main>
                <section className="listeDemandeBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/groupes/">Mon compte</a> &gt; Mes annonces</p>
                        <h1>Mes <span>annonces</span></h1>
                        <div className="col-xs12 bouton voir">
                            <Link to="/groupes/creer-annonce">Ajouter une annonce</Link>
                        </div>
                        <br/>

                            <form>
                                <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "0.1rem", margin: "0 0 0.5rem 0"}}>
                                    <div className="infoSpecial">
                                        {
                                            screenWidth > 763 ?
                                                <div className="listeEtablissementTri">
                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce1.png"
                                                                 alt="EHPAD"
                                                                 onClick={() => this.changeInput("type1", filter.type1)}
                                                                 title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_1"
                                                                   style={{fontSize: '1.4rem'}}>EHPAD</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{transform: 'scale(1.5)', width: '30px'}}
                                                               id="appbundle_alertedispo_etablissement_1"
                                                               name="type1"
                                                               value={filter.type1}
                                                               checked={filter.type1}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce3.png"
                                                                 alt="USLD"
                                                                 onClick={() => this.changeInput("type2", filter.type2)}
                                                                 title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_2"
                                                                   style={{fontSize: '1.4rem'}}>USLD</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{transform: 'scale(1.5)', width: '30px'}}
                                                               id="appbundle_alertedispo_etablissement_2"
                                                               name="type2"
                                                               value={filter.type2}
                                                               checked={filter.type2}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce4.png"
                                                                 alt="Résidence autonomie"
                                                                 onClick={() => this.changeInput("type3", filter.type3)}
                                                                 title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_3"
                                                                   style={{fontSize: '1.4rem'}}>Résidence
                                                                autonomie</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{transform: 'scale(1.5)', width: '30px'}}
                                                               id="appbundle_alertedispo_etablissement_3"
                                                               name="type3"
                                                               value={filter.type3}
                                                               checked={filter.type3}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>

                                                    <div
                                                        className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                        <div className="row" style={{textAlign: 'center'}}>
                                                            <img className="img-responsive"
                                                                 src="/img/home/homeBloc1/icone-annonce5.png"
                                                                 alt="Résidence services seniors"
                                                                 onClick={() => this.changeInput("type4", filter.type4)}
                                                                 title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                            <label htmlFor="appbundle_alertedispo_etablissement_4"
                                                                   style={{fontSize: '1.4rem'}}>Résidence
                                                                services
                                                                seniors</label>
                                                        </div>
                                                        <input type="checkbox"
                                                               style={{transform: 'scale(1.5)', width: '30px'}}
                                                               id="appbundle_alertedispo_etablissement_4"
                                                               name="type4"
                                                               value={filter.type4}
                                                               checked={filter.type4}
                                                               onChange={this.clickCheckBox}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div style={{justifyContent: 'center'}}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce1.png"
                                                                     alt="EHPAD"
                                                                     onClick={() => this.changeInput("type1", filter.type1)}
                                                                     title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type1"
                                                                    checked={filter.type1}
                                                                    name="type1"
                                                                    value={filter.type1}
                                                                    onChange={() => this.changeInput("type1", filter.type1)}
                                                                />
                                                                <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce3.png"
                                                                     alt="USLD"
                                                                     onClick={() => this.changeInput("type2", filter.type2)}
                                                                     title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type2"
                                                                    checked={filter.type2}
                                                                    name="type2"
                                                                    value={filter.type2}
                                                                    onChange={() => this.changeInput("type2", filter.type2)}
                                                                />
                                                                <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce4.png"
                                                                     alt="Résidence autonomie"
                                                                     onClick={() => this.changeInput("type3", filter.type3)}
                                                                     title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type3"
                                                                    checked={filter.type3}
                                                                    name="type3"
                                                                    value={filter.type3}
                                                                    onChange={() => this.changeInput("type3", filter.type3)}
                                                                />
                                                                <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce5.png"
                                                                     alt="Résidence services seniors"
                                                                     onClick={() => this.changeInput("type4", filter.type4)}
                                                                     title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type4"
                                                                    checked={filter.type4}
                                                                    name="type4"
                                                                    value={filter.type4}
                                                                    onChange={() => this.changeInput("type4", filter.type4)}
                                                                />
                                                                <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                        <div className="secondFiltre">
                                            <div className="villeInput triVille"
                                                 style={{position: "relative", width: "100%", margin: "0 auto"}}>

                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    highlightFirstSuggestion={true}
                                                    style={{alignSelf: 'center'}}
                                                />
                                            </div>

                                            <div className="listeKm" style={{marginRight: "1rem"}}>
                                                <button className={filter.rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                    5km
                                                </button>
                                                <button className={filter.rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                    15km
                                                </button>
                                                <button className={filter.rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                    30km
                                                </button>
                                                <button className={filter.rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                    50km
                                                </button>
                                                <button className={filter.rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                    100km
                                                </button>
                                            </div>

                                            <div className="bouton submit subConf">
                                                <input type="reset" name="supprimer"
                                                       value="Supprimer les Filtres"
                                                       style={{marginTop: "10px"}}
                                                       onClick={this.supprimerFiltre}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        <div className="filtres col-xs-12"
                             style={{
                                 border: "#bec8c9 .1rem solid",
                                 padding: "1rem",
                                 margin: "0 0 1rem 0",
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                             }}>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <select id="etablissement" name="status" onChange={this.handleChangeFilter} value={filter.Type} style={{alignSelf: 'center'}}>
                                    <option value="3">Toutes les annonces</option>
                                    <option value="1">activée</option>
                                    <option value="-1">en attente</option>
                                    <option value="0">désactivée système</option>
                                    <option value="-2">désactivée utilisateur</option>
                                </select>
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <select id="etablissement" name="etablissement" onChange={this.handleChangeFilter} value={filter.Type} style={{alignSelf: 'center'}}>
                                    <option value={0}>Tous les établissements</option>
                                    {
                                        etablissements.sort((a, b) => this.sortPostalCode(a.codePostal, b.codePostal)).map((item, index) =>
                                            <option value={item.user_id} key={index}>{item.nom_etablissement} ({item.codePostal} - {item.ville})</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <br/>
                        <h4 style={{textAlign: "center", fontSize: "2.4rem"}}><strong style={{color: "#464e6d"}}>Nombre d'annonces: </strong><span style={{color: "#858fb6"}}>{filtredList.length}</span></h4>
                        {
                            this.state.filter.etablissement === 0 ?
                            <div className="filtres col-xs-12">
                                <div className="inputInfos col-xs-12 col-sm-6 col-md-4 col-lg-4"></div>
                                <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="bouton submit subConf">
                                        <input type="reset" name="activer"
                                               value="Activer toutes les annonces du Groupe"
                                               id="1"
                                               style={{marginTop: "10px"}}
                                               onClick={this.handleMultiActivate}/>
                                    </div>
                                </div>
                                <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="bouton submit subConf">
                                        <input type="reset" name="désactiver"
                                               value="Désactiver toutes les annonces du Groupe"
                                               id="-2"
                                               style={{marginTop: "10px"}}
                                               onClick={this.handleMultiActivate}/>
                                    </div>
                                </div>
                                <div className="inputInfos col-xs-12 col-sm-6 col-md-4 col-lg-4"></div>
                            </div>
                                :
                                null
                        }
                        <br/>
                        {
                            alert ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                        }
                        {
                            this.state.message
                                ? <div className="filtres col-xs-12">
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-4 col-lg-4"></div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-4 col-lg-4" style={{color: '#c4574e', textAlign: "center"}}>{this.state.message}</div>
                                    <div className="inputInfos col-xs-12 col-sm-6 col-md-4 col-lg-4"></div>
                                </div>
                                : null
                        }
                        <br/>
                        <div id="listeDemande">
                            {filtredList.slice(start, end).map((item) =>

                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" distance="0" key={item.id}>
                                    <div className="singleDemande listeannonceAdmin">
                                        {
                                            filter.distance ? <div className="distSentenceCont">À moins de {this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude)}km.</div> : null
                                        }
                                        <div className="question">
                                            <p className="titleSingleResultat" id="nbPlace">
                                                Annonce publiée le {moment(item.createdAt).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                        {this.renderActivated(item.activate)}
                                        <div className="">
                                            <div className="question">
                                                <p className="titleSingleResultat" id="nbPlace">
                                                    {item.nbPLace} place(s) disponible(s)
                                                    à {item.ville} {item.codePostal}
                                                </p></div>
                                        </div>

                                        {
                                            this.getImages(item).length > 0 ?
                                                <ImageGallery additionalClass="ImageGallery"
                                                              showThumbnails={false}
                                                              showPlayButton={false}
                                                              showBullets={false}
                                                              showFullscreenButton={false}
                                                              items={this.getImages(item)}
                                                />
                                                :
                                                <img className="img-responsive" style={{margin:"2rem auto"}}
                                                     src={ item.titre === 'EHPAD' ?
                                                         "/img/home/homeBloc1/icone-annonce1.png" :
                                                         item.titre === 'USLD' ?
                                                             "/img/home/homeBloc1/icone-annonce3.png" :
                                                             item.titre === 'Résidence autonomie' ?
                                                                 "/img/home/homeBloc1/icone-annonce4.png" :
                                                                 "/img/home/homeBloc1/icone-annonce5.png"}
                                                     alt="icone-maison"/>
                                        }
                                        <div className="infoActivation">
                                            {this._renderTime(item.datePlace)}

                                            <p className="subTitleResultat" id="type">
                                                <i className="fa fa-building-o  img-responsive" aria-hidden="true"/>
                                                {item.titre}
                                            </p>
                                            <p className="subTitleResultat" id="type">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-budget.png"
                                                     alt="icone-budget"/>
                                                Budget mensuel : {item.prix}
                                            </p>
                                            {
                                                this.renderSpecs(item)
                                            }
                                            <hr/>
                                            <p className="subTitleResultat" id="type">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-type-etablissement.png"
                                                     alt="icone-type-etablissement"/>
                                                <strong>{item.nom_etablissement}</strong>
                                            </p>
                                            <p className="subTitleResultat" id="adresse">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-secteur.png"
                                                     alt="icone-secteur"/>
                                                {item.rue}, {item.codePostal} {item.ville}
                                            </p>
                                            <p className="subTitleResultat">
                                                <i className="fa fa-phone img-responsive" aria-hidden="true"/>
                                                <a style={{fontSize: '1.5rem'}} href={`tel:${item.telephone_etablissement}`}>{item.telephone_etablissement}</a>
                                            </p>
                                            <p className="subTitleResultat mail">
                                                <i className="fa fa-at" aria-hidden="true"></i>
                                                <a href={"mailto:"+item.email_etablissement}>{item.email_etablissement}</a>
                                            </p>
                                            {
                                                item.site_etablissement ?
                                                    <div className="subTitleResultat website">
                                                        <i className="fa fa-internet-explorer" aria-hidden="true"></i>
                                                        <a href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                           target="_blank">Site de l'établissement</a>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                item.descriptif ?
                                                    <p className="subTitleResultat description">
                                                        <br/>
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-commentaires.png"
                                                             alt="icone-commentaires"/>
                                                        {item.descriptif}
                                                    </p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-xs12 bouton voir">
                                                <button onClick={this.handleModif} id={item.id}>Modifier</button>
                                            </div>
                                            {item.activate == 1 ?
                                                <div className="col-xs12 bouton supprimer">
                                                    <button onClick={this.handleDesactivate} id={item.id}>Désactiver</button>
                                                </div>
                                                :
                                                item.activate == 0 || item.activate == -2 ?
                                                    <div className="col-xs12 bouton supprimer">
                                                        <button onClick={this.handleActivate} id={item.id}>Activer</button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-xs12 bouton supprimer">
                                                <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-xs-12 pages">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(filtredList.length / 15)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            forcePage={start === 0 ? 0 : selected}
                            containerClassName={'pagination'}
                            subContainerClassName={'btn-page'}
                            activeClassName={'active'}
                        />
                    </div>
                </section>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
            </main>
        )
    }

    async handleModif(e) {
        e.preventDefault();
        this.props.dispatch(alertActions.clear());
        const {modif, annonce} = this.state;

        const array = this.props.annonces.list.filter(item => parseInt(item.id) === parseInt(e.target.id));

        this.setState({
            annonce: {
                ...annonce,
                id: array[0].id,
                titre: array[0].titre,
                user_id: array[0].user_id,
                codePostal: array[0].codePostal,
                createdAt: new Date(array[0].createdAt),
                datePlace: new Date(array[0].datePlace),
                descriptif: array[0].descriptif,
                email_etablissement: array[0].email_etablissement,
                nbPLace: array[0].nbPLace,
                nom_etablissement: array[0].nom_etablissement,
                prix: array[0].prix,
                rue: array[0].rue,
                telephone_etablissement: array[0].telephone_etablissement,
                site_etablissement: array[0].site_etablissement,
                ville: array[0].ville,
                specs: this.props.annonces.specs.filter(function (el) { return parseInt(el.id_annonce) === parseInt(e.target.id)}),
                image_un: array[0].image_un,
                image_deux: array[0].image_deux,
                image_trois: array[0].image_trois,
                image_quatre: array[0].image_quatre,
            },
            modif: !modif,
        });
        window.scrollTo(0,0)
    }

    getImages(item) {
        let images = [];

        if (item.image_un) {
            images.push({
                original: "/img/annonces/" + item.image_un
            });
        }
        if (item.image_deux) {
            images.push({
                original: "/img/annonces/" + item.image_deux
            });
        }
        if (item.image_trois) {
            images.push({
                original: "/img/annonces/" + item.image_trois
            });
        }
        if (item.image_quatre) {
            images.push({
                original: "/img/annonces/" + item.image_quatre
            });
        }
        return images
    }

    renderModif() {
        moment.locale('fr');
        const {annonce, annonces, preview, CGU} = this.state;

        const {alert} = this.props;
        let date = new Date();
        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <main>
                {!preview ?
                    <section className="demandeEtablissementBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/groupes">Mon compte</a> &gt; <a
                                href="/groupes/liste-annonces">Mes annonces</a> &gt; Mise à jour de l'annonce</p>
                            <h1>Mise à jour de l'annonce <span>pour mes places disponibles</span></h1>

                            <div className="encadreForm">


                                <div className="text-center text-success validationDemandeEtablissement">
                                </div>
                                <form name="appbundle_annonce" onSubmit={this.handlePreview}>

                                    <div className="formBloc1">
                                        <div className="formBloc1_1 col-xs-12 col-sm-6">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Nombre de places</p>
                                                </div>
                                                <div className="inputDemande">
                                                    <input type="number" id="appbundle_annonce_nbPLace"
                                                           name="nbPLace" required="required" min="1"
                                                           max="10" value={annonce.nbPLace}
                                                           onChange={this.handleChange}
                                                        //placeholder={annoncenbPLace}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="formBloc1_2 col-xs-12 col-sm-6 datePlace">
                                            <div className="form-group row">
                                                <div className="question">
                                                    <p>A partir de quand vos places sont disponibles
                                                        ?<span> *</span></p>
                                                </div>
                                                <div className="inputDemande">
                                                    <DatePicker
                                                        selected={annonce.datePlace}
                                                        onChange={this.handleChangeDate}
                                                        minDate={date}
                                                        maxDate={date.addDays(30)}
                                                        dateFormat="dd-MM-yyyy"
                                                        locale="fr"
                                                        className=""
                                                        //placeholder={annonce.datePlace}
                                                        //withPortal
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Quel est le niveau de budget mensuel ?</p>
                                                </div>
                                                <div className="inputBudget">
                                                    <div id="appbundle_annonce_prix">
                                                        <input type="radio" id="appbundle_annonce_prix_NC"
                                                               name="prix" value="NC"
                                                               onChange={this.handleChange}
                                                               checked={annonce.prix === "NC"}/>
                                                        <label htmlFor="appbundle_annonce_prix_NC">Pas de Budget</label>
                                                        <input type="radio" id="appbundle_annonce_prix_0"
                                                               name="prix" value="Moins de 2000€"
                                                               onChange={this.handleChange}
                                                               defaultChecked={(annonce.prix === "Moins de 2000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_0">Moins de 2000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_1"
                                                               name="prix" value="2000€ à 2500€"
                                                               onChange={this.handleChange}
                                                               defaultChecked={(annonce.prix === "2000€ à 2500€") || (annonce.prix === "De 2000€ à 2500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_1">De 2000€ à
                                                            2500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_2"
                                                               name="prix" onChange={this.handleChange}
                                                               value="2500€ à 3000€"
                                                               defaultChecked={(annonce.prix === "2500€ à 3000€") || (annonce.prix === "De 2500€ à 3000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_2">De 2500€ à
                                                            3000€</label>
                                                        <br/>
                                                        <input type="radio" id="appbundle_annonce_prix_3"
                                                               name="prix" onChange={this.handleChange}
                                                               value="3000€ à 3500€"
                                                               defaultChecked={(annonce.prix === "3000€ à 3500€") || (annonce.prix === "De 3000€ à 3500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_3">De 3000€ à
                                                            3500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_4"
                                                               name="prix" onChange={this.handleChange}
                                                               value="3500€ à 4000€"
                                                               defaultChecked={(annonce.prix === "3500€ à 4000€") || (annonce.prix === "De 3500€ à 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_4">De 3500€ à
                                                            4000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_5"
                                                               name="prix" onChange={this.handleChange}
                                                               value="Plus de 4000€"
                                                               defaultChecked={(annonce.prix === "Plus de 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_5">Plus de 4000€</label>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="formBloc1_4 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong> Spécificités </strong> (Facultatif - Plusieurs choix possibles)</p>
                                                        </div>
                                                        <div className="inputInfos col-xs-12">

                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec1" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function (el) { return el && el.info_hebergement === "Place en unité Alzheimer / protégée"}).length > 0} value="Place en unité Alzheimer / protégée" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec1" style={{fontSize: "1em", marginTop: '1%'}}>Place en unité Alzheimer / protégée</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec2" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Possibilité de court séjour / séjour temporaire"}).length > 0} value="Possibilité de court séjour / séjour temporaire" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec2" style={{fontSize: "1em", marginTop: '1%'}}>Possibilité de court séjour / séjour temporaire</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec3" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "ASH possible (Aide Sociale à l’Hébergement)"}).length > 0} value="ASH possible (Aide Sociale à l’Hébergement)" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec3" style={{fontSize: "1em", marginTop: '1%'}}>ASH possible (Aide Sociale à l’Hébergement)</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec4" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Place en accueil de jour"}).length > 0} value="Place en accueil de jour" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec4" style={{fontSize: "1em", marginTop: '1%'}}>Place en accueil de jour</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc2">
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p><strong>Informations supplémentaires</strong> (que vous
                                                        souhaitez
                                                        transmettre aux familles et/ou aux personnes qui
                                                        informent
                                                        les
                                                        familles)</p><p></p>
                                                </div>
                                                <div className="inputBudget">
                                                    <textarea id="appbundle_annonce_descriptif"
                                                              name="descriptif" onChange={this.handleChange}
                                                              value={annonce.descriptif}
                                                        //placeholder={annonce.descriptif}
                                                              maxLength="400">
                                                    </textarea>
                                                </div>
                                                <div id="counter">
                                                    {annonce.descriptif.length} / 400
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bouton submit">
                                            <button type="submit" id="appbundle_annonce_previus"
                                                    name="appbundle_annonce[previus]"
                                                    label="Prévisualiser"> Prévisualiser
                                            </button>
                                        </div>
                                        <input type="hidden" id="appbundle_annonce_equipements"
                                               name="appbundle_annonce[equipements]" value="vide"/>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>
                    :
                    <section className="visualisationDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/groupes/">Mon compte</a> &gt; <a
                                href="/groupes/liste-annonces">Mes annonces</a> &gt; Je visualise mon annonce
                            </p>
                            <h1>Je visualise <span>mon annonce</span></h1>

                            <p className="titleForm"></p>
                            <div className="encadreForm">
                                <div className="visualisationDemandeBloc1_1 col-xs-12">
                                    <div className="row">
                                        <p className="titleLieu">{annonce.nbPLace} place(s) disponible(s)
                                            à {annonce.ville} {annonce.codePostal}</p>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                 alt="icone-sexe"/>À partir du {moment(annonce.datePlace).format('DD/MM/YYYY')}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-budget.png"
                                                 alt="icone-sexe"/>
                                            Niveau de budget mensuel de {annonce.prix}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-building-o  img-responsive" aria-hidden="true"/>
                                            {annonce.titre}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                 alt="icone-sexe"/>
                                            {annonce.nom_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                 alt="icone-sexe"/>
                                            {annonce.rue}, {annonce.codePostal} {annonce.ville}
                                        </div>

                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-phone img-responsive" aria-hidden="true"></i>
                                            {annonce.telephone_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-at" aria-hidden="true"></i>
                                            {annonce.email_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-internet-explorer" aria-hidden="true"></i>

                                            <a href={siteReg.test(annonce.site_etablissement) ? annonce.site_etablissement : 'https://' + annonce.site_etablissement}
                                               target="_blank">Site de l'établissement</a>
                                        </div>
                                        {
                                            this.renderSpecsAnnonce()
                                        }
                                        <div className="visualisationInfo description col-xs-12">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-commentaires.png"
                                                 alt="icone-commentaires"/>
                                            <p>Description :
                                            </p><p>{annonce.descriptif}</p><p></p>
                                        </div>
                                    </div>
                                </div>
                                <form name="appbundle_annonce" className="form-horizontal">
                                    <div
                                        className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input type="checkbox" name="CGU" value={CGU} required="required"
                                               onChange={this.handleChangeCGU}/>
                                        <label>j’accepte les <a href="/conditions-generales"
                                                                target="_blank">conditions générales
                                            d’utilisation.</a></label>
                                    </div>
                                    {
                                        alert.message ?
                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                            :
                                            <div className="boutons col-xs-12">
                                                <div className="row">
                                                    <div className="bouton modifier">
                                                        <button type="submit" id="appbundle_annonce_update"
                                                                name="appbundle_annonce[update]"
                                                                onClick={this.handlePreview}
                                                                label="Modifier ma demande">Modifier
                                                        </button>
                                                    </div>
                                                    <div className="bouton">
                                                        <button type="submit" id="appbundle_annonce_save"
                                                                name="appbundle_annonce[save]"
                                                                onClick={this.handleSubmit}
                                                                label="Enregistrer">Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </section>
                }
            </main>

        )

    }

    render() {
        const {annonces, modif, annonce} = this.state;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !(annonces) ? this.renderCollection()

                        :

                        (modif) ? this.renderModif() : this.renderCollection()
                }
                <Footer style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    textAlign: 'center',
                }}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, etablissement, alert} = state;
    const {user} = authentication;
    const {annonces, list} = etablissement;
    return {
        user,
        list,
        alert,
        annonces
    }
}

export default withRouter(connect(mapStateToProps)(Mes_Annonces))
