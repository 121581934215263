import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const user = JSON.parse(localStorage.getItem('user'))

export const GroupeRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).user.roles == "a:1:{i:0;s:11:\"ROLE_GROUPE\";}"
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)
