import React, { Component } from 'react';
import { connect } from 'react-redux';

class Liens_Recherche extends Component {

    constructor(props){
        super(props)


    }

    render(){
        return (
            <section className="banniere banniere-last">
                <img className="img-responsive" src="/img/banniere.jpg" alt="banniere-accueil"/>
                    <div className="container">
                        <div className="contentBanniere col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <a href="/place-etablissement">
                                <div className="titreBanniere">
                                    <p>J'ai une place disponible</p>
                                    <p>dans mon établissement</p>
                                </div>
                                <img className="img-responsive"
                                     src="/img/home/banniere/icone-banniere-jai-place.png"
                                     alt="icone-maison"/>
                            </a>
                        </div>
                        <div className="contentBanniere col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <a href="/place-maison">
                                <img className="img-responsive"
                                     src="/img/home/banniere/icone-banniere-je-cherche-place.png"
                                     alt="icone-maison"/>
                                    <div className="titreBanniere">
                                        <p>Je cherche une place en</p>
                                        <p>maison de retraite</p>
                                    </div>
                            </a>
                        </div>
                    </div>
            </section>
        )
    }
}



function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Liens_Recherche)
