import { dispoConstants } from '../_constants';

export function dispo(state = {}, action) {
    switch (action.type) {
        case dispoConstants.DISOP_REQUEST:
            return {
                gettingDispo: true
            };
        case dispoConstants.DISPO_SUCCESS:
            return {
                alert: action.dispo
            };
        case dispoConstants.DISPO_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}