import React, { Component } from 'react';
import { connect } from 'react-redux';

import ScrollUpButton from "react-scroll-up-button";

import Navbar  from '../../_components/Navbar';
import Footer from '../../_components/Footer';

import Recherche    from './Body/Recherche';
import Annonces     from './Body/Annonces';
import Alerte       from './Body/Inscription_Alerte'
import Type         from './Body/Maisons_Retraite'
import How          from './Body/Fonctionnement'
import Who          from './Body/Qui_Sommes_nous'
import Departement  from './Body/Recherche_Departement';
import Link         from './Body/Liens_Recherche';

class Accueil extends Component {

    constructor(props){
        super(props);

        this.myRef = React.createRef()
    }

    componentDidMount() {
        this.props.location.state && this.props.location.state.dep && this.myRef ? this.myRef.current.scrollIntoView(true) : console.log("null");
    }

    render(){
        const {alert} = this.props;
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        alert.message ?
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                            :
                            null
                    }
                    <Recherche  {...this.props} />
                    <Annonces   {...this.props} />
                    <Alerte     {...this.props} />
                    <How        {...this.props} />
                    <Type       {...this.props} />
                    <Who        {...this.props} />
                    <Link       {...this.props} />
                    <Departement refProp={this.myRef} id="dep" {...this.props} />
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { authentication, alert } = state;
    const { user } = authentication;
    return {
        user,
        alert
    };
}

//export default connect(mapStateToProps, {})(Accueil)
const connectedAccueil = connect(mapStateToProps)(Accueil);
export { connectedAccueil as Accueil };
