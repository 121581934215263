import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {etablissementActions} from "../../../../_actions";

class Etablissement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: (localStorage.getItem('user') ? this.props.user.user : false)
        }
    }

    render() {
        const {alert} = this.props;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    <section className="inscriptionStep1">
                        <div className="container">
                            <h1>Mon compte</h1>
                            <br/>
                            {
                                alert.message ?
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                    :
                                    null
                            }
                            <br/>
                            <div className="buttonMonCompte">
                                <div className="bouton submit">
                                    <Link to="/groupes/mes-informations">Mes informations</Link>
                                    <p>Modifier mes identifiant et les informations sur mon groupe. </p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/groupes/creer-annonce">Créer une Annonce</a>
                                    <p>Créer une annonce pour un établissement.</p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/groupes/liste-annonces">Mes Annonces</a>
                                    <p>Gérer les annonces des établissements.</p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/groupes/etablissements">Mes établissements</a>
                                    <p>Consulter la liste des établissements enregistrés.</p>
                                </div>
                                <div className="bouton submit">
                                    <a href="/groupes/statuts">Statuts établissements</a>
                                    <p>Consulter et gérer le statut de controle de vos établissements.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, alert} = state;
    const {user} = authentication;
    return {
        user,
        alert
    }
}

export default connect(mapStateToProps)(Etablissement)
