import React, {Component} from 'react';
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';

import {etablissementActions, groupesActions, userActions} from "../../../../_actions";
import {alertActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {withRouter} from "react-router";
import {Alert} from "react-st-modal";


const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

let GroupeList = [];

const getGroupeSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.value.nom.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getGroupeSuggestionValue = suggestion => suggestion.label;

const renderGroupeSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label}</b></div>

);

class Mes_Infos extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            value: '',
            valueGroupe: '',
            suggestions: [],
            suggestionsGroupe: [],
            user: {
                id: (localStorage.getItem('user') ? this.props.user.user.id : 0),
                etablissement_id: (localStorage.getItem('user') ? this.props.user.user.etablissement_id : 0),
                username: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.username : ""),
                email: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.email : ""),
                email2: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.email2 : ""),
                roles: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.roles : ""),
                confirmation_token: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.confirmation_token : ""),
                password: '',
                password2: '',
            },
            infos: this.props.infos,
            etablissement: {
                civilite: "",
                codePostal: "",
                code_insee: "",
                email_etablissement: "",
                groupe_etablissement: "",
                groupe_id: null,
                id: 0,
                image_deux: "",
                image_quatre: "",
                image_trois: "",
                image_un: "",
                latitude: "",
                longitude: "",
                nom: "",
                nom_etablissement: "",
                numerofinesse: "",
                prenom: "",
                rue: "",
                site_etablissement: "",
                specs: [],
                statut: "",
                telephone: "",
                telephone_etablissement: "",
                type_etablissement_id: 0,
                ville: "",
                email: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.email : ""),
                email2: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.email2 : ""),
                username: (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.username : ""),
            },
            form: 1,
            form_etablissement: {},
            form_user: {display: "none"},
            chk1: "",
            chk2: "",
            chk3: "",
            chk4: "",
            chk5: "",
            submitted: false,
            btn1: true,
            btn2: true,
            image_un: '',
            image_deux: '',
            image_trois: '',
            image_quatre: '',
            condition: true,
            alert1: false,
            alert2: false,
        };

        this._handleChangeForm = this._handleChangeForm.bind(this);

        this._handleChangeUser = this._handleChangeUser.bind(this);
        this._handleChangeInfos = this._handleChangeInfos.bind(this);
        this._handleChangeSpec = this._handleChangeSpec.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.props.dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
    }

    componentDidMount(prevProps, prevState) {
        this.props.dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
        this.props.dispatch(groupesActions.getAllGroupes());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos !== this.state.infos) {
            let infos = this.props.infos;
            if (!infos) {
                console.log("null")
            } else {
                infos.etablissement.specs.map(item => {
                    switch (item.information_hebergement_id) {
                        case 1:
                            this.setState({chk1: "checked"});
                            break;
                        case 2:
                            this.setState({chk2: "checked"});
                            break;
                        case 3:
                            this.setState({chk3: "checked"});
                            break;
                        case 4:
                            this.setState({chk4: "checked"});
                            break;
                        case 5:
                            this.setState({chk5: "checked"});
                            break;
                        default:
                            console.log("null");

                    }
                });
                this.setState({
                    infos: this.props.infos,
                    etablissement: {
                        ...this.state.etablissement,
                        id: this.props.infos ? this.props.infos.etablissement.id : 0,
                        specs: this.props.infos ? this.props.infos.etablissement.specs : "",
                        civilite: this.props.infos ? this.props.infos.etablissement.civilite : "",
                        nom: this.props.infos ? this.props.infos.etablissement.nom : "",
                        prenom: this.props.infos ? this.props.infos.etablissement.prenom : "",
                        telephone: this.props.infos ? this.props.infos.etablissement.telephone : "",
                        nom_etablissement: this.props.infos ? this.props.infos.etablissement.nom_etablissement : "",
                        groupe_etablissement: this.props.infos ? this.props.infos.etablissement.groupe_etablissement : "",
                        groupe_id: this.props.infos ? this.props.infos.etablissement.groupe_id : null,
                        numerofinesse: this.props.infos ? this.props.infos.etablissement.numerofinesse : "",
                        rue: this.props.infos ? this.props.infos.etablissement.rue : "",
                        statut: this.props.infos ? this.props.infos.etablissement.statut : "",
                        codePostal: this.props.infos ? this.props.infos.etablissement.codePostal : "",
                        code_insee: this.props.infos ? this.props.infos.etablissement.code_insee : "",
                        telephone_etablissement: this.props.infos ? this.props.infos.etablissement.telephone_etablissement : "",
                        type_etablissement_id: this.props.infos ? this.props.infos.etablissement.type_etablissement_id : 0,
                        latitude: this.props.infos ? this.props.infos.etablissement.latitude : "",
                        longitude: this.props.infos ? this.props.infos.etablissement.longitude : "",
                        ville: this.props.infos ? this.props.infos.etablissement.ville : "",
                        email_etablissement: this.props.infos ? this.props.infos.etablissement.email_etablissement : "",
                        site_etablissement: this.props.infos ? this.props.infos.etablissement.site_etablissement : "",
                        image_un: (this.props.infos && this.props.infos.etablissement.image_un ? this.props.infos.etablissement.image_un : ""),
                        image_deux: (this.props.infos && this.props.infos.etablissement.image_deux ? this.props.infos.etablissement.image_deux : ""),
                        image_trois: (this.props.infos && this.props.infos.etablissement.image_trois ? this.props.infos.etablissement.image_trois : ""),
                        image_quatre: (this.props.infos && this.props.infos.etablissement.image_quatre ? this.props.infos.etablissement.image_quatre : ""),
                        email: this.props.user.user.email,
                        email2: this.props.user.user.email2 || "",
                        username: this.props.user.user.username
                    },
                    value: this.props.infos ? this.props.infos.etablissement.ville + ' ' + this.props.infos.etablissement.codePostal : "",
                    valueGroupe: this.props.infos ? this.props.infos.etablissement.groupe_etablissement || "" : ""
                });
            }
        }
        if (prevProps.groupesList !== this.props.groupesList) {
            this.props.groupesList.map((item) => {
                GroupeList.push({
                    value: {
                        id: item.id,
                        nom: item.nom,
                    },
                    label: item.nom
                })
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.infos !== prevState.infos) {
            return {infos: nextProps.infos};
        };
        if (nextProps.groupesList !== prevState.groupesList) {
            return {groupesList: nextProps.groupesList}
        }
        return null
    }


    _handleChangeForm(e) {
        const {name} = e.target;
        switch (name) {
            case "form1":
                this.setState({form_user: {display: "none"}, form_etablissement: {}, form: 1, alert1: false, alert2: false});
                break;
            case "form2":
                this.setState({form_user: {}, form_etablissement: {display: "none"}, form: 2, alert1: false, alert2: false});
                break;
            default:
                this.setState({form_etablissement: {}, form_user: {display: "none"}, form: 1, alert1: false, alert2: false});
        }
    }

    _handleChangeUser(e) {
        const {name, value} = e.target;

        const {user} = this.state;

        this.setState({btn2: false, btn1: false});

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            },
            alert1: false,
            alert2: false
        })
    }

    _handleChangeInfos(e) {

        const {name, value} = e.target;
        const {etablissement, user} = this.state;

        this.setState({btn2: false});

        if (name === 'email' || name === 'email2') {
            this.setState({
                etablissement: {
                    ...etablissement,
                    [name]: lib.protectEntry(value)
                },
                user: {
                    ...user,
                    [name]: lib.protectEntry(value)
                },
                btn2: false,
                alert1: false,
                alert2: false
            })
        } else {
            this.setState({
                etablissement: {
                    ...etablissement,
                    [name]: lib.protectEntry(value)
                },
                btn2: false,
                alert1: false,
                alert2: false
            })
        }
    }

    _handleChangeSpec(e) {
        const {value, checked} = e.target;
        const {etablissement, chk1, chk2, chk3, chk4, chk5} = this.state;

        this.setState({btn2: false});

        switch (value) {
            case "1":
                this.setState({chk1: !chk1});
                break;
            case "2":
                this.setState({chk2: !chk2});
                break;
            case "3":
                this.setState({chk3: !chk3});
                break;
            case "4":
                this.setState({chk4: !chk4});
                break;
            case "5":
                this.setState({chk5: !chk5});
                break;
            default:
                console.log("null")
        }

        if (!checked) {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: etablissement.specs.filter(function (item) {
                        return item.information_hebergement_id !== parseInt(value);
                    })
                },
                alert1: false,
                alert2: false
            })
        } else {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: [...etablissement.specs, {etablissement_id: parseInt(etablissement.id), information_hebergement_id: parseInt(value)}],
                },
                alert1: false,
                alert2: false
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            etablissement: {
                ...this.state.etablissement,
                codePostal: '',
                code_insee: '',
                ville: '',
                latitude: '',
                longitude: ''
            }
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {etablissement} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            etablissement: {
                ...etablissement,
                codePostal: suggestion.value.codePostal,
                code_insee: suggestion.value.code_insee,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0]
            }

        })

    };

    onChangeGroupe = (event, { newValue }) => {
        this.setState({
            valueGroupe: newValue,
            etablissement: {
                ...this.state.etablissement,
                groupe_etablissement: newValue,
            },
            filter: {
                ...this.state.filter,
                groupe_id: null,
            },
        });
    };

    onGroupeSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestionsGroupe: getGroupeSuggestions(value)
        });
    };

    onGroupeSuggestionsClearRequested = () => {
        this.setState({
            suggestionsGroupe: []
        });
    };

    onGroupeSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {etablissement, filter, preview} = this.state;

        this.setState({
            valueGroupe: suggestion.value.nom,
            etablissement: {
                ...etablissement,
                groupe_id: suggestion.value.id,
                groupe_etablissement: suggestion.value.nom,
            },
            btn2: false,
        })
    };

    async handleSubmit(e) {
        e.preventDefault();


        this.setState({submitted: true});
        const {user, etablissement, form} = this.state;
        const {dispatch} = this.props;

        if (form === 2) {
            if (user.password && lib.isValidPassword(user.password) && lib.passwordMatch(user.password, user.password2)) {
                if (lib.isValidUsername(lib.protectEntry(user.username)) && lib.isValidEmail(lib.protectEntry(user.email))) {
                    await dispatch(userActions.updateUser(user, "personne", this.props.history));
                    await dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
                    this.setState({alert1: true})
                }
            } else if (lib.isValidUsername(lib.protectEntry(user.username)) && lib.isValidEmail(lib.protectEntry(user.email))) {
                await dispatch(userActions.updateUser(user, "personne", this.props.history));
                await dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
                this.setState({alert1: true})
            } else {
                Alert("Assurez que les informations personnelles soient correctes");
            }
        } else if (form === 1) {
            if (
                (etablissement.nom ? lib.isValidName(etablissement.nom) : true) &&
                (etablissement.prenom ? lib.isValidName(etablissement.prenom) : true) &&
                lib.isValidPhone(etablissement.telephone) &&
                (etablissement.numerofinesse ? lib.isValidFiness(etablissement.numerofinesse) : true) &&
                lib.isValidAdress(etablissement.rue) &&
                lib.isValidCP(etablissement.codePostal) &&
                lib.isValidPhone(etablissement.telephone_etablissement) &&
                lib.isValidEmail(etablissement.email_etablissement) &&
                lib.isValidEmail(etablissement.email) &&
                (etablissement.site_etablissement ? lib.isValidURL(etablissement.site_etablissement) : true)

            ) {
                await dispatch(etablissementActions.updateEtablissement(etablissement, user, this.props.history));
                await dispatch(etablissementActions.getEtablissement(this.props.user.user.etablissement_id));
                this.setState({alert2: true})
            } else {
                Alert("Assurez vous de renseigner correctement les informations à mettre à jour");
            }
        }
    }

    render() {
        const {user, etablissement, alert1, alert2, value, valueGroupe, suggestions, suggestionsGroupe, form, submitted} = this.state;
        const {alert} = this.props;
        const inputProps = {
            placeholder: 'Saisir une ville ou un CP et choisir dans la liste',
            value,
            onChange: this.onChange
        };
        const inputGroupeProps = {
            placeholder: 'Veuillez choisir un groupe ou en créer un',
            value: valueGroupe,
            onChange: this.onChangeGroupe
        };

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !(this.props.infos) ?
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                        <main>
                            <section className="inscriptionStep1">
                                <div className="container">

                                    <p className="chemin"><a href="/etablissements">Mon compte</a> &gt; Mes informations</p>
                                    <h1>Mes informations</h1>

                                    <div className="titleForm">
                                        <button id="formEtablisssement"
                                                name="form1" value="1"
                                                className={form === 1 ? "btn btn-primary active" : "btn btn-primary"}
                                                onClick={this._handleChangeForm}>Mon établissement
                                        </button>
                                        <button id="formCoordonnees" name="form2" value="2"
                                                className={form === 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"}
                                                onClick={this._handleChangeForm}>Mes identifiants
                                        </button>
                                    </div>

                                    <form name="adminbundle_user" onSubmit={this.handleSubmit}
                                          className="formCoordonnees"
                                          style={this.state.form_user}>
                                        <div className="encadreForm col-xs-12">
                                            <div className="question">
                                                <p>Modifiez vos identifiants de connexion</p>
                                            </div>
                                            <br/>
                                            {
                                                alert.message && alert1 ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                                    : null
                                            }
                                            <br/>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Identifiant <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeUser}
                                                       name="username" required="required" maxLength="180"
                                                       pattern=".{2,}" value={user.username}
                                                       style={{boxShadow: alert.message === "cet Identifiant existe déjà !" ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label htmlFor="userPassword">Mot de passe <span>*</span></label>
                                                <input type="password" onChange={this._handleChangeUser}
                                                       name="password" value={user.password}
                                                       className="password-field"/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label htmlFor="userPassword">Retapez votre mot de
                                                    passe <span>*</span></label>
                                                <input type="password" onChange={this._handleChangeUser }
                                                       name="password2" value={user.password2}
                                                       className="password-field"/>
                                            </div>
                                            <div className="bouton submit">
                                                <input type="submit" name="envoyer" id="valider1"
                                                       value="Modifier mes informations" disabled={this.state.btn1}/>
                                                <div className="error text-danger"> </div>
                                            </div>
                                        </div>
                                    </form>

                                    <form name="appbundle_etablissement" onSubmit={this.handleSubmit}
                                          style={this.state.form_etablissement}>
                                        <br/>
                                        {
                                            alert.message && alert1 ?
                                                <div className={`alert ${alert.type}`}>{alert.message}</div> :
                                                alert.message && alert2 ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                        }
                                        <br/>
                                        <div className="encadreForm col-xs-12">
                                            <div className="question">
                                                <p><strong>Responsable</strong> (Informations pour <span
                                                    className="urltitre">Place<span
                                                    className="rouge">Maison</span>De<span
                                                    className="rouge">Retraite</span>.fr</span> pour
                                                    la gestion des annonces, pour recevoir vos alertes. Ces informations
                                                    ne
                                                    sont
                                                    pas à destination des familles et n’apparaitront pas dans vos
                                                    annonces)
                                                </p>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Civilité</label>
                                                <select id="civilite" onChange={this._handleChangeInfos}
                                                        name="civilite" value={etablissement.civilite}>
                                                    <option value=" "> </option>
                                                    <option value="Mademoiselle">Mademoiselle</option>
                                                    <option value="Madame">Madame</option>
                                                    <option value="Monsieur">Monsieur</option>
                                                </select>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Nom</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="nom"
                                                       placeholder="Nom du responsable"
                                                       value={etablissement.nom}
                                                       style={{boxShadow: etablissement.nom && !lib.isValidName(etablissement.nom) ? '0 0 3px #CC0000' : ''}}/>
                                                <div style={{color: 'red'}}>{etablissement.nom && !lib.isValidName(etablissement.nom) ? "Format du Nom incorrect": ""}</div>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Prenom</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="prenom"
                                                       placeholder="Prenom du responsable"
                                                       value={etablissement.prenom}
                                                       style={{boxShadow: etablissement.prenom && !lib.isValidName(etablissement.prenom) ? '0 0 3px #CC0000' : ''}}/>
                                                <div style={{color: 'red'}}>{etablissement.prenom && !lib.isValidName(etablissement.prenom) ? "Format du Prenom incorrect": ""}</div>
                                            </div>
                                            <div className={"inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" + (submitted && !lib.isValidPhone(etablissement.telephone) ? ' has-error' : '')}>
                                                <label>Téléphone Responsable<span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="telephone" required="required"
                                                       placeholder="numéro du responsable"
                                                       value={etablissement.telephone}
                                                       style={{boxShadow: !lib.isValidPhone(etablissement.telephone) ? '0 0 3px #CC0000' : ''}}/>
                                                       <div style={{color: 'red'}}>{!lib.isValidPhone(etablissement.telephone) ? "doit contenir 10 chiffres (avec ou sans espaces)": ""}</div>
                                            </div>
                                            <div className={"inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" + (submitted && !lib.isValidEmail(user.email) ? ' has-error' : '')}>
                                                <label htmlFor="adminbundle_responsable_email"
                                                       className="required">Email Responsable<span>*</span>
                                                </label>
                                                <input type="email" onChange={this._handleChangeInfos}
                                                       name="email"
                                                       required="required"
                                                       maxLength="180"
                                                       pattern=".{2,}"
                                                       value={etablissement.email}
                                                       style={{boxShadow: alert.message === "cet Email de responsable existe déjà !" ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label htmlFor="adminbundle_secours_email"
                                                       className="required">Email de Secours et/ou de Groupe (facultatif)</label>
                                                <input type="email" onChange={this._handleChangeInfos}
                                                       name="email2"
                                                       maxLength="180"
                                                       pattern=".{2,}"
                                                       value={etablissement.email2}/>
                                            </div>
                                            <div className="question">
                                                <p><strong>Établissement</strong> (les informations en rouge serviront à
                                                    créer
                                                    vos annonces)</p>
                                            </div>

                                            <div className={"inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group" + (submitted && !lib.isValidUsername(etablissement.nom_etablissement) ? ' has-error' : '')}>
                                                <label style={{color: "red"}}>Nom de votre
                                                    etablissement <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="nom_etablissement" placeholder="Nom de l'établissement"
                                                       value={etablissement.nom_etablissement}
                                                       style={{boxShadow: !lib.isValidUsername(etablissement.nom_etablissement) ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Votre Groupe (le cas échéant)</label>
                                                <Autosuggest
                                                    suggestions={suggestionsGroupe}
                                                    onSuggestionsFetchRequested={this.onGroupeSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onGroupeSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onGroupeSuggestionSelected}
                                                    getSuggestionValue={getGroupeSuggestionValue}
                                                    renderSuggestion={renderGroupeSuggestion}
                                                    inputProps={inputGroupeProps}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label style={{color: "red"}}>Type
                                                    d'établissement <span>*</span></label>
                                                <select onChange={this._handleChangeInfos}
                                                        name="type_etablissement_id"
                                                        value={etablissement.type_etablissement_id}>
                                                    <option value="1">EHPAD</option>
                                                    <option value="2">USLD</option>
                                                    <option value="3">Résidence autonomie</option>
                                                    <option value="4">Résidence services seniors</option>
                                                </select>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Statut <span>*</span></label>
                                                <select onChange={this._handleChangeInfos}
                                                        name="statut"
                                                        value={etablissement.statut}
                                                >
                                                    <option value="public">PUBLIC</option>
                                                    <option value="privé non lucratif (association)">PRIVE NON LUCRATIF
                                                        (association)
                                                    </option>
                                                    <option value="privé">PRIVE</option>
                                                </select>
                                            </div>
                                            {
                                                parseInt(etablissement.type_etablissement_id) !== 4 ?
                                                <div className={"inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 finessField form-group" + (submitted && !lib.isValidFiness(etablissement.numerofinesse) ? ' has-error' : '')}>
                                                    <label>Numéro finess <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="numerofinesse" required="required"
                                                           maxLength="9"
                                                           placeholder="N° FINESS"
                                                           value={etablissement.numerofinesse}
                                                           style={{boxShadow: alert.message === "ce numéro finess est déjà attribué !" ? '0 0 3px #CC0000' : ''}}
                                                    />
                                                </div>
                                                    :
                                                    null
                                            }
                                            <div className={"inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" + (submitted && !lib.isValidAdress(etablissement.rue) ? ' has-error' : '')}>
                                                <label style={{color: "red"}}>N° et nom de la
                                                    voie <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="rue"
                                                       required="required"
                                                       placeholder="Nom et N° de Rue"
                                                       style={{boxShadow: !lib.isValidAdress(etablissement.rue) ? '0 0 3px #CC0000' : ''}}
                                                       value={etablissement.rue}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 villeInput">
                                                <label style={{color: "red"}}>Ville  <span>*</span></label>
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    highlightFirstSuggestion={true}
                                                    style={{boxShadow: !lib.isValidCP(etablissement.codePostal) ? '0 0 3px #CC0000' : ''}}/>
                                                <div style={{color: 'red'}}>{!lib.isValidCP(etablissement.codePostal) ? "Veuillez saisir un Code Postal ou une Ville puis selectionner le resultat dans le menu déroulant": ""}</div>
                                            </div>
                                            <div className={"inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" + (submitted && !lib.isValidPhone(etablissement.telephone_etablissement) ? ' has-error' : '')}>
                                                <label style={{color: "red"}}>Téléphone Établissement<span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="telephone_etablissement"
                                                       placeholder="numéro de l'établissement"
                                                       value={etablissement.telephone_etablissement}
                                                       style={{boxShadow: !lib.isValidPhone(etablissement.telephone_etablissement) ? '0 0 3px #CC0000' : ''}}/>
                                                <div style={{color: 'red'}}>{!lib.isValidPhone(etablissement.telephone_etablissement) ? "doit contenir 10 chiffres (avec ou sans espaces)": ""}</div>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label style={{color: "red"}}>Email de
                                                    l'établissement <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="email_etablissement"
                                                       placeholder="Email de l'établissement"
                                                       value={etablissement.email_etablissement}
                                                       required="required"
                                                       style={{boxShadow: !lib.isValidEmail(etablissement.email_etablissement) ? '0 0 3px #CC0000' : ''}}/>
                                                <div style={{color: 'red'}}>{!lib.isValidEmail(etablissement.email_etablissement)? "Format Email incorrect": ""}</div>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6">
                                                <label style={{color: "red"}}>Site Internet</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="site_etablissement"
                                                       placeholder="Site internet de l'établissement"
                                                       value={etablissement.site_etablissement}
                                                       style={{boxShadow: etablissement.site_etablissement && !lib.isValidURL(etablissement.site_etablissement) ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                        </div>

                                        <div className="encadreForm col-xs-12">
                                            <div className="inputConnexion inputSpecificite col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <label htmlFor="userPassword">SPECIFICITES (plusieurs
                                                    choix
                                                    possible)</label>
                                                <div id="appbundle_etablissement_informationHebergement">
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_1"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="1"
                                                           checked={this.state.chk1}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_1">Hebergement permanent</label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_2"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="2"
                                                           checked={this.state.chk2}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_2">
                                                        Hebergement temporaire</label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_3"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="3"
                                                           checked={this.state.chk3}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_3">
                                                        Accueil de jour
                                                    </label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_4"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="4"
                                                           checked={this.state.chk4}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_4">
                                                        Unité alzheimer</label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_5"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="5"
                                                           checked={this.state.chk5}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_5">
                                                        Aide social à l’hébergement
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="bouton submit col-xs-12">
                                                <input type="submit"
                                                       id="valider2"
                                                       name="envoyer"
                                                       value="Enregistrer mes informations"/>

                                                {
                                                    alert.message && alert1 ?
                                                        <div className={`alert ${alert.type}`}>{alert.message}</div> :
                                                        alert.message && alert2 ?
                                                            <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                                }
                                                < div className="error text-danger"> </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </section>
                        </main>
                }
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {user} = state.setUpdate.user ? state.setUpdate : state.authentication;
    const {infos} = state.etablissement;
    const {alert, groupes} = state;
    const groupesList = groupes.list;

    return {
        user,
        infos,
        alert,
        groupesList
    }
}

export default withRouter(connect(mapStateToProps)(Mes_Infos))
