import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from "../../_components/Footer";
import Navbar from "../../_components/Navbar";

class Charte_Utilisation extends Component {

    constructor(props){
        super(props)

        this.state = {

        }
    }

    render(){
        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>


                <section className="placeMaisonBloc1">
                    <div className="container">
                        <h1>Charte d’utilisation</h1>
                        <div style={{textAlign: 'center', fontSize: '5rem'}}>
                                <b><span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b>
                        </div>

                        <h2>Pour les Établissements</h2>

                        <h4><b>Inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b></h4>


                        <p>
                            1. Pour être inscrit et utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, l’établissement doit être soit un EHPAD, ESLD/USLD, une Résidence Autonomie ou une Résidence Services Seniors.<br/>
                            2. L’inscription et l’utilisation du site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> sont gratuites pour les établissements.<br/>
                            3. L’établissement est pleinement responsable des informations et photos qu’il enregistre sur son compte.
                        </p>
                        <br/>
                        <h4><b>Publication d’annonces de places disponibles</b></h4>

                        <p>
                            4. L’établissement est pleinement responsable des annonces qu’il publie sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> même si celles-ci sont relues par un modérateur.<br/>
                            5. L’établissement certifie que chaque annonce correspond à une disponibilité qu’il en mesure de proposer à une famille.<br/>
                            6. L’établissement s’engage à supprimer ou ne pas prolonger son annonce quand celle-ci n’a plus d’objet.<br/>
                            7. L’établissement peut avoir au maximum 3 annonces simultanément en ligne sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>.

                        </p>
                        <br/>
                        <h4><b>Consultation des demandes de places disponibles des familles et/ou professionnels qui
                            les accompagnent</b></h4>

                        <p>
                            8. L’établissement s’engage à contacter les familles ou les professionnels qui les accompagnent suite à une demande de place disponible de leur part, uniquement si l’établissement a concrètement une solution à leur proposer.<br/>
                            9. L’établissement s’engage à utiliser les données, informations des familles ou des professionnels qui les accompagnent, uniquement dans le cadre de l’annonce pour laquelle il a été contacté ou dans le cadre de la demande de place qu’il a reçue. Ces données ne peuvent être utilisées que pour le seul compte de l’établissement. Elles ne peuvent pas être transmises à tout autre établissement inscrit ou non sur le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> ou autre prestataire ou entreprise.
                        </p>
                        <br/>
                        <h4><b>Communication avec <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b></h4>

                        <p>
                            10. L’établissement accepte de recevoir par e-mail, les informations, recommandations, conseils envoyés par le gestionnaire du site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                        </p>

                        <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                            Dans le cas du non-respect de l’un des articles de la présente charte d’utilisation, alors l’établissement incriminé verra son compte supprimé sans délais et sans condition.
                        </p>


                        <h1>Pour les familles ou pour les professionnels qui les accompagnent informent et conseillent
                            les familles sans contrepartie financière auprès d’elles ou des établissements</h1>

                        <h4><b>Inscription sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b></h4>

                        <p>
                            1 - Pour être inscrit et/ou utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, le professionnel qui accompagne les familles doit les informer et les conseiller sans contrepartie financière auprès d’elles ou des établissements.<br/>
                            2 - Pour être inscrit et/ou utiliser le site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>, la famille ou le professionnel qui l’accompagne doit être en situation de recherche effective d’établissement pour une personne âgée.<br/>
                            3 - La famille ou le professionnel qui l’accompagne est pleinement responsable des informations qu’il enregistre sur son compte.
                        </p>
                        <br/>
                        <h4><b>Publication des demandes de places auprès des établissements</b></h4>

                        <p>
                            4 - La famille ou le professionnel qui l’accompagne est pleinement responsable des demandes de places qu’il publie sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> à destination des établissements inscrits sur le site, même si celles-ci sont relues par un modérateur.<br/>
                            5 - La famille ou le professionnel qui l’accompagne certifie que chaque « demande de place auprès des établissements » correspond à une recherche effective qu’il adresse aux établissements.<br/>
                            6 - La famille ou le professionnel qui l’accompagne s’engage à supprimer  ou ne pas prolonger sa demande de place quand celle-ci n’a plus d’objet.
                        </p>
                        <br/>
                        <h4><b>Consultation des annonces de places disponibles des établissements</b></h4>

                        <p>
                            7 - La famille ou le professionnel qui l’accompagne s’engage à utiliser les données, informations des établissements, uniquement dans le cadre de leur recherche. Ces données ne peuvent être utilisées que pour le seul compte de la famille ou du professionnel qui l’accompagne.
                        </p>
                        <br/>
                        <h4><b>Inscription à une «&nbsp;Alerte-Dispo&nbsp;»</b></h4>

                        <p>
                            8 - La famille ou le professionnel qui l’accompagne est pleinement responsable de son inscription à une « Alerte-Dispo » sur <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>

                        </p>
                        <br/>
                        <h4><b>Communication avec <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span></b></h4>

                        <p>
                            9 - La famille ou le professionnel qui l’accompagne accepte de recevoir par e-mail, les informations, recommandations, conseils envoyés par le gestionnaire du site <span className="urltitre2">Place<span className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span>
                        </p>

                        <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                            Dans le cas du non-respect de l’un des articles de la présente charte d’utilisation, alors la famille ou le professionnel qui l’accompagne incriminé verra son compte supprimé sans délais, sans condition.
                        </p>
                    </div>
                </section>
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        //load: state.ui.load,
    }
}

export default connect(mapStateToProps)(Charte_Utilisation)