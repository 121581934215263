import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {Confirm, Alert} from "react-st-modal";
import ScrollUpButton from "react-scroll-up-button";
import Autosuggest from 'react-autosuggest';

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import {etablissementActions} from "../../../../_actions";
import projectList from "../../../../communes/communes.json";
import {history} from "../../../../_helpers";
import ReactPaginate from "react-paginate";
import {withRouter} from "react-router";
import 'icheck/skins/all.css'; // or single skin css
import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Demandes_Familles extends Component {

    constructor(props){
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            demande: {},
            single: {},
            preview: false,
            deleted: [],
            filter: {
                Codepostal: '',
                Type: ["1", "2", "3", "4"],
                direc: 'desc',
                codePostal: '',
                ville: '',
                search: '',
                lat: '',
                lng: '',
                rayon: '',
                distance: false,
                activate: "Tous",
                placeholder: 'Indiquez un Code Postal ou une Ville'
            },
            start: 0,
            end: 15,
            selected: 0,
            screenWidth: null
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.activate = this.activate.bind(this);
        this.sortDistance = this.sortDistance.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.handlePageClick = this.handlePageClick.bind(this);

        this.distanceInKmBetweenEarthCoordinates = this.distanceInKmBetweenEarthCoordinates.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getAllDemandes(this.props.user.user.id));
        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.demandes !== this.state.demandes) {
            let demandes = this.props.demandes;

            this.setState({
                demandes
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.demandes !== prevState.demandes) {

            return {demandes: nextProps.demandes};
        } else return null;
    }

    handlePreview(event) {
        event.preventDefault();

        const array = this.props.demandes.demandes.filter(item => item.id == event.target.id)

        this.setState({
            single: array[0],
            preview: true
        })
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false})
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        let response = await Confirm("Voulez-vous vraiment supprimer cette demande?", "", "Oui", "Fermer");
        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            this.props.dispatch(etablissementActions.delDemande(id))
        }
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;
        const {filter, demandes} = this.state;

        if (name === 'direc') {
            this.setState({
                demande: {
                    ...demandes,
                    demandes: demandes.demandes.reverse()
                },
                start: 0,
                end: 15
            })
        } else if (name === "rayon") {
            if (filter.lat.length === 0 && filter.lng.length === 0) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: value,
                        distance: true,
                    },
                    start: 0,
                    end: 15
                })
            }
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value
                },
                start: 0,
                end: 15
            })
        }
    }

    changeInput(name, value, type) {
        const {filter} = this.state;
        const {Type} = filter;

        if (value) {
            this.setState({
                filter: {
                    ...filter,
                    Type: Type.filter(item => item !== type)
                },
                [name]: !value,
                start: 0,
                end: 15
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    Type: [...Type, type]
                },
                [name]: !value,
                start: 0,
                end: 15
            })
        }
    }

    clickCheckBox(e) {
        const { name, value, type, checked } = e.target;
        const { filter } = this.state;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        Type: [...filter.Type, value]
                    },
                    [name]: true,
                    start: 0,
                    end: 15
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        Type: filter.Type.filter(function (item) {
                            return item !== value
                        })
                    },
                    [name]: false,
                    start: 0,
                    end: 15
                })
            }
        }
    }

    supprimerFiltre() {
        const {filter} = this.state;
        this.setState({
            filter: {
                ...filter,
                Codepostal: '',
                Type: ["1", "2", "3", "4"],
                direc: 'desc',
                codePostal: '',
                ville: '',
                search: '',
                lat: '',
                lng: '',
                rayon: '',
                distance: false,
                activate: "Tous",
                placeholder: 'Indiquez un Code Postal ou une Ville'
            },
            value: '',
            start: 0,
            end: 15
        })
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { filter } = this.state;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            filter: {
                ...this.state.filter,
                Codepostal: '',
                lat: '',
                lng: '',
                ville: '',
                rayon: '',
                distance: false
            },
            start: 0,
            end: 15
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {filter} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            filter: {
                ...filter,
                Codepostal: suggestion.value.codePostal,
                ville: suggestion.value.nom,
                lat: suggestion.value.geoLoc.coordinates[1],
                lng: suggestion.value.geoLoc.coordinates[0],
                rayon: "5",
                distance: true
            }

        })

    };

    renderFilter(item) {
        const {filter, deleted} = this.state;
        const {types} = this.props.demandes;

        let t_final = [];
        types.filter(function(e) {
            return (e.demande_etablissement_id == item.id)
        }).map(el =>
            t_final.push(el.type_etablissement_id)
        );

        const Codepostal    = filter.Codepostal  !== '' ? (filter.Codepostal === item.Codepostal) : filter.ville !== '' ? (filter.ville === item.ville) : true;
        const rayon         = filter.distance === true  ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon, 10)) : true;
        const Type          = t_final.filter(function(value) { return filter.Type.includes(String(value))});
        const notDel        = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;
        let isActive        = (filter.activate !== 'Tous' ? (parseInt(filter.activate) === parseInt(item.activate)) : true);
        const locality = (Codepostal || filter.distance);

        return (locality && Type.length > 0 && rayon && isActive && notDel)
    }

    renderActive(item) {
        switch (item.activate) {
            case 1 : return <div style={{color: 'green'}}>Activée <span className="green-dot"></span></div>;
            case -1: return <div style={{color: 'red'}}>En attente d'activation <span className="orange-dot"></span></div>;
            case -2: return <div style={{color: 'red'}}>Désactivée utilisateur <span className="orange-dot"></span></div>;
            default: return <div style={{color: 'red'}}>Désactivée système <span className="orange-dot"></span></div>;
        }
    }

    async activate(e) {
        e.preventDefault();
        const {id, value} = e.target;
        if (parseInt(value) === 1) {
            await this.props.dispatch(etablissementActions.activateDemande(id, -1))
        } else {
            await this.props.dispatch(etablissementActions.activateDemande(id, 1))
        }
        await this.props.dispatch(etablissementActions.getAllDemandes())
    }

    fill_types(types, item) {
        let t_final = [];

        types.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            t_final.push(String(el.type_etablissement_id))
        );

        return t_final
    }

    fill_specs(specs, item) {
        let s_final = [];

        specs.filter(function(e) {
            return (e.demande_etablissement_id === item.id)
        }).map(el =>
            s_final.push(String(el.information_hebergement_id))
        );

        return s_final
    }

    redirectToModif(e, demandes, item){
        e.preventDefault();

        let demande = item;
        let types = this.fill_types(demandes.types, item);
        let specs = this.fill_specs(demandes.infos, item);


        this.props.history.push({pathname: '/demande-modification', state: {demandes: {demande, types, specs}}});
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15, selected})
        window.scrollTo(0,0)
    };

    render(){
        moment.locale('fr');
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const specs = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];
        const {alert} = this.props;
        const {demandes, preview, single, value, suggestions, filter, start, end, selected, screenWidth} = this.state;
        let filtredDemandes = this.props.demandes.demandes ? this.props.demandes.demandes.filter(item => this.renderFilter(item)) : [];
        filtredDemandes = filter.distance ? filtredDemandes.sort((a, b) => this.sortDistance(a, b)) : filtredDemandes;

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                    {
                        !preview ?
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; Demandes des familles / Pros
                                    </p>
                                    <h1>Demandes <span>DES FAMILLES / PROS</span></h1>

                                    <form>
                                        <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                            <div className="infoSpecial">
                                                {
                                                    screenWidth > 763 ?
                                                        <div className="listeEtablissementTri">
                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce1.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                         title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_1"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>EHPAD</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_1"
                                                                       name="type1"
                                                                       value="1"
                                                                       checked={filter.Type.includes("1")}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce3.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                         title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_2"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>USLD</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_2"
                                                                       name="type2"
                                                                       value="2"
                                                                       checked={filter.Type.includes("2")}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce4.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                         title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_3"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>Résidence autonomie</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_3"
                                                                       name="type3"
                                                                       value="3"
                                                                       checked={filter.Type.includes("3")}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce5.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                         title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_4"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>Résidence
                                                                        services
                                                                        seniors</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_4"
                                                                       name="type4"
                                                                       value="4"
                                                                       checked={filter.Type.includes("4")}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{justifyContent: 'center'}}>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                                             alt="EHPAD"
                                                                             onClick={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type1"
                                                                            name="type1"
                                                                            value="1"
                                                                            checked={filter.Type.includes("1")}
                                                                            onChange={() => this.changeInput("type1", filter.Type.includes("1"), "1")}
                                                                        />
                                                                        <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                                             alt="USLD"
                                                                             onClick={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type2"
                                                                            name="type2"
                                                                            value="2"
                                                                            checked={filter.Type.includes("2")}
                                                                            onChange={() => this.changeInput("type2", filter.Type.includes("2"), "2")}
                                                                        />
                                                                        <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                                             alt="Résidence autonomie"
                                                                             onClick={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type3"
                                                                            name="type3"
                                                                            value="3"
                                                                            checked={filter.Type.includes("3")}
                                                                            onChange={() => this.changeInput("type3", filter.Type.includes("3"), "3")}
                                                                        />
                                                                        <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                                             alt="Résidence services seniors"
                                                                             onClick={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type4"
                                                                            name="type4"
                                                                            value="4"
                                                                            checked={filter.Type.includes("4")}
                                                                            onChange={() => this.changeInput("type4", filter.Type.includes("4"), "4")}
                                                                        />
                                                                        <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                }
                                                <div className="secondFiltre">
                                                    <div className="villeInput triVille" style={{position: "relative", width: "100%", margin: "0 auto"}}>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>

                                                    <div className="listeKm" style={{marginRight: "1rem"}}>
                                                        <button className={filter.rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                            5km
                                                        </button>
                                                        <button className={filter.rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                            15km
                                                        </button>
                                                        <button className={filter.rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                            30km
                                                        </button>
                                                        <button className={filter.rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                            50km
                                                        </button>
                                                        <button className={filter.rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                            100km
                                                        </button>
                                                    </div>

                                                    <div className="bouton submit subConf">
                                                        <input type="reset" name="supprimer"
                                                               value="Supprimer les Filtres"
                                                               style={{marginTop: "10px"}}
                                                               onClick={this.supprimerFiltre}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="filtres col-xs-12">
                                                <select id="appbundle_demandeetablissement_rayon"
                                                        name="activate"
                                                        value={filter.activate}
                                                        onChange={this.handleChangeFilter}>
                                                    <option value="Tous">Toutes les demandes</option>
                                                    <option value="1">Activées</option>
                                                    <option value="-1">En attente</option>
                                                    <option value="0">Désactivées système</option>
                                                    <option value="-2">Désactivées utilisateur</option>
                                                </select>
                                                <p id="annoncesCount">{filtredDemandes.length} demande(s).</p>
                                            </div>
                                        </div>
                                    </form>
                                    <br/>
                                    {
                                        alert ?
                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                            :
                                            null
                                    }
                                    <br/>
                                    <div id="listeDemande">
                                        {!this.props.demandes ?
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            :
                                            filtredDemandes.filter(item => this.renderFilter(item)).slice(start, end).map((item) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={item.id}>
                                                    <div className="singleDemande">
                                                        {this.renderActive(item)}
                                                        <p>Publiée le {moment(item.created_at).format('DD/MM/YYYY')}</p>
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>Cherche place à {item.ville} ({item.Codepostal})</p>
                                                            </div>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-sexe.png"
                                                                 alt="icone-sexe"/>
                                                            <p>Publiée par: <strong>{item.username}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-sexe.png"
                                                                 alt="icone-sexe"/>
                                                            {!item.is_couple ?
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age} ans</strong></p>
                                                                :
                                                                <p>Pour <strong>{item.sexe}</strong> de <strong>{item.age_premier} ans</strong> et <strong>{item.age_second} ans</strong></p>
                                                            }
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                 alt="icone-type-etablissement"/>
                                                            <p>Type d'établissement : <br/>
                                                                {
                                                                    demandes.types.map((t, index) => {
                                                                        if (t.demande_etablissement_id === item.id) {
                                                                            return (
                                                                                <strong key={index}>
                                                                                    {types[t.type_etablissement_id - 1]}
                                                                                    <br/>
                                                                                </strong>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                                 alt="icone-secteur"/>
                                                            <p>Secteur géographique : <br/><strong>{item.ville}, dans un rayon
                                                                de {item.rayon}
                                                                km</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                                 alt="icone-echeance"/>
                                                            <p>Echéance : <strong>{item.echeance}</strong></p>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={(e) => this.handlePreview(e, item)} id={item.id}>Voir</button>
                                                        </div>

                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={(e) => this.handleDelete(e, item.id)} id={item.id}>Supprimer</button>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={(e) => this.redirectToModif(e, demandes, item)}>Modifier</button>
                                                        </div>
                                                        {
                                                            parseInt(item.activate) !== 1 ?
                                                                <div className="col-xs12 bouton supprimer">
                                                                    <button onClick={this.activate} id={item.id} value={item.activate}>Activer</button>
                                                                </div>
                                                                :
                                                                <div className="col-xs12 bouton supprimer">
                                                                    <button onClick={this.activate} id={item.id} value={item.activate}>Désactiver</button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className="col-xs-12 pages">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={Math.ceil(filtredDemandes.length/15)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        forcePage={start === 0 ? 0 : selected}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'btn-page'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </section>
                            :
                            <section className="visualisationDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; <a
                                        href="/administrateur/demande-etablissement">Demandes Etablissements</a> &gt; Je
                                        visualise la demande</p>
                                    <h1>Je visualise <span>la demande</span></h1>

                                    <p className="titleForm">Informations visibles par tous</p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_1 col-xs-12">
                                            <div className="row">
                                                <p className="titleLieu">Cherche place à {single.ville}</p>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-sexe.png"
                                                         alt="icone-sexe"/>
                                                    {!single.is_couple ?
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age} ans</strong></p>
                                                        :
                                                        <p>Pour <strong>{single.sexe}</strong> de <strong>{single.age_premier} ans</strong> et <strong>{single.age_second} ans</strong></p>
                                                    }
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-type-etablissement.png"
                                                         alt="icone-type-etablissement"/>
                                                    <p>Type d'établissement : <br/>
                                                        {
                                                            demandes.types.map(t => {
                                                                if (t.demande_etablissement_id == single.id) {
                                                                    return (
                                                                        <strong key={Math.floor(Math.random() * Math.floor(1000))}>
                                                                            {types[t.type_etablissement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-secteur.png"
                                                         alt="icone-secteur"/>
                                                    <p>Secteur géographique : <strong>{single.ville}, dans un rayon de {single.rayon}
                                                        km</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-echeance.png"
                                                         alt="icone-echeance"/>
                                                    <p>Echéance : <strong>{single.echeance}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="titleForm">Informations visibles uniquement par les établissements
                                        identifiés sur le site <span>Placemaisonderetraite.fr</span></p>
                                    <div className="encadreForm">
                                        <div className="visualisationDemandeBloc1_2 col-xs-12">
                                            <div className="row">
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-lieu.png"
                                                         alt="icone-lieu"/>
                                                    <p>La personne se trouve : <strong>{single.logemennt}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive"
                                                         src="/img/visualisation-demande/icone-budget.png"
                                                         alt="icone-budget"/>
                                                    <p>Budget mensuel : <strong>{single.budget}</strong></p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i>
                                                    <p>Type d’hébergement : <br />
                                                        {
                                                            demandes.infos.map(i => {
                                                                if (i.demande_etablissement_id == single.id) {
                                                                    return (
                                                                        <strong key={Math.floor(Math.random() * Math.floor(1000))}>
                                                                            {specs[i.information_hebergement_id - 1]}
                                                                            <br/>
                                                                        </strong>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li><strong>{single.civilite} {single.nom}</strong></li>
                                                        <li>Tél : <strong>{single.telephone}</strong></li>
                                                        <li>Email : <strong>{single.email}</strong></li>
                                                    </ul>
                                                </div>
                                                <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                    <img className="img-responsive" src="/img/visualisation-demande/icone-infos.png" alt="icone-infos"/>
                                                    <ul>
                                                        <li>Publiée par: <strong>{single.username}</strong></li>
                                                        <li>Email : <strong>{single.email_post}</strong></li>
                                                    </ul>
                                                </div>
                                                {
                                                    single.entite ?
                                                    <div
                                                        className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                        <img className="img-responsive"
                                                             src="/img/mails/building.png"
                                                             alt="icone-commentaires"/>
                                                        <p>Entité : <strong>{single.entite}</strong></p>
                                                    </div> : null
                                                }
                                                <br/>
                                                {
                                                    single.commentaires ?
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <img
                                                             src="/img/visualisation-demande/icone-commentaires.png"
                                                             alt="icone-commentaires"/>
                                                        <p>Autres informations: <br/><strong>{single.commentaires}</strong></p>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="boutons col-xs-12">
                                            <div className="row">
                                                <div className="bouton modifier">
                                                    <button onClick={this.handleBack}>ANNULER</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { authentication, etablissement, alert } = state;
    const { user } = authentication;
    const { demandes } = etablissement;
    return  {
        user,
        demandes,
        alert
    }
}

export default withRouter(connect(mapStateToProps)(Demandes_Familles))
