import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import { Confirm, Alert } from "react-st-modal";
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer";
import {alertActions, etablissementActions, userActions} from "../../../../_actions";
import ImageGallery from "react-image-gallery";
import moment from "moment";
import {authHeader, history} from "../../../../_helpers";
import lib from "../../../../lib/lib";
import DatePicker, {registerLocale} from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale('fr', fr);

class Annonces_Etablissement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user_id: localStorage.getItem('user_id'),
            annonces: [],
            annonce: {
                id: 0,
                user_id: 0,
                nbPLace: 1,
                createdAt: '',
                datePlace: new Date(),
                prix: 'NC',
                descriptif: '',
                codePostal: '',
                email_etablissement: '',
                nom_etablissement: '',
                rue: '',
                telephone_etablissement: '',
                site_etablissement: '',
                ville: '',
                activate: -1,
                specs: []
            },
            filter: {
                date: '',
                direc: 'desc',
                status: 3,
                suppress: false
            },
            deleted: [0],
            modif: false,
            preview: false,
            maxLength: 400
            //ajout: !this.props.location.state ? '' : this.props.location.state.message
        };

        this.handleDelete       = this.handleDelete.bind(this);
        this.handleModif        = this.handleModif.bind(this);
        this.handleChange       = this.handleChange.bind(this);
        this.handleChangeDate   = this.handleChangeDate.bind(this);
        this.handlePreview      = this.handlePreview.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleChangeCGU    = this.handleChangeCGU.bind(this);
        this.handleChangeSpec   = this.handleChangeSpec.bind(this);

        this.handleActivate     = this.handleActivate.bind(this);
        this.handleDesactivate  = this.handleDesactivate.bind(this);

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleChangeFilterDate = this.handleChangeFilterDate.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);
        this.renderFilter = this.renderFilter.bind(this);

        this.renderCollection   = this.renderCollection.bind(this);
        this.renderModif        = this.renderModif.bind(this);
        this.renderSpecsAnnonce = this.renderSpecsAnnonce.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getAnnoncesById(parseInt(this.state.user_id)));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.annonces !== this.state.annonces) {
            let annonces = this.props.annonces;

            this.setState({
                annonces
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.annonces !== prevState.annonces) {

            return {annonces: nextProps.annonces};
        } else return null;
    }

    handleDesactivate(e) {
        e.preventDefault();

        this.props.dispatch(etablissementActions.activateAnnonce(e.target.id, -2));
        this.props.dispatch(etablissementActions.getAnnoncesById(this.state.user_id));
    }

    handleActivate(e) {
        e.preventDefault();

        this.props.dispatch(etablissementActions.activateAnnonce(e.target.id, 1));
        this.props.dispatch(etablissementActions.getAnnoncesById(this.state.user_id));
    }

    renderActivated(num, date) {
        switch (num) {
            case 1:
                return <p className="text text-success">Activée <span style={{float: 'right', color: 'grey'}}><strong>depuis: {moment(date).format('DD/MM/YYYY')}</strong></span></p>;
                break;
            case 0:
                return <p className="text text-danger">Désactivée système <span style={{float: 'right', color: 'grey'}}><strong>depuis: {moment(date).format('DD/MM/YYYY')}</strong></span></p>;
                break;
            case -1:
                return <p className="text text-danger">En attente de validation <span style={{float: 'right', color: 'grey'}}><strong>depuis: {moment(date).format('DD/MM/YYYY')}</strong></span></p>;
                break;
            case -2:
                return <p className="text text-warning">Désactivée utilisateur <span style={{float: 'right', color: 'grey'}}><strong>depuis: {moment(date).format('DD/MM/YYYY')}</strong></span></p>;
                break;
            default:
                return <p className="text text-danger">En attente de validation <span style={{float: 'right', color: 'grey'}}><strong>depuis: {moment(date).format('DD/MM/YYYY')}</strong></span></p>;
        }
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        var response = await Confirm("Voulez-vous vraiment supprimer l'annonce?", "", "Oui", "Fermer");

        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            this.props.dispatch(etablissementActions.delAnnonce(id));
            this.props.dispatch(etablissementActions.getAnnoncesById(this.state.user_id));
        } else {
            console.log("null")
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        const {annonce, maxLength} = this.state;

        if (name === "descriptif" && annonce.descriptif.length <= maxLength) {
            this.setState({
                annonce: {
                    ...annonce,
                    descriptif: lib.protectEntry(value)
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    [name]: value
                }
            })
        }
    }

    handleChangeDate(date) {
        const {annonce} = this.state;

        this.setState({
            annonce: {
                ...annonce,
                datePlace: date
            }
        });
    };

    handleChangeCGU(e) {
        const {name, value} = e.target;

        this.setState({[name]: value})
    }

    _renderTime(time) {
        var now = moment().toISOString();
        var diff = moment.duration(moment(time).diff(moment(now)));

        var days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité dans {days} jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité immédiate <span className="green-dot"></span>
                </p>
            )
        }
    }

    handlePreview(e) {
        e.preventDefault();

        const {preview, annonce} = this.state;

        if (annonce.prix.length === 0) {
            Alert("Veuillez renseigner un budget")
        } else {
            this.setState({preview: !preview})
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const {annonce, CGU} = this.state;
        const {dispatch} = this.props;

        if (parseInt(CGU) === 1) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({annonce})
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/updateAnnonce`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    dispatch(alertActions.success(result.message));
                    this.props.dispatch(etablissementActions.getAnnoncesById(this.state.user_id));
                    this.setState({
                        modif: false,
                        preview: false
                    })
                })
                .catch(err => {
                    dispatch(alertActions.error(err));
                    console.log(err)
                });
        } else {
            Alert("Veuillez lire et accepter les conditions générales d’utilisation.")
        }
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    renderSpecs(item) {
        let specs = this.props.annonces.specs.filter(elem => elem.id_annonce === item.id);

        if (specs.length > 0) {
            return (
                <p className="subTitleResultat" id="type">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités : </strong><br/>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}
                </p>
            );
        } else
            return null
    }

    renderSpecsAnnonce() {
        let specs = this.state.annonce.specs;

        if (specs.length > 0)
            return (
                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités: </strong><br/><p></p>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}<br/>
                </div>
            );
        else
            return null
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {modif ,annonce} = this.state;
        const {annonces} = this.props;


        if (!checked) {
            this.setState({
                annonce: {
                    ...annonce,
                    specs: annonce.specs.filter(function (item) {
                        return item.info_hebergement !== value;
                    })
                },
            })
        } else {
            let item = annonces.specs.filter(function (item) {return item.info_hebergement === value});
            this.setState({
                annonce: {
                    ...annonce,
                    specs: [...annonce.specs, item[0]]
                },
            })
        }
    }

    handleChangeFilter(e) {
        const {name, value} = e.target;
        const {filter} = this.state;

        if (name === 'direc') {
            this.props.annonces.list.reverse();

            this.setState({
                filter: {
                    ...filter,
                    [name]: value,
                    suppress: value === 'asc'
                }
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: parseInt(value),
                }
            })
        }

    };

    handleChangeFilterDate(date) {
        const {filter} = this.state;

        this.setState({
            filter: {
                ...filter,
                date: date,
            }
        })
    }

    supprimerFiltre() {
        const {filter} = this.state;

        filter.suppress ? this.props.annonces.list.reverse() : console.log("null");

        this.setState({
            filter: {
                direc: 'desc',
                status: 3,
                date: '',
                suppress: false
            },
        })
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;

        let date = filter.date !== '' ? moment(filter.date).format('DD/MM/YYYY') === moment(item.createdAt).format('DD/MM/YYYY') : true;
        let status = filter.status !== 3 ? filter.status === item.activate : true;
        let isDeleted = deleted.indexOf(item.id) < 0;

        return (date && status && isDeleted);
    }

    renderCollection() {
        moment.locale('fr');
        const {deleted, filter} = this.state;
        const {alert} = this.props;
        let siteReg = /^(http(s)?:\/\/)/;

        let filtredList = this.props.annonces && this.props.annonces.list ? this.props.annonces.list.filter(item => this.renderFilter(item)) : [];


        return (
            <main>
                <section className="listeDemandeBloc1">
                    <div className="container">
                        <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/etablissements">Établissements</a> &gt; Annonces de l'Établissement</p>
                        <h1>Annonces de <span>l'Établissement</span></h1>
                        <div className="col-xs12 bouton voir">
                            <Link to="/administrateur/etablissements">Retour à la page <strong>Établissements</strong></Link>
                        </div>
                        <br/>
                        <div className="filtres col-xs-12" style={{border: "#bec8c9 .1rem solid", borderRadius: '5%', padding: "1rem", margin: "0 0 1rem 0"}}>
                            <p>Filtrer par</p>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <DatePicker
                                    //selected={null}
                                    locale="fr"
                                    onChange={this.handleChangeFilterDate}
                                    dateFormat='dd/MM/yyyy'
                                    placeholderText="selectionnez une date"
                                />
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <select id="order" name="direc" onChange={this.handleChangeFilter} value={filter.direc}>
                                    <option value="desc">Du plus récent au plus ancien</option>
                                    <option value="asc">Du plus ancien au plus récent</option>
                                </select>
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <select id="etablissement" name="status" onChange={this.handleChangeFilter} value={filter.Type}>
                                    <option value="3">Toutes les annonces</option>
                                    <option value="1">activée</option>
                                    <option value="-1">en attente</option>
                                    <option value="0">désactivée système</option>
                                    <option value="-2">désactivée utilisateur</option>
                                </select>
                            </div>
                            <div className="bouton submit subConf" style={{float: 'right'}}>
                                <input type="reset" name="supprimer"
                                       value="Supprimer les Filtres"
                                       style={{margin: "10px"}}
                                       onClick={this.supprimerFiltre}/>
                            </div>
                        </div>
                        <br/>
                        {
                            alert ? <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                        }
                        <br/>
                        <h2><strong style={{color: '#c4574e'}}>{this.props.annonces.list ? filtredList.length : 0}</strong> annonce(s) trouvée(s)</h2>
                        <br/>
                        <div id="listeDemande">
                            {filtredList.map((item) =>
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" distance="0" key={item.id}>
                                    <div className="singleDemande listeannonceAdmin">
                                        {/*
                                        <div className="question">
                                            <p className="titleSingleResultat" id="nbPlace">
                                                Annonce publiée le {moment(item.createdAt).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                        */}
                                        {this.renderActivated(item.activate, item.createdAt)}
                                        <div className="">
                                            <div className="question">
                                                <p className="titleSingleResultat" id="nbPlace">
                                                    {item.nbPLace} place(s) disponible(s)
                                                    à {item.ville} {item.codePostal}
                                                </p></div>
                                        </div>

                                        {
                                            this.getImages(item).length > 0 ?
                                                <ImageGallery additionalClass="ImageGallery"
                                                              showThumbnails={false}
                                                              showPlayButton={false}
                                                              showBullets={false}
                                                              showFullscreenButton={false}
                                                              items={this.getImages(item)}
                                                />
                                                :
                                                <img className="img-responsive" style={{margin:"2rem auto"}}
                                                     src={ item.titre === 'EHPAD' ?
                                                         "/img/home/homeBloc1/icone-annonce1.png" :
                                                         item.titre === 'USLD' ?
                                                             "/img/home/homeBloc1/icone-annonce3.png" :
                                                             item.titre === 'Résidence autonomie' ?
                                                                 "/img/home/homeBloc1/icone-annonce4.png" :
                                                                 "/img/home/homeBloc1/icone-annonce5.png"}
                                                     alt="icone-maison"/>
                                        }
                                        <div className="infoActivation">
                                            {this._renderTime(item.datePlace)}

                                            <p className="subTitleResultat" id="type">
                                                <i className="fa fa-building-o  img-responsive" aria-hidden="true"/>
                                                {item.titre}
                                            </p>
                                            <p className="subTitleResultat" id="type">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-budget.png"
                                                     alt="icone-budget"/>
                                                Budget mensuel : {item.prix}
                                            </p>
                                            {
                                                this.renderSpecs(item)
                                            }
                                            <hr/>
                                            <p className="subTitleResultat" id="type">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-type-etablissement.png"
                                                     alt="icone-type-etablissement"/>
                                                <strong style={{fontSize: '1.3em'}}>{item.nom_etablissement}</strong>
                                            </p>
                                            <p className="subTitleResultat" id="adresse">
                                                <img className="img-responsive"
                                                     src="/img/visualisation-demande/icone-secteur.png"
                                                     alt="icone-secteur"/>
                                                {item.rue}, {item.codePostal} {item.ville}
                                            </p>
                                            <p className="subTitleResultat">
                                                <i className="fa fa-phone img-responsive" aria-hidden="true"/>
                                                <a style={{fontSize: '1.5rem'}} href={`tel:${item.telephone_etablissement}`}>{item.telephone_etablissement}</a>
                                            </p>
                                            <p className="subTitleResultat mail">
                                                <i className="fa fa-at" aria-hidden="true"></i>
                                                <a href={"mailto:"+item.email_etablissement}>{item.email_etablissement}</a>
                                            </p>
                                            {
                                                item.site_etablissement ?
                                                    <div className="subTitleResultat website">
                                                        <i className="fa fa-internet-explorer" aria-hidden="true"></i>
                                                        <a href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                           target="_blank">Site de l'établissement</a>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                item.descriptif ?
                                                    <p className="subTitleResultat description">
                                                        <img className="img-responsive"
                                                             src="/img/visualisation-demande/icone-commentaires.png"
                                                             alt="icone-commentaires"/>
                                                        {item.descriptif}
                                                    </p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-xs12 bouton voir">
                                                <button onClick={this.handleModif} id={item.id}>Modifier</button>
                                            </div>
                                            {item.activate == 1 ?
                                                <div className="col-xs12 bouton supprimer">
                                                    <button onClick={this.handleDesactivate} id={item.id}>Désactiver</button>
                                                </div>
                                                :
                                                item.activate == 0 || item.activate == -2 ?
                                                    <div className="col-xs12 bouton supprimer">
                                                        <button onClick={this.handleActivate} id={item.id}>Activer</button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-xs12 bouton supprimer">
                                                <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        )
    }

    async handleModif(e) {
        e.preventDefault();
        this.props.dispatch(alertActions.clear());
        const {modif, annonce} = this.state;

        const array = this.props.annonces.list.filter(item => parseInt(item.id) === parseInt(e.target.id));

        this.setState({
            annonce: {
                ...annonce,
                id: array[0].id,
                user_id: array[0].user_id,
                codePostal: array[0].codePostal,
                createdAt: new Date(array[0].createdAt),
                datePlace: new Date(array[0].datePlace),
                descriptif: array[0].descriptif,
                email_etablissement: array[0].email_etablissement,
                nbPLace: array[0].nbPLace,
                nom_etablissement: array[0].nom_etablissement,
                prix: array[0].prix,
                rue: array[0].rue,
                telephone_etablissement: array[0].telephone_etablissement,
                site_etablissement: array[0].site_etablissement,
                ville: array[0].ville,
                specs: this.props.annonces.specs.filter(function (el) { return parseInt(el.id_annonce) === parseInt(e.target.id)})
            },
            modif: !modif,
        });
    }

    getImages(item) {
        let images = [];

        if (item.image_un)
            images.push({
                original: "/img/annonces/" + item.image_un
            });
        if (item.image_deux)
            images.push({
                original: "/img/annonces/" + item.image_deux
            });
        if (item.image_trois)
            images.push({
                original: "/img/annonces/" + item.image_trois
            });
        if (item.image_quatre)
            images.push({
                original: "/img/annonces/" + item.image_quatre
            });

        return images
    }

    renderModif() {
        moment.locale('fr');
        const {annonce, annonces, preview} = this.state;
        const {alert} = this.props;
        let date = new Date();
        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <main>
                {!preview ?
                    <section className="demandeEtablissementBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/etablissements">Établissements</a> &gt; <a href="/administrateur/annonces-actives">Annonces de l'Établissement</a> &gt; Mise à jour de l'annonce</p>
                            <h1>Mise à jour de l'annonce <span>pour mes places disponibles</span></h1>

                            <div className="encadreForm">


                                <div className="text-center text-success validationDemandeEtablissement">
                                </div>
                                <form name="appbundle_annonce" onSubmit={this.handlePreview}>

                                    <div className="formBloc1">
                                        <div className="formBloc1_1 col-xs-12 col-sm-6">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Nombre de places</p>
                                                </div>
                                                <div className="inputDemande">
                                                    <input type="number" id="appbundle_annonce_nbPLace"
                                                           name="nbPLace" required="required" min="1"
                                                           max="10" value={annonce.nbPLace}
                                                           onChange={this.handleChange}
                                                        //placeholder={annoncenbPLace}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="formBloc1_2 col-xs-12 col-sm-6 datePlace">
                                            <div className="form-group row">
                                                <div className="question">
                                                    <p>A partir de quand vos places sont disponibles
                                                        ?<span> *</span></p>
                                                </div>
                                                <div className="inputDemande">
                                                    <DatePicker
                                                        selected={annonce.datePlace}
                                                        onChange={this.handleChangeDate}
                                                        minDate={date}
                                                        maxDate={date.addDays(30)}
                                                        dateFormat="dd-MM-yyyy"
                                                        locale="fr"
                                                        className=""
                                                        //placeholder={annonce.datePlace}
                                                        //withPortal
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Quel est le niveau de budget mensuel ?</p>
                                                </div>
                                                <div className="inputBudget">
                                                    <div id="appbundle_annonce_prix">
                                                        <input type="radio" id="appbundle_annonce_prix_0"
                                                               name="prix" value="Moins de 2000€"
                                                               onChange={this.handleChange}
                                                               defaultChecked={(annonce.prix === "Moins de 2000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_0">Moins de 2000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_1"
                                                               name="prix" value="2000€ à 2500€"
                                                               onChange={this.handleChange}
                                                               defaultChecked={(annonce.prix === "2000€ à 2500€") || (annonce.prix === "De 2000€ à 2500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_1">De 2000€ à
                                                            2500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_2"
                                                               name="prix" onChange={this.handleChange}
                                                               value="2500€ à 3000€"
                                                               defaultChecked={(annonce.prix === "2500€ à 3000€") || (annonce.prix === "De 2500€ à 3000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_2">De 2500€ à
                                                            3000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_3"
                                                               name="prix" onChange={this.handleChange}
                                                               value="3000€ à 3500€"
                                                               defaultChecked={(annonce.prix === "3000€ à 3500€") || (annonce.prix === "De 3000€ à 3500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_3">De 3000€ à
                                                            3500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_4"
                                                               name="prix" onChange={this.handleChange}
                                                               value="3500€ à 4000€"
                                                               defaultChecked={(annonce.prix === "3500€ à 4000€") || (annonce.prix === "De 3500€ à 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_4">De 3500€ à
                                                            4000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_5"
                                                               name="prix" onChange={this.handleChange}
                                                               value="Plus de 4000€"
                                                               defaultChecked={(annonce.prix === "Plus de 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_5">Plus de 4000€</label>
                                                    </div>
                                                </div>
                                                <div className="formBloc1_4 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong> Spécificités </strong> (Facultatif - Plusieurs choix possibles)</p>
                                                        </div>
                                                        <div className="inputInfos col-xs-12">

                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec1" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function (el) { return el && el.info_hebergement === "Place en unité Alzheimer / protégée"}).length > 0} value="Place en unité Alzheimer / protégée" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec1" style={{fontSize: "1em", marginTop: '1%'}}>Place en unité Alzheimer / protégée</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec2" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Possibilité de court séjour / séjour temporaire"}).length > 0} value="Possibilité de court séjour / séjour temporaire" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec2" style={{fontSize: "1em", marginTop: '1%'}}>Possibilité de court séjour / séjour temporaire</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec3" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "ASH possible (Aide Sociale à l’Hébergement)"}).length > 0} value="ASH possible (Aide Sociale à l’Hébergement)" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec3" style={{fontSize: "1em", marginTop: '1%'}}>ASH possible (Aide Sociale à l’Hébergement)</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec4" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Place en accueil de jour"}).length > 0} value="Place en accueil de jour" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec4" style={{fontSize: "1em", marginTop: '1%'}}>Place en accueil de jour</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc2">
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p><strong>Informations supplémentaires</strong> (que vous
                                                        souhaitez
                                                        transmettre aux familles et/ou aux personnes qui
                                                        informent
                                                        les
                                                        familles)</p><p></p>
                                                </div>
                                                <div className="inputBudget">
                                                    <textarea id="appbundle_annonce_descriptif"
                                                              name="descriptif" onChange={this.handleChange}
                                                              value={annonce.descriptif}
                                                        //placeholder={annonce.descriptif}
                                                              maxLength="400">
                                                    </textarea>
                                                </div>
                                                <div id="counter">
                                                    {annonce.descriptif.length} / 400
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bouton submit">
                                            <button type="submit" id="appbundle_annonce_previus"
                                                    name="appbundle_annonce[previus]"
                                                    label="Prévisualiser"> Prévisualiser
                                            </button>
                                        </div>
                                        <input type="hidden" id="appbundle_annonce_equipements"
                                               name="appbundle_annonce[equipements]" value="vide"/>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>
                    :
                    <section className="visualisationDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/etablissements">Établissements</a> &gt; <a href="/administrateur/annonces-actives">Annonces de l'Établissement</a> &gt; Mise à jour de l'annonce</p>
                            <h1>Je visualise <span>mon annonce</span></h1>

                            <p className="titleForm"></p>
                            <div className="encadreForm">
                                <div className="visualisationDemandeBloc1_1 col-xs-12">
                                    <div className="row">
                                        <p className="titleLieu">{annonce.nbPLace} place(s) disponible(s)
                                            à {annonce.ville} {annonce.codePostal}</p>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                 alt="icone-sexe"/>À partir du {moment(annonce.datePlace).format('DD/MM/YYYY')}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-budget.png"
                                                 alt="icone-sexe"/>
                                            Niveau de budget mensuel de {annonce.prix}
                                        </div>
                                        {
                                            this.renderSpecsAnnonce()
                                        }
                                        <hr/>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                 alt="icone-sexe"/>
                                            {annonce.nom_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                 alt="icone-sexe"/>
                                            {annonce.rue} {annonce.ville}
                                        </div>

                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-phone img-responsive" aria-hidden="true"></i>
                                            {annonce.telephone_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-at" aria-hidden="true"></i>
                                            {annonce.email_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-internet-explorer" aria-hidden="true"></i>

                                            <a href={siteReg.test(annonce.site_etablissement) ? annonce.site_etablissement : 'https://' + annonce.site_etablissement}
                                               target="_blank">Site de l'établissement</a>
                                        </div>
                                        <div className="visualisationInfo description col-xs-12">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-commentaires.png"
                                                 alt="icone-commentaires"/>
                                            <p>Description :
                                            </p><p>{annonce.descriptif}</p><p></p>
                                        </div>
                                    </div>
                                </div>
                                <form name="appbundle_annonce" className="form-horizontal">
                                    <div
                                        className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input type="checkbox" name="CGU" value="1" required="required"
                                               onChange={this.handleChangeCGU}/>
                                        <label>j’accepte les <a href="/conditions-generales"
                                                                target="_blank">conditions générales
                                            d’utilisation.</a></label>
                                    </div>
                                    {
                                        alert.message ?
                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                            :
                                            <div className="boutons col-xs-12">
                                                <div className="row">
                                                    <div className="bouton modifier">
                                                        <button type="submit" id="appbundle_annonce_update"
                                                                name="appbundle_annonce[update]"
                                                                onClick={this.handlePreview}
                                                                label="Modifier ma demande">Modifier
                                                        </button>
                                                    </div>
                                                    <div className="bouton">
                                                        <button type="submit" id="appbundle_annonce_save"
                                                                name="appbundle_annonce[save]"
                                                                onClick={this.handleSubmit}
                                                                label="Enregistrer">Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </section>
                }
            </main>

        )

    }

    render() {
        const {annonces, modif, annonce} = this.state;

        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    !(annonces) ?
                        <main>
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/etablissements">Établissements</a> &gt; Annonces de l'Établissement</p>
                                    <h1>Nombre d'annonces <span>0</span></h1>
                                    <div className="col-xs12 bouton voir">
                                        <Link to="/administrateur/etablissements">Retour à la page <strong>Établissements</strong></Link>
                                    </div>
                                    <br/>

                                    <img alt={"loading"} src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                </div>
                            </section>
                        </main>

                        :

                        (modif) ? this.renderModif() : this.renderCollection()
                }
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, etablissement, alert} = state;
    const {user} = authentication;
    const {annonces} = etablissement;
    return {
        user,
        alert,
        annonces
    }
}

export default connect(mapStateToProps)(Annonces_Etablissement)
