import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollUpButton from "react-scroll-up-button";
import moment from 'moment';
import { Confirm, Alert } from "react-st-modal";

import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import {alertActions, groupesActions, userActions} from "../../../../_actions";
import lib from "../../../../lib/lib";

class Groupes_list extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                id              : 0,
                user_id         : null,
                username        : '',
                password        : '',
                password2       : '',
                emailResponsable: '',
                emailAnnonce    : '',
                telephoneResponsable: '',
                nomGroupe: '',
                nomOld: '',
                nomResponsable: '',
                prenomResponsable: ''
            },
            groupes: [],
            filter: {
                nom: "",
                email: ""
            },
            updateUser: false,
            submitted1: false,
            submitted2: false,
            deleted: []
        };

        this.activerCompte      = this.activerCompte.bind(this);
        this.switchToUpdate     = this.switchToUpdate.bind(this);
        this.handleChangeUser   = this.handleChangeUser.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter       = this.renderFilter.bind(this);
        this.handleDelete       = this.handleDelete.bind(this);
        this.handleSubmitGroupe  = this.handleSubmitGroupe.bind(this);
        this.handleSubmitAccount = this.handleSubmitAccount.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(groupesActions.getAllGroupes())
    }
/*
    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupes !== this.state.groupes) {
            let groupes = this.props.list;

            this.setState({
                groupes
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.list !== prevState.groupes) {

            return {groupes: nextProps.list};
        } else return null;
    }
*/
    async activerCompte(e) {
        e.preventDefault();

        const array = this.props.list.filter(item => parseInt(item.id) === parseInt(e.target.id));
        const item = array[0];
        let response;

        if (!item.user_id) {
            Alert("Veuillez associer un compte à ce groupe pour pouvoir l'activer");
        } else if (item.enabled === 1) {
            response = await Confirm(`Êtes-vous sûr de vouloir désactiver le compte du groupe "${item.nom}"?`, "", "Oui", "Fermer");
            let groupe = {
                id: item.user_id,
                email: item.emailResponsable,
                username: item.username,
                enabled: 0,
            }

            if (response) {
                await this.props.dispatch(groupesActions.activateGroupeAccount(groupe));
                await this.props.dispatch(groupesActions.getAllGroupes());
            }
        } else {
            response = await Confirm(`Êtes-vous sûr de vouloir activer le compte du groupe "${item.nom}"?`, "", "Oui", "Fermer");
            let groupe = {
                id: item.user_id,
                email: item.emailResponsable,
                username: item.username,
                enabled: 1,
            }

            if (response) {
                await this.props.dispatch(groupesActions.activateGroupeAccount(groupe));
                await this.props.dispatch(groupesActions.getAllGroupes());
            }
        }
    }

    switchToUpdate(e) {
        e.preventDefault();

        const array = this.props.list.filter(item => parseInt(item.id) === parseInt(e.target.id));
        const { updateUser, user } = this.state;
        const { dispatch } = this.props;

        dispatch(alertActions.clear());

        if ( !updateUser ) {
            this.setState({
                user: {
                    ...user,
                    id: array[0].id,
                    user_id: array[0].user_id,
                    username: array[0].username,
                    emailResponsable: array[0].emailResponsable,
                    emailAnnonce: array[0].emailAnnonce,
                    nomGroupe: array[0].nom,
                    nomOld: array[0].nom,
                    telephoneResponsable: array[0].telephoneResponsable,
                    nomResponsable: array[0].nomResponsable,
                    prenomResponsable: array[0].prenomResponsable,
                },
                submitted1: false,
                submitted2: false
            })
        }

        this.setState({updateUser: !updateUser})
    }

    handleChangeUser(event) {
        event.preventDefault();
        const { name, value } = event.target;
        const { user } = this.state;
        const { dispatch } = this.props;

        dispatch(alertActions.clear());

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            },
            submitted1: false,
            submitted2: false
        })
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;
        const {filter} = this.state;

        this.setState({
            filter: {
                ...filter,
                [name]: value
            },
        })
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;

        const nom           = filter.nom    !== '' ? item.nom.toLowerCase().includes(filter.nom.toLocaleLowerCase()) :  true;
        const email         = filter.email  !== '' ? item.emailResponsable.toLowerCase().includes(filter.email.toLocaleLowerCase()) :  true;
        const isDeleted     = deleted.includes(item.id);
        return (nom && email && !isDeleted)
    }

    handleSubmitAccount(e) {
        e.preventDefault()
        this.setState({submitted1: true});
        const {user, updateUser} = this.state;
        const {dispatch} = this.props;
        if (user.password && lib.isValidPassword(user.password) && !lib.passwordMatch(user.password, user.password2)) {
            return null
        } else if (user.password && !lib.isValidPassword(user.password)) {
            return null
        } else if (user.password && lib.isValidPassword(user.password) && lib.passwordMatch(user.password, user.password2)) {
            if (
                lib.isValidUsername(lib.protectEntry(user.username)) &&
                user.user_id
            ) {
                dispatch(groupesActions.updateGroupeAccount(user));
                this.props.dispatch(groupesActions.getAllGroupes());
                //this.setState({updateUser: !updateUser});
            }
        } else if (
            lib.isValidUsername(lib.protectEntry(user.username)) &&
            user.user_id
        ) {
            dispatch(groupesActions.updateGroupeAccount(user));
            this.props.dispatch(userActions.getAllPros());
            //this.setState({updateUser: !updateUser});
        } else {
            console.log(" NOT triggered condition ")
            console.log("is valid user", lib.isValidUsername(lib.protectEntry(user.username)))
            console.log("is valid email", lib.isValidPassword(lib.protectEntry(user.email)))
        }
    }

    async handleDelete(e) {
        e.preventDefault()

        const { deleted } = this.state
        const { id } = e.target;
        let response = await Confirm("Voulez-vous vraiment supprimer ce Groupe?", "", "Oui", "Fermer");

        if (response === true) {
            await this.props.dispatch(groupesActions.deleteGroupe(id));
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
        }
    }

    handleSubmitGroupe(e) {
        e.preventDefault()
        this.setState({ submitted2: true });
        const { user, updateUser } = this.state;
        const { dispatch } = this.props;

        if (
            lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)) &&
            lib.isValidEmail(lib.protectEntry(user.emailResponsable)) &&
            lib.isValidEmail(lib.protectEntry(user.emailAnnonce))
        ) {
            dispatch(groupesActions.updateGroupe(user));
            this.props.dispatch(groupesActions.getAllGroupes());
            //this.setState({updateUser: !updateUser});
        } else {
            console.log(" NOT triggered condition ")
            console.log("is valid Groupe name", lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)))
            console.log("is valid email", lib.isValidEmail(lib.protectEntry(user.email)))
            console.log("is valid phone", lib.isValidPhone(lib.protectEntry(user.telephoneResponsable)))
        }
    }

    render(){
        const { updating, alert } = this.props;
        const { user, updateUser, submitted1, submitted2, filter } = this.state;
        const filtredList = this.props.list ? this.props.list.filter(item => this.renderFilter(item)) : []

        return  (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>
                    {
                        updateUser ?
                            <section className="inscriptionStep1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/groupes/">Groupes</a> &gt; <a href="/administrateur/groupes/list-groupe">Liste des Groupes</a> &gt; les informations</p>
                                    <h1><span>Informations relatives au Groupe</span></h1>
                                    <form name="form" onSubmit={(e) => this.handleSubmitAccount(e)} autoComplete="off">
                                        {
                                            user.user_id ?
                                                <div className="encadreForm col-xs-12">
                                                    <div className="question" style={{textAlign: "center"}}>
                                                        <p>Identifiants de Connexion</p>
                                                    </div>
                                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !user.username ? ' has-error' : '')}>
                                                        <label>Identifiant <span>*</span></label>
                                                        <input type="text" className="form-control"
                                                               id="adminbundle_user_username" name="username"
                                                               placeholder="identifiant de connexion"
                                                               required="required" maxLength="180" pattern=".{2,}"
                                                               value={user.username} onChange={this.handleChangeUser}/>
                                                        {submitted1 && !lib.isValidUsername(user.username) &&
                                                        <div className="help-block">Mauvais format d'identifiant</div>
                                                        }
                                                    </div>
                                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group'}>
                                                    </div>
                                                    <div className="question" style={{textAlign: "center"}}>
                                                        <p>Modifiez le mot de passe (à remplir uniquement si vous changez de mot de passe)</p>
                                                    </div>

                                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !lib.isValidUpdatePassword(user.password) ? ' has-error' : '')}>
                                                        <label htmlFor="userPassword">Mot de passe </label>
                                                        <input type="password" id="adminbundle_user_plainPassword_first"
                                                               name="password" className="form-control" placeholder="mot de passe"
                                                               onChange={this.handleChangeUser}/>
                                                        {submitted1 && !lib.isValidUpdatePassword(user.password) &&
                                                        <div className="help-block">Mauvais format de Mot de mot de
                                                            passe</div>
                                                        }
                                                    </div>
                                                    <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted1 && !lib.passwordMatch(user.password, user.password2) ? ' has-error' : '')}>
                                                        <label htmlFor="userPassword">Retapez votre mot de passe </label>
                                                        <input type="password"
                                                               id="adminbundle_user_plainPassword_second"
                                                               name="password2" className="form-control"
                                                               onChange={this.handleChangeUser}/>
                                                        {submitted1 && !lib.passwordMatch(user.password, user.password2) &&
                                                        <div className="help-block">Veuillez confirmer le mot de passe (ou le supprimer si vous ne voulez pas le modifier)</div>
                                                        }
                                                    </div>
                                                    <div className="bouton annuler" style={{float: "left"}}>
                                                        <button onClick={this.switchToUpdate}>
                                                            Annuler
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <div className="bouton submit">
                                                            <input type="submit" name="envoyer" id="valideEtablissement"
                                                                   value="Modifier les identifiants"/>
                                                            {updating &&
                                                            <img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                                            }
                                                        </div>
                                                    </div>
                                                    {alert.message && submitted1 ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                                                </div>
                                                :
                                                <div className="encadreForm col-xs-12">
                                                    <h2>Aucun Compte associé à ce groupe</h2>
                                                    <p style={{color: 'red', textAlign: 'center'}}>Veuillez, le cas échéant, créer un compte pour ce groupe</p>
                                                </div>
                                        }
                                    </form>
                                    <form name="form" onSubmit={(e) => this.handleSubmitGroupe(e)}>
                                        <div className="encadreForm col-xs-12">
                                            <div className="question" style={{textAlign: "center"}}>
                                                <p>Informations du Groupe</p>
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)) ? ' has-error' : '')}>
                                                <label>Nom du Groupe <span>*</span></label>
                                                <input type="text" className="form-control" id="adminbundle_user_username" name="nomGroupe" placeholder={user.nomGroupe}
                                                       required="required" maxLength="180" pattern=".{2,}" value={user.nomGroupe} onChange={this.handleChangeUser}/>
                                                {submitted2 && !lib.isValidNomGroupe(lib.protectEntry(user.nomGroupe)) &&
                                                <div className="help-block">Le nom doit être alphanumerique, peut contenir "-, _ et des espaces" mais pas d'espaces au début ni à la fin.</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && user.emailResponsable && !lib.isValidEmail(user.emailResponsable) ? ' has-error' : '')}>
                                                <label>Email Responsable <span>*</span></label>
                                                <input type="email" id="adminbundle_user_email" className="form-control" name="emailResponsable"
                                                       maxLength="180" pattern=".{2,}" placeholder={user.emailResponsable}
                                                       value={user.emailResponsable} onChange={this.handleChangeUser}/>
                                                {submitted2 && user.emailResponsable && !lib.isValidEmail(user.emailResponsable) &&
                                                <div className="help-block">Mauvais format d'email</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && user.nomResponsable && !lib.isValidName(user.nomResponsable) ? ' has-error' : '')}>
                                                <label>Nom du Responsable (facultatif)</label>
                                                <input type="text" id="adminbundle_user_email" className="form-control" name="nomResponsable"
                                                       maxLength="180" pattern=".{2,}" placeholder={user.nomResponsable}
                                                       value={user.nomResponsable} onChange={this.handleChangeUser}/>
                                                {submitted2 && !lib.isValidName(user.nomResponsable) &&
                                                <div className="help-block">Mauvais format d'email</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && !lib.isValidEmail(user.emailAnnonce) ? ' has-error' : '')}>
                                                <label>Email gestion des annonces <span>*</span></label>
                                                <input type="email" id="adminbundle_user_email" className="form-control" name="emailAnnonce"
                                                       required="required" maxLength="180" pattern=".{2,}" placeholder={user.emailAnnonce}
                                                       value={user.emailAnnonce} onChange={this.handleChangeUser}/>
                                                {submitted2 && !lib.isValidEmail(user.emailAnnonce) &&
                                                <div className="help-block">Mauvais format d'email</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && user.prenomResponsable && !lib.isValidName(user.prenomResponsable) ? ' has-error' : '')}>
                                                <label>Prenom du Responsable (facultatif)</label>
                                                <input type="text" id="adminbundle_user_email" className="form-control" name="prenomResponsable"
                                                       maxLength="180" pattern=".{2,}" placeholder={user.prenomResponsable}
                                                       value={user.prenomResponsable} onChange={this.handleChangeUser}/>
                                                {submitted2 && !lib.isValidName(user.prenomResponsable) &&
                                                <div className="help-block">Mauvais format d'email</div>
                                                }
                                            </div>
                                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submitted2 && user.telephoneResponsable && !lib.isValidPhone(user.telephoneResponsable) ? ' has-error' : '')}>
                                                <label>Tél Responsable (facultatif)</label>
                                                <input type="tel" id="adminbundle_user_email" className="form-control" name="telephoneResponsable"
                                                       maxLength="180" pattern=".{2,}" placeholder={user.telephoneResponsable}
                                                       value={user.telephoneResponsable} onChange={this.handleChangeUser}/>
                                                {submitted2 && user.telephoneResponsable && !lib.isValidPhone(user.telephoneResponsable) &&
                                                <div className="help-block">Mauvais format de téléphone</div>
                                                }
                                            </div>
                                            <div className="bouton annuler" style={{float: "left"}}>
                                                <button onClick={this.switchToUpdate}>
                                                    Annuler
                                                </button>
                                            </div>
                                            <div >
                                                <div className="bouton submit">
                                                    <input type="submit" name="envoyer" id="valideEtablissement" value="Modifer les informations"/>
                                                    {updating &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>
                                            </div>
                                            {alert.message && submitted2 ? <div className={`alert ${alert.type}`} style={{display: 'flex', justifyContent: 'center', float: 'center'}}>{alert.message}</div> : null}
                                        </div>
                                    </form>
                                </div>
                            </section>
                            :
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; <a href="/administrateur/groupes/">Groupes</a> &gt; Liste des Groupes</p>
                                    <h1><span>Groupes</span></h1>
                                    <br/>
                                    <div className="filtres col-xs-12">
                                        <p>Filtrer par</p>
                                        <hr/>
                                        <input type="text" name="nom" value={filter.nom} onChange={this.handleChangeFilter} placeholder="recherche par nom de Groupe" autoComplete="off"/>
                                        <input type="text" name="email" value={filter.email} onChange={this.handleChangeFilter} placeholder="recherche par email du responsable" autoComplete="off"/>
                                    </div>
                                    <br/>
                                    {
                                        this.props.list ?
                                            <h1>Nombre de Groupes enregistrés: {this.props.list.length}</h1> : null
                                    }
                                    <br/>
                                    <div id="listeDemande">
                                        {!filtredList ? <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/> :
                                            filtredList.map((item, index) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                    <div className="singleDemande" style={{minHeight: '250px'}}>
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>{item.nom}</p>
                                                            </div>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-user" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word'}}>Responsable : <strong>{item.prenomResponsable} {item.nomResponsable}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-at" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word'}}>Email Responsable : <strong>{item.emailResponsable}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-at" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word'}}>Email Gestion : <strong>{item.emailAnnonce}</strong></p>
                                                        </div>
                                                        {
                                                            item.user_id ?
                                                                <div className="visualisationInfo col-xs-12">
                                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                                    <p style={{wordBreak: 'break-word'}}>Tél : <strong>{item.telephoneResponsable}</strong></p>
                                                                </div>
                                                                : null
                                                        }

                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.switchToUpdate} id={item.id}>Modifier</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.activerCompte} id={item.id}>{item.enabled === 1 ? 'Désactiver' : 'Activer'}</button>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                                        </div>
                                                        <hr/>
                                                        <h3>Nombre total d'Établissements: {item.etsTotal}</h3>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-home" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'gray', fontWeight: 'bold'}}>Établissements auto-gérés: <strong style={{color: 'black'}}>{item.etsAutoGeres}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-home" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'gray', fontWeight: 'bold'}}>Établissements contrôlés: <strong style={{color: 'black'}}>{item.etsControles}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'green'}}>avec annonces activées: <strong style={{color: 'black'}}>{item.etsActive}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>avec annonces en Attente: <strong style={{color: 'black'}}>{item.etsAttente}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>avec annonces désactivées système: <strong style={{color: 'black'}}>{item.etsSystem}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>avec annonces désactivées utilisateur: <strong style={{color: 'black'}}>{item.etsUnactive}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'red'}}>sans annonce: <strong style={{color: 'black'}}>{item.etsNoAnnonce}</strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                    }
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state){
    const { updating } = state.setUpdate;
    const { list } = state.groupes;
    const {alert} = state;

    return  {
        list,
        updating,
        alert
    }
}

export default connect(mapStateToProps)(Groupes_list)
