export const etablissementConstants = {
    INFOS_REQUEST: 'ETABLISSEMENT_INFOS_REQUEST',
    INFOS_SUCCESS: 'ETABLISSEMENT_INFOS_SUCCESS',
    INFOS_FAILURE: 'ETABLISSEMENT_INFOS_FAILURE',

    ACTIVATED_REQUEST: 'ETABLISSEMENT_ACTIVATED_REQUEST',
    ACTIVATED_SUCCESS: 'ETABLISSEMENT_ACTIVATED_SUCCESS',
    ACTIVATED_FAILURE: 'ETABLISSEMENT_ACTIVATED_FAILURE',

    ACTIVATED_COUNT_REQUEST: 'ETABLISSEMENT_ACTIVATED_COUNT_REQUEST',
    ACTIVATED_COUNT_SUCCESS: 'ETABLISSEMENT_ACTIVATED_COUNT_SUCCESS',
    ACTIVATED_COUNT_FAILURE: 'ETABLISSEMENT_ACTIVATED_COUNT_FAILURE',

    UNACTIVATED_REQUEST: 'ETABLISSEMENT_UNACTIVATED_REQUEST',
    UNACTIVATED_SUCCESS: 'ETABLISSEMENT_UNACTIVATED_SUCCESS',
    UNACTIVATED_FAILURE: 'ETABLISSEMENT_UNACTIVATED_FAILURE',

    UPDATE_REQUEST : 'ETABLISSEMENT_UPDATE_REQUEST',
    UPDATE_SUCCESS : 'ETABLISSEMENT_UPDATE_SUCCESS',
    UPDATE_FAILURE : 'ETABLISSEMENT_UPDATE_FAILURE',

    ANNONCES_REQUEST : 'ETABLISSEMENT_ANNONCES_REQUEST',
    ANNONCES_SUCCESS : 'ETABLISSEMENT_ANNONCES_SUCCESS',
    ANNONCES_FAILURE : 'ETABLISSEMENT_ANNONCES_FAILURE',

    DEMANDES_REQUEST : 'ETABLISSEMENT_DEMANDES_REQUEST',
    DEMANDES_SUCCESS : 'ETABLISSEMENT_DEMANDES_SUCCESS',
    DEMANDES_FAILURE : 'ETABLISSEMENT_DEMANDES_FAILURE',

    LIST_REQUEST: 'ETABLISSEMENT_LIST_REQUEST',
    LIST_SUCCESS: 'ETABLISSEMENT_LIST_SUCCESS',
    LIST_FAILURE: 'ETABLISSEMENT_LIST_FAILURE',

    LATEST_SUCCESS : 'ETABLISSEMENT_LATEST_SUCCESS',
    LATEST_FAILURE : 'ETABLISSEMENT_LATEST_FAILURE',
};
