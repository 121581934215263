import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Confirm, Alert} from "react-st-modal";
import Autosuggest from 'react-autosuggest';
import ScrollUpButton from "react-scroll-up-button";
import moment from "moment";
import 'icheck/skins/all.css'; // or single skin css
import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';
import Navbar from "../../../../_components/Navbar";
import Footer from "../../../../_components/Footer"
import {alertActions, etablissementActions, groupesActions, userActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import projectList from "../../../../communes/communes.json";
import {history} from "../../../../_helpers";
import {etablissement} from "../../../../_reducers/etablissement.reducer";
import {withRouter} from "react-router";
import ReactPaginate from "react-paginate";

//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

let GroupeList = [];

const getGroupeSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.value.nom.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : GroupeList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getGroupeSuggestionValue = suggestion => suggestion.label;

const renderGroupeSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label}</b></div>

);

class Etablissements extends Component {

    constructor(props){
        super(props);

        this.state = {
            value: '',
            valueGroupe: '',
            suggestions: [],
            suggestionsGroupe: [],
            codePostal: '',
            ville: '',
            user: (localStorage.getItem('user') ? this.props.user.user : false),
            etablissements: {},
            etablissement: {
                civilite: '',
                codePostal: '',
                code_insee: '',
                email_etablissement: '',
                groupe_etablissement: '',
                groupe_id: null,
                id: 0,
                image_un: '',
                image_deux: '',
                image_trois: '',
                image_quatre: '',
                latitude: '',
                longitude: '',
                nom: '',
                nom_etablissement: '',
                numerofinesse: '',
                prenom: '',
                rue: '',
                site_etablissement: '',
                specs: [],
                statut: '',
                telephone: '',
                telephone_etablissement: '',
                type_etablissement_id: '',
                ville: '',
                username: '',
                email: '',
                email2: '',
                password: '',
                password2: ''
            },
            form: 1,
            form_etablissement: {},
            form_user: {display: "none"},
            chk1: "",
            chk2: "",
            chk3: "",
            chk4: "",
            chk5: "",
            submitted: false,
            pictures: [],
            btn1: true,
            btn2: true,
            btn3: true,
            imagePreviewUrl: [],
            image_un: '',
            image_deux: '',
            image_trois: '',
            image_quatre: '',
            condition: true,
            alert0: false,
            alert1: false,
            alert2: false,
            preview: false,
            deleted: [],
            filter: {
                nom: '',
                numero: '',
                codePostal: '',
                Type: [1, 2, 3, 4],
                direc: 'asc',
                ville: '',
                search: '',
                lat: '',
                lng: '',
                rayon: '',
                groupe: '',
                groupe_id: null,
                finess: '',
                distance: false,
                activate: "Tous",
            },
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            display: "none",
            message: "",
            screenWidth: null,
            start: 0,
            end: 15,
            selected: 0,
        };

        this.handlePreview = this.handlePreview.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.renderParticularite = this.renderParticularite.bind(this);
        this.changeInput = this.changeInput.bind(this);

        this.handleAnnonces = this.handleAnnonces.bind(this);

        this._handleChangeForm = this._handleChangeForm.bind(this);

        this._handleChangeUser = this._handleChangeUser.bind(this);
        this._handleChangeInfos = this._handleChangeInfos.bind(this);
        this._handleChangeSpec = this._handleChangeSpec.bind(this);

        this.selectImages = this.selectImages.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.deleteImage  = this.deleteImage.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.sortDistance = this.sortDistance.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);
        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.handleAnnonces = this.handleAnnonces.bind(this);

        this.distanceInKmBetweenEarthCoordinates = this.distanceInKmBetweenEarthCoordinates.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getAllEtablissement());
        this.props.dispatch(groupesActions.getAllGroupes());

        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.etablissements !== this.state.etablissements) {
            let etablissements = this.props.list;

            this.setState({
                etablissements
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.list !== prevState.etablissements) {

            return {etablissements: nextProps.list};
        } else return null;
    }

    handleAnnonces(e) {
        e.preventDefault();
        const {id} = e.target;
        localStorage.setItem('user_id', id);
        this.props.history.push({pathname: '/administrateur/annonces-actives'});
    }

    handlePreview(event) {
        event.preventDefault();
        const {etablissement} = this.state;

        const array = this.props.list.etablissements.filter(item => parseInt(item.id) === parseInt(event.target.id));
        let t_final = [];
        this.props.list.specs.filter(function(e) {
            return (parseInt(e.etablissement_id) === parseInt(event.target.id))
        }).map(el =>
            t_final.push(el)
        );

        if (GroupeList.length === 0) {
            this.props.groupesList.map((item) => {
                GroupeList.push({
                    value: {
                        id: item.id,
                        nom: item.nom,
                    },
                    label: item.nom
                })
            });
        }

        this.setState({
            etablissement: {
                ...etablissement,
                civilite: array[0].civilite,
                codePostal: array[0].codePostal,
                code_insee: array[0].code_insee,
                email_etablissement: array[0].email_etablissement,
                groupe_etablissement: array[0].groupe_etablissement,
                groupe_id: array[0].groupe_id ?? null,
                id: array[0].id,
                image_deux: array[0].image_deux,
                image_quatre: array[0].image_quatre,
                image_trois: array[0].image_trois,
                image_un: array[0].image_un,
                latitude: array[0].latitude,
                longitude: array[0].longitude,
                nom: array[0].nom,
                nom_etablissement: array[0].nom_etablissement,
                numerofinesse: array[0].numerofinesse,
                prenom: array[0].prenom,
                rue: array[0].rue,
                site_etablissement: array[0].site_etablissement,
                specs: t_final,
                statut: array[0].statut,
                telephone: array[0].telephone,
                telephone_etablissement: array[0].telephone_etablissement,
                type_etablissement_id: array[0].type_etablissement_id,
                ville: array[0].ville,
                username: array[0].username,
                user_id: array[0].user_id,
                email: array[0].email,
                email2: array[0].email2,
                password: '',
                password2: ''
            },
            value: array[0].ville + ' ' + array[0].codePostal,
            valueGroupe: array[0].groupe_etablissement ?? '',
            preview: true
        });
        window.scrollTo(0,0)
    }

    handleBack(event) {
        event.preventDefault();

        this.setState({preview: false, alert1: false, alert2: false})
    }


    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        let response = await Confirm("Voulez-vous vraiment supprimer cet établissement?", "", "Oui", "Fermer");
        if (response === true) {
            this.setState({
                deleted: [...deleted, parseInt(id)]
            });
            this.props.dispatch(etablissementActions.delEtablissement(id));
            this.props.dispatch(etablissementActions.getAllEtablissement());
        }
    }

    handleChangeFilter(e) {
        e.preventDefault();
        const {name, value} = e.target;
        const {filter} = this.state;

        if (name === "rayon") {
            if (filter.lat.length === 0 && filter.lng.length === 0) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        [name]: value,
                        distance: true,
                    },
                    start: 0,
                    end: 15,
                })
            }
        } else {
            this.setState({
                filter: {
                    ...filter,
                    [name]: value
                },
                start: 0,
                end: 15,
            })
        }
    }

    changeInput(name, value, type) {
        const { filter } = this.state;
        const { Type } = filter;

        if (value) {
            this.setState({
                filter: {
                    ...filter,
                    Type: Type.filter(item => item !== parseInt(type))
                },
                [name]: !value,
                alert1: false,
                start: 0,
                end: 15,
            })
        } else {
            this.setState({
                filter: {
                    ...filter,
                    Type: [...Type, parseInt(type)]
                },
                [name]: !value,
                alert1: false,
                start: 0,
                end: 15,
            })
        }
    }

    clickCheckBox(e) {
        const { name, value, type, checked } = e.target;
        const { filter } = this.state;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    filter: {
                        ...filter,
                        Type: [...filter.Type, parseInt(value)]
                    },
                    [name]: true,
                    alert1: false,
                    start: 0,
                    end: 15,
                })
            } else {
                this.setState({
                    filter: {
                        ...filter,
                        Type: filter.Type.filter(function (item) {
                            return item !== parseInt(value)
                        })
                    },
                    [name]: false,
                    alert1: false,
                    start: 0,
                    end: 15,
                })
            }
        }
    }

    supprimerFiltre() {
        const {filter} = this.state;
        this.setState({
            filter: {
                ...filter,
                codePostal: '',
                lat: '',
                lng: '',
                ville: '',
                rayon: '',
                distance: false,
                Type: [1, 2, 3, 4],
                activate: 'Tous'
            },
            value: '',
            valueGroupe: '',
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            alert0: false,
            alert1: false,
            alert2: false,
            start: 0,
            end: 15,
        })
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        let earthRadiusKm = 6371;

        let dLat = this.degreesToRadians(lat2-lat1);
        let dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    sortDistance(a, b) {
        const { filter } = this.state;
        let dist1 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, a.latitude, a.longitude);
        let dist2 = this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, b.latitude, b.longitude);

        if (dist1 > dist2)
            return 1;
        else if (dist1 < dist2)
            return -1;
        else
            return 0
    }

    renderFilter(item) {
        const {filter, deleted} = this.state;

        const codePostal    = filter.codePostal  !== '' ? (item.codePostal.includes(filter.codePostal)) : filter.ville !== '' ? (filter.ville === item.ville) : true;
        const rayon         = filter.distance === true  ? (this.distanceInKmBetweenEarthCoordinates(filter.lat, filter.lng, item.latitude, item.longitude) <= parseInt(filter.rayon, 10)) : true;
        const nom           = filter.nom  !== '' ? item.nom_etablissement.toLowerCase().includes(filter.nom.toLocaleLowerCase()) :  true;
        const finess        = filter.finess ? item.numerofinesse ? item.numerofinesse.includes(filter.finess) : false : true;
        const groupe        = filter.groupe ? item.groupe_etablissement ? item.groupe_etablissement.toLowerCase().includes(filter.groupe.toLowerCase()) : false : true;
        const numero        = filter.numero  !== '' ? item.telephone_etablissement.includes(filter.numero) : true;
        const notDel        = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;
        const Type          = filter.Type.includes(item.type_etablissement_id);
        const locality      = (codePostal || filter.distance);

        const sum = item.system + item.attente + item.user + item.active;
        let status          = filter.activate === 'Tous';
        if (filter.activate === 'null' && sum === 0)
            status = true;
        if (filter.activate === 'active')
            status = item.active > 0;
        if (filter.activate === 'system')
            status = item.system > 0;
        if (filter.activate === 'attente')
            status = item.attente > 0;
        if (filter.activate === 'user')
            status = item.user > 0;

        return (nom && numero && notDel && locality && Type && rayon && finess && groupe && status)
    }

    renderParticularite(item) {
        const {specs} = this.props.list;
        let infos = ["Hebergement permanent", "Hebergement temporaire", "Accueil de jour", "Unité alzheimer", "Aide social à l’hébergement"];

        let tab = specs.filter(function(spec) { return parseInt(spec.etablissement_id) === parseInt(item.id)});

        return (
            tab.length > 0 ?
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{marginRight: '0.5rem'}}>
                    <i className="fa fa-check-square-o img-responsive" style={{color: '#c4574e'}} aria-hidden="true"> </i><strong>Spécificités: </strong><br/>

                        {'- ' + infos[parseInt(tab[0].information_hebergement_id) - 1]}<br/>
                        {tab[1] ? '- ' + infos[parseInt(tab[1].information_hebergement_id) - 1] : null}<br/>
                        {tab[2] ? '- ' + infos[parseInt(tab[2].information_hebergement_id) - 1] : null}<br/>
                        {tab[3] ? '- ' + infos[parseInt(tab[3].information_hebergement_id) - 1] : null}<br/>
                        {tab[4] ? '- ' + infos[parseInt(tab[4].information_hebergement_id) - 1] : null}<br/>

                </div>
                :
                null

        )
    }

    _handleChangeForm(e) {
        const {name} = e.target;
        switch (name) {
            case "form1":
                this.setState({form_user: {display: "none"}, form_etablissement: {}, form: 1, alert0: false, alert1: false, alert2: false});
                break;
            case "form2":
                this.setState({form_user: {}, form_etablissement: {display: "none"}, form: 2, alert0: false, alert1: false, alert2: false});
                break;
            default:
                this.setState({form_etablissement: {}, form_user: {display: "none"}, form: 1, alert0: false, alert1: false, alert2: false});
        }
    }

    _handleChangeUser(e) {
        const {name, value} = e.target;

        const {etablissement} = this.state;

        this.setState({btn2: false, btn1: false});

        this.setState({
            etablissement: {
                ...etablissement,
                [name]: lib.protectEntry(value)
            },
            alert1: false,
            alert0: false,
        })
    }

    _handleChangeInfos(e) {
        const {name, value} = e.target;

        const {etablissement} = this.state;

        this.setState({
            etablissement: {
                ...etablissement,
                [name]: lib.protectEntry(value)
            },
            btn2: false,
            alert1: false,
            alert0: false,
        })
    }

    selectImages(event) {
        const {dispatch} = this.props;
        const {name} = event.target;
        const {etablissement, pictures} = this.state;

        dispatch(alertActions.clear());

        let file = event.target.files[0];

        if ( file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/) && file.size < 2000000) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    pictures: [...pictures, {'file': file, 'id': etablissement.id, 'pic': name}],
                    etablissement: {
                        ...etablissement,
                        [name]: reader.result
                    },
                    [name]: reader.result,
                    btn3: false,
                    display: "none",
                    alert2: false
                });
            };
            reader.readAsDataURL(file)
        } else if (file.size > 2000000) {
            this.setState({
                message: "l'image est trop Volumineuse (supérieure à 2MB)",
                display: ""
            })
        } else {
            this.setState({
                message: "Formats d'images acceptés: .jpg, .jpeg, .png ou .gif ",
                display: ""
            })
        }
    }

    uploadImages(e) {
        e.preventDefault();

        const {condition, user, etablissement} = this.state;
        const {dispatch} = this.props;

        let i = 0;
        this.state.pictures.map(image => {
            i = i + 1;
            const data = new FormData();
            data.append("image", image.file, image.file.name);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + user.confirmation_token,
                    'id': image.id,
                    'pic': image.pic
                },
                body: data
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/upload`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    if (result.success) {
                        this.setState({
                            condition: condition && result.success,
                            alert2: true,
                            etablissement: {
                                ...etablissement,
                                [result.position]: result.filename
                            },
                            //[result.position]: ""
                        })
                    }
                    if (condition && i === this.state.pictures.length) {
                        this.props.dispatch(etablissementActions.getAllEtablissement());
                        dispatch(alertActions.success('Image(s) téléchargée(s) avec succès'))
                    }
                });
        });
    }

    deleteImage(name, position) {
        const {etablissement, user} = this.state;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization' : 'Bearer ' + user.confirmation_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: etablissement.id,
                name,
                position
            })
        };

        return fetch(`https://backend.placemaisonderetraite.fr/etablissements/deleteImage`, requestOptions)
            .then(this.handleResponse)
            .then(result => {
                if (result.success) {
                    this.setState({
                        etablissement : {
                            ...etablissement,
                            [position]: ""
                        },
                        [position]: "",
                        btn3: true,
                    });
                    Alert(result.message);
                    this.props.dispatch(etablissementActions.getAllEtablissement())
                } else {
                    Alert(result.message);
                }
            });

    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    async handleDeleteImage(event) {
        event.preventDefault();

        this.props.dispatch(alertActions.clear())
        const {etablissement} = this.state;

        const { id } = event?.target;

        let response = await Confirm("Voulez-vous vraiment supprimer cette image?", "", "Oui", "Fermer");

        if (response === true) {
            switch (id) {
                case "1":
                    return this.deleteImage(etablissement.image_un, "image_un");
                case "2":
                    return this.deleteImage(etablissement.image_deux, "image_deux");
                case "3":
                    return this.deleteImage(etablissement.image_trois, "image_trois");
                case "4":
                    return this.deleteImage(etablissement.image_quatre, "image_quatre");
                default:
                    return null
            }
        }
    }

    _handleChangeSpec(e) {
        const {value, checked} = e.target;
        const {etablissement, chk1, chk2, chk3, chk4, chk5} = this.state;

        this.setState({btn2: false});

        switch (value) {
            case "1":
                this.setState({chk1: !chk1});
                break;
            case "2":
                this.setState({chk2: !chk2});
                break;
            case "3":
                this.setState({chk3: !chk3});
                break;
            case "4":
                this.setState({chk4: !chk4});
                break;
            case "5":
                this.setState({chk5: !chk5});
                break;
            default:
                return null
        }

        if (!checked) {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: etablissement.specs.filter(function (item) {
                        return item.information_hebergement_id !== parseInt(value);
                    })
                },
                alert0: false,
                alert1: false
            })
        } else {
            this.setState({
                etablissement: {
                    ...etablissement,
                    specs: [...etablissement.specs, {etablissement_id: parseInt(etablissement.id), information_hebergement_id: parseInt(value)}],
                },
                alert0: false,
                alert1: false
            })
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            filter: {
                ...this.state.filter,
                codePostal: '',
                lat: '',
                lng: '',
                ville: '',
                rayon: '',
                distance: false
            },
            start: 0,
            end: 15,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {etablissement, filter, preview} = this.state;

        if (preview) {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                etablissement: {
                    ...etablissement,
                    codePostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                    latitude: suggestion.value.geoLoc.coordinates[1],
                    longitude: suggestion.value.geoLoc.coordinates[0],
                    rayon: "5",
                    distance: true
                },
                btn2: false,
            })
        } else {
            this.setState({
                value: suggestion.value.nom+" "+suggestion.value.codePostal,
                filter: {
                    ...filter,
                    codePostal: suggestion.value.codePostal,
                    ville: suggestion.value.nom,
                    lat: suggestion.value.geoLoc.coordinates[1],
                    lng: suggestion.value.geoLoc.coordinates[0]
                }
            })
        }
    };

    onChangeGroupe = (event, { newValue }) => {
        this.setState({
            valueGroupe: newValue,
            etablissement: {
                ...this.state.etablissement,
                groupe_etablissement: newValue,
            },
            filter: {
                ...this.state.filter,
                groupe_id: null,
                groupe: '',
            },
            start: 0,
            end: 15,
        });
    };

    onGroupeSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestionsGroupe: getGroupeSuggestions(value)
        });
    };

    onGroupeSuggestionsClearRequested = () => {
        this.setState({
            suggestionsGroupe: []
        });
    };

    onGroupeSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {etablissement, filter, preview} = this.state;

        if (preview) {
            this.setState({
                valueGroupe: suggestion.value.nom,
                etablissement: {
                    ...etablissement,
                    groupe_id: suggestion.value.id,
                    groupe_etablissement: suggestion.value.nom,
                },
                btn2: false,
            })
        } else {
            this.setState({
                valueGroupe: suggestion.value.nom,
                filter: {
                    ...filter,
                    groupe_id: suggestion.value.id,
                    groupe: suggestion.value.nom,
                }
            })
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {user, etablissement, pictures, password, password2, form} = this.state;
        const {dispatch} = this.props;

        if (parseInt(etablissement.type_etablissement_id) === 4) {
            etablissement.numerofinesse = "";
        }

        if (form === 2) {
            if (etablissement.password && lib.isValidPassword(etablissement.password) && lib.passwordMatch(etablissement.password, etablissement.password2)) {
                if (lib.isValidUsername(lib.protectEntry(etablissement.username))) {
                    dispatch(userActions.updateUser(etablissement, "personne", this.props.history));
                    this.setState({alert0: true});
                    this.props.dispatch(etablissementActions.getAllEtablissement())
                }
            } else if (lib.isValidUsername(lib.protectEntry(etablissement.username))) {
                dispatch(userActions.updateUser(etablissement, "personne", this.props.history));
                this.setState({alert0: true});
                this.props.dispatch(etablissementActions.getAllEtablissement())
            } else {
                Alert("Assurez vous que les informations personnelles sont correctes")
            }
        } else if (form === 1) {
            if (
                (etablissement.nom ? lib.isValidName(etablissement.nom) : true) &&
                (etablissement.prenom ? lib.isValidName(etablissement.prenom) : true) &&
                lib.isValidEmail(etablissement.email) &&
                lib.isValidPhone(etablissement.telephone) &&
                (etablissement.numerofinesse ? lib.isValidFiness(etablissement.numerofinesse) : true) &&
                etablissement.rue &&
                lib.isValidCP(etablissement.codePostal) &&
                lib.isValidPhone(etablissement.telephone_etablissement) &&
                lib.isValidEmail(etablissement.email_etablissement) &&
                (etablissement.site_etablissement ? lib.isValidURL(etablissement.site_etablissement) : true)

            ) {
                dispatch(etablissementActions.updateEtablissement(etablissement, etablissement, this.props.history));
                this.props.dispatch(etablissementActions.getAllEtablissement());
                this.setState({alert1: true})
            } else {
                Alert("Assurez vous de renseigner correctement les informations à mettre à jour")
            }
        }
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15, selected})
        window.scrollTo(0,0)
    };

    render(){
        const types = ["EHPAD", "USLD", "Résidence autonomie", "Résidence services seniors"];
        const {
            preview,
            etablissement,
            etablissements,
            filter,
            value,
            valueGroupe,
            suggestions,
            suggestionsGroupe,
            form,
            alert0, alert1, alert2,
            type1, type2, type3, type4,
            chk1, chk2, chk3, chk4,
            screenWidth,
            activate,
            start,
            end,
            selected
        } = this.state;
        const {alert} = this.props;

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };

        const inputGroupeProps = {
            placeholder: 'Veuillez choisir un groupe ou en créer un',
            value: valueGroupe,
            onChange: this.onChangeGroupe
        };

        let filtredEtablissements = etablissements.etablissements ? etablissements.etablissements.filter(item => this.renderFilter(item)) : [];
        filtredEtablissements = filter.distance ? filtredEtablissements.sort((a, b) => this.sortDistance(a, b)) : filtredEtablissements;
        let siteReg = /^(http(s)?:\/\/)/;

        return (
            <div style={{minHeight:"100% !important"}}>
                <Navbar />
                <main>

                    {
                        !preview ?
                            <section className="listeDemandeBloc1">
                                <div className="container">
                                    <p className="chemin"><a href="/administrateur/">Mon compte</a> &gt; Etablissements
                                    </p>
                                    <h1><span>établissements</span></h1>

                                    <br/>
                                    <form>
                                        <div className="col-xs-12 triSquare" style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                            <div className="infoSpecial">
                                                {
                                                    screenWidth > 763 ?
                                                        <div className="listeEtablissementTri">
                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce1.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type1", type1, "1")}
                                                                         title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_1"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>EHPAD</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_1"
                                                                       name="type1"
                                                                       value="1"
                                                                       checked={type1}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce3.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type2", type2, "2")}
                                                                         title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_2"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>USLD</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_2"
                                                                       name="type2"
                                                                       value="2"
                                                                       checked={type2}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce4.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type3", type3, "3")}
                                                                         title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_3"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>Résidence autonomie</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_3"
                                                                       name="type3"
                                                                       value="3"
                                                                       checked={type3}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>

                                                            <div
                                                                className="inputCheckboxContent col-xs-6 col-sm-12 col-md-8 col-lg-3">
                                                                <div className="row" style={{textAlign: 'center'}}>
                                                                    <img className="img-responsive"
                                                                         src="/img/home/homeBloc1/icone-annonce5.png"
                                                                         alt="icone-etablissement"
                                                                         onClick={() => this.changeInput("type4", type4, "4")}
                                                                         title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                    <label
                                                                        htmlFor="appbundle_alertedispo_etablissement_4"
                                                                        style={{
                                                                            marginLeft: 'auto',
                                                                            marginRight: 'auto',
                                                                            fontSize: '1.3rem'
                                                                        }}>Résidence
                                                                        services
                                                                        seniors</label>
                                                                </div>
                                                                <input type="checkbox"
                                                                       style={{
                                                                           marginLeft: 'auto',
                                                                           marginRight: 'auto',
                                                                           transform: 'scale(1.5)',
                                                                           width: '30px'
                                                                       }}
                                                                       id="appbundle_alertedispo_etablissement_4"
                                                                       name="type4"
                                                                       value="4"
                                                                       checked={type4}
                                                                       onChange={this.clickCheckBox}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{justifyContent: 'center'}}>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                                             alt="EHPAD"
                                                                             onClick={() => this.changeInput("type1", filter.Type.includes(1),"1")}
                                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type1"
                                                                            checked={type1}
                                                                            name="type1"
                                                                            value="1"
                                                                            onChange={() => this.changeInput("type1", filter.Type.includes(1),"1")}
                                                                        />
                                                                        <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                                             alt="USLD"
                                                                             onClick={() => this.changeInput("type2", filter.Type.includes(2),"2")}
                                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type2"
                                                                            checked={type2}
                                                                            name="type2"
                                                                            value="2"
                                                                            onChange={() => this.changeInput("type2", filter.Type.includes(2),"2")}
                                                                        />
                                                                        <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                                             alt="Résidence autonomie"
                                                                             onClick={() => this.changeInput("type3", filter.Type.includes(3),"3")}
                                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type3"
                                                                            checked={type3}
                                                                            name="type3"
                                                                            value="3"
                                                                            onChange={() => this.changeInput("type3", filter.Type.includes(3),"3")}
                                                                        />
                                                                        <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                                    </div>
                                                                    <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                        <img className="img-responsive"
                                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                                             alt="Résidence services seniors"
                                                                             onClick={() => this.changeInput("type4", filter.Type.includes(4),"4")}
                                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                        <Checkbox
                                                                            checkboxClass="icheckbox_flat-blue"
                                                                            increaseArea="20%"
                                                                            id="label_type4"
                                                                            checked={type4}
                                                                            name="type4"
                                                                            value="4"
                                                                            onChange={() => this.changeInput("type4", filter.Type.includes(4),"4")}
                                                                        />
                                                                        <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                }
                                                <div className="secondFiltre">
                                                    <div className="villeInput triVille" style={{position: "relative", width: "100%", margin: "0 auto"}}>
                                                        <Autosuggest
                                                            suggestions={suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            onSuggestionSelected={this.onSuggestionSelected}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            inputProps={inputProps}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>

                                                    <div className="listeKm" style={{marginRight: "1rem"}}>
                                                        <button className={filter.rayon === "5" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="5" value="5" onClick={this.handleChangeFilter}>
                                                            5km
                                                        </button>
                                                        <button className={filter.rayon === "15" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="15" value="15" onClick={this.handleChangeFilter}>
                                                            15km
                                                        </button>
                                                        <button className={filter.rayon === "30" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="30" value="30" onClick={this.handleChangeFilter}>
                                                            30km
                                                        </button>
                                                        <button className={filter.rayon === "50" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="50" value="50" onClick={this.handleChangeFilter}>
                                                            50km
                                                        </button>
                                                        <button className={filter.rayon === "100" ? "singleKm active" : "singleKm"} type="submit" name="rayon" data-km="100" value="100" onClick={this.handleChangeFilter}>
                                                            100km
                                                        </button>
                                                    </div>

                                                    <div className="bouton submit subConf">
                                                        <input type="reset" name="supprimer"
                                                               value="Supprimer les Filtres"
                                                               style={{marginTop: "10px"}}
                                                               onClick={this.supprimerFiltre}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <select id="etablissement" className="hide" onSelect={this.handleChangeFilter}>
                                                <option value="">Type d'établissement</option>
                                                <option value="1">EHPAD</option>
                                                <option value="2">USLD</option>
                                                <option value="3">Résidence autonomie</option>
                                                <option value="4">Résidence services seniors</option>
                                            </select>

                                        </div>
                                    </form>
                                    <div className="filtres col-xs-12">
                                        <p>Filtrer par</p>
                                        <hr/>
                                        <input type="text" name="nom" value={filter.nom} onChange={this.handleChangeFilter} placeholder="Nom d'Etablissement" autoComplete="off"/>
                                        <input type="text" name="groupe" value={filter.groupe} onChange={this.handleChangeFilter} placeholder="Groupe d'Etablissement" autoComplete="off"/>
                                        <input type="text" name="finess" value={filter.finess} onChange={this.handleChangeFilter} placeholder="Numero finess" autoComplete="off"/>
                                        <select id="appbundle_demandeetablissement_rayon"
                                                name="activate"
                                                value={filter.activate}
                                                onChange={this.handleChangeFilter}>
                                            <option value="Tous">Tout type d'annonces</option>
                                            <option value="active">Activées</option>
                                            <option value="attente">En attente</option>
                                            <option value="system">Désactivées système</option>
                                            <option value="user">Désactivées utilisateur</option>
                                            <option value="null">Sans annonce</option>
                                        </select>
                                    </div>
                                    <h2><span>Nombre d'établissements: {filtredEtablissements.length}</span></h2>
                                    <div id="listeDemande" style={{wordBreak: 'break-word'}}>
                                        {!this.props.list ?
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                            :
                                            filtredEtablissements.slice(start, end).map((item, index) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" key={index}>
                                                    <div className="singleDemande">
                                                        <p>dernière connexion: <strong>{moment(item.last_login).format('DD/MM/YYYY HH:mm:ss')} </strong></p>
                                                        <div className="col-xs-12">
                                                            <div className="question">
                                                                <p>{item.nom_etablissement}</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.groupe_etablissement ?
                                                                <div className="visualisationInfo col-xs-12">
                                                                    <img className="img-responsive"
                                                                         src="/img/visualisation-demande/groupe.png"
                                                                         alt="icone-sexe"
                                                                         style={{color: '#c4574e'}}
                                                                    />
                                                                    <p>Groupe <strong>{item.groupe_etablissement.toUpperCase()} </strong></p>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-building-o  img-responsive" aria-hidden="true"> </i>
                                                            <p>Type d'établissement : <strong>{types[item.type_etablissement_id - 1]} </strong></p>
                                                        </div>
                                                        {this.renderParticularite(item)}
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'green'}}>annonces actives: <strong style={{color: 'black'}}>{item.active}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>annonces en attente: <strong style={{color: 'black'}}>{item.attente}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>désactivées système: <strong style={{color: 'black'}}>{item.system}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-book" aria-hidden="true"></i>
                                                            <p style={{wordBreak: 'break-word', color: 'orange'}}>désactivées utilisateur: <strong style={{color: 'black'}}>{item.user}</strong></p>
                                                        </div>
                                                        <hr style={{border: '1px solid #ccc', clear: 'both'}}/>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                                 alt="icone-sexe"/>
                                                                <p>Situé à <strong>{item.ville} </strong>({item.codePostal}) <strong>{item.rue}</strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                                            <p>Téléphone : <strong
                                                                className="phoneNumber">{item.telephone} </strong></p>
                                                        </div>
                                                        <div className="visualisationInfo col-xs-12">
                                                            <i className="fa fa-at" aria-hidden="true"> </i>
                                                            <p>Email : <strong>{item.email} </strong></p>
                                                        </div>
                                                        {
                                                            item.site_etablissement ?
                                                            <div className="visualisationInfo col-xs-12">
                                                                <i className="fa fa-internet-explorer"
                                                                   aria-hidden="true"> </i>
                                                                <p>
                                                                    <strong>

                                                                        <a href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                                           target="_blank"> Site de l'Établissement </a>
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                                :
                                                            null
                                                        }
                                                        <div className="visualisationInfo col-xs-12">
                                                            <img className="img-responsive"
                                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                 alt="icone-type-etablissement"/>
                                                                <p>Numéro finess : <strong>{item.numerofinesse} </strong></p>
                                                        </div>
                                                        <div className="col-xs12 bouton voir">
                                                            <button onClick={this.handlePreview} id={item.id}>Modifier</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.handleDelete} id={item.id}>Supprimer</button>
                                                        </div>
                                                        <div className="col-xs12 bouton supprimer">
                                                            <button onClick={this.handleAnnonces} id={item.user_id}>Annonces</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className="col-xs-12 pages">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={Math.ceil(filtredEtablissements.length/15)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        forcePage={start === 0 ? 0 : selected}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'btn-page'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </section>
                            :
                            <section className="inscriptionStep1">
                                <div className="container">

                                    <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; <a href="/administrateur/etablissements">Etablissements</a> &gt;Mise à jour informations
                                    </p>
                                    <h1>Mise à jour informations</h1>

                                    <div className="titleForm">
                                        <button id="formEtablisssement" name="form1" value="1"
                                                className={form !== 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"} onClick={this._handleChangeForm}>
                                            établissement
                                        </button>
                                        <button id="CancelMaj" value="1"
                                                className={form === 1 ? "formCoordonnees btn btn-primary" : "formCoordonnees btn btn-primary active"} onClick={this.handleBack}>
                                            Annuler
                                        </button>
                                        <button id="formCoordonnees" name="form2" value="2"
                                                className="formCoordonnees btn btn-primary"
                                                onClick={this._handleChangeForm}>identifiants
                                        </button>
                                    </div>

                                    <form name="adminbundle_user" onSubmit={this.handleSubmit}
                                          className="formCoordonnees"
                                          style={this.state.form_user}>
                                        {
                                            alert.message && alert0 ?
                                                <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                        }
                                        <div className="encadreForm col-xs-12">
                                            <div className="question">
                                                <p>Modifiez les identifiants de connexion</p>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Identifiant <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeUser}
                                                       name="username" required="required" maxLength="180"
                                                       pattern=".{2,}" value={etablissement.username}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label htmlFor="userPassword">Mot de passe <span>*</span></label>
                                                <input type="password" onChange={this._handleChangeUser}
                                                       name="password" value={etablissement.password}
                                                       className="password-field"/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label htmlFor="userPassword">Retapez votre mot de
                                                    passe <span>*</span></label>
                                                <input type="password" onChange={this._handleChangeUser }
                                                       name="password2" value={etablissement.password2}
                                                       className="password-field"/>
                                            </div>
                                            <div className="bouton submit">
                                                <input type="submit" name="envoyer" id="valider1"
                                                       value="Modifier mes informations" disabled={this.state.btn1}/>
                                                <div className="error text-danger"> </div>
                                            </div>
                                        </div>
                                    </form>

                                    <form name="appbundle_etablissement" onSubmit={this.handleSubmit}
                                          style={this.state.form_etablissement}>
                                        <div className="encadreForm col-xs-12">
                                            <div className="question">
                                                <p><strong>Responsable</strong> (Informations pour <span
                                                    className="urltitre2">Place<span
                                                    className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour
                                                    la gestion des annonces, pour recevoir vos alertes. Ces informations
                                                    ne
                                                    sont
                                                    pas à destination des familles et n’apparaitront pas dans vos
                                                    annonces)
                                                </p>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Civilite</label>
                                                <select id="civilite" onChange={this._handleChangeInfos}
                                                        name="civilite" value={etablissement.civilite}>
                                                    <option value=" "></option>
                                                    <option value="Mademoiselle">Mademoiselle</option>
                                                    <option value="Madame">Madame</option>
                                                    <option value="Monsieur">Monsieur</option>
                                                </select>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Nom</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="nom" value={etablissement.nom}
                                                       style={{boxShadow: !lib.isValidName(etablissement.nom) ? '0 0 3px #CC0000' : ''}}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Prenom</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="prenom"
                                                       value={etablissement.prenom}
                                                       style={{boxShadow: !lib.isValidName(etablissement.prenom) ? '0 0 3px #CC0000' : ''}}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Téléphone Résponsable<span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="telephone" required="required"
                                                       value={etablissement.telephone}
                                                       style={{boxShadow: !lib.isValidPhone(etablissement.telephone) ? '0 0 3px #CC0000' : ''}}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label htmlFor="adminbundle_user_email" className="required">Email Responsable<span>*</span></label>
                                                <input type="email" onChange={this._handleChangeUser}
                                                       id="adminbundle_user_email"
                                                       name="email"
                                                       required="required"
                                                       maxLength="180"
                                                       pattern=".{2,}"
                                                       value={etablissement.email}
                                                       style={{boxShadow: !lib.isValidEmail(etablissement.email) ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label htmlFor="adminbundle_user_email" className="required">Email de Secours et/ou de Groupe (facultatif)</label>
                                                <input type="email" onChange={this._handleChangeUser}
                                                       id="adminbundle_user_email"
                                                       name="email2"
                                                       maxLength="180"
                                                       pattern=".{2,}"
                                                       value={etablissement.email2}
                                                       style={{boxShadow: etablissement.email2 && !lib.isValidEmail(etablissement.email2) ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="question">
                                                <p><strong>Établissement</strong> (les informations en rouge serviront à
                                                    créer
                                                    vos annonces)</p>
                                            </div>

                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label style={{color: "red"}}>Nom de votre etablissement <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="nom_etablissement" required="required"
                                                       value={etablissement.nom_etablissement}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Groupe (le cas échéant)</label>
                                                <Autosuggest
                                                    suggestions={suggestionsGroupe}
                                                    onSuggestionsFetchRequested={this.onGroupeSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onGroupeSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onGroupeSuggestionSelected}
                                                    getSuggestionValue={getGroupeSuggestionValue}
                                                    renderSuggestion={renderGroupeSuggestion}
                                                    inputProps={inputGroupeProps}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label style={{color: "red"}}>Type
                                                    d'établissement <span>*</span></label>
                                                <select onChange={this._handleChangeInfos}
                                                        name="type_etablissement_id"
                                                        value={etablissement.type_etablissement_id}
                                                >
                                                    <option value="1">EHPAD</option>
                                                    <option value="2">USLD</option>
                                                    <option value="3">Résidence autonomie</option>
                                                    <option value="4">Résidence services seniors</option>
                                                </select>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label>Statut <span>*</span></label>
                                                <select onChange={this._handleChangeInfos}
                                                        name="statut"
                                                        value={etablissement.statut}
                                                >
                                                    <option value="public">PUBLIC</option>
                                                    <option value="privé non lucratif (association)">PRIVE NON LUCRATIF (association)</option>
                                                    <option value="privé">PRIVE</option>
                                                </select>
                                            </div>
                                            {
                                                parseInt(etablissement.type_etablissement_id) !== 4 ?
                                                <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 finessField">
                                                    <label>Numéro finess <span>*</span></label>
                                                    <input type="text" onChange={this._handleChangeInfos}
                                                           name="numerofinesse" required="required"
                                                           value={etablissement.numerofinesse}
                                                           style={{boxShadow: alert.message === "ce numéro finess est déjà attribué !" || (etablissement.numerofinesse && !lib.isValidFiness(etablissement.numerofinesse)) ? '0 0 3px #CC0000' : ''}}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label style={{color: "red"}}>N° et nom de la
                                                    voie <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="rue" required="required"
                                                       value={etablissement.rue}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4 villeInput">
                                                <label style={{color: "red"}}>Ville <span>*</span></label>
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                                <label style={{color: "red"}}>Téléphone établissement<span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="telephone_etablissement"
                                                       value={etablissement.telephone_etablissement}
                                                       style={{boxShadow: !lib.isValidPhone(etablissement.telephone_etablissement) ? '0 0 3px #CC0000' : ''}}
                                                />
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label style={{color: "red"}}>Email de
                                                    l'établissement <span>*</span></label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="email_etablissement"
                                                       value={etablissement.email_etablissement}
                                                       required="required"
                                                       style={{boxShadow: !lib.isValidEmail(etablissement.email_etablissement) ? '0 0 3px #CC0000' : ''}}/>
                                            </div>
                                            <div className="inputConnexion col-xs-12 col-sm-6">
                                                <label style={{color: "red"}}>Site de l'établissement</label>
                                                <input type="text" onChange={this._handleChangeInfos}
                                                       name="site_etablissement"
                                                       value={etablissement.site_etablissement}
                                                       style={{boxShadow: etablissement.site_etablissement && !lib.isValidURL(etablissement.site_etablissement) ? '0 0 3px #CC0000' : ''}}
                                                />
                                            </div>
                                        </div>

                                        <div className="encadreForm col-xs-12">
                                            <div
                                                className="inputConnexion inputSpecificite col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <label htmlFor="userPassword">SPECIFICITES <span>*</span> (plusieurs
                                                    choix
                                                    possible)</label>
                                                <div id="appbundle_etablissement_informationHebergement">
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_1"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="1"
                                                           checked={etablissement.specs.some(value => value.information_hebergement_id === 1)}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_1">
                                                        Hebergement permanent
                                                    </label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_2"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="2"
                                                           checked={etablissement.specs.some(value => value.information_hebergement_id === 2)}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_2">
                                                        Hebergement temporaire</label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_3"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="3"
                                                           checked={etablissement.specs.some(value => value.information_hebergement_id === 3)}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_3">
                                                        Accueil de jour
                                                    </label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_4"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="4"
                                                           checked={etablissement.specs.some(value => value.information_hebergement_id === 4)}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_4">
                                                        Unité alzheimer</label>
                                                    <input type="checkbox"
                                                           id="appbundle_etablissement_informationHebergement_5"
                                                           name="appbundle_etablissement[informationHebergement][]"
                                                           value="5"
                                                           checked={etablissement.specs.some(value => value.information_hebergement_id === 5)}
                                                           onChange={this._handleChangeSpec}
                                                    />
                                                    <label htmlFor="appbundle_etablissement_informationHebergement_5">
                                                        Aide social à l’hébergement
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="bouton submit col-xs-12">
                                                <input type="submit" id="valider2" name="envoyer"
                                                       value="Enregistrer mes informations"
                                                       disabled={this.state.btn2}/>
                                                < div className="error text-danger"> </div>
                                                {
                                                    alert.message && alert1 ?
                                                        <div className={`alert ${alert.type}`}>{alert.message}</div> :
                                                        alert.message && alert0 ?
                                                            <div className={`alert ${alert.type}`}>{alert.message}</div> : null
                                                }
                                            </div>
                                        </div>
                                    </form>
                                    <form name="appbundle_etablissement" onSubmit={this.uploadImages}
                                          encType="multipart/form-data" style={this.state.form_etablissement}>
                                        <div className="encadreForm">
                                            <div style={{textAlign: "center"}}>
                                                <label>Images (maximum 2MB par photo)</label>
                                                <div id="errorSize" style={{
                                                    borderRadius: "5px",
                                                    border: "2px solid #D55",
                                                    width: "60%",
                                                    display: this.state.display,
                                                    margin: "10px 20%",
                                                    backgroundColor: "#FCC",
                                                }}>{this.state.message}
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                <input type="file" onChange={this.selectImages}
                                                       name="image_un" accept="image/*"/>
                                                <br/>
                                                {
                                                    (etablissement.image_un) || this.state.image_un ?
                                                        <div className="row col-lg-12">

                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_un  ? this.state.image_un : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_un}
                                                                     className="img-rounded img-responsive"
                                                                     alt="traitement en cours..."
                                                                />
                                                                <br/>
                                                                {
                                                                    etablissement.image_un.length > 255 || this.state.image_un ? null :
                                                                    <button type="button"
                                                                            className="btn btn-danger btn-xs"
                                                                            id="1"
                                                                            onClick={this.handleDeleteImage}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                <input type="file" onChange={this.selectImages}
                                                       name="image_deux" accept="image/*"/>
                                                <br/>
                                                {
                                                    (etablissement.image_deux) || this.state.image_deux ?
                                                        <div className="row col-lg-12">

                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_deux ? this.state.image_deux : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_deux}
                                                                     className="img-rounded img-responsive"
                                                                     alt="traitement en cours..."
                                                                />
                                                                <br/>
                                                                {
                                                                    etablissement.image_deux.length > 255 || this.state.image_deux ? null :
                                                                    <button type="button"
                                                                            className="btn btn-danger btn-xs"
                                                                            id="2"
                                                                            onClick={this.handleDeleteImage}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                <input type="file" onChange={this.selectImages}
                                                       name="image_trois" accept="image/*"/>
                                                <br/>
                                                {
                                                    (etablissement.image_trois) || this.state.image_trois?
                                                        <div className="row col-lg-12">

                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_trois ? this.state.image_trois : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_trois}
                                                                     className="img-rounded img-responsive"
                                                                     alt="traitement en cours..."
                                                                />
                                                                <br/>
                                                                {
                                                                    etablissement.image_trois.length > 255 || this.state.image_trois ? null :
                                                                    <button type="button"
                                                                            className="btn btn-danger btn-xs"
                                                                            id="3"
                                                                            onClick={this.handleDeleteImage}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3 singleImageUpdate">
                                                <input type="file" onChange={this.selectImages}
                                                       name="image_quatre" accept="image/*"/>
                                                <br/>
                                                {
                                                    (etablissement.image_quatre) ?
                                                        <div className = "row col-lg-12" >
                                                            <div className="col-lg-12">
                                                                <img src={this.state.image_quatre ? this.state.image_quatre : "https://placemaisonderetraite.fr/img/annonces/"+etablissement.image_quatre}
                                                                     className="img-rounded img-responsive"
                                                                     alt="traitement en cours..."
                                                                />
                                                                <br/>
                                                                {
                                                                    etablissement.image_quatre.length > 255 || this.state.image_quatre ? null :
                                                                    <button type="button"
                                                                            className="btn btn-danger btn-xs"
                                                                            id="4"
                                                                            onClick={this.handleDeleteImage}>Supprimer</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            <div className="bouton submit col-xs-12">
                                                <input type="submit" id="valider" name="envoyer"
                                                       value="Enregistrer mes Photos"
                                                       disabled={this.state.btn3}/>

                                                {alert.message && alert2 ?
                                                    <div className={`alert ${alert.type}`}>{alert.message}</div> : null}
                                                < div className="error text-danger"> </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </section>
                    }
                </main>
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state){
    const { alert, authentication, etablissement, groupes } = state;
    const { user } = authentication;
    const { list } = etablissement;
    const groupesList = groupes.list;
    return  {
        user,
        alert,
        list,
        groupesList
    }
}

export default withRouter(connect(mapStateToProps)(Etablissements))
