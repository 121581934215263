import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Confirm, Alert} from "react-st-modal";
import ImageGallery from 'react-image-gallery';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import ScrollUpButton from "react-scroll-up-button";
import ReactPaginate from 'react-paginate';
import 'icheck/skins/all.css'; // or single skin css
import {Checkbox} from 'react-icheck';
import { Row, Col } from 'react-bootstrap';
import {etablissementActions} from "../../../../_actions/etablissement.actions";

import Navbar from "../../../../_components/Navbar";
//import Filter from "../../../../_components/Filter";
import Footer from "../../../../_components/Footer";
import projectList from "../../../../communes/communes.json";
import {authHeader, history} from "../../../../_helpers";
import {alertActions, userActions} from "../../../../_actions";
import lib from "../../../../lib/lib";
import DatePicker, {registerLocale} from "react-datepicker/es";
import fr from "date-fns/locale/fr";
import {withRouter} from "react-router";
registerLocale('fr', fr);
//import Autocomplete from "react-google-autocomplete";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Activations_Etablissement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            user: (localStorage.getItem('user') ? this.props.user : false),
            codePostal: '',
            ville: '',
            search: '',
            lat: '',
            lng: '',
            rayon: '',
            distance: false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            activate: "Tous",
            deleted: [],
            activated_item: [],
            annonces: [],
            annonce: {
                id: 0,
                user_id: 0,
                nbPLace: 1,
                createdAt: '',
                datePlace: new Date(),
                prix: 'NC',
                descriptif: '',
                codePostal: '',
                email_etablissement: '',
                nom_etablissement: '',
                rue: '',
                telephone_etablissement: '',
                site_etablissement: '',
                ville: '',
                activate: -1,
                specs: []
            },
            modif: false,
            preview: false,
            maxLength: 400,
            start: 0,
            end: 15,
            active: 1,
            unactivated: null,
            screenWidth: null
        };

        this.clickCheckBox = this.clickCheckBox.bind(this);

        this.handleChange           = this.handleChange.bind(this);
        this.handleModif            = this.handleModif.bind(this);
        this.handleChangeAnnonce    = this.handleChangeAnnonce.bind(this);
        this.handleSubmitUpdate     = this.handleSubmitUpdate.bind(this);
        this.handleChangeCGU        = this.handleChangeCGU.bind(this);
        this.handleChangeDate       = this.handleChangeDate.bind(this);
        this.handleChangeCGU        = this.handleChangeCGU.bind(this);
        this.handleChangeSpec       = this.handleChangeSpec.bind(this);
        this.handlePreview          = this.handlePreview.bind(this);

        this.supprimerFiltre = this.supprimerFiltre.bind(this);

        this.handleDelete   = this.handleDelete.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        //this.handleSelect = this.handleSelect.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(etablissementActions.getAllUnactivatedAnnonces(this.props.history));
        window.addEventListener("resize", this.updateWindowDimensions());
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.unactivated !== this.state.unactivated) {
            let unactivated = this.props.unactivated;

            this.setState({
                unactivated
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.unactivated !== prevState.unactivated) {
            return {unactivated: nextProps.unactivated};
        } else return null;
    }

    renderActive(item) {
        switch (item.activate) {
            case 1 : return <div>Activée <span className="green-dot"></span></div>;
            case -1: return <div>En attente d'activation <span className="orange-dot"></span></div>;
            default: return <div>Désactivée <span className="orange-dot"></span></div>;
        }
    }

    _renderTime(time) {
        var now = moment().toISOString()
        var diff = moment.duration(moment(time).diff(moment(now)));

        var days = parseInt(diff.asDays());
        if (days > 0) {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité dans { days } jour(s) <span className="orange-dot"></span>
                </p>
            )
        } else {
            return (
                <p className="subTitleResultat description">
                    <img className="img-responsive"
                         src="/img/visualisation-demande/icone-echeance.png"
                         alt="icone-echeance"/>
                    Disponibilité immédiate  <span className="green-dot"></span>
                </p>
            )
        }
    }

    changeInput(name, value) {
        this.setState({
            [name]: !value
        })
    }

    clickCheckBox(e) {
        const { name, type, checked } = e.target;

        if (type === "checkbox") {
            if (checked) {
                this.setState({
                    [name]: true
                })
            } else {
                this.setState({
                    [name]: false
                })
            }
        }
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;


        if (name === "rayon") {
            if (!this.state.codePostal) {
                Alert("Veuillez indiquer un code postal")
            } else {
                this.setState({
                    [name]: value,
                    distance: true,
                })
            }
        } else {
            this.setState({
                [name]: value,
                start: 0,
                end: 15
            })
        }
    }

    handleChangeAnnonce(e) {
        const {name, value} = e.target;
        const {annonce, maxLength} = this.state;

        if (name === "descriptif" && annonce.descriptif.length <= maxLength) {
            this.setState({
                annonce: {
                    ...annonce,
                    descriptif: lib.protectEntry(value)
                }
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    [name]: value
                }
            })
        }
    }

    handleChangeDate(date) {
        const {annonce} = this.state;

        this.setState({
            annonce: {
                ...annonce,
                datePlace: date
            }
        });
    };

    handleChangeCGU(e) {
        const {name, value} = e.target;

        this.setState({[name]: value})
    }

    handlePreview(e) {
        e.preventDefault();

        const {preview, annonce} = this.state;

        if (annonce.prix.length === 0) {
            Alert("Veuillez renseigner un budget")
        } else {
            this.setState({preview: !preview})
        }
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        const {annonce, CGU} = this.state;
        const {dispatch} = this.props;

        if (parseInt(CGU) === 1) {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({annonce})
            };

            return fetch(`https://backend.placemaisonderetraite.fr/etablissements/updateAnnonce`, requestOptions)
                .then(this.handleResponse)
                .then(result => {
                    this.props.dispatch(etablissementActions.getAllUnactivatedAnnonces(this.props.history));
                    this.setState({
                        modif: false,
                        preview: false
                    });
                    dispatch(alertActions.success(result.message));
                })
                .catch(err => {
                    dispatch(alertActions.error(err));
                    console.log(err)
                });
        } else {
            Alert("Veuillez lire et accepter les conditions générales d’utilisation.")
        }
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userActions.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            codePostal: '',
            ville: '',
            rayon: '',
            distance: false
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {

        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            codePostal: suggestion.value.codePostal,
            ville: suggestion.value.nom,
            lat: suggestion.value.geoLoc.coordinates[1],
            lng: suggestion.value.geoLoc.coordinates[0],
            rayon: "5",
            distance: true
        })

    };

    supprimerFiltre() {
        this.props.dispatch(etablissementActions.getAllUnactivatedAnnonces(this.props.history));
        this.setState({
            codePostal: '',
            ville: '',
            value: '',
            rayon: '',
            distance: false,
            type1: true,
            type2: true,
            type3: true,
            type4: true,
            start: 0,
            end: 15
        })
    }

    degreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
        var earthRadiusKm = 6371;

        var dLat = this.degreesToRadians(lat2-lat1);
        var dLon = this.degreesToRadians(lon2-lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return Math.floor(earthRadiusKm * c);
    }

    renderFilter(item) {
        const {lat, lng, distance, codePostal, deleted, activate} = this.state;
        const cPostal       = codePostal  !== '' ? (this.state.codePostal.indexOf(item.codePostal)       >= 0 ? true : false) : this.state.ville  !== '' ? (this.state.ville.indexOf(item.ville)  >= 0 ? true : false) :  true;
        const rayon         = distance === true  ? (this.distanceInKmBetweenEarthCoordinates(lat, lng, item.latitude, item.longitude) <= parseInt(this.state.rayon, 10)) : true;
        const notDel        = deleted.length !== 0 ? (deleted.indexOf(item.id) < 0) : true;
        var type            = true;


        switch (item.titre) {
            case "EHPAD"                        :   type = this.state.type1; break;
            case "USLD"                         :   type = this.state.type2; break;
            case "Résidence autonomie"          :   type = this.state.type3; break;
            case "Résidence services seniors"   :   type = this.state.type4; break;
            default :   type = true;
        }
        const locality = (cPostal || distance);

        return (locality && rayon && type && notDel);
    }

    async handleDelete(e) {
        e.preventDefault();
        const {deleted} = this.state;
        const { id } = e.target;
        var response = await Confirm("Voulez-vous vraiment supprimer cette annonce?", "", "Oui", "Fermer");

        if (response === true) {
            this.setState({
                deleted: [...deleted, id]
            });
            this.props.dispatch(etablissementActions.delAnnonce(id));
            this.props.dispatch(etablissementActions.getAllUnactivatedAnnonces(this.props.history));
        }
    }

    handleModif(e) {
        e.preventDefault();

        this.props.dispatch(alertActions.clear());
        const {modif, annonce} = this.state;

        const array = this.props.unactivated.list.filter(item => parseInt(item.id) === parseInt(e.target.id));

        this.setState({
            annonce: {
                ...annonce,
                id: array[0].id,
                user_id: array[0].user_id,
                codePostal: array[0].codePostal,
                createdAt: new Date(array[0].createdAt),
                datePlace: new Date(array[0].datePlace),
                descriptif: array[0].descriptif,
                email_etablissement: array[0].email_etablissement,
                nbPLace: array[0].nbPLace,
                nom_etablissement: array[0].nom_etablissement,
                prix: array[0].prix,
                rue: array[0].rue,
                telephone_etablissement: array[0].telephone_etablissement,
                site_etablissement: array[0].site_etablissement,
                ville: array[0].ville,
                specs: this.props.unactivated.specs.filter(function (el) { return parseInt(el.id_annonce) === parseInt(e.target.id)})
            },
            modif: !modif,
        });
        window.scrollTo(0,0)
    }

    handleActivate(e) {
        e.preventDefault();
        const {id, active} = e.target;
        if (active === 1) {
            this.props.dispatch(etablissementActions.activateAnnonce(id, -1))
        } else {
            this.props.dispatch(etablissementActions.activateAnnonce(id, 1))
        }
        this.props.dispatch(etablissementActions.getAllUnactivatedAnnonces(this.props.history));
    }

    getImages(item) {
        let images = [];

        if (item.image_un)
            images.push({
                original: "/img/annonces/" + item.image_un
            });
        if (item.image_deux)
            images.push({
                original: "/img/annonces/" + item.image_deux
            });
        if (item.image_trois)
            images.push({
                original: "/img/annonces/" + item.image_trois
            });
        if (item.image_quatre)
            images.push({
                original: "/img/annonces/" + item.image_quatre
            });

        return images
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 15);

        this.setState({start: offset, end: offset + 15})
    };

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {annonce} = this.state;

        if (!checked) {
            this.setState({
                annonce: {
                    ...annonce,
                    specs: annonce.specs.filter(function (item) {
                        return item.info_hebergement !== value;
                    })
                },
            })
        } else {
            this.setState({
                annonce: {
                    ...annonce,
                    specs: [...annonce.specs, {info_hebergement: value}]
                },
            })
        }
    }

    renderSpecs(item) {
        let specs = this.state.unactivated.specs.filter(elem => elem.id_annonce === item.id);

        if (specs.length > 0) {
            return (
                <p className="subTitleResultat" id="type">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités
                    : </strong><br/>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}
                </p>
            );
        } else
            return null
    }

    renderSpecsAnnonce() {
        let specs = this.state.annonce.specs;

        if (specs.length > 0) {
            return (
                <div className="visualisationInfo col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <i className="fa fa-check-square-o img-responsive" aria-hidden="true"> </i><strong>Spécificités: </strong><br/><p></p>
                    {specs[0].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[0].info_hebergement}<br/>
                    {specs[1] ? specs[1].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[1].info_hebergement : null}<br/>
                    {specs[2] ? specs[2].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[2].info_hebergement : null}<br/>
                    {specs[3] ? specs[3].info_hebergement === 'Possibilité de court séjour / séjour temporaire' ? '- Possibilité de court séjour / temporaire' : '- ' + specs[3].info_hebergement : null}<br/>
                </div>
            );
        } else
            return null
    }

    renderModif() {
        moment.locale('fr');
        const {annonce, preview} = this.state;
        const {alert} = this.props;
        let date = new Date();
        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <main>
                {!preview ?
                    <section className="demandeEtablissementBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; <a
                                href="/administrateur/activations">Activation</a> &gt; <a href="/administrateur/activations/etablissements">Annonces des établissements</a> &gt; Mise à jour de l'annonce
                            </p>
                            <h1>Mise à jour de l'annonce <span>pour des places disponibles</span></h1>

                            <div className="encadreForm">


                                <div className="text-center text-success validationDemandeEtablissement">
                                </div>
                                <form name="appbundle_annonce" onSubmit={this.handlePreview}>

                                    <div className="formBloc1">
                                        <div className="formBloc1_1 col-xs-12 col-sm-6">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Nombre de places</p>
                                                </div>
                                                <div className="inputDemande">
                                                    <input type="number" id="appbundle_annonce_nbPLace"
                                                           name="nbPLace" required="required" min="1"
                                                           max="10" value={annonce.nbPLace}
                                                           onChange={this.handleChangeAnnonce}
                                                        //placeholder={annoncenbPLace}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="formBloc1_2 col-xs-12 col-sm-6 datePlace">
                                            <div className="form-group row">
                                                <div className="question">
                                                    <p>A partir de quand vos places sont disponibles
                                                        ?<span> *</span></p>
                                                </div>
                                                <div className="inputDemande">
                                                    <DatePicker
                                                        selected={annonce.datePlace}
                                                        onChange={this.handleChangeDate}
                                                        minDate={date}
                                                        maxDate={date.addDays(30)}
                                                        dateFormat="dd-MM-yyyy"
                                                        locale="fr"
                                                        className=""
                                                        //placeholder={annonce.datePlace}
                                                        //withPortal
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p>Quel est le niveau de budget mensuel ?</p>
                                                </div>
                                                <div className="inputBudget">
                                                    <div id="appbundle_annonce_prix">
                                                        <input type="radio" id="appbundle_annonce_prix_0"
                                                               name="prix" value="Moins de 2000€"
                                                               onChange={this.handleChangeAnnonce}
                                                               defaultChecked={(annonce.prix === "Moins de 2000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_0">Moins de 2000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_1"
                                                               name="prix" value="2000€ à 2500€"
                                                               onChange={this.handleChangeAnnonce}
                                                               defaultChecked={(annonce.prix === "2000€ à 2500€") || (annonce.prix === "De 2000€ à 2500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_1">De 2000€ à
                                                            2500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_2"
                                                               name="prix" onChange={this.handleChangeAnnonce}
                                                               value="2500€ à 3000€"
                                                               defaultChecked={(annonce.prix === "2500€ à 3000€") || (annonce.prix === "De 2500€ à 3000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_2">De 2500€ à
                                                            3000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_3"
                                                               name="prix" onChange={this.handleChangeAnnonce}
                                                               value="3000€ à 3500€"
                                                               defaultChecked={(annonce.prix === "3000€ à 3500€") || (annonce.prix === "De 3000€ à 3500€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_3">De 3000€ à
                                                            3500€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_4"
                                                               name="prix" onChange={this.handleChangeAnnonce}
                                                               value="3500€ à 4000€"
                                                               defaultChecked={(annonce.prix === "3500€ à 4000€") || (annonce.prix === "De 3500€ à 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_4">De 3500€ à 4000€</label>
                                                        <input type="radio" id="appbundle_annonce_prix_5"
                                                               name="prix" onChange={this.handleChangeAnnonce}
                                                               value="Plus de 4000€"
                                                               defaultChecked={(annonce.prix === "Plus de 4000€")}
                                                        />
                                                        <label htmlFor="appbundle_annonce_prix_4">Plus de 4000€</label>
                                                    </div>
                                                </div>
                                                <div className="formBloc1_4 col-xs-12">
                                                    <div className="row">
                                                        <div className="question">
                                                            <p><strong> Spécificités </strong> (Facultatif - Plusieurs choix possibles)</p>
                                                        </div>
                                                        <div className="inputInfos col-xs-12">

                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec1" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function (el) { return el && el.info_hebergement === "Place en unité Alzheimer / protégée"}).length > 0} value="Place en unité Alzheimer / protégée" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec1" style={{fontSize: "1em", marginTop: '1%'}}>Place en unité Alzheimer / protégée</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec2" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Possibilité de court séjour / séjour temporaire"}).length > 0} value="Possibilité de court séjour / séjour temporaire" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec2" style={{fontSize: "1em", marginTop: '1%'}}>Possibilité de court séjour / séjour temporaire</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec3" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "ASH possible (Aide Sociale à l’Hébergement)"}).length > 0} value="ASH possible (Aide Sociale à l’Hébergement)" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec3" style={{fontSize: "1em", marginTop: '1%'}}>ASH possible (Aide Sociale à l’Hébergement)</label>
                                                                </div>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                                                    <input id="spec4" type="checkbox" style={{transform: 'scale(1.4)'}} name="specs" checked={annonce.specs.filter( function(el) { return el && el.info_hebergement === "Place en accueil de jour"}).length > 0} value="Place en accueil de jour" onChange={this.handleChangeSpec}/>
                                                                    <label htmlFor="spec4" style={{fontSize: "1em", marginTop: '1%'}}>Place en accueil de jour</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formBloc2">
                                        <div className="formBloc2_2 col-xs-12">
                                            <div className="row">
                                                <div className="question">
                                                    <p><strong>Informations supplémentaires</strong> (que vous
                                                        souhaitez
                                                        transmettre aux familles et/ou aux personnes qui
                                                        informent
                                                        les
                                                        familles)</p><p></p>
                                                </div>
                                                <div className="inputBudget">
                                                    <textarea id="appbundle_annonce_descriptif"
                                                              name="descriptif" onChange={this.handleChangeAnnonce}
                                                              value={annonce.descriptif}
                                                        //placeholder={annonce.descriptif}
                                                              maxLength="400">
                                                    </textarea>
                                                </div>
                                                <div id="counter">
                                                    {annonce.descriptif.length} / 400
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bouton submit">
                                            <button type="submit" id="appbundle_annonce_previus"
                                                    name="appbundle_annonce[previus]"
                                                    label="Prévisualiser"> Prévisualiser
                                            </button>
                                        </div>
                                        <input type="hidden" id="appbundle_annonce_equipements"
                                               name="appbundle_annonce[equipements]" value="vide"/>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>
                    :
                    <section className="visualisationDemandeBloc1">
                        <div className="container">
                            <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; <a
                                href="/administrateur/activations">Activation</a> &gt; <a href="/administrateur/activations/etablissements">Annonces des établissements</a> &gt; Mise à jour de l'annonce
                            </p>
                            <h1>Je visualise <span>l'annonce</span></h1>

                            <p className="titleForm"> </p>
                            <div className="encadreForm">
                                <div className="visualisationDemandeBloc1_1 col-xs-12">
                                    <div className="row">
                                        <p className="titleLieu">{annonce.nbPLace} place(s) disponible(s)
                                            à {annonce.ville} {annonce.codePostal}</p>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-echeance.png"
                                                 alt="icone-sexe"/>À partir du {moment(annonce.datePlace).format('DD/MM/YYYY')}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-budget.png"
                                                 alt="icone-sexe"/>
                                            Niveau de budget mensuel de {annonce.prix}
                                        </div>
                                        {
                                            this.renderSpecsAnnonce()
                                        }
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-type-etablissement.png"
                                                 alt="icone-sexe"/>
                                            {annonce.nom_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-secteur.png"
                                                 alt="icone-sexe"/>
                                            {annonce.rue} {annonce.ville}
                                        </div>

                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                            {annonce.telephone_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-at" aria-hidden="true"> </i>
                                            {annonce.email_etablissement}
                                        </div>
                                        <div className="visualisationInfo col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                            <i className="fa fa-internet-explorer" aria-hidden="true"> </i>

                                            <a href={siteReg.test(annonce.site_etablissement) ? annonce.site_etablissement : 'https://' + annonce.site_etablissement}
                                               target="_blank">Site de l'établissement</a>
                                        </div>

                                        <div className="visualisationInfo description col-xs-12">
                                            <img className="img-responsive"
                                                 src="/img/visualisation-demande/icone-commentaires.png"
                                                 alt="icone-commentaires"/>
                                            <p>Description :
                                            </p><p>{annonce.descriptif}</p><p></p>
                                        </div>
                                    </div>
                                </div>
                                <form name="appbundle_annonce" className="form-horizontal">
                                    <div
                                        className="inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input type="checkbox" name="CGU" value="1" required="required"
                                               onChange={this.handleChangeCGU}/>
                                        <label>j’accepte les <a href="/conditions-generales"
                                                                target="_blank">conditions générales
                                            d’utilisation.</a></label>
                                    </div>
                                    {
                                        alert.message ?
                                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                                            :
                                            <div className="boutons col-xs-12">
                                                <div className="row">
                                                    <div className="bouton modifier">
                                                        <button type="submit" id="appbundle_annonce_update"
                                                                name="appbundle_annonce[update]"
                                                                onClick={this.handlePreview}
                                                                label="Modifier ma demande">Modifier
                                                        </button>
                                                    </div>
                                                    <div className="bouton">
                                                        <button type="submit" id="appbundle_annonce_save"
                                                                name="appbundle_annonce[save]"
                                                                onClick={this.handleSubmitUpdate}
                                                                label="Enregistrer">Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </section>
                }
            </main>

        )

    }

    render() {
        moment.locale('fr');
        const {unactivated, modif, type1, type2, type3, type4, screenWidth} = this.state;
        const {lat, lng, rayon, value, suggestions, start, end} = this.state;
        let filtredAnnonces = unactivated.list ? unactivated.list.filter(item => this.renderFilter(item)) : [];
        const {alert} = this.props;

        const inputProps = {
            placeholder: 'Entrez un code postal ou une ville',
            value,
            onChange: this.onChange
        };
        let siteReg = /^(http(s)?:\/\/)/;
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                {
                    modif ? this.renderModif() :
                    <main>
                        <section className="listeDemandeBloc1">
                            <div className="container">
                                <p className="chemin"><a href="/administrateur">Mon compte</a> &gt; <a
                                    href="/administrateur/activations">Activation</a> &gt; Annonces des établissements
                                </p>

                                <h1>Annonces <span>En Attente d'activation</span></h1>

                                <div className="col-xs-12 triSquare"
                                     style={{border: "#bec8c9 .1rem solid", padding: "2rem", margin: "0 0 1rem 0"}}>
                                    <div className="infoSpecial">
                                        {
                                            screenWidth > 763 ?
                                            <div className="listeEtablissementTri">
                                                <div
                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive"
                                                             src="/img/home/homeBloc1/icone-annonce1.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.changeInput("type1", type1)}
                                                             title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_1" style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            fontSize: '1.3rem'
                                                        }}>EHPAD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{
                                                               marginLeft: 'auto',
                                                               marginRight: 'auto',
                                                               transform: 'scale(1.5)',
                                                               width: '30px'
                                                           }}
                                                           id="appbundle_alertedispo_etablissement_1"
                                                           name="type1"
                                                           value={type1}
                                                           checked={type1}
                                                           onChange={this.clickCheckBox}
                                                    />
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive"
                                                             src="/img/home/homeBloc1/icone-annonce3.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.changeInput("type2", type2)}
                                                             title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_2" style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            fontSize: '1.3rem'
                                                        }}>USLD</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{
                                                               marginLeft: 'auto',
                                                               marginRight: 'auto',
                                                               transform: 'scale(1.5)',
                                                               width: '30px'
                                                           }}
                                                           id="appbundle_alertedispo_etablissement_2"
                                                           name="type2"
                                                           value={type2}
                                                           checked={type2}
                                                           onChange={this.clickCheckBox}
                                                    />
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive"
                                                             src="/img/home/homeBloc1/icone-annonce4.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.changeInput("type3", type3)}
                                                             title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_3" style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            fontSize: '1.3rem'
                                                        }}>Résidence autonomie</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{
                                                               marginLeft: 'auto',
                                                               marginRight: 'auto',
                                                               transform: 'scale(1.5)',
                                                               width: '30px'
                                                           }}
                                                           id="appbundle_alertedispo_etablissement_3"
                                                           name="type3"
                                                           value={type3}
                                                           checked={type3}
                                                           onChange={this.clickCheckBox}
                                                    />
                                                </div>

                                                <div
                                                    className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-3">
                                                    <div className="row" style={{textAlign: 'center'}}>
                                                        <img className="img-responsive"
                                                             src="/img/home/homeBloc1/icone-annonce5.png"
                                                             alt="icone-etablissement"
                                                             onClick={() => this.changeInput("type4", type4)}
                                                             title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                        <label htmlFor="appbundle_alertedispo_etablissement_4" style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            fontSize: '1.3rem'
                                                        }}>Résidence
                                                            services
                                                            seniors</label>
                                                    </div>
                                                    <input type="checkbox"
                                                           style={{
                                                               marginLeft: 'auto',
                                                               marginRight: 'auto',
                                                               transform: 'scale(1.5)',
                                                               width: '30px'
                                                           }}
                                                           id="appbundle_alertedispo_etablissement_4"
                                                           name="type4"
                                                           value={type4}
                                                           checked={type4}
                                                           onChange={this.clickCheckBox}
                                                    />
                                                </div>
                                            </div>
                                                :
                                                <div style={{justifyContent: 'center'}}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce1.png"
                                                                     alt="EHPAD"
                                                                     onClick={() => this.changeInput("type1", type1)}
                                                                     title="Etablissements d’hébergement pour personnes âgées dépendantes, ce sont des maisons de retraite médicalisées."/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type1"
                                                                    checked={type1}
                                                                    name="type1"
                                                                    value={type1}
                                                                    onChange={() => this.changeInput("type1", type1)}
                                                                />
                                                                <label htmlFor="label_type1" style={{marginLeft: '3%'}}>EHPAD</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce3.png"
                                                                     alt="USLD"
                                                                     onClick={() => this.changeInput("type2", type2)}
                                                                     title="Unités de soins de longue durée, ce sont les lieux d’accueil les plus médicalisés"/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type2"
                                                                    checked={type2}
                                                                    name="type2"
                                                                    value={type2}
                                                                    onChange={() => this.changeInput("type2", type2)}
                                                                />
                                                                <label htmlFor="label_type2" style={{marginLeft: '3%'}}>USLD</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce4.png"
                                                                     alt="Résidence autonomie"
                                                                     onClick={() => this.changeInput("type3", type3)}
                                                                     title="Ex-logements-foyers, elles accueillent majoritairement des personnes autonomes dans des petits appartements"/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type3"
                                                                    checked={type3}
                                                                    name="type3"
                                                                    value={type3}
                                                                    onChange={() => this.changeInput("type3", type3)}
                                                                />
                                                                <label htmlFor="label_type3" style={{marginLeft: '3%'}}>Résidence autonomie</label>
                                                            </div>
                                                            <div className="inputCheckboxContent col-xs-6 col-sm-12 col-md-6 col-lg-4">
                                                                <img className="img-responsive"
                                                                     src="/img/home/homeBloc1/icone-annonce5.png"
                                                                     alt="Résidence services seniors"
                                                                     onClick={() => this.changeInput("type4", type4)}
                                                                     title="Résidences avec services qui accueillent des personnes âgées autonomes seules ou en couple en T1 jusqu’au T3."/>
                                                                <Checkbox
                                                                    checkboxClass="icheckbox_flat-blue"
                                                                    increaseArea="20%"
                                                                    id="label_type4"
                                                                    checked={type4}
                                                                    name="type4"
                                                                    value={type4}
                                                                    onChange={() => this.changeInput("type4", type4)}
                                                                />
                                                                <label htmlFor="label_type4" style={{marginLeft: '3%'}}>Résidence services seniors</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                        <div className="secondFiltre">
                                            <div className="villeInput triVille"
                                                 style={{position: "relative", width: "100%", margin: "0.5 auto"}}>
                                                <Autosuggest
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>

                                            <div className="listeKm" style={{marginRight: "1rem"}}>
                                                <button className={rayon === "5" ? "singleKm active" : "singleKm"}
                                                        type="submit" name="rayon" data-km="5" value="5"
                                                        onClick={this.handleChange}>
                                                    5km
                                                </button>
                                                <button className={rayon === "15" ? "singleKm active" : "singleKm"}
                                                        type="submit" name="rayon" data-km="15" value="15"
                                                        onClick={this.handleChange}>
                                                    15km
                                                </button>
                                                <button className={rayon === "30" ? "singleKm active" : "singleKm"}
                                                        type="submit" name="rayon" data-km="30" value="30"
                                                        onClick={this.handleChange}>
                                                    30km
                                                </button>
                                                <button className={rayon === "50" ? "singleKm active" : "singleKm"}
                                                        type="submit" name="rayon" data-km="50" value="50"
                                                        onClick={this.handleChange}>
                                                    50km
                                                </button>
                                                <button className={rayon === "100" ? "singleKm active" : "singleKm"}
                                                        type="submit" name="rayon" data-km="100" value="100"
                                                        onClick={this.handleChange}>
                                                    100km
                                                </button>
                                            </div>

                                            <div className="bouton submit subConf">
                                                <input type="submit" name="supprimer"
                                                       value="Supprimer les Filtres"
                                                       style={{marginTop: "10px"}}
                                                       onClick={this.supprimerFiltre}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <br/>
                                {
                                    alert.message ?
                                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                                        :
                                        null
                                }
                                <br/>

                                <p className="text-center col-xs-12" style={{margin: "0"}}> </p>
                                {
                                    !unactivated ?
                                        <div>
                                            <h1>Pas d'annonces <span>en attente d'activation</span></h1>
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                        </div>
                                        :
                                    <div id="listeDemande">
                                        {
                                            filtredAnnonces.slice(start, end).map((item) =>
                                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4" distance="0"
                                                     key={item.id}>
                                                    <div className="singleDemande listeannonceAdmin">
                                                        {this.renderActive(item)}
                                                        <div className="">
                                                            <div className="question">
                                                                <p className="titleSingleResultat" id="nbPlace">
                                                                    Annonce publiée
                                                                    le {moment(item.createdAt).format('DD/MM/YYYY')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.distance ?
                                                                <div className="distSentenceCont">À moins
                                                                    de {this.distanceInKmBetweenEarthCoordinates(lat, lng, item.latitude, item.longitude)}km.</div> : null
                                                        }
                                                        <div className="">
                                                            <div className="question">
                                                                <p className="titleSingleResultat" id="nbPlace">
                                                                    {item.nbPLace} place(s) disponible(s)
                                                                    à {item.ville} {item.codePostal}
                                                                </p></div>
                                                        </div>

                                                        {
                                                            this.getImages(item).length > 0 ?
                                                                <ImageGallery additionalClass="ImageGallery"
                                                                              showThumbnails={false}
                                                                              showPlayButton={false}
                                                                              showBullets={false}
                                                                              showFullscreenButton={false}
                                                                              items={this.getImages(item)}
                                                                />
                                                                :
                                                                <img className="img-responsive"
                                                                     style={{margin: "2rem auto"}}
                                                                     src={item.titre === 'EHPAD' ?
                                                                         "/img/home/homeBloc1/icone-annonce1.png" :
                                                                         item.titre === 'USLD' ?
                                                                             "/img/home/homeBloc1/icone-annonce3.png" :
                                                                             item.titre === 'Résidence autonomie' ?
                                                                                 "/img/home/homeBloc1/icone-annonce4.png" :
                                                                                 "/img/home/homeBloc1/icone-annonce5.png"}
                                                                     alt="icone-maison"/>
                                                        }
                                                        <div className="infoActivation">
                                                            {this._renderTime(item.datePlace)}

                                                            <p className="subTitleResultat" id="type">
                                                                <i className="fa fa-building-o  img-responsive"
                                                                   aria-hidden="true"> </i>
                                                                {item.titre}
                                                            </p>
                                                            <p className="subTitleResultat" id="type">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-budget.png"
                                                                     alt="icone-budget"/>
                                                                Budget mensuel : {item.prix}
                                                            </p>
                                                            {
                                                                this.renderSpecs(item)
                                                            }
                                                            <hr/>
                                                            <p className="subTitleResultat" id="type">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-type-etablissement.png"
                                                                     alt="icone-type-etablissement"/>
                                                                <strong style={{fontSize: '1.3em'}}>{item.nom_etablissement}</strong>
                                                            </p>
                                                            <p className="subTitleResultat" id="adresse">
                                                                <img className="img-responsive"
                                                                     src="/img/visualisation-demande/icone-secteur.png"
                                                                     alt="icone-secteur"/>
                                                                {item.rue}, {item.codePostal} {item.ville}
                                                            </p>
                                                            <p className="subTitleResultat">
                                                                <i className="fa fa-phone img-responsive" aria-hidden="true"> </i>
                                                                <a style={{fontSize: '1.5rem'}} href={`tel:${item.telephone_etablissement}`}>{item.telephone_etablissement}</a>
                                                            </p>
                                                            <p className="subTitleResultat mail">
                                                                <i className="fa fa-at" aria-hidden="true"> </i>
                                                                <a href={"mailto:" + item.email_etablissement}>{item.email_etablissement}</a>
                                                            </p>
                                                            {
                                                                item.site_etablissement ?
                                                                    <div className="subTitleResultat website">
                                                                        <i className="fa fa-internet-explorer" aria-hidden="true"> </i>
                                                                        <a href={siteReg.test(item.site_etablissement) ? item.site_etablissement : 'https://' + item.site_etablissement}
                                                                           target="_blank">Site de l'établissement</a>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                item.descriptif ?
                                                                    <p className="subTitleResultat description">
                                                                        <br/>
                                                                        <img className="img-responsive"
                                                                             src="/img/visualisation-demande/icone-commentaires.png"
                                                                             alt="icone-commentaires"/>
                                                                        {item.descriptif}
                                                                    </p>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="row">
                                                                <div className="col-xs12 bouton voir">
                                                                    <button id={item.id} onClick={this.handleModif}>Modifier</button>
                                                                </div>
                                                                <div className="col-xs12 bouton supprimer">
                                                                    <button onClick={this.handleDelete}
                                                                            id={item.id}>Supprimer
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                {
                                                                    item.activate === 1 ?
                                                                        <div className="col-xs12 bouton voir">
                                                                            <button onClick={this.handleActivate}
                                                                                    id={item.id}
                                                                                    active={item.activate}>Désactiver
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        <div className="col-xs12 bouton voir">
                                                                            <button onClick={this.handleActivate}
                                                                                    id={item.id}
                                                                                    active={item.activate}>Activer
                                                                            </button>
                                                                        </div>
                                                                }

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }

                                        {/*
                                            this.renderPages(filtredAnnonces.length)
                                        */}
                                        <div className="col-xs-12 pages">
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={Math.ceil(filtredAnnonces.length / 15)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'btn-page'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>

                                }
                            </div>

                        </section>
                    </main>
                }
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={150}
                    EasingType='easeOutCubic'
                    AnimationDuration={500}
                    ContainerClassName='ScrollUpButton__Container'
                    TransitionClassName='ScrollUpButton__Toggled'
                    style={{}}
                    ToggledStyle={{}}
                />
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, etablissement, alert} = state;
    const {user} = authentication;
    const { unactivated } = etablissement;
    return {
        user,
        alert,
        unactivated
    }
}

export default withRouter(connect(mapStateToProps)(Activations_Etablissement))
