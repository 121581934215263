import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ReCaptcha} from "react-recaptcha-google";
//import Autocomplete from "react-google-autocomplete";


import Autosuggest from 'react-autosuggest';

import Navbar from '../../_components/Navbar';
import Footer from '../../_components/Footer';
import lib from "../../lib/lib";
import {userActions} from "../../_actions";
import projectList from "../../communes/communes.json";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Login_Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                username: '',
                password: '',
                password2: '',
                email: '',
                civilite: '',
                nom: '',
                prenom: '',
                telephone: '',
                nomEtablissement: '',
                groupe: '',
                typeEtablissement: 0,
                statut: 'PUBLIC',
                finess: '',
                adresse: '',
                ville: '',
                codePostal: '',
                longitude: '',
                latitude: '',
                telephoneEtablissement: '',
                emailEtablissement: '',
                siteEtablissement: '',
                specificite: [],
                code_insee: '',
                roles: '',
            },
            value: '',
            suggestions: [],
            submittedCon: false,
            submittedReg: false,
            condition: false,
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleChangeSpec = this.handleChangeSpec.bind(this);
        this.handleChangeCGU  = this.handleChangeCGU.bind(this);

        this.handleSubmitReg = this.handleSubmitReg.bind(this);
        this.handleSubmitCon = this.handleSubmitCon.bind(this);
    }


    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        //console.log(recaptchaToken, "<= your recaptcha token")
        return recaptchaToken;
    }

    handleChangeUser(event) {
        const {name, value} = event.target;
        const {user} = this.state;

        this.setState({
            user: {
                ...user,
                [name]: lib.protectEntry(value)
            }
        })
    }

    handleChangeSpec(event) {
        const {value, checked} = event.target;
        const {user} = this.state;

        if (!checked) {
            this.setState({
                user: {
                    ...user,
                    specificite: user.specificite.filter(function (item) {
                        return item != value;
                    })
                }
            })
        } else {
            this.setState({
                user: {
                    ...user,
                    specificite: [...user.specificite, value]
                }
            })
        }
    }

    handleChangeCGU() {
        this.setState({ condition : !this.state.condition })
    }

    handleSubmitReg(event) {
        event.preventDefault();

        this.setState({submittedReg: true});
        const { user } = this.state;
        const { dispatch } = this.props;

        if (lib.isValidUsername(user.username) &&
            lib.isValidEmail(user.email) &&
            lib.isValidPassword(user.password) &&
            lib.passwordMatch(user.password, user.password2) &&
            lib.isValidPhone(user.telephone) &&
            lib.isValidPhone(user.telephoneEtablissement) &&
            lib.isValidEmail(user.emailEtablissement) &&
            user.nomEtablissement &&
            user.finess &&
            user.statut &&
            user.typeEtablissement &&
            user.adresse &&
            user.ville &&
            this.state.condition)
        {
            dispatch(userActions.register(user, 2));
        }
    }

    handleSubmitCon(e) {
        e.preventDefault();

        this.setState({ submittedCon: true });
        const { user } = this.state;
        const { dispatch } = this.props;
        if (lib.isValidUsername(lib.protectEntry(user.username)) && lib.isValidPassword(lib.protectEntry(user.password))) {
            dispatch(userActions.login(lib.protectEntry(user.username), lib.protectEntry(user.password)));
        }
    }
/*
    handleSelect(place) {
        const {user} = this.state;

        let code_postal = "";
        let ville = "";

        place.address_components.map(item => {
            if (item.types[0] == "postal_code") {
                code_postal = item.long_name;
            } else if (item.types[0] == "locality") {
                ville = item.long_name;
            }
        });
        this.setState({
            user: {
                ...user,
                codePostal: code_postal,
                ville: ville,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            }
        })
    };
*/

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
        const {user} = this.state;
        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            user: {
                ...user,
                codePostal: suggestion.value.codePostal,
                code_insee: suggestion.value.code_insee,
                ville: suggestion.value.nom,
                latitude: suggestion.value.geoLoc.coordinates[1],
                longitude: suggestion.value.geoLoc.coordinates[0]
            }
        })

    };

    _renderConnexion(username, password, submittedCon, loggingIn) {
        return (
            <section className="inscriptionStep2 connexion">
                <div className="container">
                    <h1>Connexion</h1>
                    <div className="titleForm">
                        <p>Entrez vos identifiants de connexion</p>
                    </div>
                    <form name="form" onSubmit={this.handleSubmitCon}>

                        <div className="encadreForm col-xs-12">
                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedCon && !lib.isValidUsername(username) ? ' has-error' : '')}>
                                <label htmlFor="userName">Identifiant *</label>
                                <input type="text" id="username" name="username" className="form-control" value={username} required="required" onChange={this.handleChangeUser}/>
                                {submittedCon && !lib.isValidUsername(username) &&
                                <div className="help-block">L'identifiant peut contenir (lettres/chiffres/_) </div>
                                }
                            </div>
                            <div className={'inputConnexion col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedCon && !lib.isValidPassword(password) ? ' has-error' : '')}>
                                <label htmlFor="userPassword">Mot de passe *</label>
                                <input type="password" id="password" name="password" className="form-control" value={password} required="required" onChange={this.handleChangeUser}/>
                                {submittedCon && !lib.isValidPassword(password) &&
                                <div className="help-block">le Mot de passe doit contenir au moins 4 caractères </div>
                                }
                            </div>

                            <input type="hidden" name="_target_path" value="/"/>
                            <div className="bouton submit">
                                <input type="submit" name="_submit" value="Valider" />
                                {loggingIn &&
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                            <div className="text-center"><a href="/resetting">Mot de passe oublié ?</a></div>
                        </div>
                    </form>
                </div>
            </section>
        )
    }

    _renderRegister(user, submittedReg, registering) {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: 'Indiquez une ville ou un code postal',
            value,
            onChange: this.onChange
        };
        return (
            <section className="inscriptionStep1">
                <div className="container">
                    <h1>Inscription</h1>
                    <form name="form" onSubmit={this.handleSubmitReg} id="inscriptionEtablissement">
                        <div className="titleForm">
                            <p id="coordonne">Vos coordonnées</p>
                        </div>
                        <div className="encadreForm col-xs-12 first">
                            <div className="question">
                                <p><strong>Choisissez vos identifiants de connexion</strong></p>
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submittedReg && !user.username ? ' has-error' : '')}>
                                <label htmlFor="username">Identifiant <span className="rouge">*</span></label>
                                <input type="text" className="form-control" name="username" value={user.username}
                                       onChange={this.handleChangeUser}/>
                                {submittedReg && !lib.isValidUsername(user.username) &&
                                <div className="help-block">Identifiant requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submittedReg && !user.password ? ' has-error' : '')}>
                                <label htmlFor="password">Mot de passe <span className="rouge">*</span></label>
                                <input type="password" className="form-control" name="password" value={user.password}
                                       onChange={this.handleChangeUser}/>
                                {submittedReg && !lib.isValidPassword(user.password) &&
                                <div className="help-block">Mot de passe requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-4 form-group' + (submittedReg && !user.password2 ? ' has-error' : '')}>
                                <label htmlFor="password">Retapez votre mot de passe <span
                                    className="rouge">*</span></label>
                                <input type="password" className="form-control" name="password2" value={user.password2}
                                       onChange={this.handleChangeUser}/>
                                {submittedReg && !lib.passwordMatch(user.password2) &&
                                <div className="help-block">Confirmez Mot de passe</div>
                                }
                            </div>

                            <div className="question">
                                <p><strong>Contact</strong> (Informations pour <span className="urltitre2">Place<span
                                    className="rouge">Maison</span>De<span className="rouge">Retraite</span>.fr</span> pour la gestion des annonces, pour recevoir
                                    vos alertes. Ces informations ne sont pas à destination des familles et
                                    n’apparaitront pas dans vos annonces)</p>
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                                <label>Civilite (facultatif)</label>
                                <select name="civilite" value={user.civilite} onChange={this.handleChangeUser}>
                                    <option value=""></option>
                                    <option value="Mademoiselle">Mademoiselle</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Monsieur">Monsieur</option>
                                </select>
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                                <label>Nom (facultatif)</label>
                                <input type="text" name="nom" value={user.nom} onChange={this.handleChangeUser}/>
                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                                <label>Prénom (facultatif)</label>
                                <input type="text" name="prenom" value={user.prenom} onChange={this.handleChangeUser}/>
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.telephone ? ' has-error' : '')}>
                                <label>Téléphone <span className="rouge">*</span></label>
                                <input type="tel" name="telephone" className="form-control" value={user.telephone}
                                       onChange={this.handleChangeUser}
                                       placeholder="Ne pas mettre d'espaces, de points ou de tirets."/>
                                {submittedReg && !lib.isValidPhone(user.telephone) &&
                                <div className="help-block">Mauvais format de N° de téléphone</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.telephone ? ' has-error' : '')}>
                                <label>Email <span className="rouge">*</span></label>
                                <input type="email" name="email" className="form-control" value={user.email}
                                       onChange={this.handleChangeUser}/>
                                {submittedReg && !lib.isValidEmail(user.email) &&
                                <div className="help-block">Mauvais format d'Email</div>
                                }
                            </div>
                            <div className="question">
                                <p><strong>Établissement</strong> (les informations en rouge serviront à créer vos
                                    annonces)</p>
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.nomEtablissement ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Nom de votre établissement <span
                                    className="rouge">*</span></label>
                                <input type="text" name="nomEtablissement" className="form-control"
                                       value={user.nomEtablissement} onChange={this.handleChangeUser}
                                       placeholder="Ex&nbsp;: Résidence des Lilas"/>
                                {submittedReg && !user.nomEtablissement &&
                                <div className="help-block">Nom de votre établissement requis</div>
                                }

                            </div>
                            <div className="inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <label>Votre groupe (le cas échéant)</label>
                                <input type="text" name="groupe" className="form-control" value={user.groupe}
                                       onChange={this.handleChangeUser} placeholder="Ex&nbsp;: Groupe Fleurs"/>
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.typeEtablissement ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Type d'établissement <span
                                    className="rouge">*</span></label>
                                <select name="typeEtablissement" value={user.typeEtablissement} onChange={this.handleChangeUser}>

                                    <option value="0">Choisir</option>
                                    <option value="1">EHPAD</option>
                                    <option value="2">USLD</option>
                                    <option value="3">Résidence autonomie</option>
                                    <option value="4">Résidence services seniors</option>
                                </select>
                                {submittedReg && !user.typeEtablissement &&
                                <div className="help-block">Type de votre établissement requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.statut ? ' has-error' : '')}>
                                <label>Statut <span className="rouge">*</span></label>
                                <select name="statut" value={user.statut} onChange={this.handleChangeUser}>
                                    <option value="public">PUBLIC</option>
                                    <option value="privé non lucratif (association)">PRIVE NON LUCRATIF
                                        (association)
                                    </option>
                                    <option value="privé">PRIVE</option>
                                </select>
                                {submittedReg && !user.statut &&
                                <div className="help-block">Statut de votre établissement requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 finessField form-group' + (submittedReg && !user.finess ? ' has-error' : '')}>
                                <label>N° Finess <span className="rouge">*</span></label>
                                <input type="text" name="finess" className="form-control" value={user.finess}
                                       onChange={this.handleChangeUser}
                                       placeholder="Ne pas mettre d'espaces, de points ou de tirets."/>
                                {submittedReg && !user.finess &&
                                <div className="help-block">N° Finess requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos villeInput col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.adresse ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Adresse <span className="rouge">*</span></label>
                                <input type="text" name="adresse" className="form-control" value={user.adresse}
                                       onChange={this.handleChangeUser}
                                       placeholder="N° et nom de la voie"/>
                                {submittedReg && !user.adresse &&
                                <div className="help-block">Adresse de votre établissement requis</div>
                                }
                            </div>

                            <div
                                className={'inputInfos villeInput col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.ville ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Ville *</label>

                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    highlightFirstSuggestion={true}
                                />
                                {submittedReg && !user.ville &&
                                <div className="help-block">Nom ou Code Postal de la ville requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.telephoneEtablissement ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Téléphone Établissement <span
                                    className="rouge">*</span></label>
                                <input type="text" name="telephoneEtablissement" className="form-control"
                                       value={user.telephoneEtablissement} onChange={this.handleChangeUser}
                                       placeholder="Ne pas mettre d’espace, de point ou de tiret"
                                       />
                                {submittedReg && !lib.isValidPhone(user.telephoneEtablissement) &&
                                <div className="help-block">N° de téléphone de votre établissement requis</div>
                                }
                            </div>
                            <div
                                className={'inputInfos col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group' + (submittedReg && !user.emailEtablissement ? ' has-error' : '')}>
                                <label style={{color: "red"}}>Email <span className="rouge">*</span></label>
                                <input type="text" name="emailEtablissement" className="form-control"
                                       value={user.emailEtablissement} onChange={this.handleChangeUser}/>
                                {submittedReg && !lib.isValidEmail(user.emailEtablissement) &&
                                <div className="help-block">Mauvais format d'Email</div>
                                }
                            </div>

                            <div className="inputInfos col-xs-12 col-sm-6 col-md-4">
                                <label style={{color: "red"}}>Site Internet</label>
                                <input type="text" name="siteEtablissement" className="form-control"
                                       value={user.siteEtablissement} onChange={this.handleChangeUser}/>
                            </div>

                            <div className="question">
                                <p><strong>SPECIFICITES</strong> (Plusieurs choix possibles)</p>
                            </div>
                            <div className="inputInfos col-xs-12">

                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="row">
                                        <input type="checkbox" name="specificite" value="1"
                                               onChange={this.handleChangeSpec}/>
                                        <label>HEBERGEMENT PERMANENT</label>
                                    </div>
                                </div>
                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="row">
                                        <input type="checkbox" name="specificite" value="2"
                                               onChange={this.handleChangeSpec}/>
                                        <label>HEBERGEMENT TEMPORAIRE</label>
                                    </div>
                                </div>
                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="row">
                                        <input type="checkbox" name="specificite" value="3"
                                               onChange={this.handleChangeSpec}/>
                                        <label>ACCUEIL DE JOUR</label>
                                    </div>
                                </div>
                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="row">
                                        <input type="checkbox" name="specificite" value="4"
                                               onChange={this.handleChangeSpec}/>
                                        <label>UNITE ALZHEIMER</label>
                                    </div>
                                </div>
                                <div className="inputCheckboxContent col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="row">
                                        <input type="checkbox" name="specificite" value="5"
                                               onChange={this.handleChangeSpec}/>
                                        <label>AIDE SOCIALE A L’HEBERGEMENT (ASH)</label>
                                    </div>
                                </div>

                            </div>
                            <div
                                className={'inputCheckboxAbonnement col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group' + (submittedReg && !this.state.condition ? ' has-error' : '')}>
                                <input type="checkbox" name="choix" value={true} onChange={this.handleChangeCGU}/>
                                <label>j’accepte les <a href="/conditions-generales">conditions générales
                                    d’utilisation.</a></label>
                            </div>
                            <div style={{width: "304px", height: "78px", display: "table", margin: "0 auto"}}>
                                <div className="g-recaptcha" data-sitekey="6LdhlKoUAAAAABKtxFungDO9i8ZhlsdnFZ9my7ZR"/>
                            </div>
                            <div className="errorFirstStep" style={{color:"red"}}> </div>

                            <div style={{display: "inline-block", float: "left"}}>
                                <div className="bouton annuler">
                                    <a href="/">
                                        Accueil
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="bouton submit"
                                     style={{display:  "inline-block", float: "right", marginTop: 0}}>
                                    <input type="submit" name="_submit" id="valideEtablissement" value="Valider"/>
                                    <div className="error text-danger"> </div>
                                    {registering &&
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        )
    }

    render() {
        const { registering, loggingIn  } = this.props;
        const { user, submittedReg, submittedCon } = this.state;
        return (
            <div style={{minHeight: "100% !important"}}>
                <Navbar/>
                <main>
                    {this._renderConnexion(user.username, user.password, submittedCon, loggingIn)}
                    {this._renderRegister(user, submittedReg, registering)}
                </main>
                <Footer/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { loggingIn, user } = state.authentication;
    const { registering } = state.registration;
    return {
        registering,
        loggingIn,
        user
    };
}

export default connect(mapStateToProps)(Login_Register)
