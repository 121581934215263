import { dispoConstants }    from '../_constants';
import { dispoService }     from '../_services';
import { alertActions }     from './';
import { history } from '../_helpers';

export const dispoActions = {
    getDispo,
    getDispoById,
    postDispo,
    delDispo,
    updateDispo,
};

/****************************/
/*** Get Alert Dispo list ***/
/****************************/

function getDispo(history) {
    return dispatch => {
        dispatch(request());

        dispoService.getAlerteDispo()
            .then(
                dispo => {
                    dispatch(success(dispo));
                    history.push('/administrateur/alerte-dispo');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: dispoConstants.DISPO_REQUEST } }
    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DISPO_FAILURE, error } }
}

/****************************************/
/* Get list of Alert Dispo By user's Id */
/****************************************/

function getDispoById(id) {
    return dispatch => {
        dispatch(request());

        dispoService.getAlerteDispoById(id)
            .then(
                dispo => {
                    dispatch(success(dispo));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: dispoConstants.DISPO_REQUEST } }
    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DISPO_FAILURE, error } }
}

/************************/
/*** Post Alert Dispo ***/
/************************/

function postDispo(dispo, history) {
    return dispatch => {

        dispoService.postAlertDispo(dispo)
            .then(
                result => {
                    if (JSON.parse(localStorage.getItem('user'))) {
                        switch (JSON.parse(localStorage.getItem('user')).user.roles) {
                            case "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}": history.push('/personnes/mes-alertes-dispo'); break;
                            case "a:1:{i:0;s:10:\"ROLE_ADMIN\";}": history.push('/administrateur/alerte-dispo'); break;
                            default: history.push('/');
                        }
                        dispatch(alertActions.success(result.message));
                    } else {
                        history.push('/');
                        dispatch(alertActions.success(result.message));
                    }


                    /*
                    (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || JSON.parse(localStorage.getItem('user')).user.roles == "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                    history.push('/personnes/mes-alertes-dispo')
                    :
                    history.push('/administrateur/alerte-dispo');
                     */
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

//    function request() { return { type: dispoConstants.DISPO_REQUEST } }
//    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DISPO_FAILURE, error } }
}

/**************************/
/*** Delete Alert Dispo ***/
/**************************/

function delDispo(id) {
    return dispatch => {

        dispoService.delAlertDispo(id)
            .then(
                result => {
                    dispatch(alertActions.success(result.message))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

//    function request() { return { type: dispoConstants.DISPO_REQUEST } }
//    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DISPO_FAILURE, error } }
}

/**************************/
/*** Update Alert Dispo ***/
/**************************/

function updateDispo(dispo, history) {
    return dispatch => {

        dispoService.updateAlertDispo(dispo)
            .then(
                result => {
                    (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                        history.push('/personnes/mes-alertes-dispo')
                        :
                        history.push('/administrateur/alerte-dispo');
                    (JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:13:\"ROLE_PERSONNE\";}" || JSON.parse(localStorage.getItem('user')).user.roles === "a:1:{i:0;s:14:\"ROLE_ASSISTANT\";}") ?
                        dispatch(getDispoById(dispo.personnes_id))
                        :
                        dispatch(getDispo());
                    dispatch(alertActions.success(result.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

//    function request() { return { type: dispoConstants.DISPO_REQUEST } }
//    function success(dispo) { return { type: dispoConstants.DISPO_SUCCESS, dispo } }
    function failure(error) { return { type: dispoConstants.DISPO_FAILURE, error } }
}
