import React, {Component} from 'react';
import {connect} from 'react-redux';
import { history } from "../../../_helpers";

import Autosuggest from 'react-autosuggest';

import projectList from '../../../communes/communes.json';
import {withRouter} from "react-router";
import {Alert} from "react-st-modal";

const searchList = [];
projectList.map((item) => {
    item.codesPostaux.map((cp) => {
        searchList.push({
            value: {
                codePostal: cp,
                geoLoc: item.centre,
                nom: item.nom,
                code_insee: item.code,
                departement: item.departement
            },
            label: item.nom
        })
    })
});

const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (Number.isInteger(parseInt(inputValue))) {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.value.codePostal.slice(0, inputLength) === inputValue.toString()
        );
    } else {
        return inputLength < 3 ? [] : searchList.filter(item =>
            item.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    }
};

const getSuggestionValue = suggestion => suggestion.label;

const renderSuggestion = (suggestion, { query, isHighlighted }) => (

    <div><b>{suggestion.label} {suggestion.value.codePostal}</b></div>

);

class Recherche_Accueil extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            suggestions: [],
            codePostal: '',
            ville: '',
            formatted_address: '',
            lat: '',
            lng: '',
            rayon: '',
            distance: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const {codePostal, ville, formatted_address, lat, lng, rayon, distance, value} = this.state;

        if (!value)
            this.props.history.push({
                pathname: '/liste-annonce'
            })
        else if (codePostal && ville && lat && lng && rayon && distance)
            this.props.history.push({
                pathname: '/liste-annonce',
                state: { codePostal, ville, formatted_address, lat, lng, rayon, distance }
            })
        else
            Alert('veuillez choisir une ville dans la liste...')
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = ( event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {

        this.setState({
            value: suggestion.value.nom+" "+suggestion.value.codePostal,
            codePostal: suggestion.value.codePostal,
            ville: suggestion.value.nom,
            lat: suggestion.value.geoLoc.coordinates[1],
            lng: suggestion.value.geoLoc.coordinates[0],
            rayon: '30',
            distance: true
        })

    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: 'Saisir une Ville ou un Code Postal',
            value,
            onChange: this.onChange
        };

        return (
            <section className="banniere">
                <img className="img-responsive" src={"/img/banniere.jpeg"} alt="banniere-accueil"/>
                <div className="container">
                    <p className="accroche">
                        Trouvez les places disponibles dans les Maisons de Retraite proches de chez vous
                    </p>
                    <span className="form ">
                        <form onSubmit={this.handleSubmit.bind(this)} id="formHomeAnnonce" style={{display: 'flex', justifyContent: 'center', alignItem: 'center'}}>
                            <div className="villeInput" >
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    highlightFirstSuggestion={true}
                                />
                            </div>
                            <input id="empty_research" type="submit" value="Trouver" style={{position: 'relative', display: 'inline-block'}}/>
                        </form>
                    </span>
                    <div className="contentBanniere col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <a href="/place-etablissement">
                            <div className="titreBanniere">
                                <p>J'ai une place disponible</p>
                                <p>dans mon établissement</p>
                            </div>
                            <img className="img-responsive"
                                 src="/img/home/banniere/icone-banniere-jai-place.png"
                                 alt="icone-maison"/>
                        </a>
                    </div>
                    <div className="contentBanniere col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <a href="/place-maison">
                            <img className="img-responsive"
                                 src="/img/home/banniere/icone-banniere-je-cherche-place.png"
                                 alt="icone-maison"/>
                            <div className="titreBanniere">
                                <p>Je cherche une place en</p>
                                <p>maison de retraite</p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        //load: state.ui.load,
    }
}

export default withRouter(connect(mapStateToProps)(Recherche_Accueil))
