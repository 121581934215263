import { contactConstants } from '../_constants';
import { contactService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const contactActions = {
    getMessages,
    postMessage,
    delMessage
};

/*************************/
/*** Get Messages list ***/
/*************************/

function getMessages() {
    return dispatch => {
        dispatch(request());

        contactService.getMessages()
            .then(
                list => {
                    dispatch(success(list));
                    //history.push('/admin');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}

/********************/
/*** Post Message ***/
/********************/

function postMessage(nom, prenom, telephone, entite, email, message) {
    return dispatch => {
        dispatch(request());

        contactService.postMessage(nom, prenom, telephone, entite, email, message)
            .then(
                resp => {
                    //history.push('/admin');
                    dispatch(alertActions.success(resp.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}

/*************************/
/*** Del Messages list ***/
/*************************/

function delMessage(id, history) {
    return dispatch => {
        dispatch(request());

        contactService.delMessage(id)
            .then(
                list => {
                    dispatch(success(list));
                    history.push('/administrateur/mes-messages');
                    dispatch(alertActions.success("message supprimé"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: contactConstants.MESSAGES_REQUEST } }
    function success(list) { return { type: contactConstants.MESSAGES_SUCCESS, list } }
    function failure(error) { return { type: contactConstants.MESSAGES_FAILURE, error } }
}
