import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const user = JSON.parse(localStorage.getItem('user'));

export const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).user.roles == "a:1:{i:0;s:10:\"ROLE_ADMIN\";}"
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/404_Forbidden_Adm', state: { from: props.location } }} />
    } />
)